import {Circle as CircleStyle, Fill, Text, Stroke, Style} from "ol/style";

const keyName = 'mappings.js';
export default (feature, resolution) => {
    if (window.cssOn) console.debug(`${keyName} css`, feature);
    const props = feature.getProperties()
    const specId = props.spec_id || props.meeting_spec;
    //  const specId = feature.getProperties().spec_name;
    const radius = 4;
    let color = [255, 255, 255, 1];
    let text = feature.getProperties().spec_name;
    // const colors = {
    //     'Косуля': [144, 255, 144, 1],
    //     'Кабан': [224, 255, 50, 1],
    //     'Бурый медведь': [230, 170, 20, 1],
    //     'Лось': [245, 105, 105, 1],
    //     'Речной бобр': [255, 105, 215, 1],
    //     'Волк': [125, 125, 125, 1]
    // };
    switch (specId) {
        case 8801:
            color = [144, 255, 144, 1];
            text = 'Кс';
            break;
        case 8804:
            color = [224, 255, 50, 1];
            text = 'Ка';
            break;
        case 8818:
            color = [230, 170, 20, 1];
            text = 'Ме';
            break;
        case 8800:
            color = [245, 105, 105, 1];
            text = 'Ло';
            break;
        case 8842:
            color = [255, 105, 215, 1];
            text = 'Бо';
            break;
        case 8805:
            color = [125, 125, 125, 1];
            text = 'Во';
            break;
        case 8862:
            color = [255, 150,150, 1];
            text = 'Бе';
            break;
        case 0:
            color = [, ,, 1];
            text = '';
            break;
    }
    const w = new Style({
        image: new CircleStyle({
            radius: radius + 1,
            fill: new Fill({
                color: [0, 0, 0, 1],
            }),
        }),
    });
    const p = new Style({
        image: new CircleStyle({
            radius: radius,
            fill: new Fill({
                color: color || [100, 100, 255, 1],
            }),
        }),
        text: new Text({
            text: text,
            offsetX: 10,
            offsetY: -10,
            stroke: new Stroke({
                color: color,
                width: 1
            })
        }),
    });
    return [w, p];
}