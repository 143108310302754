import {Circle as CircleStyle, Stroke, Style} from "ol/style";

const keyName='base.js';
export default (feature,resolution) => {
 if (window.cssOn) console.debug(`${keyName} css`,feature);
    const Point = new Style({
        image: new CircleStyle({
            radius: 5,
            fill: null,
            stroke: new Stroke({color: 'red', width: 1}),
        }),
    });
    const Polygon = new Style({
        stroke: new Stroke({
            color: [0, 150, 255, 1],
            width: 2
        })
    });
    const LineString = new Style({
        stroke: new Stroke({
            color: [0, 255, 150, 1],
            width: 4
        })
    });
    return {
        'Point': Point,
        'Polygon': Polygon,
        'MultiPolygon': Polygon,
        'LineString': LineString
    }[feature.getGeometry().getType()];
}