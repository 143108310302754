import React from "react";
import ErrorBoundary from "../../../ErrorBoundary";
import * as f from "../../../common/Funcs";
import BaseZMUGrid from "../../../Base/ZMU/BaseZMUGrid";
import {GridField,ActiveFunc} from "../../../collections/GridField";

const keyName = 'PassagesGrid';

// export default class PassagesGrid extends BaseByMapGrid {
export default class PassagesGrid extends BaseZMUGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'DocumentPage';
        context.tableName = `routes`;
        context.pageSize = 150;
        context.idName = 'passage_id';
        context.hiddenColumns = ['passage_id'];
        context.columnsDefaults = [
            {
                column_name: 'isvalid_state',
                data_type: 'custom',
                ignorePrint: true,
                ignoreExport: true,
                className: 'rotate-header align-center',
                renderer: (params) => {
                    let {value, record, type, prm} = params;
                    const clickFunc = (k) => {
                        f.callPath({
                            context,
                            path: `zmu/passages/${record.data['passage_id']}/passed`,
                            id: '',
                            callback: (result) => {
                                context.getData({context});
                            }
                        });
                    };
                    if ( f.getRoles() && f.getRoles().filter(a => a == 10 || a == 11).length > 0) {
                        switch (value) {
                            case true:
                                return <div key={'validPassed'} onClick={() => clickFunc()}><span
                                    className={'active-column is-grid-button fa fa-check-circle-o'}/></div>;
                            case false:
                                return <div key={'validPassed'} onClick={() => clickFunc()}><span
                                    className={'active-column is-grid-button fa fa-times-circle-o'}/></div>;
                            default:
                                return <div key={'validPassed'} onClick={() => clickFunc()}><span
                                    className={'active-column is-grid-button fa fa-circle-thin'}/></div>;
                        }
                    } else {
                        return ActiveFunc(value);
                    }
                },
                maxWidth: 80,
                pos: 10
            },
            {column_name: 'route_id', hidden: true},
            {
                column_name: 'route_name', pos: 20, maxWidth: 100,
                data_type: 'custom',
                renderer: (params) => {
                    const clickFunc = (k) => {
                        context.props.parent.setState({subElementName: 'PasAllPage'},
                            () => {
                                context.showEditPanel(context, true);
                            });
                    };
                    return <div key={'route_name'} onClick={() => clickFunc()}><span
                        className="link">{params.value}</span></div>;
                },
            },
            {column_name: 'passage_num', className: 'rotate-header align-center', pos: 21, maxWidth: 50},
            //            {
            // /*               column_name: 'common_page',
            //                data_type: 'custom',
            //                ignorePrint: true,
            //                ignoreExport: true,
            //                className: 'rotate-header align-center',
            //                renderer: (params) => {
            //                    params.context = context;
            //                    context.tableName = 'passages';
            //                    params.subElementName = 'PassagePage';
            //                    params.iconCls = 'x-fa fa-file';
            //                    return context.renderCallEditForm(params);
            //                },
            //                maxWidth: 80,
            //                pos: 22
            //            },{*/
            //            column_name: 'tables_page',
            //            data_type: 'custom',
            //            ignorePrint: true,
            //            ignoreExport: true,
            //            className: 'rotate-header align-center',
            //                hidden: ()=>!Boolean(document.getCookie('isAuth').slice(3,-1).split(',').find(r=>r==10||r==11)),
            //            renderer: (params) => {
            //                params.context = context;
            //                params.tableName = 'pas_animal_data';
            //                params.subElementName = 'TablesPage';
            //                params.iconCls = 'x-fa fa-table';
            //                params.text='встречи';
            //                return context.renderCallEditForm(params);
            //            },
            //            maxWidth: 80,
            //            pos: 23
            //        },
            //            {
            //            column_name: 'animals_page',
            //            data_type: 'custom',
            //            ignorePrint: true,
            //            ignoreExport: true,
            //            className: 'rotate-header align-center',
            //            renderer: (params) => {
            //                params.context = context;
            //                context.tableName = 'passage_points';
            //                params.subElementName = 'PointsPage';
            //                params.iconCls = 'x-fa fa-map-marker';
            //                params.text='следы';
            //                return context.renderCallEditForm(params);
            //            },
            //            maxWidth: 80,
            //            pos: 24
            //        },
            {column_name: 'passage_date', pos: 35},
            {column_name: 'ea_name', pos: 40, width: 200},
            {column_name: 'erase', pos: 50},
            {column_name: 'blankfilling_state', pos: 60},
            {column_name: 'speciesfilling_state',
                hidden: ()=>!f.isCommittee(),
                pos: 70},
            {column_name: 'pointsfilling_state', pos: 80},
            {column_name: 'passed', pos: 90},
            {column_name: 'accepted', pos: 100},
            {column_name: 'isvalid_state', pos: 110},
            {column_name: 'iserasegpx', pos: 120},
            {column_name: 'ispasgpx', pos: 130},
            {column_name: 'pasrights', pos: 140, hidden: true},
        ]
    }


    //кастомизация результатов с сервереа
    upResult(context, result) {
        //поворот заголовков таблицы
        result.columns = result.columns.map(c => {
            if (c.data_type == 'boolean') {
                if (!f.exist(c.className)) c.className = 'rotate-header';
            }
            return c;
        });
        /*
                result.columns.push({
                    column_name: 'common_page',
                    data_type: 'custom',
                    ignorePrint: true,
                    ignoreExport: true,
                    className: 'rotate-header align-center',
                    renderer: (params) => {
                        params.context = context;
                        context.tableName = 'passages';
                        params.subElementName = 'PassagePage';
                        params.iconCls = 'x-fa fa-file';
                        return context.renderCallEditForm(params);
                    },
                    maxWidth: 80,
                    pos: 22
                });
                result.columns.push({
                    column_name: 'tables_page',
                    data_type: 'custom',
                    ignorePrint: true,
                    ignoreExport: true,
                    className: 'rotate-header align-center',
                    renderer: (params) => {
                        params.context = context;
                        params.tableName = 'pas_animal_data';
                        params.subElementName = 'TablesPage';
                        params.iconCls = 'x-fa fa-table';
                        return context.renderCallEditForm(params);
                    },
                    maxWidth: 80,
                    pos: 23
                });
                result.columns.push({
                    column_name: 'animals_page',
                    data_type: 'custom',
                    ignorePrint: true,
                    ignoreExport: true,
                    className: 'rotate-header align-center',
                    renderer: (params) => {
                        params.context = context;
                        context.tableName = 'passage_points';
                        params.subElementName = 'PointsPage';
                        params.iconCls = 'x-fa fa-map-marker';
                        return context.renderCallEditForm(params);
                    },
                    maxWidth: 80,
                    pos: 24
                });
        result.columns=result.columns.sort((a, b) => {
            return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
        });*/
        return result;
    }

    //id выделенной строки
    getCurrentId() {
        const context = this;
        const idName = (context.props.parent?.state?.subElementName == 'RoutePage') ? 'route_id' : 'passage_id';
        try {
            return context.getCurrentRow()[idName];
        } catch (err) {
            return null;
        }
    }

    //кнопка вызова окна общей информации
    renderCallEditForm(params) {
        let {record, cell, column_name, column_label, prm, subElementName, context, iconCls} = params;
        const clickFunc = (k) => {
            context.props.parent.setState({subElementName: subElementName}, () => {
                context.showEditPanel(context, true);
            });
        };

        return <div key={column_name} onClick={() => clickFunc()}><span className="link">{params.text}</span></div>;
//        return <div key={column_name} onClick={() => clickFunc()}><span className={iconCls || ''}/></div>;

    };

    getHeight() {
        return f.getCentralHeight() - ((!this.paging) ? 40 : 70);
    }
}