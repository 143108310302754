import * as f from "../../../common/Funcs";
import BaseEditPage from "../../../Base/BaseEditPage";

const keyName = "PassCommentPage";
export default class PassCommentPage extends BaseEditPage {
    static defaultProps = {
        width: '50%',height:'50px'
    }

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'pass_comments';
        context.columnsDefaults = [...context.columnsDefaults, ...[
            {column_name: "passage_id", hidden: true},
            {column_name: "last_user", hidden: true},
            {column_name: "last_modified", hidden: true},
            {
                column_name: "row_id",
                hidden: true,
            },
            {
                "column_name": "comment",data_type:'text',height:'150px'
            }]];
        context.footerButtonNames = ['saveclose', 'save','delete', 'close'];

    }
}


