import React, {Component} from 'react';
import {Button, MenuItem, Menu} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";
import * as siteFuncs from "../common/siteFunctions";
import ErrorBoundary from "../ErrorBoundary";

export class label extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const context = this;
        return <span>{context.props.text}</span>
    }
}

/*типовая кнопка перехода на другую страницу, с возможностью выполнить функцию до перехода*/
export class apage extends Component {
    static defaultProps = {
        name: "undefined",
        style: {height: 'auto'}
    }

    render() {
        const context = this;
        /*функция для выполнения*/
        const handler = (...event) => {
            if (event[1].ctrKey) {
                const url = window.location.origin + '?page=' + context.props.element_url;
                window.open(url);
            }
            if (context.props.element_func) {
                const func = (typeof (context.props.element_func) == 'string') ?
                    new Function('args', 'callback', context.props.element_func) :
                    context.props.element_func;
                func(null, () => {
                    if (context.props.element_url)
                        context.props.appViewPort.rePage.call(context.props.appViewPort, context.props.element_url, context.props.role_rights);
                });
            } else if (context.props.element_url)
                context.props.appViewPort.rePage.call(context.props.appViewPort, context.props.element_url, context.props.role_rights);
        };
        const btn = <Button
            handler={handler}
            pressed={Boolean(context.props.pressed)}
            text={context.props.text}
            data-qtip={context.props.text}
            data-qshowOnTap="true"
            textAlign={'left'}
            ui={window.IasConfig.ui}
            key={context.props.name}
            name={context.props.name}
            style={context.props.style}>
        </Button>;
        return (btn);
    }
}

/*типовая кнопка перехода на другую страницу, с возможностью выполнить функцию до перехода*/
export class abutton extends Component {
    static defaultProps = {
        name: "undefined",
        style: {height: 'auto'}
    }

    constructor(props) {
        super(props);
        const context=this;
        let text = props.text;
        if (['closePlan', 'closeAcc'].indexOf(props.element_func) > -1) {
            window.IasLoApp[props.element_func] = context;
            switch (props.element_func) {
                case 'closeAcc':
                    context.param='passage_closed';
                    break;
                default:
                    context.param='plan_closed';
                    break;
            }
            text = (context.props.closed) ? ('Закрыть ' + text) : ('Открыть ' + text);
        }
        context.props.parent.buttons[context.param]=context;
        context.state={
            text:text,
            closed:context.props.closed
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const context=this;
        if (prevState.closed!=context.state.closed) {}
    }

    render() {
        const context = this;
        /*функция для выполнения*/
        const handler = () => {
            if (context.props.element_func) {
                const func = siteFuncs[context.props.element_func];
                func(context);
            }
        };
        let text = context.props.text;
        text=(context.state.closed) ?  ('Открыть ' + text):('Закрыть ' + text);
        console.log(text);
        const btn = <Button
            handler={handler}
            pressed={context.props.pressed}
            text={text}
            data-qtip={ text}
            data-qshowOnTap="true"
            textAlign={'left'}
            ui={window.IasConfig.ui}
            key={context.props.name}
            name={context.props.name}
            style={context.props.style}>
        </Button>;
        return (btn);
    }
}

/*типовая кнопка menu*/
export class amenu extends Component {
    static defaultProps = {
        name: "undefined",
        style: {height: 'auto'},
        items: []
    }

    render() {
        const context = this;
        const btn = <Button
            text={context.props.text}
            cls={'user-cabinet'}
            pressed={context.props.pressed}
            data-qtip={context.props.text}
            data-qshowOnTap="true"
            textAlign={'left'}
            ui={window.IasConfig.ui}
            key={context.props.name}
            name={context.props.name}
        >
            <Menu>
                {context.props.items}
            </Menu>
        </Button>;
        return (btn);
    }
}

/*типовая кнопка перехода на другую страницу, с возможностью выполнить функцию до перехода*/
export class amenuitem extends Component {
    static defaultProps = {
        name: "undefined",
        style: {height: 'auto'}
    }

    render() {
        const context = this;
        /*функция для выполнения*/
        const handler = (event) => {
            if (context.props.element_func) {
                const func = (typeof (context.props.element_func) == 'string') ?
                    new Function('args', 'callback', context.props.element_func) :
                    context.props.element_func;
                func(null, () => {
                    if (context.props.element_url)
                        context.props.appViewPort.rePage.call(context.props.appViewPort, context.props.element_url, context.props.role_rights);
                });
            } else if (context.props.element_url)
                context.props.appViewPort.rePage.call(context.props.appViewPort, context.props.element_url, context.props.role_rights);
        };
        const btn = <Button
            handler={handler}
            pressed={context.props.pressed}
            text={context.props.text}
            data-qtip={context.props.text}
            data-qshowOnTap="true"
            textAlign={'left'}
            ui={window.IasConfig.ui}
            key={context.props.name}
            name={context.props.name}
            style={context.props.style}/>;
        return (btn);
    }
}

/*типовой элемент меню*/
export class menuitem extends Component {
    static defaultProps = {
        name: "undefined",
        style: {height: 'auto'},
        items: []
    }

    render() {
        const context = this;
        const btn = <MenuItem
            handler={context.props.handler}
            text={context.props.text}
            data-qtip={context.props.text}
            data-qshowOnTap="true"
            textAlign={'left'}
            ui={window.IasConfig.ui}
            key={context.props.name}
            name={context.props.name}>
        </MenuItem>;
        return (btn);
    }
}

/*типовая кнопка вызова стороннего приложения в новом окне*/
export class aurl extends Component {
    static defaultProps = {
        name: "undefined",
        style: {height: 'auto'}
    }

    constructor(props) {
        super(props);
    }

    render() {
        const context = this;
        const handler = () => {
            if (context.props.element_func) {
                const func = (typeof (context.props.element_func) == 'string') ?
                    new Function('args', 'callback', context.props.element_func) :
                    context.props.element_func;
                func(null, () => {
                    if (context.props.element_url)
                        window.open(context.props.element_url);
                });
            } else if (context.props.element_url)
                window.open(context.props.element_url);
        };
        const btn = <Button
            style={context.props.style}
            handler={handler}
            key={context.props.name}
            name={context.props.name}
            text={context.props.text}
            tooltip={context.props.text}
            textAlign={'left'}
            ui={window.IasConfig.ui}
        />
        return (btn);
        // return (<Button handler={handler}>{context.props.text}</Button>)
    }
}

/*типовой элемент вызова в отдельном окне*/
export class awindow extends Component {
    static defaultProps = {
        name: "undefined",
        style: {height: 'auto'}
    }

    constructor(props) {
        super(props);
    }

    render() {
        const context = this;
        const handler = () => {
            if (context.props.element_func) {
                const func = (typeof (context.props.element_func) == 'string') ?
                    new Function('args', 'callback', context.props.element_func) :
                    context.props.element_func;
                func(null, () => {
                    if (context.props.element_url)
                        context.props.appViewPort.rePage.call(context.props.appViewPort, context.props.element_url);
                });
            } else if (context.props.element_url)
                context.props.appViewPort.rePage.call(context.props.appViewPort, context.props.element_url);
        };
        const btn = <Button
            style={context.props.style}
            handler={handler}
            key={context.props.name}
            name={context.props.name}
            text={context.props.text}
            ui={window.IasConfig.ui}
        />
        return (btn);
    }
}

