import ErrorBoundary from "../../../ErrorBoundary";
import React from "react";
import BaseByMapGrid from "../../../Base/BaseByMapGrid";
import * as f from "../../../common/Funcs";
import * as z from "../../../common/zmuFuncs";
import BaseSubGrid from "../../../Base/BaseSubGrid";
import style from "../../../Maps/styles/ea";

const keyName = 'EaRoutesSubGrid';

export default class EaRoutesSubGrid extends BaseSubGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'DocumentPage';
        context.pageSize=1500;
        context.tableName = `earoutes`;
        context.idName = `gid`;
        if (props.styleFunction) context.styleFunction = props.styleFunction; else context.styleFunction = (feature, resolution) => {
            return style(feature, resolution, context);
        };
    }

    afterGetData(context, result){
        const olMap = context.props.parent.props.parent.olMap;
        if (olMap) {
            const features = result.data.filter(r => r.geom).map(r => {
                const props = {...r};
                delete props.geom;
                props.source = context.tableName;
                return {
                    type: 'Feature',
                    properties: props,
                    geometry: JSON.parse(r.geom)
                }
            })
            olMap.clearFeatures({layerName:context.tableName,  context: olMap});
            olMap.addFeatures({geoJson: features, context: olMap,layerName:context.tableName,styleFunction:context.styleFunction});
            // olMap.addFeatures({geoJson: features,layerName:context.tableName, context: olMap});
        }    }

    getHeight() {
        const context=this;
        const itemsCount = context.state.data.length;
        const maxHeight=window.innerHeight/2;
        if (itemsCount == 1&&context.state.singleMode) return '100%';
        const result= (1+itemsCount) * context.props.rowHeight+30;
        return result;
    }

}