import BaseGridPage from "../../Base/BaseGridPage";

const keyName = `ContactsPage`;
export default class ContactsPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.title=`список контактов`;
        context.gridName = "ContactsGrid";
        context.subElementName = 'ContactPage';
        context.tableName = 'contacts';
        context.idName = 'contactid';
        context.hiddenColumns = [context.idName];
        context.topMenuButtonNames = ['reload', 'xls', 'print', 'add', 'clipboard'];
        context.filters = [
            {name: 'ac',pos:1, required: false,defaultValue:true},
            {name: 'users',pos:2, required: false},
            {
            name: 'contactorg',pos:3,
            required: false
        }, {name: 'fio',pos:4, required: false},
            {name: 'page',pos:60, required: false}];
    }

}