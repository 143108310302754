import React from 'react';
import {ExtGrid, LockedGrid, Panel,} from '@sencha/ext-react-modern';

import * as f from "../../../common/Funcs";
import GridField from "../../../collections/GridField";
import ErrorBoundary from "../../../ErrorBoundary";
import BasePageGrid from "../../../Base/BasePageGrid";

const keyName = `ZmuAdminGrid`;
const Ext = window['Ext'];
Ext.require('Ext.grid.plugin.Summary');

export default class ZmuAdminGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        context.rowNumberWidth = 40;
        const storeProps = {
            data: context.state.data,
            onDataChanged: (event) => {
                debugger;
            }
        };
        context.store = Ext.create('Ext.data.Store', storeProps);
        context.columnsDefaults = [
            {"column_name": "row_id", hidden: true},
            {"column_name": "spec_name", pos: 0}, {
                "column_name": "isbird", column_label: 'птица', data_type: 'check',
                className: 'rotate-header align-center',
                pos: 1
            }, {
                "column_name": "disable", column_label: 'не учитывается в году', data_type: 'check',
                className: 'rotate-header align-center',
                pos: 1
            }, {"column_name": "table_num", column_label: 'N таблицы', pos: 2}
            , {"column_name": "column_num", column_label: '№ колонки', pos: 3}
            , {"column_name": "pos_in_tables", column_label: '№ строки', pos: 4}
            , {"column_name": "k", column_label: 'коэффициент', pos: 5}];

    }

    testOnSave(context, callback) {
        /*контроль несохраненных данных*/
        if (context.state.modified) {
            f.confirm({
                title: 'Внимание, не все данные сохранены.',
                message: 'Сохранить данные перед обновлением страницы?'
            }, (result) => {
                if (result) {
                    context.props.parent.saveFunc(context.props.parent, () => (callback) ? callback(context) : '');
                } else if (callback) callback(context);
            })
        } else if (callback) callback(context);
    }

    afterEdit(context, sender, location, eOpts) {
        //признак измененной записи
        location.record.data.modified = true;
    }

    beforeEdit(context, sender, location) {
        //признак измененной записи
        const allow = f.isEditRight(location.record.data.acc_rights);
        if (allow) {
            location.record.data.modified = true;
            context.setState({modified: true});
        }
        return allow;
    }

    getHeight() {
        return f.getCentralHeight() - f.getInnerHeightMenu();
    }

    getData(params) {
        const {context} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName(), params);
        context.testOnSave(context, () => super.getData(params));
    }

    afterGetData(context, upData) {
        context.isLoaded = true;
        if (!upData.vdata || upData.vdata.length == 0) context.store.clearData();
        if (Number(document.getCookie('yearFilter'))) upData.columns.map(c => c.editable = true);
        // upData.data=upData.vdata;
        context.setState(upData, () => {
            context.loadPagingData(context, upData.vdata)
        });
    }

    testOnSave(context, callback) {
        /*контроль несохраненных данных*/
        if (context.state.modified) {
            f.confirm({
                title: 'Внимание, не все данные сохранены.',
                message: 'Сохранить данные перед обновлением страницы?'
            }, (result) => {
                if (result) {
                    context.props.parent.saveFunc(context.props.parent, () => (callback) ? callback(context) : '');
                } else if (callback) callback(context);
            })
        } else if (callback) callback(context);
    }

    getGrid(context, columns) {
        const plugins = {};
        return <ErrorBoundary key={`${keyName}err`}><ExtGrid
            layout={'fit'}
            key={keyName + 'grid'}
            cls={'acc-grid'}
            name={keyName}
            height={context.getHeight()}
            maxHeight={context.getMaxHeight(context)}
            rowHeight={context.props.rowHeight}
            rowNumbers={{
                width: context.rowNumberWidth,
                docked: 'left'
            }}
            plugins={plugins}
            platformConfig={{
                '!desktop': {
                    plugins: {
                        grideditable: true,
                    }
                },
                desktop: {
                    plugins: {
                        gridcellediting: true,
                    }
                }
            }}
            itemConfig={context.getItemConfig(context)}
            store={context.store}
            ref={grid => context.grid = grid}
            variableHeights={false}
            listeners={{
                columnmenucreated(grid, column, menu, eOpts) {
                    menu.on('beforeshow', (sender) => {
                        sender.items.items.map(i => {
                            if (i.menu)
                                i.menu.getItems().items.map(c => {

                                    //!!добавить поля
                                    if (['acc_rights',
                                        'region_name',
                                        'huntuser_name',
                                        'acc_year',
                                        'acc_region',
                                        'acc_huntuser'
                                    ].indexOf(c.column._dataIndex) > -1) c.hide();
                                });
                            f.localeMenu(i);
                        });
                    });
                },
                edit: (sender, location, eOpts) => {
                    context.afterEdit(context, sender, location, eOpts)
                },
                beforeedit: (sender, location) => {
                    context.beforeEdit(context, sender, location)
                }
            }}
        >
            {columns}
        </ExtGrid></ErrorBoundary>
    }

    getColumn(context, c) {
        const hidden = ((context.hiddenColumns && context.hiddenColumns.indexOf(c.column_name) > -1) || c.hidden || c.column_name == 'order_value');
        const isNum = (c.data_type == 'integer' || c.data_type == 'numeric');
        if (isNum) {
            c.width = 60;
            c.className = 'rotate-header'
        }
        c.minWidth = c.width;
        c.maxWidth = c.width;
        return <GridField
            key={c.column_name}
            column_name={c.column_name}
            column_label={c.column_label}
            // scrollDock={(c.docked)?'start':'end'}
            // docked={c.docked}
            // scrollable={(c.docked)}
            flex={c.flex}
            readonly={c.readonly}
            hidden={hidden}
            className={c.className}
            getColor={c.getColor}
            colored={c.colored}
            parent={context.props.parent}
            grid={context}
            relation={c.relation}
            title={c.title}
            align={c.align}
            ignoreExport={c.ignoreExport}
            editable={c.editable}
            data_type={c.data_type}
            width={c.width}
            minWidth={c.minWidth}
            maxWidth={c.maxWidth * ((context.state?.data?.length > 10) ? 0.95 : 1)}
            ignorePrint={c.ignorePrint}
        />
    }

    render() {
        // if (window.IasConfig.devMode) console.debug(`${keyName} render`);
        const context = this;
        const columns = [];
        const content = [];
        if (!context.inload) {
            const loadedtext = context.emptyMessages(context.state.error);
            if (loadedtext) content.push(loadedtext);
            if (context.isLoaded && !loadedtext && context.state.columns) {
                window.unmask();
                /*заполнение полей для store*/
                context.store.fields = context.state.columns.filter(c => c.column_name !== context.idName);
                /*обработка ячейки с признаком редакции*/
                context.state.columns.sort((a, b) => (a.pos == null) ? 1 : a.pos - b.pos).map(c => {
                    //const column = context.getExtColumn(c);
                    const column = context.getColumn(context, c);
                    /*форматирование ячеек*/
                    columns.push(column);
                });
            }
            const itemConfig = (context.tpl) ? {
                body: {
                    tpl: context.tpl()
                }
            } : {};
            //тело таблицы
            if (columns.length > 0) {
                const grid = context.getGrid(context, columns);
                content.push(grid);
                window.testGrid = grid;
                // content.push(context.getPaging(context, columns));
            }
        } else {
            window.mask();
        }
        //панель инструментов гриды. Внутреннее меню страницы - в base...page
        content.push(context.getToolBar());

        return (<Panel key={keyName + 'key'}
                       ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >{content}</Panel>)
    }
}
