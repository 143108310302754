import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import BaseRelationSub from "../Base/BaseRelationSub";
import {Button} from "@sencha/ext-react-modern";
import Subs from "../collections/subElements";
import DialogForm from "../common/DialogForm";

const keyName = `NamesakeSub`;

export default class  NamesakeSub extends BaseRelationSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.topMenuButtonNames=(props.topMenuButtonNames)?props.topMenuButtonNames: ['reload'];
        context.gridName= `NamesakeSubGrid`;
        context.idName =  'contact_id';
        context.noSingle=true;
        // context.hiddenColumns=props.hiddenColumns ||[context.idName];
        context.activeColumn = 'contact_active';
        context.subElementName = props.subElementName;
        // context.columnsDefaults = props.columnsDefaults || [];
        // context.relColumnsDefaults = props.relColumnsDefaults || [];
    }


}

