import React from 'react';
import BasePassageTableSub from "../../Base/ZMU/BasePassageTableSub";

const keyName = `PointBirdSub`;

export default class  PointBirdSub extends BasePassageTableSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.subElementName = 'BirdPage';
        context.gridName= `BirdsGrid`;
    }


}

