import BaseEditPage from "../../../Base/BaseEditPage";
import * as f from "../../../common/Funcs";
import React from "react";

const keyName = "EventPage";
export default class EventPage extends BaseEditPage {
    static defaultProps = {
        width: '98%',
        allowNull: true
    }

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'events';
        context.idName = 'row_id';
        context.hiddenColumns = ['row_id', 'acc_year']
        context.columnsCount = 1;
        context.footerButtonNames = ['save', 'saveclose', 'delete', 'close'];
        context.topMenuButtonNames = ['print', 'reload'];

        context.fieldsSet = [
            {
                title: 'Мероприятие', fieldset: 'event',
                column: 0, pos: 1, block: 1
            },
        ];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {
            column_name: "row_id",
            hidden: true
        }, {
            column_name: "event_date",
            row: 1,
            pos: 2,
            defaultValue: new Date(),
            "required": true,
            "max_length": null,
            "is_pkey": false,
            is_list: false
        }, {
            column_name: "event_obj_type",
            row: 1, pos: 11,
        },
            {
                column_name: "event_object",
                row: 1,
                pos: 3,
                "required": true,
            }, {
                column_name: "event_desc",
                row: 2,
                pos: 5,
                "data_type": "text",
            }, {
                column_name: "event_comment",
                row: 3,
                pos: 6,
                "data_type": "text",
            }, {
                column_name: "isviolation",
                row: 4,
                pos: 4,
            }, {
                column_name: "result_comment",
                row: 4,
                pos: 7,
            }, {
                column_name: "event_oktmo",
                row: 5,
                pos: 8,
                is_list: true,
                source: {
                    tableName: 'regions',
                    titleField: 'region_name',
                    idField: 'oktmo',
                }
            }, {
                column_name: "event_huntuser",
                row: 5,
                pos: 10,
                is_list: true,
                source: {
                    tableName: 'hunt_users',
                    titleField: 'user_name',
                    idField: 'huntuser_id',
                    filterString: "date_on<'$event_date$'"

                }
            }, {
                column_name: "event_ea",
                hidden: true
            }]];
        context.subTables = [];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        if (!context.props.parent.state.showEditPanel && context.updated) context.updated = false;
        if (context.props.parent.state.showEditPanel && !context.updated) {
            context.updated = true;
            if (context.elements?.ZmuEaSingleMap)
                context.elements.ZmuEaSingleMap.getData({context: context.elements.ZmuEaSingleMap});
        }
    }

    //есть ли права на запиcь
    isEditRight() {
        const context = this;
        const rights = context.state.rights || context.props.rights || document.getCookie('rights');
        return f.isEditRight(rights);
    }

    //есть ли права на добавление
    isAddRight() {
        const context = this;
        const rights = context.state.rights || context.props.rights || document.getCookie('rights');
        return f.isEditRight(rights);
    }

    //есть ли права на удаление
    isDropRight() {
        const context = this;
        const rights = context.state.rights || context.props.rights || document.getCookie('rights');
        return f.isDropRight(rights);
    }


}


