import React from 'react';
import BaseGrid from "../Base/BaseGrid";
import * as f from "../common/Funcs";
import {Panel, Toolbar} from "@sencha/ext-react-modern";
import {CloseButton, SaveButton, SaveCloseButton} from "../common/Buttons";

const keyName = `BaseRelationGrid`;

export default class BaseRelationGrid extends BaseGrid {

    constructor(props) {
        super(props);
        const context = this;
        context.paging = false;
        context.height = props.height || f.getCentralHeight() * 0.8;
        context.rowId = context.props.rowId;
        // context.rowId = context.props.rowId;

        f.getCentralHeight()
        context.rowNumberWidth = '40px';
        context.parent = context.props.parent;
        context.footerButtonNames = ['saveclose', 'save', 'close'];
        context.columnsDefaults=[
            {column_name: "active", column_label:' ',pos: 0},
        ];
        context.state = {
            vdata: (context.props.data) ? context.props.data : [],
            data: (context.props.data) ? context.props.data.slice(0, window.IasConfig.relationPageSize) : [],
            columns: [],
            title: 'Started',
            singleMode: false,
        };
    }

    componentDidMount = () => {
        const context = this;
            context.getData({context});
    }

    componentDidCatch(error, errorInfo) {
        console.warn(error,errorInfo);
    }

    //применить фильтры грида к данным и сбрасываем страницу

    applyFilter(context) {
            const filters = context.store.getFilters().items;
            const sorters = context.store.getSorters().items;
            let data = context.state.vdata;
            filters.map(f => {
                data = data.filter(r => {
                    if (f.fn) return f.fn(r);
                   if (f._filterFn) return f._filterFn(r);
                });
            });
            data.sort((a, b) => {
                //перебираем сортировки. если первая дает ==, применяем следующую
                return sorters.reduce((z, s) => {
                    if (z) return z;
                    const x = a[s.config.property];
                    const y = b[s.config.property];
                    const direction = (s.config.direction == 'ASC') ? 1 : -1;
                    return (x < y) ? -1 * direction :
                        (x > y) ? 1 * direction :
                            0;
                }, null);
            });
        const dataCount = Math.ceil(data.length / window.IasConfig.relationPageSize);
        if (JSON.stringify(data.map(d => Number(d[context.props.idName]))) == JSON.stringify(context.state.data.map(d => Number(d[context.props.idName])))) return;
        if (context.paging) context.paging.setState({pageCurrent: 1, pageCount: dataCount});
        context.setState({data: data}, () =>
            context.loadPagingData(context, data));
    }

    // //загрузить данные источника в грид
    // loadPagingData(context, data) {
    //     const dataCount = Math.ceil(data.length / window.IasConfig.relationPageSize);
    //     context.store.loadRawData(data.slice(0, window.IasConfig.relationPageSize), true);
    // }

    getData(params) {
        const {context,callback,activeColumn=''}=params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        const parent=context.props.parent;
        const panel=context.props.panel;
        const viewName = context.props.tableName || context.tableName;
        // let filterString=(activeColumn&&(context.props.parent.state.showAll==false))?`${activeColumn}=true`:'';
        context.inload=true;
        f.getRelData(
            {
                context: context.props.appViewPort, viewName: viewName, id: context.props.rowId,
                // context: context.props.appViewPort, viewName: viewName, id: context.props.rowId,
                // filterString:filterString,
                values:{pageCurrent:panel.state.pageCurrent,pageSize:panel.state.pageSize,search:panel.state.search,ac:(parent.activeColumn&&parent.state.showAll==false)},
                callback:
                    (result) => {
                        if (window.IasConfig.devMode) console.debug(`${keyName} getViewData getted`, `result ${result}`);
                        context.inload=false;
                        //добавляем колонку редактирования, если её нету в списке и родитель выдал права на редактирование
                        result.columns = result.columns.map(c => {
                            // c.flex={grow:2};
                            if (context.columnsDefaults) {
                                const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                                c = {...c, ...def};
                            }
                            if (c.column_name == context.props.relation.parentField) {
                                c.pos = 1;
                                c.editable = true;
                                c.relation = true;
                                c.width=50;
                            }
                            return c;
                        }).sort((a, b) => {
                            return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                        });
                         context.isLoaded = true;
                         context.setState({'title': 'updated', columns: result.columns,vdata:result.data},()=>{
                             context.store.loadRawData(result.data);
                             if (callback) callback(result);
                         });
                       // const upData = {columns: result.columns, data: result.data};
                   //     context.afterGetData(context,upData);

                    }
            })
    }

    getHeight() {
        const result = f.getCentralHeight() - 120;
        return result;
    }

    getToolBar() {
        const context = this;
        const onSave = () => {
            const items = context.state.vdata.filter(i => Boolean(i[context.props.relation.parentField])).map(i => {
                return {data: i}
            });
            let i = items.length;
            const callback = (result) => {
                const parentGrid = context.parent.grid;
                parentGrid.getData({context:parentGrid});
            };

            f.callRelationSend({
                props: context.props,
                items: items, callback: callback, replace: true
            })
        };
        const onEditOk = () => {
            onSave();
            onEditCancel();
        };
        const onEditCancel = () => {
            context.parent.hideRelPanel(context.parent);
        };

        return (<Panel ref={(p => context.panel = p)} key={'baseRelPanel'} layout={'fit'} docked={'bottom'}>
            <Toolbar
                shadow={false} key={'relToolBar'} cls={'no-print'} docked="bottom"
                layout={{type: 'hbox', pack: 'right'}}>
                <SaveButton key={'SaveButton'} func={onSave}/>
                <SaveCloseButton key={'SaveCloseButton'} func={onEditOk}/>
                <CloseButton key={'CloseButton'} func={onEditCancel}/>
            </Toolbar></Panel>)
    }


}

