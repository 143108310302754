import ErrorBoundary from "../../ErrorBoundary";
import BasePageGrid from "../../Base/BasePageGrid";
import * as f from "../../common/Funcs";
import {Button} from "@sencha/ext-react-modern";
import React from "react";
const keyName='ReportsGrid';
export default class ReportsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.idName = 'report_id';
        context.hiddenColumns=[context.idName];
        context.columnsDefaults=[
            {column_name:'report_year',pos:1, maxWidth:100
            },{column_name:'report_path',pos:3,tableName:'reports'},
            {column_name:'report_name',flex:{grow:2},title:true,pos:0},{column_name:'report_auth',flex:{grow:2},maxWidth:200,pos:2}]
    }

}