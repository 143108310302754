import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import * as f from "../../common/Funcs";

const keyName = 'HuntLandsPage';
export default class HuntLandsPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.title = `список участков`;
        context.gridName = "HuntLandsGrid";
        context.subElementName = 'HuntLandPage';
        context.tableName = 'lands';
        context.idName = 'land_id';
        context.topMenuButtonNames = ['reload', 'xls', 'print', 'add', 'clipboard'];
        context.filters = [{name: 'ac',pos:10,defaultValue:true, required: false}, {name: 'region',pos:20, required: false}, {
            name: 'users',pos:30,
            required: false
        }, {name: 'ecos',pos:40, required: false}, {name: 'lands',pos:45, required: false}, {
            name: 'landtype',pos:50,
            required: false
        }, {name: 'reason',pos:60, required: false},
            {name: 'num',pos:70, required: false},
            // {name: 'page', required: false}
            ];
    }
}