import React,{Component} from "react";
import BaseZmuAccPage from "../../../Base/ZMU/BaseZmuAccPage";

const keyName = `ZmuEventsPage`;
const title=`Зимний маршрутный учет. Список контрольных мероприятий.`;
export default class ZmuEventsPage extends BaseZmuAccPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'EventPage';
        context.gridName="EventsGrid";
        context.tableName = 'events';
        context.topMenuButtonNames = ['reload', 'xls', 'print', 'add', 'clipboard'];
        context.filters = [{name:'year',pos:10},{name:'users',pos:20},{name:'region',pos:15},{name:'it',pos:30},{name: 'page',pos:60},{name:'to',pos:11},{name: 'from',pos:12}];
    }
}