import React from 'react';
import {Panel} from '@sencha/ext-react-modern';
    import * as f from "../common/Funcs";
import Subs from "../collections/subElements"
import DialogForm from "../common/DialogForm";
import BasePage from "./BasePage";
import ErrorBoundary from "../ErrorBoundary";

const Ext = window['Ext'];
const keyName = `BaseSubGridPanel`;

export default class BaseSubGridPanel extends BasePage {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.parent = props.parent;
        context.rowId = props.rowId;
        // context.rowId = props.rowId;
        //    context.tableName = 'economies';
        //элемент окна редактирования
        context.idName = props.idName || 'economies_id';
        context.gridName = props.gridName || "BaseSubGrid";
        context.activeColumn = props.activeColumn || null;
        context.subElementName = props.subElementName;
        context.hiddenColumns = props.hiddenColumns || [context.idName, 'hu_id'];
        context.columnsDefaults = props.columnsDefaults || [];
        context.relColumnsDefaults = props.relColumnsDefaults || [];
        context.state = {
            data: context.props.data || [], title: 'Started', showEditPanel: false,
            showAll: false, singleMode: false
        };
        context.store = Ext.create('Ext.data.Store', {
            data: context.state.data,
        });
        context.topMenuButtons = [];
        context.noSingle=props.noSingle;
        context.collapsed=props.collapsed;

//меню
        context.topMenuButtonNames = (props.topMenuButtonNames) ? props.topMenuButtonNames : ['reload', 'add','edit'];
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.warn(error, errorInfo);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        if (prevProps.rowId != context.props.rowId || prevProps.record != context.props.record) {
            context.reloadFunc();
        }
    }

    //кнопки для меню - общая функция
    addMenuButtons() {
    const context = this;
        context.topMenuButtons = [];
        //Если позволяет редактировать, добавляем кнопку "добавить"
        context.topMenuButtonNames.map((n,i) => {
            context.addMenuButton(n,i);
        })
        context.addAdditButtons();
    }

    //добавить кнопку по имени
    addMenuButton(n,i) {
        const context = this;
        const parent=context.props.parent;
        switch (n) {
            case 'add':
                if (!(parent.isAddRight()&&context.isAddRight())) return;
                break;
            case 'edit':
                if (!(parent.isEditRight()&&context.isEditRight())) return;
                break;
            case 'relation':
                if (!(parent.isEditRight()&&context.isEditRight())) return;
                break;
        }
        context[`add${n.firstToUpper()}Button`](i);
    }

    //кнопки для меню
    addAdditButtons() {
        const context = this;
        //если есть поле "активный" чекбокс - показать все
        if (context.activeColumn) context.addShowAll();
    }

    reloadFunc() {
        const context = this;
        const params={context:context.grid};
        if (context.activeColumn && !this.state.showAll) params.activeColumn=context.activeColumn;
        if (context.grid) context.grid.getData(params);
    }

    addReloadButton(i) {
        const context = this;
        context.topMenuButtons[i]=({
            iconCls: 'x-fa fa-refresh',
            html:`<label class="menu-label">Обновить</label>`,
            tooltip: f.locale('reload'),
            handler: () => context.reloadFunc.apply(context)
        })
    }

    addRepeatButton(i) {
        const context = this;
        context.topMenuButtons[i]=(  {
            iconCls: 'x-fa fa-repeat',
            html:`<label class="menu-label">Пересчитать</label>`,
            data:'<p>ха-ха-ха</p>',
            tooltip: f.locale('repeat'),
            //ззапрос данных с пересчетом тестов
            handler: () => context.grid.getData({context:context.grid, repeat:true})
        })
    }

    //checkbox фильтра по актуальности
    addShowAll() {
        const context = this;
        const key = 'showAll';
        context.topMenuButtons.unshift({
            iconCls: `x-fa ${(context.state.showAll) ? 'fa-check-square' : 'fa-square'}`,
            html: `<label key="showall_label">${f.locale(key)}</label>`,
            handler: (sender) => {
                context.setState({showAll: !context.state.showAll});
                context.reloadFunc();
            }
        })
    }

    //добавление новой записи
    addAddButton(i) {
        const context = this;
        const addFunc = () => {
            document.setCookie(context.tableName, null);
            context.rowId = null;
            context.showEditPanel(context);
        };
        context.topMenuButtons.push({iconCls: 'x-fa fa-plus', html:`<label class="menu-label">Добавить</label>`,handler: addFunc})
    }

    //кнопка редактирования, только для single mode
    addEditButton(i) {
        const context = this;
        const editFunc = () => {
            context.rowId = context.grid.getFirstId();
            context.showEditPanel(context);
        };
        context.topMenuButtons.push({
            iconCls: 'x-fa fa-edit',
            html:`<label class="menu-label">Изменить</label>`,
            handler: editFunc,
            hidden: !(context.grid && context.grid.state.singleMode)
        })
    }

    //кнопка записи в таблицу для связанных таблиц - из введенных в базу
    //$new,$button,$huntusers,$grid,$call
    addRelationButton(i) {
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} addRelationButton`);
        const key = 'relbutton';
        const relFunc = () => {
            if (window.IasConfig.devMode) console.debug("relFunc", arguments);
            document.setCookie(context.tableName, null);
            context.rowId = null;
            context.subPanel.expand();
            context.showRelPanel(context);
        };
        context.topMenuButtons.push({
            iconCls: 'x-fa fa-link',
            html:`<label class="menu-label">Связать с...</label>`,
            tooltip: f.locale('reload'),
            handler: relFunc
        })
    }

    //перейти на страницу редактирования
    showEditPanel(context) {
        if (!Boolean(context.subElementName?.length)) return console.debug(`${keyName} showEditPanel: no subName`);
        // собирает ограничения для формы редактирования из грида - родительское значение поля
        const gridFilter = context.grid.getFilterStringFromRow().split('&');
        if (window.IasConfig.devMode) console.debug(`${keyName} showEditPanel`, gridFilter);
        context.subPanel.expand();
        const upData={            showEditPanel: true};
        //применяется фильтр, указанный для саба в описании в родительском объекте
        if (!context.rowId) upData.pageDefaults=gridFilter.map(pair => {
            const ar = pair.replace(' ','').split('=');
            return {column_name: ar[0], value: ar[1], disabled: true};
        });
        context.setState(upData, () => (context.dialog) ? context.dialog.cmp.toFront() : '');
        const parentRow=context.props.parent.state.data;
        f.getTitle(context,parentRow);
        if (context.editForm) {
            //          context.editForm.getData(context.editForm, context);
            context.editForm.rowId = context.rowId;
            context.editForm.getData({context:context.editForm});
        }
    }

    hideEditPanel(context) {
        context.setState({showEditPanel: false});
    }

    //перейти на страницу добавления связи
    showRelPanel(context) {
        if (!Boolean(context.relation)) return console.debug(`${keyName} showRelPanel: no relation`)
        const gridFilter = context.grid.getFilterStringFromRow().split('&');
        if (window.IasConfig.devMode) console.debug(`${keyName} showRelPanel`, gridFilter);
        context.setState({
            //применяется фильтр, указанный для саба в описании в родительском объекте
            pageDefaults: gridFilter.map(pair => {
                const ar = pair.replace(' ','').split('=');
                return {column_name: ar[0], value: ar[1], disabled: true};
            }), showRelation: true
        });
        const parentRow=context.props.parent.state.data;
        f.getTitle(context, parentRow,true);
        //     if (context.editForm) context.editForm.getData(context.editForm,context);
    }

    hideRelPanel(context) {
        if (window.IasConfig.devMode) console.debug(`${keyName} hideRelPanel`);
        context.setState({showRelation: false});
    }

    isEditRight() {
        const context=this;
        const rights = context.props.rights;
        return Boolean(context.subElementName)&& f.getBool(!context.props.readonly)
            && f.isEditRight(rights);
    }

    // генерация таблицы
    getGrid(context) {
        const p= context.getGridType(context);
        return React.createElement(p.el, {
            parent: context,
            appViewPort: context.props.appViewPort,
            disableSingleEdit: context.props.disableSingleEdit,
            tableName: context.props.tableName,
            testName:context.props.testName,
            idName: context.idName,
            tpl:context.props.tpl,
            rowHeight:context.props.rowHeight,
            hiddenColumns: context.hiddenColumns,
            columnsDefaults: context.columnsDefaults,
            collapsed: context.collapsed,
            noSingle: context.noSingle,
            record: context.props.record,
            relation: context.props.relation,
            rowId: context.props.rowId||context.rowId,
            rights: context.props.rights,
            filterString: context.props.filterString,
            key: context.props.tableName,
            name: context.props.tableName,
            //    height: (context.props.maxHeight)?context.props.maxHeight:(context.props.parent.innerPanelHeight(context.props.parent)-window.IasConfig.innerHeight),
            ref: (g) => {
                if (g) context.grid = g
            }
        })
    }

    getSingleContent(context, params = {}) {
        const p =context.getEditType(context);
        if (!p.el) return null;
        return <p.el
            ref={(e =>(e!=null)? context.editForm = e:'')}
            pageDefaults={context.state.pageDefaults}
            width={context.props.editWidth}
            relation={context.props.relation}
            rowId={params.rowId || context.rowId}
            rights={params.rights || context.props.rights}
            key={context.subElementName}
            parent={context} appViewPort={context.props.appViewPort}/>;
    }

    getDialog(context) {
        // if (!window.dialogs) window.dialogs = [];
        //тело таблицы
        const edit = context.getSingleContent(context);
        if (!edit) return null;
        //окно редактирования. проверить скорость - прятать или генерировать
        const dialog = <DialogForm showEditPanel={context.state.showEditPanel} isNew={context.rowId==null} editFormEl={edit} propName={'dialog'}
                                   key={'dialog'}
                                   parent={context}/>
        // window.dialogs.push(dialog);
        return dialog;
    }

    getRelation(context) {
        const p = {relationGridPanel: Subs['BaseRelationGridPanel']};
        const relation = React.createElement(p.relationGridPanel, {
            parent: context,
            appViewPort: context.props.appViewPort,
            tableName: context.props.tableName,
            idName: context.idName||context.props.idName,
            hiddenColumns: context.hiddenColumns,
            relColumnsDefaults: context.relColumnsDefaults,
            //        height: f.getCentralHeight() * 0.8,
            relation: context.props.relation,
            rowId: context.props.rowId,
            // rowId: context.props.rowId,
            subElementName: context.props.subElementName,
            record: context.props.record,
            rights: context.props.rights,
            key: context.props.tableName,
            name: context.props.tableName,
            ref: (grid) => {
                if (grid)
                context.relation = grid
            }
        });
        //окно редактирования. проверить скорость - прятать или генерировать
        const dialog = <DialogForm showEditPanel={context.state.showRelation} key={'dialog2'} editFormEl={relation}
                                   propName={'dialog2'}
                                   parent={context}/>
        return dialog;
    }

    render() {
        const context = this;
        const elements = [];
        const grid = context.getGrid(context);
        elements.push(grid);

        const gridKey = `gridMenu${context.props.tableName}`;

        if (context.subElementName) {
            elements.push(context.getDialog(context));
        }
        if (context.props.relation&&context.state.showRelation) {
            elements.push(context.getRelation(context));
        }
        context.addMenuButtons();
        console.log('tools',context.topMenuButtons);
        return (<ErrorBoundary><Panel
            region={"center"} margin="3"
            layout={'fit'}
            title={context.props.title}
            cls={'edit-window-panel'}
            collapsible={{
                direction: 'top',
                dynamic: true
            }}
            collapsed={context.props.collapsed}
            scrollable={false}
            titleCollapse={true}
            key={'gridPagePanel'}
            tools={context.topMenuButtons}
            ref={p => {
             if   (p) {
                 context.subPanel = p.cmp;
             } else {
                 context.subPanel = null;
             }
            }}>
            {elements}
        </Panel></ErrorBoundary>)
    }

}

