import ErrorBoundary from "../../../ErrorBoundary";
import React from "react";
import BasePointsGrid from "../../../Base/ZMU/BasePointsGrid";
const keyName='BirdsGrid';

export default class BirdsGrid extends BasePointsGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'DocumentPage';
        context.tableName = `bird_points_view`;
        context.idName = 'point_id';
    }

}