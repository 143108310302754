import ErrorBoundary from "../../../ErrorBoundary";
import React,{Component} from "react";
import BaseZmuAnalizePage from "../../../Base/ZMU/BaseZmuAnalizePage";

const keyName = `ZmuPAnalizePage`;
const title=`Зимний маршрутный учет. Анализ исследуемых территорий.`;
export default class ZmuPAnalizePage extends BaseZmuAnalizePage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName="EaAnalizGrid";
        context.subElementName = 'EaPage';
        context.tableName = 'explore_areas';
    }

}