import BasePageGrid from "../../Base/BasePageGrid";
import ErrorBoundary from "../../ErrorBoundary";
import React from "react";

const keyName = 'UsersAdminGrid';
export default class UsersAdminGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.idName = 'user_id';
        context.hiddenColumns = [context.idName];
        context.columnsDefaults = [
            {
                pos: 7,
                column_name: 'isblocked', minWidth: 100,ignorePrint:true
            },
            {column_name: "active", pos: 0},
            {column_name: "fio", pos: 2,minWidth: 200,maxWidth: 250,title:true},
            {column_name: "username", pos: 1,minWidth: 150,maxWidth: 200,flex:{grow:2},title:true,ignorePrint: true},
            {column_name: "email",pos: 3,minWidth: 200,maxWidth: 250},
            {column_name: "phone",pos: 4,minWidth: 120, maxWidth: 150},
            {column_name: "roles", pos: 5,flex:{grow:2},minWidth: 300}
        ]
    }

    //для колонки редакции. Проверка на колонку, проверка прав, значок
    // rndFunc(value) {
    //     const context = this;
    //     if (window.IasConfig.devMode) console.debug(`${keyName} rndFunc`);
    //     const editButton = context.editButton(value);
    //     return [editButton];
    // }

}