import React from 'react';
import * as f from "../common/Funcs";
import { Stroke, Fill,  Text} from "ol/style";
import * as cf from "../common/cacheFunc";
const Ext = window['Ext'];

//данные для грида, без указания прав
export const getLegend = async (params) => {
    let {context, url, callback} = params;
    document.setCookies();
    fetch(`${url}`, {
        referrerPolicy: "no-referrer",
        referrer: ''
    })
        .then(res => {
            if (res.url.indexOf('image/png')>-1) return res.blob();
            else return res.text();
        })
        .then(
            (res) => {
                if (!res)
                    if (callback) return callback({html: ''});
                //если ответ - страница, возвращаем вызов страницы
                if (res.page && window.IasLoApp && res.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(res.page);
                }
                let result;
                if (res?.type&&res.type.indexOf('image')>-1 ) {
                    const image = new Image();
                    image.src=URL.createObjectURL(res);
                    result=image;
                }
                else {
                    f.flashMessages(res);
                    const parser = new DOMParser();
                    result = parser.parseFromString(res, 'text/html').body.getElementsByClassName('formTable')[0];
                }
                if (callback) return callback({html: result});
            },
            (error) => {
                if (callback) callback({"type": "FeatureCollection", "features": []});
                f.flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

export const gisAbort = (tableName) => {
    if (window.IasConfig[`${tableName}Abort`]) window.IasConfig[`${tableName}Abort`].abort();
    window.unmask();
    window.IasConfig[`${tableName}Abort`] = new AbortController();
    return window.IasConfig[`${tableName}Abort`];
};

//данные для грида, без указания прав
export const getGisData = async (params) => {
    let {context, tableName, actualDate,filter, cache=true, callback} = params;
    if (cache&&
         !(['route', 'layout','pas'].findIndex(i => tableName?.indexOf(i) > -1) > -1)
        ) {
        const result = cf.getCache(params);
        if (result) return (callback) ? callback(result) : true;
    }
    if (tableName) {
        let url = `${window.IasConfig.homePath}gis/${tableName}/`;
        if (actualDate) url += actualDate;
        if (filter) url += `?${filter}`;
        document.setCookies();
        fetch(url, {
            signal:gisAbort(tableName).signal,
            credentials: 'include'
        })
            .then(res => {
                return res.json();
            })
            .then(
                (result) => {
                    if (!result)
                        if (callback) return callback({"type": "FeatureCollection", "features": []});
                    if (result.features) result.features.map(f => f.properties.source = tableName);
                    //если ответ - страница, возвращаем вызов страницы
                    if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                        return window.IasLoApp.rePage(result.page);
                    }
                    f.flashMessages(result);
                    if (!result.flash) cf.setCache(params, result);
                    if (callback) return callback(result);
                },
                (error) => {
                    if (error.name == 'AbortError') return;
                    if (callback) callback({"type": "FeatureCollection", "features": []});
                    f.flashMessages({
                        flash: [{level: 'e', message: JSON.stringify(error.message)}]
                    });
                }
            )
    }
};

//данные для ea грида, без указания прав
export const getEaGisData = async (params) => {
    let {context, eaType, callback} = params;
    document.setCookies();
    document.setCookie('eaFilter',context.props.rowId);
    fetch(`${window.IasConfig.homePath}zmu/ea/gis/${eaType}/`, {
        signal:gisAbort(eaType).signal,
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (!result)
                    if (callback) return callback({"type": "FeatureCollection", "features": []});
                if (result.features) result.features.map(f => f.properties.source = eaType);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                document.dropCookie('eaFilter');
                f.flashMessages(result);
                if (callback) return callback(result);
            },
            (error) => {
                if (error.name == 'AbortError') return;
                if (callback) callback({"type": "FeatureCollection", "features": []});
                f.flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

//строка из таблицы
export const getGeomById = async (params) => {
    let {context, tableName, id, callback, active,filters=''} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}gis/${tableName}/feature/${id}?props=false${(active) ? '&a=1' : ''}${filters}`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка getObjectData, result: ${result}`);
                if (!result)
                    if (callback) return callback({data: [], columns: [], flash: []});
                if (result.features) result.features.map(f => f.properties.source = tableName);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (result.columns) {
                    const path_columns = result.columns.filter(c => (c.column_name.indexOf('path') > -1));
                    path_columns.map(c => c.data_type = 'path');
                    if (path_columns.length > 0 && (f.isEditRight(result.rights))) {
                        path_columns.map(c => c.data_type = 'path').map(c => result.columns.push({
                            column_name: "upload",
                            data_type: "upload",
                            pos: c.pos,
                        }))
                    }
                }
                f.flashMessages(result);
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                f.flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

//строка из таблицы
export const deleteGeomById = async (params) => {
    let {context, tableName, id, idName, callback} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}gis/${tableName}/${idName}/${id}`, {
        method: 'DELETE',
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (result.state)
                    if (window.IasConfig.devMode) console.debug(`загрузка getObjectData, result: ${result}`);
                f.flashMessages(result);
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                f.flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

//строка из таблицы
export const getDescriptionData = async (params) => {
    let {tableName, id, callback} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}gis/${tableName}/desc/${id}`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (window.IasConfig.devMode) console.debug(`загрузка getObjectData, result: ${result}`);
                if (!result)
                    if (callback) return callback({data: [], columns: [], flash: []});
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (result.columns) {
                    const path_columns = result.columns.filter(c => (c.column_name.indexOf('path') > -1));
                    path_columns.map(c => c.data_type = 'path');
                    if (path_columns.length > 0 && (f.isEditRight(result.rights))) {
                        path_columns.map(c => c.data_type = 'path').map(c => result.columns.push({
                            column_name: "upload",
                            data_type: "upload",
                            pos: c.pos,
                        }))
                    }
                }
                f.flashMessages(result);
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                // f.flashMessages({
                //     flash: [{level: 'e', message: JSON.stringify(error.message)}]
                // });
            }
        )
};

export const setObjectData = async (params) => {
    let {context, tableName, id, relation, rowId, form,path, values, callback} = params;
    // let {context, tableName, id, relation, rowId, form, values, callback} = params;
    if (!path) path=`api/data/${tableName}/row/`;
    const data = (values) ? values : form.cmp.getValues();
    if (relation) {
        data.relation = relation;
        data.relation.rowId = rowId;
        // data.relation.rowId = rowId;
    }
    const requestOptions = {
        method: 'POST',
        //     mode:'no-cors',
        credentials: 'include',
        body: data
    };
    document.setCookies();
    fetch(`${window.IasConfig.homePath}${path}${id}`,
        requestOptions
    ).then(res => {
            return res.json();
        }
    )
        .then(
            (result) => {
                window.unmask();
                if (window.IasConfig.devMode) console.debug(`отправка setObjectData, result: ${result}`);
                // if (result.flash) {
                //     result.flash.map(fl => message(`Сообщение сервера: ${fl.message}`))
                // }
                f.flashMessages(result);
                if (callback) callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                window.unmask();
                f.flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            })
};

export const toGeoJson = (feature) => {
    let prp = feature.getProperties();
    delete prp.geometry;
    return {
        type: 'Feature',
        geometry: {
            type: feature.getGeometry().getType(),
            crs: {type: "name", properties: {name: "EPSG:3857"}},
            coordinates: feature.getGeometry().getCoordinates()
        },
        properties: prp
    };
}

export const toGeoJsonCollection = (features) => {
    const fts = [];
    if (Array.isArray(features))
        features.map(f => fts.push(toGeoJson(f)));
    return {
        type: 'FeatureCollection',
        features: fts
    }

}


/*text standart function*/
function stringDivider(str, width, spaceReplacer) {
    if (str.length > width) {
        var p = width;
        while (p > 0 && str[p] != ' ' && str[p] != '-') {
            p--;
        }
        if (p > 0) {
            var left;
            if (str.substring(p, p + 1) == '-') {
                left = str.substring(0, p + 1);
            } else {
                left = str.substring(0, p);
            }
            var right = str.substring(p + 1);
            return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
        }
    }
    return str;
}

export const getText = (feature, params) => {
    let {resolution, field = 'label', type = 'wrap', minResolution = 0, maxResolution = 1200, color = [0, 0, 0, 1]} = params;
    let text = feature.get(field);
    if ((resolution > maxResolution) || (resolution < minResolution)) {
        text = '';
    } else if (type == 'hide') {
        text = '';
    } else if (type == 'shorten') {
        text = text.trunc(12);
    } else if (
        type == 'wrap') {
        text = stringDivider(text, 16, '\n');
    }
    return feature.get(field);
    return text;
};
export const getLabel = (feature, params) => {
    let {align, baseline = 'Middle', font = 'Verdana', color = [0, 0, 0, 1], outlineColor, outlineWidth, offsetX, offsetY, placement = 'Point'} = params;
    return new Text({
        textAlign: align,
        textBaseline: baseline,
        font: font,
        text: getText(feature, params),
        fill: new Fill({color: color}),
        stroke: new Stroke({color: outlineColor, width: outlineWidth}),
        offsetX: offsetX,
        offsetY: offsetY,
        placement: placement,
        // maxAngle: maxAngle,
        // overflow: overflow,
        // rotation: rotation,
    });
}

/*параметры экпорта карты в картинку*/
export const getExportOptions = () => {
    return {
        useCORS: true,
        ignoreElements: function (element) {
            const className = element.className || '';
            if (element.id == 'pdfPanel') return true;
            // return false;
            if (className.indexOf('ol-scale-bar') > -1) return false;
            if (className.indexOf('ol-control') > -1) return true;
            if (className.indexOf('ol-list') > -1) return true;
            // if (className.indexOf('ol-scale-bar') > -1) return false;
            return false;
        },
    };
}

/*ждем появления карты, но не слишком долно*/
export const mapResize=(context)=>{
    let i = 0;
    const f = () => {
        i++;
        if (context.olMap?.mapResize) context.olMap.mapResize(); else if (i < 5) setTimeout(f, 500);
    }
    f();
}