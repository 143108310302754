import React from 'react';
import BaseEditPage from "../../Base/BaseEditPage";
import BaseMapSub from "../../Base/BaseMapSub";
import SimpleMap from "../../Maps/SimpleMap";
import landspolStyle from "../../Maps/styles/landspol";
import paslinStyle from "../../Maps/styles/zmu";
// import zmupntStyle from "../../Maps/styles/zmupnt";
import pasStyle from "../../Maps/styles/zmu";
import admpolStyle from "../../Maps/styles/admpol";
import zmupntStyle from "../../Maps/styles/pnt";

const keyName = `ZmuPasMapSub`;

export default class ZmuPasMapSub extends BaseMapSub {

    static defaultProps = {
        load:'record',
        layerNames: [
            {
                layerName: 'landspol', hidden: false, type: 'own', styleFunction: landspolStyle
            },
            {
                layerName: 'passagelin',
                hidden: false,
                type: 'own',
                styleFunction: paslinStyle,
                filter: 'gid=$passage_id$',
                goto: true
            },
            {
                layerName: 'points',
                hidden: false,
                type: 'own',
                styleFunction: zmupntStyle,
                filter: 'passage_id=$passage_id$',
            },
            {
                layerName: 'admpol', hidden: false, type: 'own', styleFunction: admpolStyle
            }]
    }

    constructor(props) {
        super(props);
        const context=this;
        context.state = {showAll: true};
        context.styleFunction=pasStyle;
    }


    getMap(context, params = {}) {
        let parent = context;
        while (!(parent instanceof BaseEditPage)) {
            parent = parent.props.parent;
        }
        return <SimpleMap
            ref={(e => {
                context.olMap = e;
                parent.olMap = e;
            })}
            // height={context.getHeight() || 600} width={params.width || '100%'}
            height={ 600} width={params.width || '100%'}
            rights={params.rights || context.props.rights}
            key={'pasMap'}
            layerNames={context.props.layerNames}
            record={context.props.record}
            load={context.props.load}
            styleFunction={context.styleFunction || context.props.styleFunction}
            rowId={parent.rowId}
            parent={context} appViewPort={context.props.appViewPort}/>;
    }
}

