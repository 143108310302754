import {Fill, Stroke, Style, Text} from "ol/style";
import * as f from "../../common/Funcs";

const keyName='singleea.js';
export default (feature, resolution, context) => {
 if (window.cssOn) console.debug(`${keyName} css`,feature);
    let fillColor = [0, 0, 0, 1];
    let stokeColor = [0, 0, 0, 1];
    let lineWidth=2;
    const props = feature.getProperties();
    let style;
    if (['routes', 'layout'].findIndex(i => props.source?.indexOf(i) > -1) > -1) {
        if ((props.route_active || (f.exist(props.date_off)&&props.date_off == null)) && (
            !(props.pass_count == 0)
        )) {
            stokeColor = [225, 0, 0, 1];
        } else {
            stokeColor = [50, 50, 50, 0.5];
        }
        style = new Style({
            stroke: new Stroke({
                color: stokeColor,
                width: lineWidth
            }),
            text: new Text({
                text: (resolution < 500) ? props.route_name : '',
                font: `${window.IasConfig.map.fontSize} Tahoma`,
                maxAngle:0,
                textBaseline: 'bottom',
                fill: new Fill({color: stokeColor}),
                placement: 'line'
            })
        });
    }
    else {
        if ((!props.object_ea))
            fillColor = [50, 50, 50, 0.2];
        else {
            fillColor =  [0,0,0,0];
            // stokeColor =[125,0,125,1];
            stokeColor = document.getRGB(props.gid);
            stokeColor[3]=1;
        }
        if (props.source == 'eapol') {
            fillColor =  [0,0,0,0];
            stokeColor =[0, 255, 150, 1];
            lineWidth=4;
            // stokeColor = document.getRGB(props.gid);
            // stokeColor[3]=1;
        }
        style = new Style({
            fill: new Fill({
                color: fillColor
            }),
            stroke: new Stroke({
                color: stokeColor,
                width: lineWidth
            }),
            text: new Text({
                text: (resolution < 500&&!props.source=='eapol') ? props.label : '',
                font: `${window.IasConfig.map.fontSize} Tahoma`,
                fill: new Fill({color: stokeColor}),
            })
        });
    }    return style;
}