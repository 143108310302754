import React from "react";
import * as f from "../../common/Funcs";
import BasePageGrid from "../../Base/BasePageGrid";
import ErrorBoundary from "../../ErrorBoundary";

const keyName = 'BaseZmuGrid';

export default class BaseZmuGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.pageSize = 300;
        context.subElementName = 'EaPage';
        context.rowNumberWidth = '40px';
        context.tableName = `ea_table`;
        context.idName = 'area_id';
        context.hiddenColumns = ['area_id', 'acc_year']
        context.columnsDefaults = []
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        const context = this;
        if (context.state.eaType != prevState.eaType) {
            context.getData({context});
            if (context.props.parent.editForm) context.props.parent.editForm.setState({
                eaType: context.state.eaType,
                eaYear: document.getCookie('yearFilter')
            });
        }
    }

    update(context) {
        context.getData({context});
    }

    resultUpdate(result) {
        return result;
    }

    getData(params) {
        const {context, callback} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName(), params);
        const viewName = context.tableName;
        const year=f.getFilter('year');
        const filterString = 'acc_year=' + year;
        context.inload = true;
        window.mask();
        // f.getViewData(
        f.getPageData(
            {
                context: context.props.appViewPort,
                pageSize: context.pageSize,
                keyName: context.props.appViewPort.state.pageName,
                viewName, filterString,
                callback:
                    (result) => {
                        context.inload = false;
                        result = context.resultUpdate(result);
                        result.columns = result.columns.map(c => {
                            if (context.columnsDefaults) {
                                const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                                c = {...c, ...def};
                            }
                            return c;
                        });
                        context.columnsDefaults.filter(d => !result.columns.some(c => c.column_name == d.column_name)).map(d => result.columns.push(d));
                        result.columns=result.columns.sort((a, b) => {
                            return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                        })
                        context.isLoaded = true;
                        if (context.upResult) result = context.upResult(context, result);
                        const upData = {columns: result.columns, data: result.data};
                        context.store.loadRawData(result.data);
                        context.setState(upData, () => {
                            if (callback) callback(result);
                            window.unmask();
                        });
                    }
            })
    }
}