import React, {Component} from 'react';
import {Panel} from '@sencha/ext-react-modern';
import "./config";
import * as f from "./common/Funcs";
import Pels from "./collections/panelElements";
import Zmu from "./collections/zmuPages";
import {pageAbort} from "./common/Funcs";

const keyName = `AppViewPort`;
//основная структура интерфейса
const panelsTemplate = {"TopPanel": [], "LeftPanel": [], "RightPanel": [], "FooterPanel": [], "InlineMenu": []};
const anonymousPages = ['Login', 'Reset', 'Url'];

/**/
class AppViewPort extends Component {
    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        let autoload = document.getCookie('autoload');
        if (!autoload || autoload == 'false')
            context.autoload = false;
        else {
            document.setCookie('autoload', true);
            context.autoload = true;
        }
        context.state = {pageName: null, showMenu: true};
        context.showDialog = false;
        context.filters = {};
        context.dialogs = {};
        context.buttons = {};
        //история перемещений
        window.onpopstate = () => {
            const page = window.location.search.match(/page=[\D\d]*/)[0].substr(5);
            // context.setState({pageName: page});
            context.rePage(page);
        }
        window.onsave = (...args) => {
            debugger;
        }
        context.state = f.clone(panelsTemplate);
        const isAuth = document.getCookie("isAuth");

        let pgName = null;
        if (!isAuth && anonymousPages.filter(a => a == f.getPageName()).length == 0) pgName = 'Login';
        else {
            if (f.getPageName()) pgName = f.getPageName();
        }
        Object.assign(context.state, {
            pageName: pgName,
            content: {data: []},
            flash: []
        });
        context.rePage(pgName);
    }

    componentDidMount() {
        const context = this;
        const storage = window.localStorage.getItem('globalContext');
        if (storage) {
            const globalContext = JSON.parse(storage);
            context.setState({globalContext});
        }
        else {
            fetch(`${window.IasConfig.homePath}api/page/Global/get/filter?np=true`, {
                credentials: 'include'})
                .then(res => {
                    return res.json();
                })
                .then(
                    (result) => {
                        if (result.data[0]) {
                    window.localStorage.setItem('globalContext', result.data[0].getglobaldata);
                    context.setState({globalContext: JSON.parse(result.data[0].getglobaldata)});
                }
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        const bbs = context.buttons;
        const zmu = context.state.zmu;
        if (zmu) {
            ['plan_closed', 'passage_closed'].map(p => {
                if (bbs[p] && (bbs[p].state == null || bbs[p].state.closed != zmu[p])) {
                    bbs[p].setState({closed: zmu[p]})
                }
            })
        }


    }

    /*запрос описания таблицы*/
    callPage() {
        const context = this;
        //падает если нет центральной панели
        if (window.IasConfig.devMode) console.debug(`${keyName} callPage`);
        //второй callback - если ответ - страница
        //     f.pageAbort();
        f.getPanels({
            context: context,
            keyName: context.state.pageName,
            callback: (panels) => {
                if (context.state.pageName == 'MapPage') delete panels.LeftPanel;
                context.setState(panels);
                // document.setCookie('rights', rights);
                if (context.filter?.PageFilter && context.filters.PageFilter != 1) context.state.filterRefs.PageFilter.setState({pageCurrent: 1});
            }
        }).then(r => void 0);
    }

    /*чтение параметров страницы*/
    rePage(newPageName, rights) {
        const context = this;
        const rePageFunc = () => {

            if (rights) {
                document.setCookie('rights', rights);
            }
            let isauth = f.getRoles();
            if ((['Login', 'ProfilePage'].indexOf(newPageName) == -1) && isauth && isauth.filter(a => a < 10).length == 0 && isauth.filter(a => a > 9).length > 0 && (!newPageName || newPageName.toLowerCase().indexOf('zmu') == -1))
                newPageName = 'ZmuPlanPage';

            if (context.state.pageName.toLowerCase().indexOf('zmu') == -1 && newPageName.toLowerCase().indexOf('zmu') > -1)
                if (context.state.filters?.YearFilter)
                    context.filters.YearFilter.setValue((new Date()).getFullYear() + ((new Date()).getMonth() > 5 ? 1 : 0));
            if (!newPageName) newPageName = "Index";
            if (window.IasConfig.devMode) console.debug(`${keyName} rePage`, newPageName, rights);
            if (newPageName == 'Login' && document.location.search.split('page=')[1] != 'Login') {
                window.unmask();
                context.nextPage = document.location.search.split('page=')[1];
            }
            if (document.location.search.indexOf(`page=${newPageName}`) < 0) {
//            window.history.replaceState('', '', `${document.location.origin}?page=${newPageName}`);
                window.history.pushState(newPageName, '', `${document.location.origin}?page=${newPageName}`);
                Object.keys(context.filters).map(k => {
                    const filter = context.filters[k];
                    if (!filter?.getDefault) return;
                    document.setCookie(k.firstToLower(), filter.getDefault());
                    filter.setDefault();
                })

                context.setState({pageName: newPageName, lastPage: context.state.pageName, rights: rights}, () => {
                    context.callPage();
                });
            } else context.callPage();
        }
        if (context.page?.grid?.state?.modified)
            context.page.grid.testOnSave(context.page.grid, rePageFunc);
        else rePageFunc();

    }

    render() {
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} Layout`, context.props, context.state);
        const state = context.state;
        const pageName = state.pageName;
        //создание страницы
        let Page;
        if (!pageName) {
            Page = <Panel key={'spanan'} html={'Нет имени страницы'}/>;
        } else {
            const ps = Pels;
            const type = (Object.keys(ps).indexOf(pageName) > -1) ? ps[pageName] : ((Object.keys(Zmu).indexOf(pageName) > -1) ? Zmu[pageName] : '')
            if (!type) {
                Page = <Panel key={'spann'} html={`Такая страница не существует ${pageName}`}/>;
            } else {
                Page = React.createElement(type, {
                    data: state.data,
                    id: "innerPage",
                    key: "innerPage",
                    columns: state.columns,
                    parent: context,
                    rights: context.state.rights,
                    appViewPort: context,
                    globalContext: context.state.globalContext,
                    pageDefaults: context.pageDefaults,
                    ref: (p) => context.page = p

                })
            }
        }
        //заголовок
        const header = <Pels.Header style={{backgroundColor: "blue"}} key={'header'} parent={context}
                                    pageName={state.pageName} appViewPort={context}
                                    globalContext={context.state.globalContext}/>;
        //панель сообщений
        const flashMessages = <Pels.FlashPanel key={"flash" + Math.ceil(Math.random() * 100)}
                                               ref={(p => window.IasLoFlash = p)}/>;
        const footer = <Pels.FooterPanel parent={context} appViewPort={context} key={'footerpanel'}
                                         content={context.state.FooterPanel}
                                         globalContext={context.state.globalContext}/>;
        //контейнер страницы с боковыми панелями
//                <Pels.InlineMenu parent={context} appViewPort={context}  content={context.state.InlineMenu}/>
        const rightPanel = <Pels.RightPanel parent={context} key={'rightpanel'}
                                            appViewPort={context} filters={context.state.filters}
                                            ref={inner => context.rightPanel = inner}
                                            width={context.state.rightWidth || 250} content={context.state.RightPanel}
                                            globalContext={context.state.globalContext}/>;
        const borderContainer = <Panel
            layout='fit' docked={"top"}
            width={window.innerWidth - 8 - 2 * window.IasConfig.margin}
            key={'borderContainer'}
        >
            <Pels.TopPanel parent={context} appViewPort={context} key={'toppanel'} content={context.state.TopPanel}
                           globalContext={context.state.globalContext}/>
            <Pels.LeftPanel parent={context} appViewPort={context} key={'leftpanel'} content={context.state.LeftPanel}
                            globalContext={context.state.globalContext}
                            visible={context.state.showMenu}/>
            <Panel key={'centerpanel'} cls={'print-100-percent center-panel'} region={"center"}
                   ref={inner => context.centerPanel = inner}>
                <Panel key={'pagepanel'} layout="fit" height={f.getCentralHeight()} cls={'print-100-percent'}
                       bodyCls={'central-panel-body'}>{Page}</Panel>
            </Panel>
            {rightPanel}
        </Panel>;
        //сайт
        const viewPort = <Panel cls={'print-100-percent'} layout='fit' key={'viewport'}
                                ref={p => context.cmp = p}
        >
            {header}
            {flashMessages}
            {borderContainer}
            {/*{footer}*/}
        </Panel>
        return viewPort;
    }
}

export default AppViewPort;