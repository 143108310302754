import * as f from "../../common/Funcs";
import BaseEditPage from "../../Base/BaseEditPage";

const keyName = 'PickupPage';
export default class PickupPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'pickup_voucher';
        context.idName = 'pickup_id';
        context.titleName = 'pickup_address';
        context.columnsCount = 1;
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.footerButtonNames = ['saveclose', 'save', 'delete', 'close'];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {
                column_name: 'pickup_economy',
                disabled: true,
                pos: 0, row: 0,
                is_list: true,
                source: {
                    tableName: 'economies',
                    idField: 'economy_id',
                    titleField: 'economy_name'
                }
            },
            {
                column_name: 'pickup_address', pos: 2, row: 1,
            },
            {
                column_name: 'pickup_desc', pos: 3, row: 2,
            },
            {
                column_name: 'pickup_active', pos: 1, row: 0, defaultValue: true
            },
        ]];
        context.subTables = [
            {
                title: 'Контакты пункта выдачи',
                // elementName: 'BaseRelationSub',
                elementName: 'ContactsSub',
                tableName: 'pickupcontacts',
                subElementName: 'ContactPage',
                activeColumn: 'contact_active',
                idName: 'contact_id',
                hiddenColumns: ['contact_id', 'contact_pickup'],
                relColumnsDefaults: [{column_name: 'fio',width:500,maxWidth:500,minWidth:500, pos: 1}, {
                    column_name: 'post',
                    minWidth: 300,
                    pos: 2
                }, {column_name: 'contact_active', pos: 0}, {column_name: 'phones', minWidth: 300, pos: 3},
                    {column_name: 'emails', minWidth: 300, pos: 4}],
                // noSingle:true,
                relation: {tableName: 'pickup_contacts', parentField: 'contact_pickup', relField: 'pickup_contact'},
                filterString: "contact_pickup=$pickup_id$",
            },
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='pickup_voucher' and record_id = $pickup_id$::varchar",
            }
        ]
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        if (record)
            context.title = `Пункт выдачи охотхозяйства ${parentRow['economy_name']}`;
        else
            context.title = `Новый пункт выдачи охотхозяйства ${parentRow['economy_name']}`;
        return context.title;
        return context.title;
    }
}


