import React, {Component} from 'react';
import {
    Panel,
    Button,
} from '@sencha/ext-react-modern';

import * as f from "../common/Funcs";
import Grids from "../collections/gridElements";
import Pels from "../collections/panelElements";
import {
    AddButton,
    ClipboardButton,
    HtmlButton,
    PrintButton,
    ReloadButton,
    RepeatButton,
    XlsButton
} from "../common/Buttons";
import ErrorBoundary from "../ErrorBoundary";

const Ext = window['Ext'];
const keyName = `BasePage`;

export default class BasePage extends Component {

    static defaultProps = {data: [], appViewPort: null, parent: null}

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.myRef = React.createRef();
        //страница редактирования
        context.subElementName = `BaseEditPage`;
        //имя класса таблицы
        context.gridName = "BaseGrid";
        //таблица редактирования - для учетов и редактируемых гридов
        context.tableName = 'acc_table';
        //ключевое поле
        context.idName = '_id';
        context.state = {
            data: context.props.data, title: 'Started', showEditPanel: false
        };
        context.store = Ext.create('Ext.data.Store', {
            data: context.state.data,
        });
        context.topMenuButtons = [];
        context.topMenuButtonNames = [];
        //фильтры страницы
        context.filters = [];

//фильтры панели фильтров, возможные значения см. в collections\panelFilters
        //передаются приложению appViewPort
//        context.filters=['ac','region'...];

    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.warn(error, errorInfo);
    }

    update(context) {
        const f = (context.filters)?context.filters.filter(p => p.required && (!document.getCookie(`${p.name}Filter`)))
        :[];
        if (context.grid) {
            if (f.length == 0) context.grid.getData({context:context.grid});
            else context.grid.setState({columns: []});
        }
        if (context.olMap) {
            {
                context.olMap.update();
            }
        }
    }

    /*вызов функции печати*/
    async callPrint(context) {
        if (!context.getJson) return f.alert('Нет функции для получения данных getJson');
        let img = null;
        if (context.getImg) {
            img = await context.getImg(context);
        }
        f.getOutputFile({
            jsonString: context.getJson(context),
            typeoffile: 'pdf',
            file: img,
            filename: context.title || context.gridName || 'Doc',
            callback: () => {
            }
        });
    }

    getColData = (params) => {
        let {context, source, scolumns} = params;
        if (!source && !scolumns && !context) return {columns: [], data: []};
        if (!source) {
            if (context.grid)
                if (context.grid.state.vdata && context.grid.state.vdata.length > 0)
                    source = Array.isArray(context.grid.state.vdata) ? context.grid.state.vdata : [context.grid.state.vdata];
                else
                    source = Array.isArray(context.grid.state.data) ? context.grid.state.data : [context.grid.state.data];
            else if (context.state.vdata && context.state.vdata.length > 0)
                source = Array.isArray(context.state.vdata) ? context.state.vdata : [context.state.vdata];
            else
                source = Array.isArray(context.state.data) ? context.state.data : [context.state.data];
        }

        if (!scolumns)
            if (context.grid)
                scolumns = context.grid.state.columns;
            else
                scolumns = context.state.columns;

        let data = [];
        const columns = [];
        const hiddenColumns=context.hiddenColumns||context.props.hiddenColumns||[];
        scolumns.filter(c => (c.column_name != context.idName &&
            c.column_name != context.props.idName &&
            c.column_name != 'geom' &&
            (!(c.hidden==true)&& hiddenColumns.filter(h => h == c.column_name).length == 0) && c.column_name.indexOf('rights') == -1&& c.column_name.indexOf('path') == -1)).map(c => {
            columns.push({
                column_name: c.column_name,
                column_width: c.column_width,
                column_label:  c.column_label||f.locale(c.column_name),
                data_type: c.data_type,
                cls: c.className
                // hidden:c.hidden
            });
            source.map((r, idx) => {
                if (!data[idx]) data[idx] = {};
                let value = f.fixNull(r[c.column_name]);
                if (value !== '')
                    if (c.is_list) {
                        value = context.elements[c.column_name].field.cmp.getRawValue();
                    } else if (c.data_type == 'date') {
                        value = (new Date(value)).toLocaleDateString();
                    } else if (c.data_type == 'boolean') {
                        value = (value == false||value=='false') ? 'Нет' : 'Да';
                    }
                data[idx][c.column_name] = value;
            });
            });
        return {
            columns: columns,
            data: data
        };
    }

    //кнопки для меню - общая функция
    addMenuButtons() {
        const context = this;
        context.topMenuButtons = [];
        //Если позволяет редактировать, добавляем кнопку "добавить"
        context.topMenuButtonNames.map((n,i) => {
            const buttonCall=`add${n.firstToUpper()}Button`;
            if (!context[buttonCall]) {
            }
            else context[`add${n.firstToUpper()}Button`](i);
        })
        context.addAdditButtons();
    }

    //кнопка записи в таблицу для связанных таблиц - из введенных в базу
    //$new,$button,$huntusers,$grid,$call
    addRelationButton(i) {
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} addRelationButton`);
        const key = 'rel';
        const relFunc = () => {
            if (window.IasConfig.devMode) console.debug("relFunc", arguments);
            document.setCookie(context.tableName, null);
            context.rowId = null;
            context.showRelPanel(context);
        };
        context.topMenuButtons.push(<Button
            ui={window.IasConfig.ui}
            key={key}
            name={key}
            iconCls={'x-fa fa-link'}
            handler={relFunc}/>)
        //context.setState({manuButtons:context.topMenuButtons});

    }

    addAdditButtons() {
    }

    //кнопка reload общая для всех страниц
    addReloadButton(i) {
        const context = this;
        const key = 'reloadbutton';
        context.topMenuButtons.push(<ReloadButton key={key} func={() => {
            if (context.getData) context.getData({context});
            if (context.grid) context.grid.getData({context:context.grid});
        }}/>)
    }

    //кнопка repeat общая для всех страниц
    addRepeatButton(i) {
        const context = this;
        const key = 'repeatbutton';
        context.topMenuButtons.push(<RepeatButton key={key} func={() => {
            f.callCalcs({context,
                tableId: context.calcTableName,
                zmuTableId: (context.zmuTableId||context.tableName).replace('_view',''),
                objectType:context.testType,objectId:context.rowId,
                wait:true,
                callback:()=>{
                    if (context.getData) context.getData({context});
                    if (context.grid) context.grid.getData({context:context.grid});

                }})
         }}/>);
    }

    //кнопка экспорта в xls
    addXlsButton(i) {
        const context = this;
        const key = 'xlsbutton';
        // const exportFunc = () => {
        //     debugger;
        //     context.grid.grid.cmp.saveDocumentAs({
        //         type: 'xlsx',
        //         title: context.title,
        //         fileName: `${context.title}.xlsx`
        //     });
        // }
        context.topMenuButtons.push(<XlsButton key={key} context={context}/>)
    }

    //кнопка экспорта в xls
    addClipboardButton(i) {
        const context = this;
        const key = 'clipboardbutton';
        const exportFunc = () => {

        }
        context.topMenuButtons.push(<ClipboardButton key={key} context={context}/>)
    }

    //кнопка экспорта в html
    addHtmlButton(i) {
        const context = this;
        const key = 'htmlbutton';
        const exportFunc = () => {
            if (context.grid?.grid?.cmp?.getDocumentData) {
                const content = context.grid.grid.cmp.getDocumentData({
                    type: 'html',
                    title: context.title,
                    fileName: `${context.title}.html`
                });
                const p = window.open();
                p.document.write(content);
                p.print();
            }
        }
        context.topMenuButtons.push(<HtmlButton key={key} context={context} func={exportFunc}/>)
    }

    //кнопка экспорта в html
    addPrintButton(i) {
        const context = this;
        const key = 'printbutton';
        context.topMenuButtons.push(<PrintButton key={key} func={() => {
            if (context.callPrint) context.callPrint(context); else window.print();
        }}/>);
    }

    //кнопка новой записи в таблицу
    //$new,$button,$huntusers,$grid,$call
    addAddButton(i) {
        const context = this;
        const key = 'addbutton';
        const addFunc = () => {
            context.rowId = null;
            document.setCookie(context.tableName, null);
            context.setState({pageDefaults: []},
                () => context.showEditPanel(context, true));
        };
        if (context.isAddRight())
        context.topMenuButtons.push(<AddButton key={key} func={addFunc}/>)
    }

    isEditRight() {
        const context=this;
        const rights = context.state.rights || context.props.rights || document.getCookie('rights');
        return  f.getBool(!context.props.readonly)&&f.isEditRight(rights);
    }

    isAddRight() {
        const context=this;
        const rights = context.state.rights || context.props.rights || document.getCookie('rights');
        return f.getBool(!context.props.readonly)&&f.isAddRight(rights);
    }

    isDropRight() {
        const context=this;
        const rights = context.state.rights || context.props.rights || document.getCookie('rights');
        return f.getBool(!context.props.readonly)&&f.isDropRight(rights);
    }

    getEditMenu(context) {
        context.addMenuButtons();
        return <Panel
            bodyCls={"inner-header"}
            key={`gridMenu${context.gridName}`}
            layout="hbox" shadow docked={"top"}
            cls={'no-print'}
            height={f.getInnerHeightMenu()}
            margin={`0 0 ${window.IasConfig.margin} 0`}>
            {context.topMenuButtons}
        </Panel>
    }

    getEditType(context, subElementName) {
        return {el: Pels[subElementName || context.state.subElementName || context.subElementName]};
    }

    getGridType(context, gridName) {
        return {el: Grids[gridName || context.state.gridName || context.gridName]};
    }

    render() {
        const context = this;
        //тело таблицы
        const grid = React.createElement(context.getGridType(context).el, {
            parent: context,
            appViewPort: context.props.appViewPort,
            key: context.gridName,
            name: context.gridName,
            height: '100%',
            ref: (grid) => context.grid = grid
        });
        const editMenu = context.getEditMenu(context);
        const dialog = '';
        return (<ErrorBoundary  key={`error${keyName}`}><Panel
            cls={'print-100-percent base-page'}
            bodyCls={'print-100-percent base-page-body'}
            height={f.getCentralHeight()}
            region={"center"} margin="3"
            key={`gridPanel${keyName}`}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            {editMenu}
            {grid}
            {/*dialog*/}
        </Panel></ErrorBoundary>)
    }
}

