import ErrorBoundary from "../../ErrorBoundary";
import React,{Component} from "react";
import BaseTopPage from "../../Base/BaseTopPage";
const Ext=window['Ext'];

const keyName = `ZmuIndex`;
const title=`Зимний маршрутный учет`;

export default class ZmuIndex extends BaseTopPage {
    constructor(props) {
            super(props);
            const context = this;
            context.state = {
                content: [`<p></p>`]
                , title: title
            };
    }
}