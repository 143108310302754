import {Fill, Stroke, Style, Text} from "ol/style";
import * as g from "../../common/gisFuncs";

const keyName='zonespol';
export default (feature, resolution) => {
 if (window.cssOn) console.debug(`${keyName} css`,feature);

 let color = [100, 255, 100, 0.5];
 if (feature.getProperties().zone_type==2) color=[100,100,255,0.5];

    let shadow = [100, 100, 100];
    const w = new Style({
        stroke: new Stroke({
            color: shadow,
            width: 1
        })
    });
    const p = new Style({
        fill: new Fill({
            color: color,
        }),
        stroke: new Stroke({
            color: color,
            width: 1
        }),
        text: new Text({
            text: g.getText(feature, {resolution: resolution}),
            stroke: new Stroke({color: shadow, width: 1}),
            fill: new Fill({color: color}),
            overflow:(resolution>100)
        })
    });
    return [w, p];
}