import ErrorBoundary from "../ErrorBoundary";
import React, {Component} from 'react';

import {
    Panel,
    FormPanel,
    Textfield,
    PasswordField,
    Button,
    Toolbar,
} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";

const Ext = window['Ext'];
const keyName = `Reset`;

export default class Reset extends Component {

    constructor(props) {
        super(props);
        const context = this;
        context.state = {
            content: {data: [], title: ''}
        };
    }

    /*смена страницы*/
    rePage(newPageName) {
        const context = this;
        context.props.appViewPort.rePage.call(context.props.appViewPort, newPageName);
    }

    render() {
        const context = this;
        //очистить куки
        document.clearCookies();
        const geturl=()=>context.rePage('Url');
        const reset = (sender) => {
            const valid = context.form.cmp.validate();
            if (!valid) return;
            const values=context.form.cmp.getValues();
            if (values.password!=values.password2) return f.toast('Пароли не совпадают.');
            const requestOptions = {
                method: 'POST',
                //     mode:'no-cors',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify({p:document.location.search.match(/p=[^&]+/)[0].substr(2),pas:values.password})
            };
            document.setCookies();
            fetch(`${window.IasConfig.homePath}users/reset`,
                requestOptions
            ).then(res => {
                    return res.json();
                }
            )
                .then(
                    (result) => {
                        if (result.status) {
                            context.rePage("Login");
                        } else if (result.flash) {
                            f.flashMessages(result)
                        }
                        return result;
                    },
                    (error) => {
                        debugger;
                        return error;
                    })
        };
        return <Panel
            key={keyName}
            height={f.getCentralHeight()}
            ref={p=>context.cmp=p}
            width={200} region={"center"} margin="3">
            <FormPanel method={"POST"} key={"loginForm"} id={"loginForm"} title="Сменить пароль" ref={(d)=>context.form=d}>
                <PasswordField name="password" id={'pasField'} label="Новый пароль" required
                               placeholder="Это обязательное поле"/>
                <PasswordField name="password2" id={'pasField2'} label="Введите пароль еще раз" required
                               placeholder="Это обязательное поле" listeners={{
                    keyup: (sender, event) => {
                        if (event.altKey == false &&
                            event.ctrlKey == false &&
                            event.keyCode == 13) reset(sender);
                    }
                }}/>
                <Toolbar shadow={false} docked="bottom" layout={{type: 'hbox', pack: 'right'}}>
                    <Button text="Сменить и войти" handler={reset}/>
                    <Button text="Выслать повторно" handler={geturl}/>
                </Toolbar>
            </FormPanel>
        </Panel>
    }
}

