import React, {Component} from "react";
import {BaseTextButton} from "../../../Base/BaseButtons";
import * as f from "../../../common/Funcs";
import {Panel} from "@sencha/ext-react-modern";
import BaseZmuAccPage from "../../../Base/ZMU/BaseZmuAccPage";
import {Label} from "@sencha/ext-react-modern";

const Ext = window['Ext'];
const keyName = `ZmuResultPage`;
const title = `Зимний маршрутный учет. Результаты ЗМУ.`;
export default class ZmuResultPage extends BaseZmuAccPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.filters = [{name: 'year', pos: 10}, {name: 'users', pos: 30}, {name: 'region', pos: 20}, {
            name: 'it',
            pos: 40
        },{name: 'zmuspec', pos: 50}];
        context.state = {
            data: [],
            content: [`<p></p>`]
            , title: title
        };
    }

    componentDidMount() {
        super.componentDidMount();
        const context = this;
        f.callPath({
            context: context.props.appViewPort,
            path: `zmu/accyear/species/${document.getCookie('yearFilter')}`,
            callback: (result) => {
                context.isLoaded = true;
                context.inload = false;
                context.setState({data: result.data});
            }
        }).then(r => void 0);

    }

    getButton(params) {
        const openDoc = () => {
            const call = (recreate) => {
                let filters = `?${(recreate) ? `recreate=1&recalc=1&` : ``}${f.getFilters('string')}&${'title=' + f.translit(params.label)}`;
                window.open(`${window.IasConfig.homePath}forms/pack/${params.key}/${params.ext}${filters}`);
                window.focus();
            };
            f.confirm({
                message: 'Выполнить перерасчет?<br>' +
                    'Это займет дополнительное время.',
                buttons: {yes: {text: 'Пересчитать'}, no: {text: 'Скачать без перерасчета'},cancel:{text:'Отменить'}}}, (recreate) => {
                if (f.exist(recreate)) {
                    call(recreate);
                }
            });
        }

        return <BaseTextButton key={params.key} name={params.key} label={params.label}
                               func={() => {
                                   if (window.IasConfig.devMode) console.debug("call pack");
                                   (params.attention) ? params.attention((e) => openDoc(params.key)) : openDoc(params.key)
                               }}/>;
    }

    getSpecies(context, isbird, columnNum) {
        return [...[
            <Label key={'qua1_label'} className={'doc-title'} html={`Ведомости численности ${(isbird)?'птиц':'зверей'}`}/>
        ], ...context.state.data.filter(d => d.isbird == isbird && (d.c == columnNum)).map(d => {
            const openDoc = (doctype) => {
                const call = (recreate) => {
                    let filters = `?${(recreate) ? `recalc=1&` : ``}recreate=1&specid=${d.spec_id}&${f.getFilters('string')}`;
                    window.open(`${window.IasConfig.homePath}forms/pack/${(d.isbird) ? 'quant_bird' : 'quant_animal'}/xls${filters}`);
                    window.focus();
                };
                f.confirm({ message: 'Выполнить перерасчет?<br>' +
                        'Это займет дополнительное время.',
                    buttons: {yes: {text: 'Пересчитать'}, no: {text: 'Скачать без перерасчета'},cancel:{text:'Отменить'}}}, (recreate) => {
                    if (f.exist(recreate)) {
                        call(recreate);
                    }
                });
            }
            return <BaseTextButton
                key={d.spec_id} name={d.spec_id} label={d.spec_name}
                func={() => {
                    openDoc();
                }}/>;
        })]
    }

    render() {
        const context = this;
        const animals1 = context.getSpecies(context, false, 1);
        const animals2 = context.getSpecies(context, false, 2);
        const birds = context.getSpecies(context, true, null);
        const elements = [
            // берем все доступные пользователю
            <Label key={'qua1_label'} className={'doc-title'} html={'Общие данные'}/>,
            context.getButton({context, key: 'pointstable', type: 'single', ext: 'xls', label: 'Учет следов'}),
            context.getButton({context, key: 'zmublank', type: 'pack', ext: 'doc', label: 'Ведомости учетов'}),
            <Label key={'qua_label'} className={'doc-title'} html={'Ведомости численности'}/>];
        //только сотрудники комитета могут генерить общие пакеты численности
        if (f.isCommittee()) {
            elements.push(context.getButton({
                context,
                key: 'quant_animal',
                type: 'pack',
                ext: 'xls',
                label: 'Всех зверей, архив'
            }));
            elements.push(context.getButton({
                context,
                key: 'quant_bird',
                type: 'pack',
                ext: 'xls',
                label: 'Всех птиц, архив'
            }));
        }

        elements.push(context.getButton({
            context,
            key: 'quant_animal_ea',
            type: 'pack',
            ext: 'xls',
            label: 'Сводная, звери, по ИТ'
        }));
        elements.push(context.getButton({
            context,
            key: 'quant_bird_ea',
            type: 'pack',
            ext: 'xls',
            label: 'Сводная, птицы, по ИТ'
        }));
        elements.push(context.getButton({
            context,
            key: 'quant_animal_jea',
            type: 'pack',
            ext: 'xls',
            label: 'Распределение, звери, по ОИТ'
        }));
        elements.push(context.getButton({
            context,
            key: 'quant_bird_jea',
            type: 'pack',
            ext: 'xls',
            label: 'Распределение, птицы, по ОИТ'
        }));
        return (
            <Panel layout={'fit'}>
                <Panel
                    cls={'print-100-percent by-map-panel'}
                    bodyCls={'button-doc-panel'}
                    layout={'vbox'}
                    height={f.getCentralHeight()}
                    region={"left"} margin="3"
                    key={`buttonPanel${keyName}1`}
                    ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}>
                    {elements}
                </Panel>
                <Panel
                    cls={'print-100-percent by-map-panel'}
                    bodyCls={'button-doc-panel'}
                    layout={'vbox'}
                    height={f.getCentralHeight()}
                    region={"right"} margin="3"
                    key={`buttonPanel${keyName}2`}
                    ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}>
                    {animals1}
                </Panel>
                <Panel
                    cls={'print-100-percent by-map-panel'}
                    bodyCls={'button-doc-panel'}
                    layout={'vbox'}
                    height={f.getCentralHeight()}
                    region={"right"} margin="3"
                    key={`buttonPanel${keyName}4`}
                    ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}>
                    {animals2}
                </Panel>
                <Panel
                    cls={'print-100-percent by-map-panel'}
                    bodyCls={'button-doc-panel'}
                    layout={'vbox'}
                    height={f.getCentralHeight()}
                    region={"right"} margin="3"
                    key={`buttonPanel${keyName}3`}
                    ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}>
                    {birds}
                </Panel>
            </Panel>
        );
    }
}