import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../Base/BaseSubGridPanel";

const keyName = `PickupSub`;

export default class PickupSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.gridName=`PickupSubGrid`;
        context.rowexpander=true;
    }

}

