import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName = 'ReportPage';
export default class ReportPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'reports';
        context.idName = 'report_id';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.footerButtonNames=['saveclose','save','copy', 'delete','close'];
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.columnsDefaults=[...context.lastUserColumns,...[
            {column_name:'report_name',row:0,pos:0},
            {column_name:'report_auth',row:1,pos:1},
            {column_name:'report_year',row:2,pos:2},
            {column_name:'report_path',row:3,pos:5,
                path: (context) => `reports/`,
            },
            {column_name:'report_path_upload',row:2,pos:4}]];
        context.subTables=[
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='reports' and record_id = $report_id$::varchar",
            }
        ]
    }
    /*отправка полей на сервер*/
    getValues(context) {
        if (window.IasConfig.devMode) console.debug(`${keyName} getValues`, context.form.cmp.getValues());
        const result=context.getBaseValues(context);
        result.append('report_path','');
        return result;
    }

}


