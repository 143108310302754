import React, {Component} from 'react';
import * as f from "../../common/Funcs";
import ErrorBoundary from "../../ErrorBoundary";
import SimpleMap from "../SimpleMap";
import style from "../styles/zmu";
import landstyle from "../styles/landspol";
import admstyle from "../styles/admpol";
import eastyle from "../styles/ea";

const keyName = "ZmuMap";

export default class ZmuMap extends SimpleMap {
    static defaultProps={
        mapButtonNames:[],
        layerNames :[
            {
                layerName: 'landspol', hidden: false, type: 'own', styleFunction: landstyle
            },
            {
                layerName: 'admpol', hidden: false, type: 'own', styleFunction: admstyle
            },
            {
                layerName: 'eapol', hidden: false, type: 'own', styleFunction: eastyle
            },
        ],
        load:'auto'
    }

    constructor(props) {
        super(props);
        const context = this;
        //geomType= 'Point', 'LineString', 'LinearRing', 'Polygon', 'MultiPoint', 'MultiLineString', 'MultiPolygon', 'GeometryCollection', 'Circle'.
        context.geomType = 'LineString';
        context.state.legendOn = false;
        if (props.styleFunction) context.styleFunction = props.styleFunction; else context.styleFunction = style;
    }

    getActualDate(context) {
        return `01.01.${document.getCookie('yearFilter')}`;

    }

    getHeight() {
        return f.getCentralHeight();
    }

    getAdditControls() {
        return [];
    }
}
