import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../Base/BaseSubGridPanel";

const keyName = `LandsSub`;

export default class LandsSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.gridName=`LandsSubGrid`;
        context.rowexpander=true;
    }

}

