import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import * as f from "../../common/Funcs";

//отчеты в разделе сведения о животном мире
const keyName = 'ReportsPage';
export default class ReportsPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        context.title=`список НИР`;
        context.tableName = 'reports';
        context.idName = 'report_id';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.gridName = "ReportsGrid";
        context.subElementName = 'ReportPage';
        //фильтры в правой части
        context.filters=[{name:'year',required:false},
            {name: 'num', required: false},
        ];
        //прячем столбцы
        context.hiddenColumns = [context.idName];
        context.topMenuButtonNames= ['reload','xls','print','add','clipboard'];
    }

    // //кнопки для меню
    // addMenuButtons(props) {
    //     const context = this;
    //     if (window.IasConfig.devMode) console.debug(`${keyName} addMenuButtons`, props, context.props.rights);
    //     const rights = context.props.rights || document.getCookie('rights');
    //     if (rights == 'a') context.addAddButton();
    // }
}

