import React from 'react';
import * as f from "../../common/Funcs";
import BaseSubGridPanel from "../../Base/BaseSubGridPanel";
import * as z from "../../common/zmuFuncs";

const Ext = window['Ext'];
const keyName = `EaPassagesSub`;

export default class EaPassagesSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.parent = props.parent;
        context.rowId = props.rowId;
        context.tableName = 'eapassages';
        context.gridName = "EaPassagesSubGrid";
        //элемент окна редактирования
        // context.idName = 'passage_id';
        context.subElementName = 'PasAllPage';
        context.hiddenColumns = props.hiddenColumns || ['layout_ea', 'gid'];
        context.columnsDefaults = [
            {column_name: "route_name", pos: 2, className: 'no-copy', title: true, maxWidth: 90},
            {column_name: 'fst', pos: 3, maxWidth: 70},
            {column_name: 'fld', pos: 4, maxWidth: 70},
            {column_name: 'bog', pos: 5, maxWidth: 70},
            {column_name: 'all', pos: 6, maxWidth: 70},
            {column_name: 'valid_state', pos: 1, minWidth: 100},
        ];
        context.state = {
            data: context.props.data || [], title: 'Started', showEditPanel: false,
            showAll: false, singleMode: false
        };
        context.store = Ext.create('Ext.data.Store', {
            data: context.state.data,
        });
        context.topMenuButtons = [];
        context.noSingle = true;

//меню
        context.topMenuButtonNames = ['reload'];
        context.rowId = props.rowId;
        context.state = {
            data: [],
        };
        context.store = Ext.create('Ext.data.Store', {
            data: context.state.data,
        });
    }

    addMenuButtons() {
    }
}

