import * as f from "../../common/Funcs";
import * as z from "../../common/zmuFuncs";
import React from "react";
import BaseGrid from "../../Base/BaseGrid";
import ErrorBoundary from "../../ErrorBoundary";

const Ext = window['Ext'];
const keyName = `TestsSubGrid`;

export default class TestsSubGrid extends BaseGrid {
    static defaultProps = {
        rowHeight: '40px',
        expanded:true,
        noRN:true,
    }

    constructor(props) {
        super(props);
        const context = this;
        context.height = props.height || 200;
        context.tpl = (data) => {
            const div = <div className={`contact-tpl copy-container ${(data.state == 'false') ? 'red' : ''}`}>
                <div className={'copy-class x-gridcell '}>
                    <span className={`tpl-text `} dangerouslySetInnerHTML ={{ __html: data.message}}/>
                    <div className="x-after-input-el"/>
                </div>
            </div>

            return div;
        }
        context.rowNumberWidth = '30px';
        context.hiddenColumns = ['object_id', 'test_object', 'test_year','description','message'];
        context.columnsDefaults = [  {pos:1,column_name: 'state', hidden: false},
            {
                column_name: 'test_label', pos: 2, colored: 'alert', color_field: 'state',
            }];
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);

    }

    componentDidMount = () => {
        const context = this;
        context.getData({context});
    }
    //получение данных. если repeat, сначал пересчет
    getData(params) {
        const {context, repeat = false}=params;
        context.store.clearData();
        if (!context.props.rowId || context.props.rowId==-1) return;
        const viewName = 'results_view';
        const get=()=>{
            const filterString = `object_id=${context.props.rowId} and test_object='${context.props.testName}' and test_year=${document.getCookie('yearFilter')}`;
            f.getViewData(
                {
                    context: context.props.appViewPort, viewName: viewName, filterString: filterString,
                    callback:
                        (result) => {
                            context.isLoaded = true;
                            result.columns = result.columns.map(c => {
                                c.flex={grow:2};
                                if (context.columnsDefaults) {
                                    const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                                    c = {...c, ...def};
                                }
                                return c;
                            }).sort((a, b) => {
                                return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                            });
                            const upData = {columns: result.columns, data: result.data};
                            if (context.props.parent.subPanel)
                                if (result.data.length == 0) {
                                    context.props.parent.subPanel.collapse();
                                } else {
                                    context.props.parent.subPanel.expand();
                                }
                            context.store.loadRawData(result.data);
                            context.setState(upData, () => {
                            });
                        }
                })
        }
        if (repeat) {
            f.callTests({context, zmuTableId:context.props.tableName, objectId:context.props.rowId, wait:true,callback:get})
        } else
            get();

    }

    getHeight() {
        const context = this;
        const itemsCount = context.state.data.length;
        // const maxHeight=window.innerHeight/2;
        const result = (1 + itemsCount) * 30;
        return result;
        // return (maxHeight>result)?result:maxHeight
    }


}