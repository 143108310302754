import ErrorBoundary from "../ErrorBoundary";
import 'ol/ol.css';
import {ScaleLine} from "ol/control";
import React, {Component} from "react";
import ReactDOM from "react-dom";
import * as f from "../common/Funcs";
import * as g from "../common/gisFuncs";
import Control from 'ol/control/Control';
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import {OSM, TileArcGISRest, Vector as VectorSource, TileWMS, XYZ, ImageWMS} from "ol/source";
import Draw from 'ol/interaction/Draw';
import Overlay from 'ol/Overlay';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {LineString, Polygon} from 'ol/geom';
import {getArea, getLength} from 'ol/sphere';
import {unByKey} from 'ol/Observable';
import Geocoder from "ol-geocoder";
import ExtReactDOM, {Button, ComboBoxField, ExtNumberfield, Panel} from "@sencha/ext-react-modern";
import {fromLonLat, toLonLat, getPointResolution, transform as Transform} from 'ol/proj';
import * as html2canvas from 'html2canvas';
import gisSources from '../common/gisSources';

import Select from 'ol/interaction/Select';
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import {defaults, Modify, Snap} from 'ol/interaction';
import {confirm} from "../common/Funcs";
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import ImageLayer from "ol/layer/Image";
const printListSize = [
    {value: 'a4', title: 'A4'},
    {value: 'a3', title: 'A3'},
    {value: 'a2', title: 'A2'},
    {value: 'a1', title: 'A1'},
    {value: 'a0', title: 'A0'},
    {value: 'a5', title: 'A5'},
];
const dims = {
    a0: [1189, 841],
    a1: [841, 594],
    a2: [594, 420],
    a3: [420, 297],
    a4: [297, 210],
    a5: [210, 148],
};
const printResolutions = [
    {value: 72, title: '72 dpi'},
    {value: 150, title: '150 dpi'},
    {value: 300, title: '300 dpi'},
    {value: 600, title: '600 dpi'},
];
const printScale = [
    {value: 500, title: '1:500 000'},
    {value: 250, title: '1:250 000'},
    {value: 100, title: '1:100 000'},
    {value: 50, title: '1:50 000'},
    {value: 25, title: '1:25 000'},
    {value: 10, title: '1:10 000'},
];
const exportOptions = g.getExportOptions();
const events = [];
/*стандартная кнопка с переключением*/
const toggledButton = (params) => {
    const {className, iconClassName, onMouseOver, onMouseLeave, onEnabled, onDisabled} = params;
    //переключаемая кнопка
    const isToggled = true;
    //описание кнопки
    const baseButton = document.createElement('div');
    baseButton.className = `ol-control ol-unselectable ${className}`;
    const icon = document.createElement('div');
    icon.className = iconClassName;
    baseButton.append(icon);
    const control = new Control({element: baseButton});
    //карта
    //поведение
    baseButton.onmouseover = onMouseOver;
    baseButton.onmouseleave = onMouseLeave;
    baseButton.onclick = () => {
        if (isToggled) {
            let toggleStatus = !baseButton.classList.contains('is-pressed');
            const olMap = control.getMap();
            if (toggleStatus) {
                document.getElementsByClassName('ol-viewport')[0].getElementsByClassName('is-pressed').getArray().map(e => e.classList.remove('is-pressed'));
                disableGotoPoint(olMap,baseButton);
                if (onEnabled) onEnabled(olMap, baseButton);
            } else {
                // disableInteractions(olMap);
                disableGotoPoint(olMap,baseButton);
                if (onDisabled) onDisabled(olMap, baseButton);
            }
            baseButton.classList.toggle('is-pressed');
        }
    };
    control.baseButton = baseButton;
    return control;
};

/*стандартная кнопка без переключения*/
const stableButton = (params) => {
    const {className, iconClassName, onMouseOver, onMouseLeave, onClick} = params;
    //описание кнопки
    const baseButton = document.createElement('div');
    baseButton.className = `ol-control ol-unselectable ${className}`;
    const icon = document.createElement('div');
    icon.className = iconClassName;
    baseButton.append(icon);
    const control = new Control({element: baseButton});
    //карта
    //поведение
    baseButton.onmouseover = onMouseOver;
    baseButton.onmouseleave = onMouseLeave;
    baseButton.onclick = () => {
        const olMap = control.getMap();

        onClick(olMap, baseButton);
    };
    control.baseButton = baseButton;
    return control;
};

/*бланк для контролов*/
export const getBaseControl = () => {
    //переключаемая кнопка
    const isToggled = true;
    const baseButton = document.createElement('div');
    baseButton.className = 'ol-control ol-unselectable ol-basecontrol';
    const icon = document.createElement('div');
    icon.className = 'x-fa fa-icon-name';
    baseButton.append(icon);
    const control = new Control({element: baseButton});
    baseButton.onmouseover = () => {
    };
    baseButton.onmouseleave = () => {
    };
    let toggleStatus = false;
    baseButton.onclick = () => {
        if (isToggled) {
            toggleStatus = !toggleStatus;
            if (toggleStatus) {
                document.getElementsByClassName('is-pressed').map(e => e.classList.remove('is-pressed'));
                baseButton.classList.toggle('is-pressed');
            } else {
            }
        }
    };

    return control;
}

/*линейка*/
export const getScaleControl = () => {
    return new ScaleLine({
        units: 'metric',
        bar: true,
        steps: 1,
        text: true
    });
}

/*переключение топооснов*/
export const getBasemapToggle = () => {
    let panel = null;
    const onclick = (olMap, baseButton) => {
        if (panel == null) {
            panel = document.createElement('div');
            panel.className = 'ol-list ol-basemaps';
            const callTopo = (t) => {
                if (window.IasConfig.devMode) console.debug(window.funcName(), t);
                panel.childNodes.forEach((c)=>(c.name!=t)?c.classList.remove('selected'):c.classList.add('selected'));
                olMap.mapControl.setTopos(t);
            };
            const createLabel = (t) => {
                const label = document.createElement('label');
                label.className = 'topo-item';
                label.name=t;
                label.onclick = () => callTopo(t);
                if (!t) t = 'notopo';
                label.innerHTML = `<span>${f.locale(t)}</span><img class="basemap-icon" src="/imgs/${t}.png" title="${f.locale(t)}"/>`;
                panel.append(label);
            }
            window.IasConfig.topos.map(t => createLabel(t));
            createLabel(null);
            baseButton.after(panel);
        }
    };
    return toggledButton({
        className: 'ol-basemaps', iconClassName: 'x-fa fa-layer-group',
        onMouseOver: void 0, onMouseLeave: void 0,
        onEnabled: onclick, onDisabled: onclick
    });
}

/*переключение видимости слоев*/
export const getLayersToggle = () => {
    let panel = null;
    const onclick = (olMap, baseButton) => {
        if (panel == null) {
            panel = document.createElement('div');
            panel.className = 'ol-list ol-layers-toggle';
            const toogleLayer = (e, t) => {
                olMap.mapControl.toggleLayer({layerName: t, context: olMap.mapControl, visible: e.target.checked});
                olMap.render();
            };
            const createLabel = (t) => {
                const label = document.createElement('label');
                label.className = 'layers-item';
                label.onclick = (e) => toogleLayer(e, t.layerName);
                label.innerHTML = `<span>${f.locale(t.layerName)} </span><input type="checkbox" ${(t.hidden) ? '' : 'checked'}/>`;
                panel.append(label);
            }
            //props карты, задается в MapPage
            olMap.mapControl.props.layerNames.map(t => createLabel(t));
            baseButton.after(panel);
        }
    };
    return toggledButton({
        className: 'ol-layers-toggle',
        iconClassName: 'flaticon-map',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onEnabled: onclick,
        onDisabled: onclick
    });
}

/*измерение площадей*/
export const getAreaMeasure = () => {
    return toggledButton({
        className: 'ol-areameasure',
        iconClassName: 'flaticon-area',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onEnabled: enableMeasureArea,
        onDisabled: void 0
    });
}
/*включаем изменрение площадей */
const enableMeasureArea = (olMap) => {
    if (!olMap.measureSource) addMeasureLayer(olMap);
    disableInteractions(olMap);
    addInteraction(olMap, 'Polygon');
}

/*добавляем слой для измерения*/
const addMeasureLayer = (olMap) => {
    olMap.measureSource = new VectorSource();
    const vector = new VectorLayer({
        source: olMap.measureSource,
        style: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: '#ffcc33',
                width: 2,
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: '#ffcc33',
                }),
            }),
        }),
    });
    olMap.addLayer(vector);
    vector.statusName = 'measure';

}

/*измерение длин*/
export const getLineMeasure = () => {
    return toggledButton({
        className: 'ol-linemeasure',
        iconClassName: 'flaticon-ruler',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onEnabled: enableMeasureLine,
        onDisabled: void 0
    });
}
/*включаем измерение длин */
const enableMeasureLine = (olMap) => {
    if (!olMap.measureSource) addMeasureLayer(olMap);
    disableInteractions(olMap);
    addInteraction(olMap, 'LineString');
}

/*редактирование объекта длин*/
export const getLineDraw = () => {
    return toggledButton({
        className: 'ol-linemeasure',
        iconClassName: 'flaticon-ruler',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onEnabled: enableMeasureLine,
        onDisabled: void 0
    });
}
/*включаем измерение длин */
const enableDrawLine = (olMap) => {
    if (!olMap.measureSource) addMeasureLayer(olMap);
    disableInteractions(olMap);
    addInteraction(olMap, 'LineString');
}

/*отображение проблемных узлов и участков */
export const getAlertMeasure = () => {
    return toggledButton({
        className: 'ol-alert',
        iconClassName: 'fa fa-exclamation',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onEnabled: enableAlert,
        onDisabled: void 0
    });
}
/*включаем изменрение площадей */
const enableAlert = (olMap) => {

    //if (!olMap.alertSource) addAlertLayer(olMap);
}

/*базовый экстент*/
export const getGoHome = () => {
    const goto = (olMap) => {
        if (window.IasConfig.devMode) console.debug(`goto home`,);
        olMap.getView().setCenter(olMap.mapControl.state.homeCenter || window.IasConfig.homeCenter);
    };
    return stableButton({
        className: 'ol-gohome',
        iconClassName: 'x-fa fa-home',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onClick: goto
    });
}

/*очистить выделение*/
export const getClearSelection = () => {
    const clear = (olMap) => {
        olMap.getLayers().getArray().find(l => l.id == 'select').getSource().clear();
        olMap.render();
    };
    return stableButton({
        className: 'ol-clear-selection',
        iconClassName: 'x-fa fa-eraser',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onClick: clear
    });
}

/*поиск точки*/
export const getGotoPoint = () => {
    return toggledButton({
        className: 'ol-gotopoint',
        iconClassName: 'x-fa fa-map-marker',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onEnabled: enableGotoPoint,
        // onDisabled: disableGotoPoint,
    });
}
/*поиск точки */
const enableGotoPoint = (olMap) => {
    //объекты - инпуты
    const coordinates = {lat: {d: 0, m: 0, s: 0}, lon: {d: 0, m: 0, s: 0}};

    //получить координаты из инпутов
    const getCoordinates = () => {
        const byParent = (parent) => {
            return coordinates[parent].d.cmp.getValue() + Number(coordinates[parent].m.cmp.getValue()) / 60 + Number(coordinates[parent].s.cmp.getValue()) / 3600;
        }
        let x = byParent('lon');
        let y = byParent('lat');
        return [x, y];
    };

    //изменения координат
    const changeCoord = (event) => {
        const name = event.sender.name;
        const parent = event.sender.getParent().name;
        const disable = (name) => {
            coordinates[parent][name].cmp.disable();
            coordinates[parent][name].cmp.setValue(null);
        };
        const enable = (name) => {
            coordinates[parent][name].cmp.enable();
        }
        //отключаем, если десятичные
        if (name == 'd') {
            if (event.newValue % 1 > 0) {
                disable('m');
                disable('s');
            } else {
                enable('m');
                enable('s');
            }
        }
        if (name == 'm') {
            if (event.newValue % 1 > 0) {
                disable('s');
            } else {
                enable('s');
            }
        }
    };
    //перейти к точке
    const gotoCoords = () => {
        const coordinates = fromLonLat(getCoordinates());
        const point = new Feature(new Point(coordinates));
        const source=olMap.getLayers().array_.find(l => l.id == 'select').getSource();
        source.clear();
        source.addFeature(point);
        olMap.getView().setCenter(coordinates);
        // document.getElementsByClassName('ol-gotopoint')[0].click();

    };
    //контейнер попапа
    const getContainer = () => {
        let p = document.getElementsByClassName('ol-popup');
        if (p.getArray().length > 0) p.getArray().map(i => i.remove());
        p = document.getElementsByClassName('ol-goto-container');
        if (p.getArray().length > 0) p.getArray().map(i => i.remove());
        p = document.createElement('div');
        p.className = 'ol-goto-container no-pref';
        p.innerHTML = '<a href="#" class="ol-popup-closer hidden"/>' +
            '<div class="ol-popup-content"></div>';
        window.IasLoApp.rightPanel.panel.cmp.element.dom.append(p);
        // const closer = document.getElementsByClassName('ol-popup-closer').getArray()[0];
        // closer.onclick = function () {
        //     overlay.setPosition(undefined);
        //     closer.blur();
        //     disableGotoPoint(olMap);
        //     return false;
        // };
        return p;
    };
    //поле ввода координаты
    const getField = (name, parent) => <ExtNumberfield
        key={name} name={name} label={(name == 'd') ? 'градусы' : (name == 'm' ? 'минуты' : 'секунды')}
        cls={'filter-field number-field'}
        width={'65px'}
        decimals={2} minValue={0} maxValue={(name == 'd') ? 180 : 60}
        value={null}
        ref={fi => {
            if (!fi) return;
            coordinates[parent][name] = fi;
        }}
        onChange={changeCoord}
    />

    disableInteractions(olMap);

    getContainer();
    const contentContainer = document.getElementsByClassName('ol-goto-container').getArray()[0];
    const content = <Panel layout={'vbox'}>
        <Panel title={'С.Ш.'} layout={'hbox'} name={'lat'} key={'lat'}>
            {[getField('d', 'lat'), getField('m', 'lat'), getField('s', 'lat')]}
        </Panel>
        <Panel title={'В.Д.'} layout={'hbox'} name={'lon'} key={'lon'}>
            {[getField('d', 'lon'), getField('m', 'lon'), getField('s', 'lon')]}
        </Panel>
        <Panel layout={'hbox'} height={'30px'} name={'b'} key={'b'}><Button name={'goto'} key={'goto'}
                                                                            handler={() => gotoCoords()} text={'Найти'}
                                                                            centered={true} ui={window.IasConfig.ui}
        /></Panel>
    </Panel>;
    const event = (event) => {
        const cc = toLonLat(event.coordinate);
        const fdms = (v) => {
            const o = {};
            o.m = v % 1;
            o.d = v - o.m;
            o.m = o.m * 60;
            o.s = o.m % 1;
            o.m = o.m - o.s;
            o.s = o.s * 60;
            o.s = o.s - o.s % 1;
            return o;
        }
        const point = new Feature(new Point(event.coordinate));
        const source=olMap.getLayers().array_.find(l => l.id == 'select').getSource();
        source.clear();
        source.addFeature(point);
        // olMap.getView().setCenter(event.coordinate);

        const inc = {lat: fdms(cc[1]), lon: fdms(cc[0])};
        Object.keys(inc).map(k => {
            Object.keys(inc[k]).map(g => {
                if (coordinates[k][g].cmp) coordinates[k][g].cmp.setValue(inc[k][g]);
            })
        })
    };
    olMap.on('click', event);
    events.push(event);
    //курсор мыши
    olMap.getViewport().style.cursor = 'help';
    ExtReactDOM.render(content, contentContainer);
}
/*закрыть окно поиска */
const disableGotoPoint = (olMap) => {
    //скрыть окно, отжать кнопку
    let p = document.getElementsByClassName('ol-goto-container');
    if (p.getArray().length > 0) p.getArray().map(i => i.remove());
    disableInteractions(olMap);
}
/*координаты курсора*/
export const getMousePosition= ()=> {
   return new MousePosition({
       coordinateFormat: (e)=>{
           return `${e[1].toFixed(4)} с.ш. ${e[0].toFixed(4)} в.д.`;
       },
        projection: 'EPSG:4326',
    });
}

/*инфо*/
export const getInfo = () => {
    return toggledButton({
        className: 'ol-info',
        iconClassName: 'x-fa fa-info',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onEnabled: enableInfo,
        onDisabled: (olMap) => olMap.getViewport().style.cursor = 'auto',
    });
}
/*поиск точки */
const enableInfo = (olMap) => {
    //открыть окно, прописать функционал
    const getContainer = () => {
        let p = document.getElementsByClassName('ol-popup');
        if (p.getArray().length > 0) p.getArray().map(i => i.remove());
        p = document.createElement('div');
        p.className = 'ol-popup';
        p.innerHTML = '<a href="#" class="ol-popup-closer"></a><div class="ol-popup-content" style="width: 400px"/>';
        document.body.append(p);
        const closer = document.getElementsByClassName('ol-popup-closer').getArray()[0];
        closer.onclick = function () {
            overlay.setPosition(undefined);
            closer.blur();
            getInteraction(olMap, 'Select').getFeatures().clear();
            return true;
        };
        olMap.setPopup = (content, pos) => {
            if (!content) {
                olMap.info.objects.splice(pos, 1);
                if (olMap.info.objects.length == 0) {
                    const contentContainer = document.getElementsByClassName('ol-popup-content').getArray()[0];
                    contentContainer.innerHTML = '<div class="popup-header">Объекты не найдены:</div>';
                } else {
                    while (pos > olMap.info.objects) {
                        pos = pos - 1
                    }
                    olMap.info.getByPos(pos);
                }
            } else {
                if (!Array.isArray(content)) content = [content];
                const contentContainer = document.getElementsByClassName('ol-popup-content').getArray()[0];
                const footer = '<div class="popup-footer"></p>Перейти к ' + olMap.info.objects.reduce((a, i, idx) => {
                    return a + `<div class="popup-position${(pos == idx) ? ' selected' : ''}" position="${idx}">${idx + 1}</div>`;
                }, '') + '</div>';
                //дописать варианты для реакт
                if (typeof (content[0]) == 'string')
                    contentContainer.innerHTML = '<div class="popup-header">Найденные объекты:</div><code>' + content.join('') + '</code>' + footer;
                else {
                }
                document.getElementsByClassName('popup-position').getArray().map(el => el.onclick = () => {
                    const pos = el.getAttribute('position');
                    if (window.IasConfig.devMode) console.debug(`select ${pos}`,);
                    olMap.info.getByPos(pos);
                });
            }
        }
        return p;
    };
    const overlay = new Overlay({
        element: getContainer(),
        autoPan: true,
        autoPanAnimation: {
            duration: 250,
        },
    });
    disableInteractions(olMap);
    olMap.addOverlay(overlay);

    //включаем селект, чтобы было видно, что смотрим
    const selectSingleClick = new Select({multi: true});

    selectSingleClick.on('select', (event) => {
        if (event.selected.length < 1) return;
        olMap.info = {
            objects: event.selected, current: 0, getByPos: (pos) => {
                const id = event.selected[pos].getProperties().gid;
                const a = getInteraction(olMap, 'Select').getFeatures();
                a.clear();
                a.push(olMap.info.objects[pos]);
                //вызвать функцию по запросу данных. результат вернуть в попап
                const callback = (result) => {
                    if (result.data) {
                        const rows = [];
                        const titles = Object.keys(result.data);
                        titles.map(t => {
                            if (t != 'id') {
                                const row = `<div class="popup-row"><div class="popup-label">${f.locale(t)}:</div>
                                <div class="popup-text">${result.data[t]}</div></div>`;
                                rows.push(row);
                            }
                        });
                        olMap.setPopup(rows, pos);
                        overlay.setPosition(event.mapBrowserEvent.coordinate);
                    } else {
                        olMap.setPopup(null, pos);
                        overlay.setPosition(event.mapBrowserEvent.coordinate);
                    }
                }
                const tableName = event.selected[pos].getProperties().source;
                if (window.IasConfig.devMode) console.debug(`call description table: ${tableName} id: ${id}`);
                if (tableName && f.exist(id))
                    g.getDescriptionData({tableName: tableName, id: id, callback});
            }
        };
        olMap.info.getByPos(0);

    });
    selectSingleClick.interactionName = 'Select';
    olMap.addInteraction(selectSingleClick);
    //курсор мыши
    olMap.getViewport().style.cursor = 'help';
}

const getInteraction = (olMap, name) => {
    return olMap.interactions.getArray().find(i => i.interactionName == name);
}

/*печать*/
/*переключение видимости слоев*/
export const getPdfTool = () => {
    let panel = null;
    panel = document.createElement('div');
    panel.className = 'ol-list ol-pdf-export no-print';
    const onclick = (olMap, baseButton) => {
        if (!panel.parentElement) {
            baseButton.after(panel);
            const printParams = () => {
                if (panel.sizes && panel.sizes.state && panel.sizes.state.value &&
                    panel.reses && panel.reses.state && panel.reses.state.value &&
                    panel.scales && panel.scales.state && panel.scales.state.value)
                    panel.params = printOn(olMap, {
                        format: panel.sizes.state.value,
                        size: dims[panel.sizes.state.value],
                        res: panel.reses.state.value,
                        scale: panel.scales.state.value,
                        print: false
                    });
            };
            const sizes = <PdfCombo keyName={'pageSize'} key={'pageSize'}
                                    func={printParams}
                                    ref={(fi) => {
                                        panel.sizes = fi;
                                    }} data={printListSize}/>;
            const reses = <PdfCombo keyName={'pageRes'} key={'pageRes'}
                                    func={printParams} ref={(fi) => {
                panel.reses = fi;
            }} data={printResolutions}/>;
            const scales = <PdfCombo keyName={'pageScale'} key={'pageScale'}
                                     func={printParams} ref={(fi) => {
                panel.scales = fi;
            }} data={printScale}
                                     onReady={printParams}/>;
            const inputs = [sizes, reses, scales];
            //функция печати
            const printFunction = () => {
                panel.params = {
                    format: panel.sizes.state.value,
                    size: dims[panel.sizes.state.value],
                    res: panel.reses.state.value,
                    scale: panel.scales.state.value,
                    print: true
                }
                const params = Object.assign(panel.params, {callback: () => baseButton.click()});
                setTimeout(() => {
                        panel.params = printOn(olMap, panel.params);
                    }, 200
                )
            };
            inputs.push(
                <Button
                    ui={window.IasConfig.ui}
                    text={f.locale('exportPdf')} key={'expPdf'} handler={printFunction}/>
            );
            ReactDOM.render(<Panel id={'pdfPanel'} key={'expPdfPanel'} bodyCls={'pdf-panel'}>{inputs}
            </Panel>, panel);
        }
    };
    return toggledButton({
        className: 'ol-pdf-export',
        iconClassName: 'fa fa-file-pdf-o',
        onMouseOver: void 0,
        onMouseLeave: void 0,
        onEnabled: (olMap, baseButton) => {
            onclick(olMap, baseButton);
            if (panel.params) {
                panel.params.print = false;
                printOn(olMap, panel.params)
            }
        },
        onDisabled: (olMap, baseButton) => {
            onclick(olMap, baseButton);
            printParamsOff(olMap, panel.params)
        }
    });
}

const printOn = (olMap, params) => {
    // exportButton.disabled = true;
    if (!params.print) return;
    const map = olMap;
    const dim = params.size;
    const width = Math.round((dim[0] * params.res) / 25.4);
    const height = Math.round((dim[1] * params.res) / 25.4);
    params.viewResolution = map.getView().getResolution();
    const pointResolution = getPointResolution(
        map.getView().getProjection(),
        params.res / 25.4,
        map.getView().getCenter()
    );
    const scaleResolution =
        params.scale /
        pointResolution;
    if (params.print)
        map.once('rendercomplete', function () {
            exportOptions.width = width;
            exportOptions.height = height;
            setTimeout(() => {
                html2canvas(olMap.getViewport(), exportOptions).then(function (canvas) {
                    f.toPdf({image: canvas, pageSize: params.format});
                    if (params.callback) params.callback();
                    printParamsOff(olMap, params);
                });
            }, 1000)
        });

    // Set print size
    map.getControls().getArray().find(c => (c.setDpi)).setDpi(params.res);
    map.getTargetElement().style.width = width + 'px';
    map.getTargetElement().style.height = height + 'px';
    map.getView().setResolution(scaleResolution);
    map.updateSize();
    return params;
}
const printParamsOff = (olMap, params) => {
    // exportButton.disabled = true;
    const map = olMap;
    if (!params) return console.error('А почему без параметров?');
    map.getControls().array_.find(c => (c.setDpi)).setDpi();
    map.getTargetElement().style.width = '100%';
    map.getTargetElement().style.height = `${f.getCentralHeight()}px`;
    map.updateSize();
    map.getView().setResolution(params.viewResolution);
    // exportButton.disabled = false;
    document.body.style.cursor = 'auto';
}

class PdfCombo extends Component {
    static defaultProps = {
        keyName: 'pdfCombo',
        data: []
    }

    constructor() {
        super();
        this.state = {value: ''};
    }

    render() {
        const context = this;
        return (<ComboBoxField
            key={context.props.keyName} name={context.props.keyName}
            label={context.props.label || f.locale(context.props.keyName)}
            hidden={context.props.hidden}
            store={context.props.data}
            displayField="title" valueField="value"
            onChange={(e) => {
                context.setState({value: e.newValue}, () => {
                    if (context.props.func) context.props.func(e);
                });
            }}
            queryMode="local"
            labelAlign="placeholder"
            onReady={(sender) => {
                if (sender.cmp.getStore() && sender.cmp.getStore().data.items[0]) sender.cmp.setValue(sender.cmp.getStore().data.items[0]);
            }}
        />)
    }
}

/*инфошка*/

/*поиск точки*/

const measure = {
    sketch: null, draw: null, helpTooltipElement: null,
    helpTooltip: null, measureTooltipElement: null,
    measureTooltip: null,
    continuePolygonMsg: f.locale('continuePolygonMsg'),
    continueLineMsg: f.locale('continueLineMsg'),
    helpMsg: f.locale('helpMsg')
};
/*форматирование результатов измерений*/
const formatArea = (polygon) => {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + ' км<sup>2</sup>';
    } else {
        output = Math.round(area * 100) / 100 + ' м<sup>2</sup>';
    }
    return output;
};
const formatLength = (line) => {
    var length = getLength(line);
    var output;
    if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + ' км';
    } else {
        output = Math.round(length * 100) / 100 + ' м';
    }
    return output;
};

const createHelpTooltip = (olMap) => {
    return;
    if (measure.helpTooltipElement) {
        measure.helpTooltipElement.parentNode.removeChild(measure.helpTooltipElement);
    }
    measure.helpTooltipElement = document.createElement('div');
    measure.helpTooltipElement.className = 'ol-tooltip hidden';
    measure.helpTooltip = new Overlay({
        element: measure.helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left',
    });
    olMap.addOverlay(measure.helpTooltip);
};
const createMeasureTooltip = (olMap) => {
    if (measure.measureTooltipElement) {
        measure.measureTooltipElement.parentNode.removeChild(measure.measureTooltipElement);
    }
    measure.measureTooltipElement = document.createElement('div');
    measure.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    measure.measureTooltip = new Overlay({
        element: measure.measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
    });
    olMap.addOverlay(measure.measureTooltip);
}

/*добавить слушателей карты*/
const addMapListeners = (olMap) => {

    const pointerMoveHandler = (evt) => {
        if (evt.dragging) {
            return;
        }
        if (measure.sketch) {
            var geom = measure.sketch.getGeometry();
            if (geom instanceof Polygon) {
                measure.helpMsg = measure.continuePolygonMsg;
            } else if (geom instanceof LineString) {
                measure.helpMsg = measure.continueLineMsg;
            }
        }
        measure.helpTooltipElement.innerHTML = measure.helpMsg;
        measure.helpTooltip.setPosition(evt.coordinate);
        if (measure.helpTooltipElement) measure.helpTooltipElement.classList.remove('hidden');
    };
    //отключены подсказки
    return;
    olMap.on('pointermove', pointerMoveHandler);
    olMap.getViewport().addEventListener('mouseout', function () {
        if (measure.helpTooltipElement) measure.helpTooltipElement.classList.add('hidden');
    });
}
/*добавляем действие карте*/
const addInteraction = (olMap, interactionType) => {
    addMapListeners(olMap);
    measure.draw = new Draw({
        source: olMap.measureSource,
        type: interactionType,
        style: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.5)',
                lineDash: [10, 10],
                width: 2,
            }),
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
            }),
        }),
    });
    measure.draw.interactionName = interactionType;
    olMap.addInteraction(measure.draw);

    createMeasureTooltip(olMap);
    createHelpTooltip(olMap);

    var listener;
    measure.draw.on('drawstart', function (evt) {
        // set sketch
        measure.sketch = evt.feature;

        var tooltipCoord = evt.coordinate;

        listener = measure.sketch.getGeometry().on('change', function (evt) {
            var geom = evt.target;
            var output;
            if (geom instanceof Polygon) {
                output = formatArea(geom);
                tooltipCoord = geom.getInteriorPoint().getCoordinates();
            } else if (geom instanceof LineString) {
                output = formatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
            }
            measure.measureTooltipElement.innerHTML = output;
            measure.measureTooltip.setPosition(tooltipCoord);
        });
    });

    measure.draw.on('drawend', function () {
        measure.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
        measure.measureTooltip.setOffset([0, -7]);
        // unset sketch
        measure.sketch = null;
        // unset tooltip so that a new one can be created
        measure.measureTooltipElement = null;
        createMeasureTooltip(olMap);
        unByKey(listener);
    });

}

export const disableInteractions = (olMap => {
    //   olMap.removeInteraction(measure.draw);
    const d = defaults();
    olMap.getInteractions().clear();
    d.getArray().map(i => olMap.addInteraction(i));
    if (olMap.measureSource) olMap.measureSource.clear();
    olMap.getOverlays().clear();
    events.map(e => olMap.removeEventListener('click', e));
    events.slice(0, 0);
    olMap.getViewport().style.cursor = 'default';

})

/*геокодер*/
export const getGeoCoder = (olMap) => {
    const element = document.createElement('div');
    const popup = new Overlay({element: element});
    const geocoder = new Geocoder('nominatim', {
        provider: 'osm',
        // key: '__some_key__',
        lang: 'ru-Ru', //en-US, fr-FR
        placeholder: f.locale('search_placeholder'),
        targetType: 'glass-button',
        limit: 5,
        keepOpen: true
    });
    geocoder.on('addresschosen', function (evt) {
        var feature = evt.feature,
            coord = evt.coordinate,
            address = evt.address;
        geocoder.getMap().addOverlay(popup);
        popup.setPosition(coord);
    });
    return geocoder;
}

/*слои сторонних ресурсов*/
export const getLayer = (topoName) => {
    const ldesc = gisSources[topoName.toLowerCase()];
    const layers = [];
    const legends = [];
    ldesc.layers.map(l => {
        let layer;
        let legend = {...l.legend};
        if (l.legend?.url) legend.url = l.url + l.legend.url;
        switch (l.type) {
            case 'osm':
                layer = new TileLayer({
                    source: new OSM(),
                })
                break;
            case 'agtile':
                layer = new TileLayer({
                    source: new TileArcGISRest({
                        url: l.url,
                        // crossOrigin: "Anonymous"
                    })
                })
                break;
            case 'wmsimg':
                layer = new ImageLayer({
                    singleTile: true,
                    source: new ImageWMS({
                        url: l.url,
                        params: l.params,
                        singleTile: true,
                        serverType: 'geoserver',
                        transition: l.transition,
                        hidpi:l.hidpi
                    })
                })
                break;
            case 'wmstile':
                layer = new TileLayer({
                    source: new TileWMS({
                        url: l.url,
                        params: l.params,
                        serverType: 'geoserver',
                        transition: 0,
                        hidpi:l.hidpi
                    })
                })
                break;
            case 'xyz':
                layer= new TileLayer({
                    source: new XYZ({
                        url:l.url
                    })
                })
        }
        layer.id = topoName;
        layers.push(layer);
        legends.push(legend);
    })
    return {layers: layers, legends: legends};
}
export const getTopos = (topoName) => {
    const layerDesc = getLayer(topoName);
    layerDesc.layers.map(l => {
        l.statusName = 'topo';
        l.pos=1;
    });
    return layerDesc;
}


