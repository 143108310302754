import ErrorBoundary from "../../ErrorBoundary";
import React from "react";
import DocumentsSubGrid from "./DocumentsSubGrid";

const Ext = window['Ext'];
const keyName = `ZoneDocumentsSubGrid`;

export default class ZoneDocumentsSubGrid extends DocumentsSubGrid {
    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        this.tableName = 'zonedocuments';
    }
}