import ErrorBoundary from "../../ErrorBoundary";
import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

export default class ContactPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("ContactPage constructor", arguments);
        context.tableName = 'log_view';
        context.idName='log_id';
        context.titleName='';
        context.maximizeOnShow=false;
        context.footerButtonNames=['close'];
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet=[
            {title: 'Основная информация'},
        ];
        context.columnsDefaults = [
            {"column_name":"log_id","row":0,"pos":0},
            {"column_name":"table_name","row":0,"pos":1},
            {"column_name":"table_desc","row":0,"pos":2},
            {"column_name":"op_name","row":1,"pos":0},
            {"column_name":"record_dif","row":2,"pos":0},
            {"column_name":"old_record","row":3,"pos":0},
            {"column_name":"new_record","row":4,"pos":0},
            {"column_name":"modified","row":5,"pos":0},{"column_name":"fio","row":5,"pos":0},
            {"column_name":"record_id","row":0,"pos":0}];
        context.subTables=[
        ]
    }
}


