//классы фильтров приложения, необходима инициализация в панели
import {Component} from "react";
import * as f from "../common/Funcs";
import * as g from "../common/gisFuncs";
import {
    Button,
    CheckBoxField,
    ComboBoxField, ExtDatepickerfield, FieldSet, Label,
    Panel,
    SpinnerField,
    TextField,
    Toolbar, Tree,
    TreeList
} from "@sencha/ext-react-modern";
import React from "react";
import ErrorBoundary from "../ErrorBoundary";

import {forEach} from "ol/geom/flat/segments";

const Ext = window["Ext"];
Ext.require(['Ext.data.TreeStore']);
const getGeomFunc = (params) => {
    const {context, result} = params;
    if (!(result && result.features && result.features.length > 0)) return false;
    const olMap = getOlMap(context);
    if (!olMap) return;
    context.gisFeature = olMap.createFeatures(result);
}

const getOlMap = (context) => context.props.appViewPort.page.olMap;

const updatePage = (context, val, setValue) => {
    const app = context.props.appViewPort;
    const eOps = {
        filter: {
            name: context.keyName.replace('Filter'),
            value: val
        }
    }
    const upFunction = (app && app.page && app.page.update && app.autoload) ? () => app.page.update(app.page, eOps) : () => false;
    //сравнили с куки, значение не изменилось
    const callChilds = document.getCookie(context.keyName) != val;
    if (!setValue) {
        if (context.keyName) document.dropCookie(context.keyName);
        if (context.keyName2) document.dropCookie(context.keyName2);
    }
    else {
        if (context.keyName) document.setCookie(context.keyName, val);
        if (context.keyName2) document.setCookie(context.keyName2, val);
    }
    //если не гис поиск, обновляем данные страницы
    if (context.props.gisSearch && val) {
        let filters = '';
        if (context.parentFilters) context.parentFilters().filter(f => f.gis && document.getCookie(f.name + 'Filter')).map(f => filters += `&${f.name}=${document.getCookie(f.name + 'Filter')}`);
        g.getGeomById({
            callback: result => getGeomFunc({context: context, result: result}),
            id: document.getCookie(context.keyName),
            tableName: context.gisParams.tableName,
            active: context.gisParams.active,
            filters
        });
    }
    else if (context.props.gisSearch) {
        const olMap = getOlMap(context);
        if (olMap) olMap.clearSelection(olMap);
    }
    if (!context.props.nUpdate && callChilds) {
        if (context.keyName.toLowerCase() != 'pagefilter' && window.IasLoApp && window.IasLoApp.filters && window.IasLoApp.filters.PageFilter && window.IasLoApp.filters.PageFilter.props.hidden == false && window.IasLoApp.filters.PageFilter.state.pageCurrent != 1) {
            window.IasLoApp.filters.PageFilter.setState({pageCurrent: 1});
        }
        else {
            upFunction();
        }
    }
    // }
    //ищем деток по parentFilters, пинаем на обновление
    if (callChilds) {
        const childs = Object.keys(app.filters).filter(fn => {
            const filter = app.filters[fn];
            if (filter) {
                if ((filter.loadData == 'onvisible' && !filter.props.hidden) || filter.loadData == 'onload') {
                    const f = filter.parentFilters;
                    return f && (f(app.filters[fn]).filter(pf =>
                        pf.name == context.keyName.replace('Filter', '')).length > 0)
                }
            }
        }).map(fn => app.filters[fn]);
        childs.map(c => {
            // if (c.state.loaded)
            c.getData({context: c});
        });
    }
    return setValue;
}

class baseFilter extends Component {
    keyName = 'baseFilter';
    defaultValue = null;
    _isMounted = false; // Flag to track component's mounted state
    componentDidMount() {
        this._isMounted = true; // Component is now mounted
    }

    componentWillUnmount() {
        this._isMounted = false; // Component is about to be unmounted
    }
    getValue() {
        return (this.field?.cmp?.getValue) ? this.field?.cmp?.getValue() : document.getCookie(this.keyName);
    }

    getDefault() {
        return this.defaultValue;
    };

    setDefault() {
        return this.setValue(this.getDefault());
    }

    setValue(val = null) {
        this.field.cmp.setValue(val);
        return this.field.cmp;
    }

    onChange(context, val, sender) {
        if (window.IasConfig.devMode) console.debug(`${context.keyName} onChange`, arguments);
        let saveValue = val && val.toString().length > 6 && (val instanceof Date) && val !== document.getCookie(context.keyName);
        if (val == '' || val == null) {
            return updatePage(context, val, false);
        }
        if (!saveValue) return;
        return updatePage(context, val.toLocaleDateString(), true);
    }
}

//фильтр - список
class baseListFilter extends baseFilter {
    keyName = 'listFilter'

    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const context = this;
        //проверям загружены ли данные, есть сохраненное значение для фильтра
        if (prevState.data.length == 0 && context.state.data.length > 0) {
            if (window.IasConfig.devMode) console.debug(`${context.keyName} set filter value `);
            const prevValue = document.getCookie(context.keyName);
            if (prevValue && !context.field.cmp.getValue())
                context.field.cmp.setValue(prevValue);
        }
    }

    //обновляем куки и страницу
    onChange(context, val) {
        if (window.IasConfig.devMode) console.debug(`${context.keyName} onChange`, arguments);
        if (!f.exist(val) || (val == ''))
            return updatePage(context, null, false);
        else
            return updatePage(context, val, true);
    }

    render() {
        const context = this;
        return <ComboBoxField
            key={context.keyName} name={context.keyName} label={context.props.label || f.locale(context.keyName)}
            cls={`filter-field combobox-field`}
            hidden={context.props.hidden}
            store={context.state.data}
            displayField="title" valueField="value"
            ref={fi => context.field = fi}
            onChange={(val) => context.onChange(context, val.newValue)}
            queryMode="local"
            labelAlign="placeholder"
            clearable
            required={context.state?.required}
            value={(context.props.hasOwnProperty('defaultValue')) ? context.props.defaultValue : document.getCookie(context.keyName)}
        />
    }
}

//фильтр даты - база
class DateFilter extends baseFilter {
    keyName = 'dateFilter';

    constructor(props) {
        super(props);
        if (!document.getCookie(this.keyName)) document.dropCookie(this.keyName);
    }

    getValue() {
        let value;
        if (this.field?.cmp?.getValue) {
            value = this.field?.cmp?.getValue();
            if (value) value = value.toLocaleDateString();
        }
        else
            document.getCookie(this.keyName);
        return value;
    }


    getDefault() {
        let d = this.defaultValue;
        if (d && d.indexOf('/') > -1) {
            d = d.split('/')
            d = `${d[1]}.${d[0]}.${d[2]}`;
        }
        return d;
    };

    onChange(context, val, sender) {
        if (window.IasConfig.devMode) console.debug(`${context.keyName} onChange`, arguments);
        let saveValue = val && val.toString().length > 6 && (val instanceof Date) && val !== document.getCookie(context.keyName);
        if (val == '' || val == null) {
            return updatePage(context, val, false);
        }
        if (!saveValue) return;
        return updatePage(context, val.toLocaleDateString(), true);

    }

    render() {
        const context = this;
        return <ExtDatepickerfield
            key={context.keyName} name={context.keyName} label={context.props.label || f.locale(context.keyName)}
            cls={'filter-field data-picker'}
            hidden={context.props.hidden}
            dateFormat={'d.m.Y'}
            inpuMask={'99.99.9999'}
            placeholder={'dd.mm.20yy'}
            listeners={{
                keyup: (sender, event) => {
                    return (event.altKey == false &&
                        event.ctrlKey == false &&
                        event.keyCode == 13) ? context.onChange(context, sender.getValue()) : ''
                },
                focusleave: (sender, event) => {
                    return context.onChange(context, sender.getValue());
                },
            }}
            ref={fi => context.field = fi}
            value={document.getCookie(context.keyName) || context.props.defaultValue}
            required={context.state?.required}
        />
    }
}

//фильтр даты "от"
export class FromFilter extends DateFilter {
    keyName = 'fromFilter';
}

//фильтр даты "до"
export class ToFilter extends DateFilter {
    keyName = 'toFilter';
    defaultValue = (new Date()).toLocaleDateString();
}

//общий фильтр checkbox
export class CheckBoxFilter extends baseFilter {
    keyName = 'checkboxFilter';

    constructor(props) {
        super(props);
    }

    getValue() {
        return this.field.cmp.getChecked();
    }

    setValue(val = false) {
        this.field.cmp.setChecked(val);
        return this.field.cmp;
    }

    //сохраняем соостояние в куки
    onChange(context, val) {
        if (window.IasConfig.devMode) console.debug(`${context.keyName} onChange`, arguments);
        return updatePage(context, val.newValue, val.newValue);
    }

    //сессия начинается с true
    render() {
        const context = this;
        return <CheckBoxField
            key={context.keyName} name={context.keyName} boxLabel={context.props.label || f.locale(context.keyName)}
            cls={'filter-field check-box'}
            hidden={context.props.hidden}
            onChange={val => context.onChange(context, val)}
            ref={fi => context.field = fi}
            checked={(context.props.hasOwnProperty('defaultValue')) ? context.props.defaultValue : document.getCookie(context.keyName)}/>
    }
}

//фильтр по актуальности
export class AcFilter extends CheckBoxFilter {
    keyName = 'acFilter';
    defaultValue = true;

    constructor() {
        super();
        document.setCookie(this.keyName, true);
    }
}

//фильтр по редактируемости
export class EditableFilter extends baseListFilter {
    keyName = 'editableFilter';
    defaultValue = null;

    constructor() {
        super();
        //фильтр охраняемый вид rf
        this.state = {
            data: [
                {value: 'true', title: 'не утвержденные'}, //не утвержденные редактируются, утвержденные не редактируются
                {value: 'false', title: 'утвержденные'},
            ]
        };
        document.setCookie(this.keyName, false);
    }
}

//фильтр по году
export class YearFilter extends baseFilter {
    keyName = 'yearFilter';

    constructor(props) {
        super(props);
        const curYear = (new Date()).getFullYear();
        if (!document.getCookie(this.keyName)) document.setCookie(this.keyName, curYear);
    }

    //сохраняет значение, если виден и очищается, если спрятан.
    getDefault() {
        if (this.props.hidden)
            return null;
        else
            return document.getCookie(this.keyName);
    }


    onChange(context, val, sender) {
        if (window.IasConfig.devMode) console.debug(`${context.keyName} onChange`, arguments);
        let saveValue = !isNaN(Number(val));
        if (val == '' || val == null) {
            return updatePage(context, val, false);
        }
        if (sender.getMinValue && sender.getMinValue()) saveValue = saveValue && (sender.getMinValue() <= val);
        if (sender.getMaxValue && sender.getMaxValue()) saveValue = saveValue && (sender.getMaxValue() >= val);
        if (saveValue)
            return updatePage(context, val, true);

    }

    render() {
        const context = this;
        return <SpinnerField
            key={context.keyName} name={context.keyName} label={context.props.label || f.locale(context.keyName)}
            cls={'filter-field number-field'}
            hidden={context.props.hidden}
            decimals={0} minValue={2000}
            maxValue={(new Date()).getFullYear() + 1}
            ref={fi => context.field = fi}
            onChange={(event) => context.onChange(context, event.newValue, event.sender)}
            value={document.getCookie(context.keyName) || context.props.defaultValue}
            required={context.state?.required}
        />
    }
}

//общий combo фильтр прописать наложение фильтра на запрос
class ComboFilter extends baseFilter {
    keyName = 'ComboFilter'
    static defaultProps = {
        hidden: true, required: false,
    }

    getDefault() {
        return document.getCookie(this.keyName.firstToLower()) || null;
        // return super.getDefault();
    }

    loadData = 'onload'  //момент загрузки справочника: 'onload'/'onvisible'

    dataParams = {
        tableName: 'hunt_users',
        titleField: 'user_name',
        idField: 'huntuser_id',
    }

//строка фильтра, если нужно
    getFilterString(context) {
        let result = '1=1 ';
        if (!(context.parentFilters && window.IasLoApp)) return '';
        context.parentFilters().map(f => {
            const value = document.getCookie(`${f.name}Filter`);
            const fltrs = window.IasLoApp.filters;
            //фильтр должен быть виден
            if (fltrs[f.name.firstToUpper() + 'Filter'] && (f.canbehidden || fltrs[f.name.firstToUpper() + 'Filter'].props.hidden == false) && value && value != 'false') {
                let filter = '';
                if (f.like)
                    filter += `${f.field} like '%${value}%'`;
                else if (f.any)
                    filter += ` ${value}=any(${f.field})`;
                else if (f.type == 'date')
                    filter += f.field + f.compare + `'${value}'`;
                else if (f.type == 'varchar' || f.type == 'text')
                    filter += f.field + f.compare + `'${value}'`;
                else filter += f.field + f.compare + value;
                if (f.isnull) filter += ` or ${f.field} is null`;
                result += ` and (${filter})`;
            }
        })
        return result;
    }

//проверка на родительские фильтры
    requiredFilter(context) {
        let result = true;
        if (!context.parentFilters) return result;
        context.parentFilters().map(f => {
            const value = document.getCookie(`${f.name}Filter`);
            result = result && ((!f.required) || (value && f.required))
        })
        return result;
    }

    constructor(props) {
        super(props);
        this.state = {data: []};
    }

    componentDidMount() {
        super.componentDidMount();
        const context = this;
        context.abortController = new AbortController();
        //автоматическая загрузка данных
        if (context.loadData == 'onload' || (context.loadData == 'onvisible' && !context.props.hidden)) {
            if (window.IasConfig.devMode) console.debug(`${context.keyName} load 'onload`);
            context.getData({context});
        }

    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const context = this;
        if (!this._isMounted) return;
            //обнуляем значение в поле, если в списке его нету
        if (context.getValue() && !context.state.data.find(i => i.value == context.getValue())) context.setValue(null);
        //проверям загружены ли данные, есть сохраненное значение для фильтра
        if (prevState.data.length == 0 && context.state.data.length > 0) {
            if (window.IasConfig.devMode) console.debug(`${context.keyName} set filter value `);
            const prevValue = document.getCookie(context.keyName);
            if (prevValue && !context.field.cmp.getValue())
                context.field.cmp.setValue(prevValue);
        }

        // загрузка данных по видимости

        if (context.loadData == 'onvisible' && !context.props.hidden && !context.state.loaded) {
            if (window.IasConfig.devMode) {
                console.debug(`${context.keyName} load 'onvisible'`);
            }
            context.getData({context});
        }

        if (context.loadData == 'onload' && !context.state.loaded) {
            if (window.IasConfig.devMode) console.debug(`${context.keyName} load 'onload' in update`);
            context.getData({context});
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        const context = this;
        context.abortController.abort();
        delete context.props.appViewPort.filters[context.keyName];
    }

    //обновляем куки и страницу
    onChange(context, val) {
        if (window.IasConfig.devMode) console.debug(`${context.keyName} onChange`, arguments);
        if (!context.state.loaded) return false;
        if (!f.exist(val))
            return updatePage(context, null, false);
        let saveValue = context.testValue(val);
        if (saveValue)
            return updatePage(context, val, true);
    }

    //проверям значение
    testValue(val) {
        return !isNaN(Number(val));
    }

    //запрос данных для фильтра
    getData(params) {
        const {context} = params;
        if (window.IasConfig.devMode) console.debug(`${context.keyName} get data`);
        // if (!context.requiredFilter(context)) {
        //     context.field.cmp.setValue(null);
        //     context.field.cmp.disable();
        //     return;
        // }
        //    context.field.cmp.enable();
        f.getRefData({
            context: context,
            abortController: context.abortController,
            tableName: context.dataParams.tableName,
            titleField: context.dataParams.titleField,
            idField: context.dataParams.idField,
            filterString: context.getFilterString(context),
            callback: (result) => {
                console.debug(`${context.keyName} getted data`,result,context);
                try {
                    if (this._isMounted)
                    if (context?.state)
                        if (result.data && result.data.length) {
                            context.setState({data: result.data, loaded: true});
                        }
                        else {
                            context.setState({data: [], loaded: true});
                        }
                } catch (e) {
                }
            }
        })
    }

    render() {
        const context = this;
        const gisElements = [];
        const gisFunc = (operation) => {
            if (!context.gisFeature) return;
            const olMap = context.props.appViewPort?.page?.olMap;
            if (!olMap) return;
            switch (operation) {
                case 'select':
                    // olMap.flash({features:context.gisFeature,context:olMap});
                    olMap.select({features: context.gisFeature, context: olMap});
                    break;
                case 'zoom':
                    olMap.gotoFeatures({features: context.gisFeature, context: olMap});
                    break;
            }

        }
        return <Panel><ComboBoxField
            key={context.keyName} name={context.keyName} label={context.props.label || f.locale(context.keyName)}
            hidden={context.props.hidden}
            disabled={(!context.requiredFilter(context)) && (!(context.state.data && context.state.data.length > 0))}
            cls={`filter-field combobox-field ${(context.props.gisSearch) ? 'gis-options' : ''}`}
            store={context.state.data}
            displayField="title" valueField="value"
            ref={fi => context.field = fi}
            listeners={{
                click: {
                    element: 'element',
                    fn: (sender, ...eOps) => {
                        if (context.props.gisSearch) {
                            let callback = void 0;
                            switch (sender.target.className) {
                                case  'x-after-input-el':
                                    callback = () => gisFunc('select');
                                    break;
                                case 'x-before-input-el':
                                    callback = () => gisFunc('zoom');
                                    break;
                                default:
                                    return;
                            }
                            const id = Number(document.getCookie(context.keyName));
                            if (isNaN(id)) return;
                            if (!context.gisFeature || Number(context.gisFeature[0].getProperties().gid) != id) {
                                g.getGeomById({
                                    context: context,
                                    tableName: context.gisParams.tableName,
                                    active: context.gisParams.active,
                                    id: id,
                                    callback: result => {
                                        getGeomFunc({context: context, result: result});
                                        callback(result);
                                    }
                                })
                            }
                            else {
                                callback();
                            }
                        }
                    }
                },
                change: (sender, newValue, oldValue, eOpts) => {
                    if (sender.getSelection() == null || (sender.getSelection().store)) {
                        context.onChange(context, newValue);
                        if (context.props.gisSearch) {
                            let callback = void 0;
                            const id = Number(document.getCookie(context.keyName));
                            if (isNaN(id)) return;
                            if (!context.gisFeature || Number(context.gisFeature[0].getProperties().gid) != id) {
                                let filters = '';
                                if (context.parentFilters) context.parentFilters().filter(f => f.gis && document.getCookie(f.name + 'Filter')).map(f => filters += `&${f.name}=${document.getCookie(f.name + 'Filter')}`);
                                g.getGeomById({
                                    context: context,
                                    tableName: context.gisParams.tableName,
                                    active: context.gisParams.active,
                                    id,
                                    filters,
                                    callback: result => {
                                        if (context.noLabel) result.features.map(f => f.properties.label = '');
                                        getGeomFunc({context: context, result: result});
                                        gisFunc('select');
                                        gisFunc('zoom');
                                    }
                                })
                            }
                            else {
                                gisFunc('select');
                                gisFunc('zoom');
                            }
                        }
                    }
                }
            }}
            queryMode="local"
            labelAlign="placeholder"
            value={document.getCookie(context.keyName) || context.props.defaultValue}
            clearable
            required={context.state?.required}
        />{gisElements}</Panel>
    }
}

//фильтр по району
export class RegionFilter extends ComboFilter {
    keyName = 'regionFilter';
    dataParams = {
        tableName: 'regions',
        titleField: 'region_name',
        idField: 'oktmo',
    };
    gisParams = {
        tableName: 'admpol',
    };
}

//фильтр по пользователям системы
export class LousersFilter extends ComboFilter {
    keyName = 'lousersFilter';
    dataParams = {
        tableName: 'log_users',
        titleField: 'fio',
        idField: 'u_id',
    }
    loadData = 'onload';
    parentFilters = () => [
        {name: 'ac', field: 'u_active', compare: '=', type: 'bool'},
        // {name: 'from', field: 's_date', compare: '>=', type: 'date'},
        // {name: 'to', field: 's_date', compare: '<=', type: 'date', isnull: true},
    ]

}

//фильтр по операциям сессий
export class SessionopsFilter extends baseListFilter {
    keyName = 'sessionopsFilter';
    defaultValue = null;

    constructor() {
        super();
        //фильтр охраняемый вид rf
        this.state = {
            data: [
                {value: 'ENTER', title: 'Вход'}, //не утвержденные редактируются, утвержденные не редактируются
                {value: 'LOGIN', title: 'Авторизация'},
                {value: 'LOGOUT', title: 'Выход'},
            ]
        };
    }
}

//фильтр по охотпользователю
export class UsersFilter extends ComboFilter {
    keyName = 'usersFilter';
    dataParams = {
        tableName: 'hunt_users_ref',
        titleField: 'user_name',
        idField: 'huntuser_id',
    }
    gisParams = {tableName: 'huntuserspol', active: true};
    loadData = 'onload';
    parentFilters = () => [
        {name: 'year', field: `date_part('year',date_on)`, compare: '<=', type: 'integer'},
        {name: 'to', field: 'date_on', compare: '<', type: 'date'},
        {name: 'year', field: `date_part('year',date_off)`, compare: '>=', type: 'integer', isnull: true},
        {name: 'to', field: 'date_off', compare: '>', type: 'date', isnull: true},
        {name: 'ac', field: 'active', compare: '=', type: 'bool'},
        {name: 'region', field: 'oktmo', like: true, gis: true},

    ]

    getDefault() {
        return document.getCookie(this.keyName.firstToLower()) || null;
        // return super.getDefault();
    }

}

//фильтр по охотхозяйству
export class EcosFilter extends ComboFilter {
    keyName = 'ecosFilter';
    dataParams = {
        tableName: 'eco_ref',
        titleField: 'economy_name',
        idField: 'economy_id',
    }
    gisParams = {tableName: 'ecospol', active: true};
    loadData = 'onvisible';
    parentFilters = () => [
        {name: 'users', field: 'economy_huntuser', compare: '=', type: 'integer'},
        {name: 'ac', field: 'm_active', compare: '=', type: 'bool'},
        {name: 'region', field: 'oktm', like: true},
        {name: 'to', field: 'date_on', compare: '<', type: 'date'},
        {name: 'to', field: 'date_off', compare: '>', type: 'date', isnull: true},

    ]
}

//фильтр по охотучастку
export class LandsFilter extends ComboFilter {
    keyName = 'landsFilter';
    dataParams = {
        tableName: 'lands_ref',
        titleField: 'label',
        idField: 'land_id',
    };
    gisParams = {tableName: 'landspol'};
    loadData = 'onvisible';
    noLabel = true;
    parentFilters = () => [
        {name: 'users', field: 'land_huntuser', compare: '=', type: 'integer'},
        {name: 'ecos', field: 'land_economy', compare: '=', type: 'integer'},
        {name: 'ac', field: 'land_active', compare: '=', type: 'bool'},
        {name: 'region', field: 'oktmo', like: true},
        {name: 'to', field: 'land_start_date', compare: '<', type: 'date'},
        {name: 'to', field: 'land_date_off', compare: '>', type: 'date', isnull: true},

    ]
}

//фильтр по исследуемой территории
export class ItFilter extends ComboFilter {
    keyName = 'itFilter';
    dataParams = {
        tableName: 'ea_view',
        titleField: 'area_name',
        idField: 'ea_id',
    }
    gisParams = {tableName: 'eapol'};
    loadData = 'onvisible';
    parentFilters = () => [
        {name: 'year', field: `acc_year`, compare: '=', type: 'integer', isnull: true},
        {name: 'region', field: 'oktmo', any: true},
        {name: 'users', field: 'huntuser', any: true},
    ]

    getDefault() {
        return document.getCookie(this.keyName.firstToLower()) || null;
        // return super.getDefault();
    }

}

//фильтр по МО
export class RoleFilter extends ComboFilter {
    keyName = 'roleFilter';
    dataParams = {
        tableName: 'roles',
        titleField: 'role_desc',
        idField: 'role_id',
    };
    loadData = 'onvisible';
}

/*фильтры для карты*/

//фильтр по МО
export class MoFilter extends ComboFilter {
    keyName = 'moFilter';
    dataParams = {
        tableName: 'mopol',
        titleField: 'mo_name',
        idField: 'mo_id',
    };
    gisParams = {tableName: 'mopol'};
    loadData = 'onvisible';
    parentFilters = () => [
        {name: 'region', field: 'oktmo', compare: '=', required: true},
    ]
}

//фильтр по населенным пунктам
export class PopFilter extends ComboFilter {
    keyName = 'popFilter';
    dataParams = {
        tableName: 'poppol',
        titleField: 'pop_name',
        idField: 'pop_id',
    };
    gisParams = {tableName: 'poppol'};
    loadData = 'onvisible';
    parentFilters = () => [
        {name: 'region', field: 'oktmo', compare: '=', required: true},
    ]
}

//фильтр по гидрополигонам
export class HdrpolFilter extends ComboFilter {
    keyName = 'hdrpolFilter';
    dataParams = {
        tableName: 'hdrpol',
        titleField: 'hdr_name',
        idField: 'hdr_id',
    };
    gisParams = {tableName: 'hdrpol'};
    loadData = 'onvisible';
    parentFilters = () => [
        {name: 'region', field: 'oktmo', compare: '=', required: true},
    ]
}

//фильтр по гидро линиям
export class HdrlinFilter extends ComboFilter {
    keyName = 'hdrlinFilter';
    dataParams = {
        tableName: 'hdrlin',
        titleField: 'hdr_name',
        idField: 'hdr_id',
    };
    gisParams = {tableName: 'hdrlin'};
    loadData = 'onvisible';
    parentFilters = () => [
        {name: 'region', field: 'oktmo', compare: '=', required: true},
    ]
}

//фильтр по лесничествам
export class FstFilter extends ComboFilter {
    keyName = 'fstFilter';
    dataParams = {
        tableName: 'fstpol',
        titleField: 'forest_name',
        idField: 'forest_id',
    };
    gisParams = {tableName: 'fstpol'};
    loadData = 'onvisible';
    parentFilters = () => [
        // {name: 'region', field: 'oktmo', compare: '=', required: true},
    ]
}

//фильтр по участковым лесничествам
export class FstlocFilter extends ComboFilter {
    keyName = 'fstlocFilter';
    dataParams = {
        tableName: 'fstlocpol',
        titleField: 'forestloc_name',
        idField: 'forestloc_id',
    };
    gisParams = {tableName: 'fstlocpol'};
    loadData = 'onvisible';
    parentFilters = () => [
        {name: 'fst', field: 'forest_id', compare: '=', required: true},
    ]
}

//фильтр по кварталам
export class FstqrtFilter extends ComboFilter {
    keyName = 'fstqrtFilter';
    dataParams = {
        tableName: 'fstqrtpol',
        titleField: 'quarter_num',
        idField: 'forestqrt_id',
    };
    gisParams = {tableName: 'fstqrtpol'};
    loadData = 'onvisible';
    parentFilters = () => [
        {name: 'fstloc', field: 'forestloc_id', compare: '=', required: true},
    ]
}

//фильтр по обоснованию участка
export class ReasonFilter extends ComboFilter {
    keyName = 'reasonFilter';
    loadData = 'onvisible';
    dataParams = {
        tableName: 'reasons',
        titleField: 'reason',
        idField: 'reason_id',
    }
}

//фильтр по типу угодий
export class LandtypeFilter extends ComboFilter {
    keyName = 'landtypeFilter';
    loadData = 'onvisible';
    dataParams = {
        tableName: 'land_types',
        titleField: 'land_type',
        idField: 'land_type_id',
    }
}

//фильтр по организации для контакта
export class ContactorgFilter extends ComboFilter {
    keyName = 'contactorgFilter';
    loadData = 'onvisible';
    dataParams = {
        tableName: 'contact_org',
        titleField: 'contact_org_name',
        idField: 'contact_org_id',
    }
}

//фильтр по типу операции - для лога
export class OpFilter extends ComboFilter {
    keyName = 'opFilter';
    dataParams = {
        tableName: 'log_ops',
        titleField: 'op_title',
        idField: 'op_name',
    }
    loadData = 'onvisible';

    testValue(val) {
        return true;
    }
}

//фильтр по таблице - для лога
export class TableFilter extends ComboFilter {
    keyName = 'tableFilter';
    dataParams = {
        tableName: 'log_tables',
        titleField: 'table_desc',
        idField: 'table_name',
    }
    loadData = 'onvisible';

    testValue(val) {
        return val.search(/[а-я]/g) == -1;
    }
}

//фильтр по типу документа
export class DoctypeFilter extends ComboFilter {
    keyName = 'doctypeFilter';
    loadData = 'onvisible';
    dataParams = {
        tableName: 'doc_type',
        titleField: 'type_name',
        idField: 'doc_type_id',
    }
}

//фильтр по организации документа
export class OrgFilter extends ComboFilter {
    keyName = 'orgFilter';
    loadData = 'onvisible';
    dataParams = {
        tableName: 'organisations',
        titleField: 'org_name',
        idField: 'org_id',
    }
}

//фильтр по характеру обнаружения
export class CharFilter extends ComboFilter {
    keyName = 'charFilter';
    loadData = 'onvisible';
    dataParams = {
        tableName: 'regions',
        titleField: 'region_name',
        idField: 'oktmo',
    }
}

//фильтр по встречаемому виду
export class MeetspecFilter extends ComboFilter {
    keyName = 'meetspecFilter';
    loadData = 'onvisible';
    dataParams = {
        tableName: 'meetings_spec',
        titleField: 'spec_name',
        idField: 'meeting_spec',
    }

    getDefault() {
        return document.getCookie(this.keyName.firstToLower()) || null;
        // return super.getDefault();
    }
}


//фильтр по виду ЗМУ
export class ZmuspecFilter extends ComboFilter {
    keyName = 'zmuspecFilter';
    loadData = 'onvisible';
    dataParams = {
        tableName: 'zmu_species',
        titleField: 'spec_name',
        idField: 'zmu_spec',
    }
    parentFilters = () => [
        {name: 'year', field: `acc_year`, compare: '=', type: 'integer'},
    ]

    getDefault() {
        return document.getCookie(this.keyName.firstToLower()) || null;
        // return super.getDefault();
    }
}

//фильтр по полу
export class MaleFilter extends baseListFilter {
    keyName = 'maleFilter'
    static defaultProps = {
        hidden: true, required: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            data: [
                {value: 0, title: 'самка'},
                {value: 1, title: 'самец'},
            ]
        };
    }
}

//фильтр по фамилии
export class FioFilter extends baseFilter {
    keyName = 'fioFilter';

    onChange(context, val, sender) {
        if (window.IasConfig.devMode) console.debug(`${context.keyName} onChange`, arguments);
        if (val == '' || val == null) {
            return updatePage(context, val, false);
        }
        return updatePage(context, val, true);
    }

    render() {
        const context = this;
        return <TextField
            key={context.keyName} name={context.keyName} label={context.props.label || f.locale(context.keyName)}
            cls={`filter-field text-field`}
            hidden={context.props.hidden}
            ref={fi => context.field = fi}
            listeners={{
                keyup: (sender, event) => {
                    return (event.altKey == false &&
                        event.ctrlKey == false &&
                        event.keyCode == 13) ? context.onChange(context, sender.getValue()) : ''
                },
                focusleave: (sender, event) => {
                    return context.onChange(context, sender.getValue());
                },
            }}
            value={document.getCookie(context.keyName) || context.props.defaultValue}
            required={context.state?.required}
        />
    }
}

//фильтр по номеру
export class NumFilter extends baseFilter {
    keyName = 'numFilter';

    onChange(context, val, sender) {
        if (window.IasConfig.devMode) console.debug(`${context.keyName} onChange`, arguments);
        if (val == '' || val == null) {
            return updatePage(context, val, false);
        }
        return updatePage(context, val, true);
    }

    render() {
        const context = this;
        return <TextField
            key={context.keyName} name={context.keyName} label={context.props.label || f.locale(context.keyName)}
            cls={`filter-field text-field`}
            hidden={context.props.hidden}
            ref={fi => context.field = fi}
            listeners={{
                keyup: (sender, event) => {
                    return (event.altKey == false &&
                        event.ctrlKey == false &&
                        event.keyCode == 13) ? context.onChange(context, sender.getValue()) : ''
                },
                focusleave: (sender, event) => {
                    return context.onChange(context, sender.getValue());
                },
            }}
            value={document.getCookie(context.keyName) || context.props.defaultValue}
            required={context.state?.required}
        />
    }
}

//фильтр по возрасту
export class AgeFilter extends baseFilter {
    keyName = 'ageFilter';

    onChange(context, val, sender) {
        if (window.IasConfig.devMode) console.debug(`${context.keyName} onChange`, arguments);
        if (val == '' || val == null) {
            return updatePage(context, val, false);
        }
        return updatePage(context, val, true);
    }

    render() {
        const context = this;
        return <TextField
            key={context.keyName} name={context.keyName} label={context.props.label || f.locale(context.keyName)}
            cls={`filter-field text-field`}
            ref={fi => context.field = fi}
            hidden={context.props.hidden}
            onChange={(event) => context.onChange(context, event.newValue, event.sender)}
            value={document.getCookie(context.keyName) || context.props.defaultValue}
        />
    }
}

//фильтр по встречаемому виду
export class AccspecFilter extends ComboFilter {
    keyName = 'accspecFilter';
    loadData = 'onvisible';
    dataParams = {
        tableName: 'acc_species',
        titleField: 'spec_label',
        idField: 'acc_spec',
    }
    parentFilters = () => {
        if (!(window.IasLoApp && window.IasLoApp.page)) return [];
        const accId = window.IasLoApp.page.accId;
        return [
            {
                name: (accId == 1) ? 'reestr' : (accId == 2) ? 'stock' : (accId == 3) ? 'monitoring' : 'acc',
                canbehidden: true,
                field: 'spec_acc',
                compare: '=',
                type: 'varchar',
                required: true
            },
        ]
    }

    getFilterString(context) {
        if (window.IasLoApp.page.accId == 5) return `spec_acc='perm'`;
        return super.getFilterString(context);
    }

    getDefault() {
        return document.getCookie(this.keyName.firstToLower()) || null;
        // return super.getDefault();
    }

}

//фильтр по учету
export class AccFilter extends ComboFilter {
    keyName = 'accFilter';
    keyName2 = 'accFilter';
    groupId = '1';

    loadData = 'onvisible'  //момент загрузки справочника: 'onload'/'onvisible'

    parentFilters = () => [
        {name: 'year', field: `year_on`, compare: '<=', type: 'integer'},
        {name: 'year', field: `year_off`, compare: '>=', type: 'integer', isnull: true}
    ]
    dataParams = {
        tableName: 'acc_full_desc',
        titleField: 'acc_name',
        idField: 'acc_id',
    }

    testValue(val) {
        return val.search(/[а-я]/g) == -1;
    }

    getFilterString(context) {
        let result = `group_id=${context.groupId}`;
        if (!(context.parentFilters && window.IasLoApp)) return '';
        context.parentFilters().map(f => {
            const value = document.getCookie(`${f.name}Filter`);
            if (f.name == 'acc') {
            }
            const fltrs = window.IasLoApp.filters;
            //фильтр должен быть виден
            if (fltrs[f.name.firstToUpper() + 'Filter'] && (f.canbehidden || fltrs[f.name.firstToUpper() + 'Filter'].props.hidden == false) && value && value != 'false') {
                let filter = '';
                if (f.like)
                    filter += `${f.field} like '%${value}%'`;
                else if (f.type == 'date')
                    filter += f.field + f.compare + `'${value}'`;
                else if (f.type == 'varchar' || f.type == 'text')
                    filter += f.field + f.compare + `'${value}'`;
                else filter += f.field + f.compare + value;
                if (f.isnull) filter += ` or ${f.field} is null`;
                result += ` and (${filter})`;
            }
        })
        return result;
    }
}

//фильтр по учету
export class StockFilter extends AccFilter {
    keyName = 'stockFilter';
    groupId = '2';

}

//фильтр по мониторинг
export class MonitoringFilter extends AccFilter {
    keyName = 'monitoringFilter';
    groupId = '3';

}

//фильтр по реестр
export class ReestrFilter extends AccFilter {
    keyName = 'reestrFilter';
    groupId = '1';

}

export class SpeciesFilter extends Component {
    keyName = 'speciesFilter'

    static defaultProps = {
        hidden: true, required: false,
    }
    dataParams = {}
    loadData = 'onload'  //момент загрузки справочника: 'onload'/'onvisible'

    constructor(props) {
        super(props);
        const context = this;
        context.state = {
            data: [],
            store: Ext.create('Ext.data.TreeStore', {
                rootVisible: true,
                root: {
                    expanded: true,
                    text: 'Систематика',
                    iconCls: 'x-fa fa-sitemap',
                    children: [],
                    specId: 0
                },
                proxy: {
                    type: 'memory',
                    reader: {
                        type: 'json',
                        rootProperty: 'children',
                    }
                }
            })
        }
    }

    getValue() {
        return this.state.id;
    }

    componentDidMount() {
        const context = this;
        //автоматическая загрузка данных
        if (context.loadData == 'onload') {
            if (window.IasConfig.devMode) console.debug(`${context.keyName} load 'onload`);
            context.getData({context, node: context.tree.cmp.getRootNode()});
        }
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const context = this;
        if (!context.tree) return;
        // загрузка данных по видимости
        if (context.loadData == 'onvisible' && !context.props.hidden && !context.getRootNode().loaded) {
            if (window.IasConfig.devMode) console.debug(`${context.keyName} load 'onvisible'`);
            context.getData({context, node: context.getRootNode()});
        }
        if (context.loadData == 'onload' && !context.getRootNode().loaded) {
            if (window.IasConfig.devMode) console.debug(`${context.keyName} load 'onload' in update`);
            context.getData({context, node: context.getRootNode()});
        }
    }

    // getDefault(){return null;}

    sendToSpecPage(context, nodes) {
        nodes.map(node => {
            //если без списка
            const id = (node.data.specRang < 8) ? node.data.specId : Number(node.data.specParent);
            if (1 == 0) {
                if (node.data.specRang < 7) return;
                context.props.appViewPort.page.setState({rowId: id});
            }
            else {
                context.setState({id}, () =>
                    updatePage(context, id, node.data.specRang < 8));
            }
        })
        //проверить уровень, если 7 отправить specId если 8 отправить parentId
        console.error('in constraction');
    }

    //развернуть всё дерево с загрузкой данных
    expandAll(context) {
        const root = context.getRootNode();
        const func = (node) => {
            if (node.loaded) {
                node.expand();
                node.childNodes.map(c => func(c));
            }
            else context.getData({context, node, func});
        }
        func(root);
    }

    //главный узел
    getRootNode() {
        return this.tree.cmp.getRootNode();
    }

    //перезагрузить узел
    reloadTree(context) {
        const node = context.tree.cmp.getRootNode();
        context.clearNode(context, node);
        context.getData({context, node});
    }

    //перезагрузить узел
    reloadNode(context, node) {
        context.clearNode(context, node);
        context.getData({context, node})
    }

    //очистить детей узла
    clearNode(context, node) {
        node.removeAll();
        node.loaded = false;
    }

    //запрос данных для узла
    getData(params) {
        const {context, node, callback} = params;
        if (window.IasConfig.devMode) console.debug(`${context.keyName} get data`);
        if (node.loaded) return;
        const filter = (context.filterField?.cmp) ? context.filterField.cmp.getValue() : null;
        if (filter) document.setCookie('specString', filter)
        else document.dropCookie('specString');
        f.getSpecData({
            context: context,
            filterString: filter,
            parentId: node.data.specId,
            callback: (result) => {
                if (!result.data || node.loaded) return;
                context.fillNode(node, result.data);
                node.loaded = true;
                if (callback) callback(node);
            }
        })
    }

    fillNode(node, data) {
        data.map(d => {
            const child = {
                specId: d.spec_id,
                text: d.spec_name,
                specParent: d.spec_parent,
                specRang: d.spec_rang,
                leaf: d.is_leaf
            };
            if (Number(d.spec_rang) == 7) child.iconCls = 'x-fa  fa-chevron-left';
            node.appendChild(child);
        });
        node.expand();
    }

    render() {
        const context = this;
        //  return <Panel html={'блин'}/>
        return <Panel
            hidden={context.props.hidden}
            layout={"fit"}
            docked={"top"}
            height={"50%"}
            minWidth={"100%"}
            scrollable={"y"}
            required={context.state?.required}
        >
            <Toolbar docked="top">
                <FieldSet
                    titile={"Поиск"}
                    layout={"hbox"}>
                    <TextField
                        placeholder={"Введите название для поиска"} ref={(f => context.filterField = f)}
                        cls={`filter-field text-field`}
                        value={document.getCookie('specString')}
                        listeners={
                            {
                                keyup: (sender, e, eOpts) => {
                                    if (e.keyCode == 13) context.reloadTree(context);
                                },
                                focusleave: {
                                    element: 'element',
                                    fn: (sender, event) => {
                                        context.reloadTree(context);
                                    }
                                }
                            }
                        }
                    />
                    <Button
                        ui={window.IasConfig.ui}
                        key={`${context.keyName}filter`}
                        name={`${context.keyName}filter`}
                        data-qtip={`найти/перезагрузить`}
                        data-qshowOnTap="true"
                        textAlign={'left'}
                        iconCls={'x-fa fa-arrow-right'}
                        handler={() => context.reloadTree(context)}/>

                    <Button
                        ui={window.IasConfig.ui}
                        key={`${context.keyName}expand`}
                        name={`${context.keyName}expand`}
                        data-qtip={`развернуть всё дерево`}
                        data-qshowOnTap="true"
                        textAlign={'left'}
                        iconCls={'x-fa fa-folder-open'}
                        handler={() => context.expandAll(context)}/>
                </FieldSet>
            </Toolbar>
            <Tree
                store={context.state.store}
                ref={(t => context.tree = t)}
                height={"100%"}
                minWidth={"100%"}
                key={context.keyName} name={context.keyName} label={f.locale(context.keyName)}
                selectOnExpander={false}
                singleExpand={true}
                highlightPath={true}
                expanderOnly={true}
                nameable={false}
                scrollable
                listeners={{
                    nodeexpand: (row, record, eOpts) => {
                        context.getData({context, node: record});
                    },
                    groupexpand: (row, group, eOpts) => {
                        console.error('in constraction');
                        context.getData({context, node: group});
                    },
                    select: (sender, selected, eOpts) => {
                        context.sendToSpecPage(context, selected);
                    }
                }}/>
        </Panel>
    }
}

//фильтр охраняемый вид lo
export class SavingloFilter extends baseListFilter {
    keyName = 'savingloFilter';

    constructor(props) {
        super(props);
        this.state = {
            data: [
                {value: 'false', title: 'не включен'},
                {value: 'true', title: 'включен'},
            ]
        };
    }
}

//фильтр охраняемый вид rf
export class SavingrfFilter extends baseListFilter {
    keyName = 'savingrfFilter';

    constructor(props) {
        super(props);
        this.state = {
            data: [
                {value: 'false', title: 'не включен'},
                {value: 'true', title: 'включен'},
            ]
        };
    }
}

//фильтр охотничий ресурс
export class HuntingFilter extends baseListFilter {
    keyName = 'huntingFilter';

    constructor(props) {
        super(props);
        this.state = {
            data: [
                {value: 'false', title: 'не охотничий ресурс'},
                {value: 'true', title: 'охотничий ресурс'},
            ]
        };
    }

}

//фильтр водный ресурс
export class WateringFilter extends baseListFilter {
    keyName = 'wateringFilter';

    constructor(props) {
        super(props);
        this.state = {
            data: [
                {value: 'false', title: 'не водный биологический ресурс'},
                {value: 'true', title: 'водный биологический ресурс'},
            ]
        };
    }
}

//фильтр водный ресурс
export class IsbirdFilter extends baseListFilter {
    keyName = 'isbirdFilter';

    constructor(props) {
        super(props);
        this.state = {
            data: [
                {value: 'false', title: 'не птица'},
                {value: 'true', title: 'птица'},
            ]
        };
    }
}

//блок работы с картой
export class MapFilters extends baseFilter {
    constructor() {
        super();
    }

    render() {
        const boxes = [];
        //район
        //охотпользователи
        //охотучастки
        //МО 3 уровень
        //населенный пункт
        //водный объект полигоны
        //водный объект линии
        //лесничество

        const panel = <Panel key={'nul'}></Panel>;
        return panel;
    }
}

//следующие 100, предыдущие 100
export class PageFilter extends Component {
    keyName = 'pageFilter'
    static defaultProps = {pageCount: 1, pageCurrent: 1}

    getValue() {
        return this.state.pageCurrent;
    }

    constructor(props) {
        super(props);
        const context = this;
        context.state = {pageCount: context.props.pageCount, pageCurrent: context.props.pageCurrent};
        document.setCookie(context.keyName, context.props.pageCurrent);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const context = this;
        if (document.getCookie(context.keyName) != nextState.pageCurrent && !context.props.hidden) {
            // document.setCookie(context.keyName, nextState.pageCurrent);
            context.updatePage(context, nextState);
        }
        return true;
    }

    updatePage(context, nextState) {
        updatePage(context, nextState.pageCurrent, true);
    }

    clearFilters = ['from', 'to', 'ac', 'region', 'fio',]

    render() {
        const context = this;
        const buttons = [];
        const getCount = () => {
            let buttonCount = Math.floor((parentWidth - 42) / 2 / 38) * 2 + 1;
            if (buttonCount > 10) buttonCount = 9;
            if (buttonCount < 3 || isNaN(buttonCount)) buttonCount = 3;
            return buttonCount;
        }
        buttons.push(<Button
            docked={'left'}
            region={'center'}
            disabled={context.state.pageCurrent < 2}
            cls={'no-print'}
            iconCls={`x-fa fa-backward`}
            key={'prev'}
            handler={() => context.setState({pageCurrent: 1})}/>);
        const parentWidth = (context.props.parent && context.props.parent.panel) ? context.props.parent.panel.cmp.innerElement.getWidth() : '100%';
        let buttonCount = getCount();
        let i = (context.state.pageCurrent < buttonCount / 2) ? 1 : context.state.pageCurrent - Math.floor(buttonCount / 2);
        let maxVal = i + buttonCount;
        if (maxVal > context.state.pageCount) maxVal = context.state.pageCount + 1;
        while (i < maxVal) {
            const val = ((i) => i)(i);
            buttons.push(<Button
                region={'center'}
                cls={`no-print ${(i == context.state.pageCurrent) ? 'current-page' : 'number-page'}`}
                text={i}
                key={`p${i}`}
                handler={() => context.setState({pageCurrent: val})}/>);
            i++;
        }
        buttons.push(<Button
            docked={'right'}
            disabled={(context.state.pageCurrent + 1) > context.state.pageCount}
            region={'center'}
            cls={'no-print'}
            iconCls={`x-fa fa-forward`}
            key={'last'}
            handler={() => context.setState({pageCurrent: context.state.pageCount})}/>);

        return (
            <Panel key={'pageCountPanel0'}>
                <Panel
                    cls={'page-count-panel'}
                    key={'pageCountPanel00'}
                    bodyCls={'page-count-body'}
                    hidden={context.props.hidden}
                    layout={'fit'}
                    maxWidth={parentWidth - 5}>
                    {/*<Panel key={'pageCountLabel'} html={'Переход по страницам'}></Panel>*/}
                    <Panel
                        cls={'page-count-panel'}
                        bodyCls={'page-count-body'}
                        key={'pageCountPanel000'}
                        hidden={context.props.hidden}
                        layout={'fit'}>
                        {buttons}
                    </Panel>
                </Panel>
            </Panel>
        );
    }

}
