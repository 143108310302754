import ErrorBoundary from "../../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../../Base/BaseSubGridPanel";
import * as f from "../../common/Funcs";

const keyName = `SpecDescSub`;

export default class SpecDescSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.subElementName = 'BaseEditPage';
        context.topMenuButtonNames=['reload'];
        context.idName ='spec_id';
        context.gridName=`SpecDescSubGrid`;
        context.noSingle=false;
    }

    addMenuButton(n) {
        const context = this;
        switch (n) {
            case 'add':
                if (context.isEditRight()&&context.state.data?.length==0)
                    context.addAddButton();
                break;
            case 'reload':
                context.addReloadButton();
                break;
        }
    }

    addAddButton() {
        const context = this;
        const values=new FormData();
        values.append('spec',context.props.rowId);
        const addFunc = () => {
            f.setObjectData({
                context: context,
                tableName: 'spec_desc',
                id: 'null',
                rowId: context.props.rowId,
                values,
                callback: ()=> {
                    context.reloadFunc.apply(context)
                }
            })
        };
        context.topMenuButtons.push({iconCls: 'x-fa fa-plus',
            html:`<label class="menu-label">Добавить</label>`,
            handler: addFunc})
    }
    //заголовок окна
    setDialogTitle(context) {
//        context.dialog.cmp.setTitle(`Краснокнижное описание вида ${context.grid.getCurrentRow()[`name_rus`]}`);
    }

}

