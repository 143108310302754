import React, {Component} from 'react';
import * as f from "./Funcs";
import {BaseButton, BaseTextButton} from "../Base/BaseButtons";

export class DropButton extends Component {
    render() {
        const props = this.props;
        return (<BaseButton name={'dropButton'}                             text={props.label|| f.locale(props.text)}
 iconCls={'fa-trash'} hidden={this.props.hidden}  func={this.props.func}/>)
    }
}

export class CancelButton extends Component {
    render() {
        const props = this.props;
        return (<BaseButton name={'cancelButton'}                             text={props.label|| f.locale(props.text)}
 iconCls={'fa-close'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class AddButton extends Component {
    render() {
        const props = this.props;
        return (<BaseButton name={'addButton'}                             text={props.label|| f.locale(props.text)}
 iconCls={'fa-plus'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class DownloadButton extends Component {
    render() {
        const props = this.props;
        return (<BaseButton name={'downloadButton'}                             text={props.label|| f.locale(props.text)}
 iconCls={'fa-download'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class UploadButton extends Component {
    render() {
        const props = this.props;
        return (<BaseButton name={'uploadButton'}                             text={props.label|| f.locale(props.text)}
 iconCls={'fa-upload'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class EditButton extends Component {
    render() {
        const props = this.props;
        return (<BaseButton name={'editButton'}  pressed={this.props.pressed}                              text={props.label|| f.locale(props.text)}
 iconCls={'fa-edit'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class ReloadButton extends Component {
    render() {
        const props = this.props;
        return (<BaseButton name={'reloadButton'}                             text={props.label|| f.locale(props.text)}
 iconCls={'fa-refresh'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class RepeatButton extends Component {
    render() {
        const props = this.props;
        return (<BaseButton
            text={props.label|| f.locale(props.text)}
            name={'repeatButton'}                             text={props.label|| f.locale(props.text)}
 iconCls={'fa-repeat'} text={'Пересчитать'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class PrintButton extends Component {
    render() {
        const props = this.props;
        const func = (props.func) ? props.func : () => window.print();
        return (<BaseButton name={'printButton'}
                            text={'Печать'||props.label|| f.locale(props.text)}
                            iconCls={'fa-print'} hidden={props.hidden} func={func}/>)
    }
}

export class HtmlButton extends Component {
    render() {
        const props = this.props;
        const func = (props.func) ? props.func : () => window.print();
        return (<BaseButton name={'printButton'}
                            text={props.label|| f.locale(props.text)}
                            iconCls={'fa-print'} hidden={this.props.hidden} func={func}/>)
    }
}

export class XlsButton extends Component {
    render() {
        const props = this.props;
        const func = (props.func) ? props.func : () => {
            if (!props.context.grid.grid) f.alert('Нет данных для экспорта.');
            props.context.grid.grid.cmp.saveDocumentAs({
                type: 'xlsx',
                title: props.context.title,
                fileName: `${props.context.title}.xlsx`
            });
        }
        return (<BaseButton name={'xlsButton'} iconCls={'fa-file-excel-o'}
                            text={props.label|| f.locale(props.text)}
                            hidden={this.props.hidden} func={func}/>)
    }
}

export class ClipboardButton extends Component {
    render() {
        const props = this.props;
        const func = (props.func) ? props.func : () => {
            const row = props.context.grid.getCurrentRow();
            if (!row) return navigator.clipboard.writeText('строка не выделена');
            const grid = props.context.grid.grid;
            const data = Object.keys(row).reduce((s, k) => {
                //колонка, название
                const z = grid.cmp.getColumns().find(c => c._dataIndex == k);
                if (z) {
                    let value = row[k];
                    switch (value) {
                        case true:
                            value = 'Да';
                            break;
                        case false:
                            value = 'Нет';
                            break;
                        case null:
                            value = '';
                            break;
                    }
                    return s + `, ${z._text}: ${value}`;
                } else {
                    return s;
                }
            }, '')

            navigator.clipboard.writeText(data.substring(2)).then((e)=>
            f.toast({title:'скопированно',message:data.substring(2),timeout:1000}));
        }
        return (<BaseButton name={'clipboardButton'}                             text={props.label|| f.locale(props.text)}
 iconCls={'fa-copy'} hidden={this.props.hidden} func={func}/>)
    }
}

export class SaveButton extends Component {
    render() {
        return (<BaseTextButton name={'saveButton'} text={'save'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class SaveCloseButton extends Component {
    render() {
        return (<BaseTextButton name={'saveCloseButton'} text={'saveclose'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class CloseButton extends Component {
    render() {
        return (<BaseTextButton name={'closeButton'} text={'close'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class DeleteButton extends Component {
    render() {
        return (<BaseTextButton name={'deleteButton'} text={'delete'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class CopyButton extends Component {
    render() {
        return (<BaseTextButton name={'copyButton'} text={'copy'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

export class ChildButton extends Component {
    render() {
        return (<BaseTextButton name={'childButton'} text={'child'} hidden={this.props.hidden} func={this.props.func}/>)
    }
}

