import ErrorBoundary from "../../ErrorBoundary";
import BaseAccPage from  "../../Base/BaseAccPage";
import * as f from "../../common/Funcs";

const keyName = `ReestrsPage`;

export default class ReestrsPage extends BaseAccPage {
    static defaultProps={acc:'reestr'};
    constructor(props) {
        super(props);
        const context = this;
        context.accId='1';
        context.filters.push({name:'reestr',required:true,message:f.locale('needreestr')});
         context.filters.push({name:'accspec'});
        //context.hiddenColumns=[context.idName];
    }
}
