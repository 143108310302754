import * as f from "../../common/Funcs";
import BaseEditPage from "../../Base/BaseEditPage";
const keyName='HuntCodesPage';

export default class HuntCodesPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'huntusers_codes';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.idName = 'row_id';
        context.titleName = 'hu_name';
        context.fieldsSet = [
            {title: 'Коды'},
        ];
        context.footerButtonNames=['saveclose','save','copy','delete','close'];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {column_name:'codes_huntuser',hidden:true},
            {column_name:'code_active',pos:0,defaultValue:true},
        {column_name: "row_id", pos: 20, },
        // {column_name: "date_off", row: 0,onChange: (sender, record, newValue, oldValue) => {
        //     return    record['code_active'] = (newValue == null);
        //     } },
        {column_name: 'date_on',row:0, defaultValue: new Date()},
            {column_name: 'inn', row:0,},
            {column_name: 'kpp', row:1,},
            {column_name: 'okved', row:2},
            {column_name: 'okpo', row:3},
            {column_name: 'oktmo', row:4},
            {column_name: 'okonh',row:5},
            {column_name: 'ogrn',row:6},
        ]]
        context.subTables=[
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: ()=>!f.isAdmin(),
                filterString: "table_name ='huntusers_codes' and record_id = $row_id$::varchar",
            }
        ]
    }
}


