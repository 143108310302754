import ErrorBoundary from "../../ErrorBoundary";
import BaseSubGrid from "../../Base/BaseSubGrid";

const keyName = `HuntDescSubGrid`;

export default class HuntDescSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'huntusers_description';
        //элемент окна редактирования
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName, 'desc_huntuser'];
        context.columnsDefaults = [
            {column_name: "full_name", title: true},
            {column_name: "actual_address", title: true}
        ];
        context.singleColumnsCount=1;
        context.singleColumns=[
            {column_name:'desc_huntuser',row:10,hidden:true},
            {column_name: "full_name", row: 1 ,hidden:true},
            {column_name: "short_name", row: 2},
            {column_name: "legal_address", row: 3},
            {column_name: "actual_address", row: 4},
            {column_name: "row_id", pos: 20, hidden:true},
            // {column_name: "date_off", row: 3, },
            {column_name: 'date_on', row:3, defaultValue: new Date()},
        ];
    }

}