import * as f from "../../common/Funcs";
import BaseEditByMapPage from "../../Base/BaseEditByMapPage";


const keyName = "DtpPage";
export default class DtpPage extends BaseEditByMapPage {
    constructor(props) {
        super(props);
        const context = this;
        context.columnsCount = 2;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'dtp';
        context.fieldsSet = [
            {title: 'Факт встречи',fieldset: 'meeting'},
            {title: 'Описание животного', fieldset: 'spec'},
            {title:'Карта',fieldset:'map',pos:10,column:1},
            {title: 'Документы',fieldset: 'doc'},
        ];
        context.columnsDefaults = [...context.lastUserColumns,...context.columnsDefaults, ...[
            {column_name: 'dtp_fio', fieldset: 'meeting', pos:7,row:10,},
            {column_name: 'dtp_date', fieldset: 'meeting', pos:7,row:6,},
            {
                column_name: 'dtp_condition', pos:0,row:0,
                is_list: true,
                hidden: false,
                fieldset: 'meeting',
                source: {
                    tableName: 'dtp_condition',
                    titleField: 'dtp_condition_name',
                    idField: 'dtp_condition_id',
                }
            },
            {
                column_name: 'dtp_male', pos:2,row:0,
                is_list: true,
                hidden: false,
                fieldset: 'spec',
                data: [{value: false, title: 'самка'}, {value: true, title: 'самец'}]
            },
            {column_name: 'dtp_olds', fieldset: 'spec', pos:3,row:0,},
            {column_name: 'meeting_count', fieldset: 'spec', pos:1,row:1,},
            {column_name: 'dtp_embcount', fieldset: 'spec', pos:2,row:1,},
            {column_name: 'dtp_doc_case', fieldset: 'doc', pos:0,row:0,},
            {column_name: 'dtp_init_proc', fieldset: 'doc', pos:0,row:1,},
            {column_name: 'dtp_result', fieldset: 'doc', pos:0,row:2,},
            {column_name: 'meeting_direction',hidden:true}
        ]];
        context.columnsDefaults.filter(c => (c.column_name == 'meeting_type'))[0].value=2;
        context.subTables=[
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='dtp' and record_id = $row_id$::varchar",
            }
        ]

        //context.columnsDefaults.push();
    }


    //заголовок формы редактирования по умолчанию
    titleFunc(context, record,parentRow) {
        try {
            context.title= `ДТП от ${((record['dtp_date']) ? new Date(record['dtp_date']).toLocaleDateString() : '--.--.-- ')}`;
        } catch (e) {
            context.title= `ДТП --.--.-- `;
        }
        return context.title;
    }

}


