import React, {Component} from 'react';
import {
    Panel,
    TextField,
    SpinnerField,
    DatePickerField,
    TextAreaField,
    NumberField,
    ComboBoxField,
    CheckBoxField, FileField, Button, Img, ExtTimefield
} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";
import SimpleEditMap from "../Maps/SimpleEditMap";


export default class EditFormField extends Component {
    static defaultProps = {
        columnDescription: {is_list: false, source: null},
        value: null,
        record: null,
    }

    constructor(props) {
        super(props);
        const context = this;
        const c = context.props.columnDescription;
        context.state = {data: (c.data) ? c.data : []};
        if (c.is_list && c.source) {
            // f.getRefData(context, c.source.tableName, c.source.titleField, context.getFilterStringFromRow(), (result) => {
            //     context.setState({data: result.data});
            // })
        }
    }

    toBool(val){
        return (typeof(val)=='function')? val(): Boolean(val);
    }

    setValue(value) {
        const context = this;
        if (context.field && context.field.cmp)
            if (context.field.cmp.select)
                context.field.cmp.select(value);
            else
                context.field.cmp.setValue(value);
    }

    copyFunc(sender) {
        let el = sender.currentTarget.getElementsByTagName('input')[0];
        if (!el)
            el = sender.currentTarget.getElementsByTagName('textarea')[0];
        navigator.clipboard.writeText(el.value);
        return f.toast({title: 'скопированно', message: el.value, timeout: window.IasConfig.messageTimeout});
    }

    getComboField(c, value) {
        const context = this;
        let cnt = 0;
        const setValue = () => {
            try {
                if (cnt > 10) debugger;
                if (context.field) context.field.cmp.setValue(value);
                else {
                    cnt++;
                    setTimeout(() => setValue(), 100);
                }
            } catch (e) {
                debugger;
            }
        }
        return (<ComboBoxField
            cls={`edit-field copy-class ${(c.cls) ? c.cls : ''} ${(c.disabled || context.toBool(c.readonly)) ? ' underline ' : ''}`}
            key={`${c.column_name}`}
            name={`${c.column_name}`}
            hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
            disabled={context.toBool(c.disabled)}
            readonly={context.toBool(c.readonly)}
            required={c.required}
            clearable
            store={context.state.data}
            value={value}
            displayField="title"
            valueField="value"
            label={c.column_label || f.locale(c.column_name)}
            labelAlign={c.labelAlign || 'placeholder'}
            labelMinWidth={c.labelMinWidth}
            ref={(fi => {
                context.field = fi
            })}
            listeners={{
                click: {
                    element: 'element',
                    fn: (sender, ...eOps) => {
                        if (sender.target.className == 'x-after-input-el') context.copyFunc(sender);
                    }
                },
                change: (sender, newValue, oldValue) => {
                    if (c.onChange) c.onChange(context.field, context.props.record, newValue, oldValue);
                },
                focusleave: (sender, event) => {
                    if (c.onFocusLeave) c.onFocusLeave(context.field, sender.getValue(), sender)
                }
            }}

            onBeforePickerCreate={(sender, picker, eOpts) => {
                debugger;
            }
            }
            onReady={() => setValue()}
        />)
    }

    getPathField(c, cname, value) {
        if (cname.indexOf('photo') > -1)
            return <Img key={cname} name={cname} src={`/imgs/${value}`} height={'100px'} className={'photo'}/>
        else {
            const context = this;
            const buttons = [];
            if (value) {
                if (!context.props.readonly)
                    buttons.push(<Button
                        handler={() => {
                            context.field.cmp.setValue(null);
                            context.del_button.cmp.hide();
                            context.button.cmp.hide();
                        }}
                        key={cname + 'delete'}
                        name={cname + 'delete'}
                        text={'удалить ' + (c.button_label||c.column_label || '')}
                        ref={f => context.del_button = f}
                        ui={window.IasConfig.ui}
                    />);
                buttons.push(<Button
                    handler={() => {
                        window.open(`${window.IasConfig.homePath}files/${c.path||''}${value}`)
                    }}
                    key={cname + 'load'}
                    name={cname + 'load'}
                    text={'скачать ' + (c.button_label||c.column_label || '')}
                    ui={window.IasConfig.ui}
                    ref={f => context.button = f}
                />);
            }
            return <Panel layout={'hbox'}>{buttons}<TextField key={cname} name={cname} hidden={true} value={value}
                                                              ref={(fi => context.field = fi)}
            /></Panel>;
        }
    }

    getUploadField(c, cname, value) {
        const context = this;
        const onChange = (field) => {
            context.file = {file: field.sender.getFiles()["0"]};
            if (c.onChange) c.onChange(context.field, context.props.record, context.file);
        };
        return <Panel key={'fileFieldPanel'} layout={'hbox'} bodyCls={'upload-panel-body'}><FileField
            label={c.column_label || f.locale(cname)}
            labelAlign={c.labelAlign || 'placeholder'}
            labelMinWidth={c.labelMinWidth}
            ref={f => this.file = f}
            key={`${context.props.tableName}${cname}file`}
            name={`${cname}file`}
            required={c.required}
            hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
            onChange={onChange}
        /><TextField key={`${context.props.tableName}${cname}value`} name={`${cname}value`} hidden={true} value={value}
                     ref={(fi => this.field = fi)}/></Panel>
    }

    getRefData(context) {
        const c = context.props.columnDescription;
        if (c.is_list && c.source) {
            const params = {
                context: context,
                tableName: c.source.tableName,
                idField: c.source.idField,
                titleField: c.source.titleField,
                filterString: context.getFilterStringFromRow(),
                callback: (result) => {
                    if (result.data && result.data.length)
                        context.setState({data: result.data, loaded: true});
                    else
                        context.setState({data: [], loaded: true});
                    // const value = (context.props.isNew) ? (c.value ||  ((typeof(c.defaultValue)=='function')?c.defaultValue(): c.defaultValue)) : context.props.value;
                    const value = context.props.value;
                    const set = () => {
                        if (!context.field) setTimeout(set, 100); else context.field.cmp.setValue(value);
                    }
                    set();
                }
            };
            f.getRefData(params);
        } else if (c.data) {
            context.setState({data: c.data, loaded: true});
            if (context.props.value) {
                const value = context.props.value;
                const set = () => {
                    if (!context.field) setTimeout(set, 100); else context.field.cmp.setValue(value);
                }
                set();
            }
        }
    }

    getFilterStringFromRow() {
        const context = this;
        const c = context.props.columnDescription;
        const record = context.record || context.props.record;
        if (!(c.source && (c.source.filterString || context.toBool(c.disabled)))) return '';
        let value = c.value || context.props.value;
        let filterString = (c.disabled) ? `${c.source.idField}=${value}` : c.source.filterString;
        // let filterString = (c.disabled)?`${c.source.idField}=${c.value}`:c.source.filterString ;
        //выполнить замену подстановок в строке фильтра
        let array = filterString.match(/\$[^\$]*\$/g);
        if (array) {
            array = array.map(e => e.replaceAll('$', ''));
            array.map(a => filterString = filterString.replace(`$${a}$`, (record[a] && record[a].toLocaleDateString) ? record[a].toLocaleDateString() : record[a]));
            //    array.map(a => (a=='user_id')?'':filterString = filterString.replace(`$${a}$`, (record[a] && record[a].toLocaleDateString) ? record[a].toLocaleDateString() : record[a]));
        }
        return filterString;
    }

    getCheckBoxField(c, cname, value) {
        const context = this;
        return <CheckBoxField
            key={`${context.props.tableName}${cname}`}
            cls={`edit-field  copy-class ${(c.cls) ? c.cls : ''} ${(c.disabled || context.toBool(c.readonly)) ? ' underline ' : ''}`}
            name={`${cname}`}
            boxLabel={c.column_label || f.locale(cname)}
            hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
            disabled={context.toBool(c.disabled) || context.toBool(c.readonly)}
            readonly={context.toBool(c.readonly)}
            maxWidth={c.width}
            boxLabelAlign={'before'}
            bodyAlign={'left'}
            ref={(fi => this.field = fi)}
            onChange={(event) => {
                if (c.onChange) c.onChange({
                    sender: context.field,
                    elements: context.props.parent.elements,
                    newValue: event.newValue,
                    oldValue: event.oldValue
                });
            }}
            checked={(value || value == 'Yes')}/>
    }

    getTextField(c, cname, value) {
        const context = this;
        // return <Panel
        //     hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
        // >
        return <TextField
            key={`${context.props.tableName}${cname}`}
            name={`${cname}`}
            cls={`edit-field copy-class ${(c.cls) ? c.cls : ''} ${(c.disabled || context.toBool(c.readonly)) ? ' underline ' : ''}`}
            label={(cname.indexOf('photo_label') > -1) ? '' : (c.column_label || f.locale(cname))}
            labelAlign={c.labelAlign || 'placeholder'}
            labelMinWidth={c.labelMinWidth}
            grow={true}
            maxWidth={c.maxWidth}
            minWidth={c.minWidth}
            disabled={context.toBool(c.disabled)}
            hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
            required={c.required}
            readonly={context.toBool(c.readonly)}
            onChange={(sender, newValue, oldValue) => {
                if (c.onChange) c.onChange(context.field, context.props.record, newValue, oldValue);
            }}
            listeners={{
                click: {
                    element: 'element',
                    fn: (sender, ...eOps) => {
                        if (sender.target.className == 'x-after-input-el') context.copyFunc(sender);
                    }
                }
            }}
            ref={(fi => this.field = fi)}
            value={value}/>
        // </Panel>
    }

    getAreaField(c, cname, value) {
        const context = this;
        return <TextAreaField
            key={`${context.props.tableName}${cname}`}
            name={`${cname}`}
            cls={`edit-field copy-class ${(c.cls) ? c.cls : ''} ${(c.disabled || context.toBool(c.readonly)) ? ' underline ' : ''}`}
            label={(cname.indexOf('photo_label') > -1) ? '' : (c.column_label || f.locale(cname))}
            labelAlign={c.labelAlign || 'placeholder'}
            labelMinWidth={c.labelMinWidth}
            hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
            maxWidth={c.maxWidth}
            minWidth={c.minWidth}
            height={c.height}
            disabled={context.toBool(c.disabled)}
            readonly={context.toBool(c.readonly)}
            required={c.required}
            onChange={(sender, newValue, oldValue) => {
                if (c.onChange) c.onChange(context.field, context.props.record, newValue, oldValue);
            }}
            ref={(fi => this.field = fi)}
            listeners={{
                click: {
                    element: 'element',
                    fn: (sender, ...eOps) => {
                        if (sender.target.className == 'x-after-input-el') context.copyFunc(sender);
                    }
                }
            }}
            value={value}/>
    }

    getNumericField(c, cname, value) {
        const context = this;
        return <NumberField
            key={`${context.props.tableName}${cname}`}
            cls={`edit-field copy-class ${(c.cls) ? c.cls : ''} ${(c.disabled || context.toBool(c.readonly)) ? ' underline ' : ''}`}
            name={`${cname}`}
            label={c.column_label || f.locale(cname)}
            labelAlign={c.labelAlign || 'top'}
            labelMinWidth={c.labelMinWidth}
            minValue={c.minValue}
            maxValue={c.maxValue}
            decimals={2}
            required={c.required}
            hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
            maxWidth={c.width}
            readonly={context.toBool(c.readonly)}
            disabled={context.toBool(c.disabled)}
            onChange={(sender, newValue, oldValue) => {
                if (c.onChange) c.onChange(context.field, context.props.record, newValue, oldValue);
            }}
            ref={(fi => this.field = fi)}
            listeners={{
                click: {
                    element: 'element',
                    fn: (sender, ...eOps) => {
                        if (sender.target.className == 'x-after-input-el') context.copyFunc(sender);
                    }
                }
            }}
            value={value}/>
    }

    render() {
        const context = this;
        const c = context.props.columnDescription;
        c.readonly = context.toBool(c.readonly) || context.props.readonly;
        c.disabled = context.toBool(c.disabled) || context.props.disabled;
        let result = null;
        const cname = context.props.cname || c.column_name;
        let value = (f.exist(context.props.value)) ? context.props.value : c.value;
        //собственная функция отрисовки
        switch (typeof (c.renderer)) {
            case 'object':
                return c.renderer;
            case 'function':
                return c.renderer(c);
        }

        if (value && ['integer', 'bigint', 'numeric'].indexOf(c.data_type) > -1) value = Number(value);
        if (c['is_pkey']) {
            //по умолчанию скрываем ключени, если не прописано другое
            if (!(c.disabled == false)) c.disabled = true;
            c.required = false;
            return context.getTextField(c, cname, value);
        }

        //список
        if ((c.is_list && (c.source)) || c.data) {
            //если справочник - подгружаем
            result = context.getComboField(c, value);
        }

        //прочее
        else {
            switch (c.data_type) {
                case "path":
                    result = context.getPathField(c, cname, value);
                    break;
                case "upload":
                    result = context.getUploadField(c, cname, value);
                    break;
                case "jsonb":
                case "character varying":
                case "text":
                    if (c.max_length > 1024 || (c.max_length == null && (value) && value.length > 128)) {
                        result = context.getAreaField(c, cname, value);
                    } else
                        result = context.getTextField(c, cname, value);
                    break;
                case "password":
                    //!!!доделать
                    const onSetPas = (field) => {
                        const val1 = context.pas1.cmp.value;
                        const val2 = context.pas2.cmp.value;
                        if (val1 == val2) context.pas.cmp.setValue(val1); else context.pas.cmp.setValue(null);
                    }
                    result = <Panel
                        key={`${context.props.tableName}${cname}panel`}
                        maxWidth={c.width}
                        cls={'edit-field'}
                        hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
                    ><TextField
                        key={`${context.props.tableName}${cname}`}
                        name={`${cname}`}
                        hidden={true}
                        ref={(p => {
                            context.pas = p;
                            context.field = p;
                        })}
                        value={value}/>
                        <TextField
                            key={`${context.props.tableName}${cname}1`}
                            name={`${cname}1`}
                            label={c.column_label || f.locale(cname)}
                            ref={(p => context.pas1 = p)}
                            grow={true}/>
                        <TextField
                            key={`${context.props.tableName}${cname}2`}
                            name={`${cname}2`}
                            label={c.column_label || f.locale(cname)}
                            ref={(p => context.pas2 = p)}
                            grow={true}/></Panel>
                    break;
                case "boolean":
                    result = context.getCheckBoxField(c, cname, value);
                    break;
                case "integer":
                case "bigint":
                    result = <SpinnerField
                        key={`${context.props.tableName}${cname}`}
                        cls={`edit-field copy-class ${(c.cls) ? c.cls : ''} ${(c.disabled || context.toBool(c.readonly)) ? ' underline ' : ''}`}
                        name={`${cname}`}
                        label={c.column_label || f.locale(cname)}
                        labelAlign={c.labelAlign || 'top'}
                        labelMinWidth={c.labelMinWidth}
                        hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
                        minValue={c.minValue}
                        maxValue={c.maxValue}
                        maxWidth={c.width}
                        disabled={context.toBool(c.disabled)}
                        readonly={context.toBool(c.readonly)}
                        required={c.required}
                        onChange={(sender, newValue, oldValue) => {
                            if (c.onChange) c.onChange(context.field, context.props.record, newValue, oldValue);
                        }}
                        ref={(fi => this.field = fi)}
                        listeners={{
                            click: {
                                element: 'element',
                                fn: (sender, ...eOps) => {
                                    if (sender.target.className == 'x-after-input-el') context.copyFunc(sender);
                                }
                            }
                        }}
                        value={value}/>
                    break;
                case "numeric":
                    result = context.getNumericField(c, cname, value);
                    break;
                case "timestamp without time zone":
                case  "date":
                    result = <DatePickerField
                        label={c.column_label || f.locale(cname)}
                        labelAlign={c.labelAlign || 'top'}
                        labelMinWidth={c.labelMinWidth}
                        cls={`edit-field copy-class ${(c.cls) ? c.cls : ''} ${(c.disabled || context.toBool(c.readonly)) ? ' underline ' : ''}`}
                        key={`${context.props.tableName}${cname}`}
                        name={`${cname}`}
                        required={c.required}
                        dateFormat='d.m.Y'
                        hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
                        maxWidth={c.width}
                        disabled={context.toBool(c.disabled)}
                        readonly={context.toBool(c.readonly)}
                        ref={(fi => this.field = fi)}
                        listeners={{
                            click: {
                                element: 'element',
                                fn: (sender, ...eOps) => {
                                    if (sender.target.className == 'x-after-input-el') context.copyFunc(sender);
                                }
                            }
                        }}
                        onChange={(sender, newValue, oldValue) => {
                            if (c.onChange) c.onChange(context.field, context.props.record, newValue, oldValue);
                        }}
                        value={(value) ? new Date(value) : null}/>
                    break;
                case "time without time zone":
                case  "time":
                    result = <ExtTimefield
                        label={c.column_label || f.locale(cname)}
                        labelAlign={c.labelAlign || 'top'}
                        labelMinWidth={c.labelMinWidth}
                        cls={`edit-field copy-class ${(c.cls) ? c.cls : ''} ${(c.disabled || context.toBool(c.readonly)) ? ' underline ' : ''}`}
                        key={`${context.props.tableName}${cname}`}
                        name={`${cname}`}
                        required={c.required}
                        format='H:i'
                        hidden={context.toBool(c.hidden) || (c.readonly && (!value || value.length == 0))}
                        maxWidth={c.width}
                        disabled={context.toBool(c.disabled)}
                        readonly={context.toBool(c.readonly)}
                        ref={(fi => {
                            this.field = fi;
                        })}
                        listeners={{
                            click: {
                                element: 'element',
                                fn: (sender, ...eOps) => {
                                    if (sender.target.className == 'x-after-input-el') context.copyFunc(sender);
                                }
                            }
                        }}
                        onChange={(sender, newValue, oldValue) => {
                            if (c.onChange) c.onChange(context.field, context.props.record, newValue, oldValue);
                        }}
                        onReady={() => {
                            if (value) {
                                context.field.cmp.setValue(value.substr(0, 5));
                            }
                        }}
                        value={(value) ? value.substr(0, 5) : null}/>
                    break;
                case 'geoJson':
                    const fld = <TextAreaField
                        key={`geom`}
                        name={`geom`}
                        hidden={true}
                        value={(typeof (value) == 'string') ? value : JSON.stringify(value)}
                        required={c.required}
                        ref={(geomField) => {
                            // if (!geomField) return;
                            const f = () => {
                                try {
                                    if (context.props.dialog.olMap) {
                                        context.field = geomField;
                                        context.props.dialog.olMap.geomField = geomField
                                    }
                                    // else {
                                    //     setTimeout(() => f(), 100);
                                    // }
                                } catch (e) {
                                    debugger;
                                }
                            };
                            f();
                        }}
                    />;
                    const record = context.record || context.props.record;
                    result = <Panel
                        cls={'edit-field'}
                        maxWidth={c.width}
                    ><SimpleEditMap
                        height={c.height || 400} width={c.width || '100%'}
                        readonly={context.toBool(c.readonly)}
                        record={record}
                        parent={context.props.parent}
                        gotoCls={c.gotoCls}
                        load={'auto'}
                        mapButtonNames={c.mapButtonNames}
                        ref={(olMap) => {
                            context.props.parent.olMap = olMap;
                            context.props.dialog.olMap = olMap;
                        }}
                        onAddFeatures={c.onAddFeatures}
                        onRemoveFeatures={c.onRemoveFeatures}
                        layerNames={c.layerNames || []}
                        objects={(value) ? [{
                            type: 'Feature',
                            properties: {id: record["row_id"]},
                            geometry: (typeof (value) == 'string') ? JSON.parse(value) : value
                        }] : []}/>
                        {fld}
                    </Panel>;
                    break;
                default:
                    result = <Panel>
                        <label hidden={context.toBool(c.hidden)}>
                            <span>{`Ошибка в ${cname} тип поля ${c.data_type} не описан в системе`}</span>
                        </label>
                    </Panel>
            }
        }
        return result;
    }
}