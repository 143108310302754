import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import BaseRelationSub from "../Base/BaseRelationSub";
import * as f from "../common/Funcs";
const keyName = `DocumentsSub`;

export default class DocumentsSub extends BaseRelationSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.gridName=`DocumentsSubGrid`;
        context.idName = 'doc_id';
        context.topMenuButtonNames=(props.topMenuButtonNames)?props.topMenuButtonNames: ['reload','add','edit','relation'];
        context.hiddenColumns = ['doc_id', 'doc_huntuser','doc_land','doc_zone','order_value','doc_type','date_on','date_off','region'];
        context.columnsDefaults =  [

            {column_name: "doc_type",hidden:true},
            {column_name: "doc_name",minWidth:600, title: true},
            {column_name: "doc_active",pos:0, maxWidth: 100},
            {column_name: "date_on", maxWidth: 100},
            {column_name: "date_off", maxWidth: 100}
        ];
        context.relColumnsDefaults=context.columnsDefaults;
        context.relColumnsDefaults.push({column_name:'doc_type',hidden:false,maxWidth: 300});
        context.noSingle=true;
    }
}

