import React from 'react';
import BasePassageTableSub from "../../Base/ZMU/BasePassageTableSub";
import * as f from "../../common/Funcs";

const keyName = `PointAnimalSub`;

export default class PointAnimalSub extends BasePassageTableSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.subElementName = 'AnimalPage';
        context.gridName = props.gridName || `AnimalsGrid`;
    }

    addAddButton(i) {
        const context = this;
        context.topMenuButtons[i] = {
            iconCls: 'x-fa fa-plus',
            html:`<label class="menu-label">Добавить</label>`,
            handler: () => {
                document.setCookie(context.tableName, null);
                context.rowId = null;
                context.showEditPanel(context);
            }
        };
    }

    //перейти на страницу редактирования
    showEditPanel(context) {
        if (!Boolean(context.subElementName)) return console.debug(`${keyName} showEditPanel: no subName`);
        // собирает ограничения для формы редактирования из грида - родительское значение поля
        const gridFilter = context.grid.getFilterStringFromRow().split('&');
        if (window.IasConfig.devMode) console.debug(`${keyName} showEditPanel`, gridFilter);
        context.subPanel.expand();
        const upData={            showEditPanel: true};
        //применяется фильтр, указанный для саба в описании в родительском объекте
        if (!context.rowId) upData.pageDefaults=gridFilter.map(pair => {
            const ar = pair.replace(' ','').split('=');
            return {column_name: ar[0], value: ar[1], disabled: true};
        });
        context.setState(upData, () => (context.dialog) ? context.dialog.cmp.toFront() : '');
        const parentRow=context.props.parent.state.data;
        f.getTitle(context,parentRow);
        if (context.editForm) {
            //          context.editForm.getData(context.editForm, context);
            context.editForm.rowId = context.rowId;
            context.editForm.getData({context:context.editForm});
        }
        const eaFeatures = context.props.parent.olMap.featuresSource.getFeatures();
        setTimeout(() => {
            const routeMap = context.editForm.olMap;
            routeMap.addFeatures({features: eaFeatures, context: routeMap, layerName: 'route'});
            routeMap.gotoFeatures({features: eaFeatures, context: routeMap});
        }, 1000);
    }


}

