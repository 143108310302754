import ErrorBoundary from "../../ErrorBoundary";
import * as f from "../../common/Funcs";
import BaseByMapPage from "../../Base/BaseByMapPage";
import {Circle as CircleStyle, Fill, Style} from "ol/style";

const keyName = `ViolationsPage`;

export default class ViolationsPage extends BaseByMapPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName = "ViolationsGrid";
        context.subElementName = 'ViolationPage';
        context.tableName = 'violations';
        context.filters = [
            {name: 'from', required: true, message: f.locale('needfrom')},
            {name: 'to', required: true, message: f.locale('needto')},
            {name: 'region', required: false},
            {name: 'users', required: false},
            {name: 'fio', required: false},
            {name: 'num', required: false},
            {name: 'page', required: false},
    ];
        context.styleFunction=(feature)=>{
            const specId = feature.getProperties().spec_name;
            const radius = 4;
            const colors = {
                'Косуля': [144, 255, 144, 1],
                'Кабан': [224, 255, 50, 1],
                'Бурый медведь': [230, 170, 20, 1],
                'Лось': [245, 105, 105, 1],
                'Речной бобр': [255, 105, 215, 1],
                'Волк': [125, 125, 125, 1]
            };
            const w = new Style({
                image: new CircleStyle({
                    radius: radius + 1,
                    fill: new Fill({
                        color: [0, 0, 0, 1],
                    }),
                }),
            });
            const p = new Style({
                image: new CircleStyle({
                    radius: radius,
                    fill: new Fill({
                        color: colors[specId] || [100, 100, 255, 1],
                    }),
                }),
            });
            return [w, p];
        }

    }
}