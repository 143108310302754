import ErrorBoundary from "../../ErrorBoundary";
import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

export default class NewPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("ContactPage constructor", arguments);
        context.tableName = 'news';
        context.idName = 'row_id';
        context.titleName = '';
        context.maximizeOnShow = false;
        // context.footerButtonNames=['close'];
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {column_name:'section',minWidth:'250px',pos:1,row:1},
            {column_name:'date_on',minWidth:'150px',pos:2,row:2},
            {column_name:'news_text',minWidth:'100%',minHeight:'100px',pos:3,row:3}
        ]];
            context.subTables = [];
        context.footerButtonNames = ['saveclose', 'save','delete', 'close'];
    }
}


