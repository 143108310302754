import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../Base/BaseSubGridPanel";
import {Button, ExtNumberfield, Panel,FieldSet} from "@sencha/ext-react-modern";
import {fromLonLat, toLonLat} from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";

const keyName = `CoordsSub`;

export default class CoordsSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.olMap = props.parent.olMap;
        context.coordinates = {lat: {d: 0, m: 0, s: 0}, lon: {d: 0, m: 0, s: 0}};
    }

    getCoordinates (context) {
        const byParent = (parent) => {
            return context.coordinates[parent].d.cmp.getValue() +
                Number(context.coordinates[parent].m.cmp.getValue()) / 60 +
                Number(context.coordinates[parent].s.cmp.getValue()) / 3600;
        }
        let x = byParent('lon');
        let y = byParent('lat');
        return [x, y];
    };

    //поле ввода координаты
    getField(context, name, parent) {
        return <ExtNumberfield
            key={parent+name} name={parent+name}
            label={(name == 'd') ? 'градусы' : (name == 'm' ? 'минуты' : 'секунды')}
            cls={'coords-sub-fieldset'}
            decimals={2} minValue={0} maxValue={(name == 'd') ? 180 : 60}
            value={null}
            ref={fi => {
                context.coordinates[parent][name] = fi;
            }}
            onChange={(event)=>context.changeCoord(context,event)}
        />
    }

    changeCoord  (context,event) {
        const name = event.sender.name.substr(-1);
        const parent = event.sender.getParent().name;
        const disable = (name) => {
            context.coordinates[parent][name].cmp.disable();
            context.coordinates[parent][name].cmp.setValue(null);
        };
        const enable = (name) => {
            context.coordinates[parent][name].cmp.enable();
        }
        //отключаем, если десятичные
        if (name == 'd') {
            if (event.newValue % 1 > 0) {
                disable('m');
                disable('s');
            } else {
                enable('m');
                enable('s');
            }
        }
        if (name == 'm') {
            if (event.newValue % 1 > 0) {
                disable('s');
            } else {
                enable('s');
            }
        }
    }

    /*применить к карте*/
    applyCoords(context) {
        if (!context.olMap) context.olMap=context.props.parent.olMap;
        if (!context.olMap) return;
        const coordinates = fromLonLat(context.getCoordinates(context));
        const point = new Feature(new Point(coordinates));
        const source = context.olMap.featuresSource;
        source.clear();
        source.addFeature(point);
       context.olMap.setToField(point);
        context.olMap.olMap.getView().setCenter(coordinates);
        // document.getElementsByClassName('ol-gotopoint')[0].click();
    }

    /*взять с объекта карты*/
    getFromFeature(context,feature){
        const fdms = (v) => {
            const o = {};
            o.m = v % 1;
            o.d = v - o.m;
            o.m = o.m * 60;
            o.s = o.m % 1;
            o.m = o.m - o.s;
            o.s = o.s * 60;
            o.s = o.s - o.s % 1;
            return o;
        }
        const cc = (!feature)?[0,0]: toLonLat(feature.getGeometry().getCoordinates());
        const inc = {lat: fdms(cc[1]), lon: fdms(cc[0])};
        Object.keys(inc).map(k => {
            Object.keys(inc[k]).map(g => {
                if (context.coordinates[k][g].cmp)
                    context.coordinates[k][g].cmp.setValue(inc[k][g]);
            })
        })
    }

    render() {
        const context = this;
        const content = <Panel layout={'vbox'} key={'vbox1'}>
            <FieldSet layout={'hbox'} name={'lat'} key={'lat'}>
                {[context.getField(context, 'd', 'lat'),
                    context.getField(context, 'm', 'lat'),
                    context.getField(context, 's', 'lat'),
                <label key={'label1'} className={"x-layout-fit-item"}>С.ш.</label>]}
            </FieldSet>
            <FieldSet layout={'hbox'} name={'lon'} key={'lon'}>
                {[context.getField(context, 'd', 'lon'),
                    context.getField(context, 'm', 'lon'),
                    context.getField(context, 's', 'lon'),
                <label  key={'label2'}  className={"x-layout-fit-item"}>В.д.</label>]}
            </FieldSet>
            <Panel layout={'hbox'} height={'30px'} name={'b'} key={'b'}>
                <Button name={'sendToMap'} key={'sendToMap'}
                        handler={() => context.applyCoords(context)}
                        text={'Применить к карте'}
                        centered={true} ui={window.IasConfig.ui}
                /></Panel>
        </Panel>;
        const elements = [content];
        return (<Panel
            region={"center"} margin="3"
            layout={'fit'}
            title={context.props.title}
            cls={'edit-window-panel'}
            collapsible={{
                direction: 'top',
                dynamic: true
            }}
            collapsed={context.props.collapsed}
            titleCollapse={true}
            key={'gridPagePanel'}
            tools={context.topMenuButtons}
            ref={p => {
                if (p) {
                    context.subPanel = p.cmp;
                } else {
                    context.subPanel = null;
                }
            }}>
            {elements}
        </Panel>)
    }
}

