import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

export default class DocumentPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("DocumentPage constructor", arguments);
        context.columnsCount = 1;
        context.tableName = 'documents';
        context.idName = 'doc_id';
        context.titleName = 'docname';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet = [
            {title: 'Основная информация', pos: 0},
        ];
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {
                column_name: 'doc_type', pos: 0, row: 0,
                is_list: true,
                source: {
                    tableName: 'doc_type',
                    titleField: 'type_name',
                    idField: 'doc_type_id'
                }
            },
            {column_name: 'doc_seria', pos: 1, row: 0},
            {column_name: 'doc_num', pos: 2, row: 0},
            {column_name: 'full_name', column_label: 'Полное наименование документа', pos: 3, row: 1},
            {column_name: 'date_on', pos: 3, row: 2},
            {column_name: 'date_off', pos: 4, row: 2},
            {column_name: 'doc_active', pos: 5, row: 2, defaultValue: true},
            {
                column_name: 'organisation', column_label: 'Государственная организация', pos: 6, row: 2,
                is_list: true,
                source: {
                    tableName: 'organisations',
                    titleField: 'org_name',
                    idField: 'org_id'
                }
            },
            {column_name: 'doc_name', column_label: 'Краткое наименование документа', pos: 7, row: 3},
            {column_name: 'doc_comment', pos: 8, row: 4},
            {column_name: 'path_upload', pos: 9, row: 5},
            {
                column_name: 'path',
                pos: 10,
                row: 5,
                path: (context) => `documents/`,
            }
        ]];
        context.subTables = [
            {
                title: 'Охотпользователи',
                elementName: 'BaseRelationSub',
                subName: 'HuntUsersSub',
                tableName: 'documentusers',
                relation: {tableName: 'huntusers_documents', relField: 'doc_huntuser', parentField: 'huntuser_doc'},
                subElementName: 'HuntUserPage',
                topMenuButtonNames: ['relation'],
                activeColumn: 'active',
                filterString: "huntuser_doc=$doc_id$",
                idName: 'huntuser_id',
                hiddenColumns: ['huntuser_id', 'huntuser_doc'],
                columnsDefaults: [...context.lastUserColumns, ...[
                    {column_name: "user_name", title: true},
                    {column_name: "active", title: true},
                ]],
                noSingle: true,
                singleColumns: [
                    {column_name: 'huntuser_id', row: 0, hidden: true},]
            },
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: ()=>!f.isAdmin(),
                filterString: "table_name ='documents' and record_id = $doc_id$::varchar",
            }
        ]
    }
}


