import BaseGrid from "./BaseGrid";
import * as f from "../common/Funcs";
import React from "react";
import ErrorBoundary from "../ErrorBoundary";

const keyName = 'BaseByMapGrid';
export default class BaseByMapGrid extends BaseGrid {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("BasePageGrid constructor", arguments);
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName];
        context.paging = false;
        context.pageSize = 300;
        context.columnsDefaults = [{column_name: "row_id"},
            {column_name: "meeting_spec", flex: {grow: 2}},
            {column_name: "user_name", minWidth: 300, pos: 1, flex: {grow: 2}},
            {column_name: "region_name", minWidth: 300, pos: 0, flex: {grow: 2}},
            {column_name: "economy_name", minWidth: 300, pos: 2, flex: {grow: 2}},
            {
                column_name: "spec_name",
                title: true, pos: 3, minWidth: 150, flex: {grow: 2}
            },
            {column_name: "meeting_date", flex: {grow: 2}},
            {column_name: "mrights", flex: {grow: 2}},
            {column_name: "geom", pos: 50, flex: {grow: 2}},
            {column_name: "x", pos: 20, minWidth: 50, flex: {grow: 2}},
            {column_name: "y", pos: 30, minWidth: 50, flex: {grow: 2}},
        ];
    }

    update(context) {
        context.getData({context});
    }

    getData(params) {
        const {context, callback} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        context.store.loadRecords([]);
        context.inload = true;
        window.mask();
        f.getPageData({
            context: context.props.appViewPort,
            pageSize: context.pageSize,
            keyName: context.props.appViewPort.state.pageName,
            filtersOn: true,
            callback: (result) => {
                context.inload = false;
                context.isLoaded = true;
                result.columns = result.columns.map(c => {
                    if (context.columnsDefaults) {
                        const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                        c = {...c, ...def};
                    }
                    return c;
                }).sort((a, b) => {
                    return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                });
                if (context.upResult) result = context.upResult(context, result);
                const upData = {columns: result.columns, vdata: result.data, error: result.flash};
                if (context.afterGetData)
                    context.afterGetData(context, upData);
                //заполнение карты пришедшими данными
                const olMap = context.props.parent.olMap||context.props.parent.props.olMap;
                if (olMap) {
                    const features = result.data.filter(r => r.geom).map(r => {
                        const props = {...r};
                        delete props.geom;
                        props.gid = props['row_id'];
                        props.source = context.tableName;
                        delete props['row_id'];
                        return {
                            type: 'Feature',
                            properties: props,
                            geometry: JSON.parse(r.geom)
                        }
                    })
                    olMap.clearFeatures({context: olMap});
                    olMap.addFeatures({geoJson: features, context: olMap});
                    if (callback) callback(result);
                    window.unmask();
                }
            }
        })
    }

    getHeight() {
        return (f.getCentralHeight() / 2) - ((this.paging) ? 70 : 40);
    }

}