import ErrorBoundary from "../../../ErrorBoundary";
import React from "react";
import BaseSubGrid from "../../../Base/BaseSubGrid";

const keyName = 'SpecStatusSubGrid';
export default class SpecStatusSubGrid extends BaseSubGrid {
    static defaultProps = {data: [], tableName: 'spec_status', idName: 'spec', rowHeight: 44, rowId: null}

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'BaseEditPage';
        context.tableName = 'spec_status';
        //элемент окна редактирования
        context.idName = 'row_id';
        context.hiddenColumns = ['spec','row_id'];
        context.columnsDefaults= [
            {column_name: keyName},
            {column_name: "status_active", row: 0, pos: 0},
            {column_name: "spec_area", hidden: true, row: 0, pos: 10, className: 'copy-class'},
            {column_name: "spec_count", pos: 0, row: 1, maxWidth: 150, className: 'copy-class'},
            {column_name: "spec_state", pos: 2, row: 1, className: 'copy-class'},
            {column_name: "spec_habitat_structure", hidden: true, pos: 3, row: 1, className: 'copy-class'},
            {column_name: "spec_habitat_quality", hidden: true, pos: 3, row: 1, className: 'copy-class'},
            {column_name: "spec_habitat_area", hidden: true, pos: 3, row: 1, className: 'copy-class'},
            {column_name: "spec_start_year", hidden: true, pos: 3, row: 1, className: 'copy-class'},
            {column_name: "spec_end_year", hidden: true, pos: 3, row: 1, className: 'copy-class'},
            {column_name: "spec", hidden: true, pos: 3, row: 1, className: 'copy-class'},
        ];

            context.tpl = (data) => {
            return (
                <div className={'contact-tpl copy-container'}>
                    <span className={'tpl-title'}>Тел:</span>
                    <div className={'copy-class x-gridcell '}>
                        <span className={'tpl-text'}>{data.phones}</span>
                        <div className="x-after-input-el"/></div>
                    <span className={'tpl-title'}> Email: </span>
                    <div className={'copy-class x-gridcell '}>
                        <span className={'tpl-text'}>{data.emails}</span>
                        <div className="x-after-input-el"/>
                    </div>
                </div>
            )
        };
        // context.singleColumns = [
        //     {column_name: 'spec',hidden:true},
        //     {column_name: "status_active", row: 0, pos: 1,hidden:false},
        //     {column_name: "spec_end_year", row: 0, pos: 2},
        //     {column_name: "spec_start_year", row: 0, pos: 0,hidden:true},
        //     {column_name: "spec_area", row: 1, pos: 10, className: 'copy-class',height:'50px'},
        //     {column_name: "spec_count", pos: 0, row: 2, className: 'copy-class',height:'50px'},
        //     {column_name: "spec_state", pos: 2, row: 3, className: 'copy-class',height:'50px'},
        //     {column_name: "spec_habitat_structure",  pos: 3, row: 4, className: 'copy-class'},
        //     {column_name: "spec_habitat_quality",  pos: 3, row: 5, className: 'copy-class'},
        //     {column_name: "spec_habitat_area",  pos: 3, row: 6, className: 'copy-class'},
        // ];
    }

}