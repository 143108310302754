import ErrorBoundary from "../../../ErrorBoundary";
import React,{Component} from "react";
import BaseTopPage from "../../../Base/BaseTopPage";

const keyName = `ZmuPPassesPage`;
const title=`Зимний маршрутный учет. Ввод данных.`;
export default class ZmuPPassesPage extends BaseTopPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const now=new Date();
        document.setCookie('toFilter',now.toLocaleDateString());
        context.gridName="ZmuGrid";
        context.subElementName = 'MappingPage';
        context.tableName = 'mappings';
        context.filters = context.filters.filter(f=>['year','users','region','it'].indexOf(f.name)==-1)
        context.state = {
            content: [`<p></p>`]
            , title: title
        };
    }
}