import * as f from "../../common/Funcs";
import BaseEditPage from "../../Base/BaseEditPage";

const keyName = 'HuntEconPage';
export default class HuntEconPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'economies';
        context.idName = 'economy_id';
        context.titleName = 'eco_name';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet = [
            {title: 'Основная информация'}
        ];
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {column_name: 'economy_id', hidden: true},
            {
                column_name: 'economy_huntuser', row: 0,
                disabled: true,
                is_list: true,
                source: {
                    tableName: 'hunt_users',
                    titleField: 'user_name',
                    idField: 'huntuser_id'
                }
            },
            {column_name: "economy_name", row: 0},
            {column_name: "economy_year", row: 1},
            {column_name: "economy_percent", row: 1}
        ]];
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.subTables = [
            {
                title: 'Участки охотхозяйства',
                elementName: 'LandsSub',
                activeColumn: 'land_active',
                tableName: 'shortlands',
                disableSingleEdit: true,
                subElementName: 'HuntLandPage',
                filterString: "land_economy=$economy_id$",
            },
            {
                title: 'Пункты выдачи путевок',
                elementName: 'PickupSub',
                tableName: 'pickupview',
                disableSingleEdit: true,
                activeColumn: 'pickup_active',
                subElementName: 'PickupPage',
                filterString: "pickup_economy=$economy_id$",
                columnsDefaults: [...context.lastUserColumns, ...[
                    {column_name: 'pickup_address', title: true, pos: 1},
                    {column_name: 'pickup_active', pos: 0},
                ]],
            },
            {
                title: 'Штат охотхозяйства',
                elementName: 'StaffSub',
                tableName: 'economy_staff',
                noSingle: true,
                subElementName: 'StaffPage',
                filterString: "staff_economy=$economy_id$",
                columnsDefaults: [...context.lastUserColumns, ...[{column_name: 'staff_post', title: true, pos: 0},
                ]]
            },
            {
                title: 'Контактные данные',
                // elementName: 'BaseRelationSub',
                elementName: 'ContactsSub',
                tableName: 'ecocontacts',
                relation: {tableName: 'economies_contacts', parentField: 'contact_eco', relField: 'eco_contact'},
                subElementName: 'ContactPage',
                filterString: "contact_eco=$economy_id$",
                activeColumn: 'contact_active',
                noSingle: true,
                idName: 'contact_id',
                hiddenColumns: ['contact_id', 'contact_eco'],
                columnsDefaults: [...context.lastUserColumns, ...[{
                    column_name: 'fio',
                    flex: {grow: 2},
                    title: true,
                    pos: 1
                }]],
                relColumnsDefaults: [...context.lastUserColumns, ...[{
                    column_name: 'fio',
                    width: 300,
                    maxWidth: 300,
                    minWidth: 300
                }]],
                singleColumns: [
                    {column_name: 'contact_id', hidden: true},
                    {column_name: 'contact_eco', hidden: true},
                    {column_name: 'fio', minWidth: '70%', flex: {grow: 2}, row: 1, pos: 3}],
            },
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='economies' and record_id = $economy_id$::varchar",
            }
        ];
    }
}


