import React from 'react';
import DocumentsSub from "./DocumentsSub";

const keyName = `ZoneDocumentsSub`;

export default class ZoneDocumentsSub extends DocumentsSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.noSingle=true;
        context.gridName=`ZoneDocumentsSubGrid`;
    }

}

