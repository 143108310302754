import ErrorBoundary from "../../ErrorBoundary";
import BaseSubGrid from "../../Base/BaseSubGrid";

const keyName = `PickupSubGrid`;

export default class PickupSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        //элемент окна редактирования
        context.idName = 'pickup_id';
        context.hiddenColumns = [context.idName, 'pickup_economy'];
        context.singleColumns = [ {
            column_name: 'pickup_address', row: 0, pos: 0        },
            {column_name: 'contact', minWidth: '70%',row:1,pos:3}
    ];
    }

}