const config = {
    version: '14.08.24:1',
    homePath: "/",
    // homePath: "http://localhost:80/",
    proxyPath: "http://localhost:6030/",
    locale: "ru",
    margin: 1,
    headerHeight: 50,
    innerHeight: 20,
    footerHeight: 10,
    topHeight: 30,
    leftWidth: 180,
    messageTimeout: 500,
    dialogBodyPadding: 0,
    relationPageSize: 50,
    devMode: true,
    labelMode: true,
    topos: ['osm','eeco', 'esri', 'mosaic','forestmap','admmap','biotopsmap','classesmap'],
    map: {fontSize: '12px'},
    ui: 'normal raised round',
    homeCenter: [3500000, 8350000],
    useApplyButton: true
};
window.IasConfig = config;
