import ZmuIndex from "../Pages/ZMU/ZmuIndex";
import ZmuAccPage from "../Pages/ZMU/Acc/ZmuAccPage";
import ZmuAdminPage from "../Pages/ZMU/Acc/ZmuAdminPage";
import ZmuEventsPage from "../Pages/ZMU/Acc/ZmuEventsPage";
import ZmuPassagesPage from "../Pages/ZMU/Acc/ZmuPassagesPage";
import ZmuPPassesPage from "../Pages/ZMU/Acc/ZmuPPassesPage";
import ZmuPDocPage from "../Pages/ZMU/Plan/ZmuPDocPage";
import ZmuEaPage from "../Pages/ZMU/Plan/ZmuEaPage";
import ZmuLayoutPage from "../Pages/ZMU/Plan/ZmuLayoutPage";
import ZmuEaAnalizePage from "../Pages/ZMU/Acc/ZmuEaAnalizePage";
import ZmuPAnalizePage from "../Pages/ZMU/Plan/ZmuPAnalizePage";
import ZmuPRAnalizePage from "../Pages/ZMU/Plan/ZmuPRAnalizePage";
import ZmuPasAnalizePage from "../Pages/ZMU/Acc/ZmuPasAnalizePage";
import ZmuRoutesPage from "../Pages/ZMU/Plan/ZmuRoutesPage";
import ZmuPlanPage from "../Pages/ZMU/Plan/ZmuPlanPage";
import ZmuQMapPage from "../Pages/ZMU/Analize/ZmuQMapPage";
import ZmuGraphPage from "../Pages/ZMU/Analize/ZmuGraphPage";
import ZmuCorrectPage from "../Pages/ZMU/Analize/ZmuCorrectPage";
import ZmuQuantYearPage from "../Pages/ZMU/Analize/ZmuQuantYearPage";
import ZmuAnalizePage from "../Pages/ZMU/Analize/ZmuAnalizePage";
import ZmuResultPage from "../Pages/ZMU/Acc/ZmuResultPage";
import ZmuProfilePage from "../Security/ZmuProfilePage";

import RoutePage from "../SinglePages/ZMU/Plan/RoutePage";
import EaPage from "../SinglePages/ZMU/Plan/EaPage";
import PasEaPage from "../SinglePages/ZMU/Acc/PasEaPage";
import EventPage from "../SinglePages/ZMU/Acc/EventPage";
import PassCommentPage from "../SinglePages/ZMU/Acc/PassCommentPage";

export default {
    ZmuIndex,
    ZmuAccPage,
    ZmuEventsPage,
    ZmuEaAnalizePage,
    ZmuPassagesPage,
    ZmuPPassesPage,
    ZmuAnalizePage,
    ZmuCorrectPage,
    ZmuGraphPage,
    ZmuQMapPage,
    ZmuLayoutPage,
    ZmuPDocPage,
    ZmuPAnalizePage,
    ZmuPRAnalizePage,
    ZmuPasAnalizePage,
    ZmuPlanPage,
    ZmuResultPage,
    ZmuEaPage,
    EaPage,
    EventPage,
    PasEaPage,
    ZmuRoutesPage,
    RoutePage,
    ZmuProfilePage,
    ZmuAdminPage,
    ZmuQuantYearPage,
    PassCommentPage
};