import BasePageGrid from "../../Base/BasePageGrid";
import ErrorBoundary from "../../ErrorBoundary";
import React from "react";
const keyName='HuntLandsGrid';
export default class HuntLandsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.paging=true;
        context.idName = 'land_id';
        context.hiddenColumns=[context.idName,'eco_id','hu_id'];
        context.columnsDefaults = [
            {"column_name": "hu_name", pos: 1, minWidth:100, maxWidth: 200, flex: {grow: 2}},
            {"column_name": "lnd_name", pos: 2, minWidth:100,title:true, flex: {grow: 2}},
            {"column_name": "lnd_region", pos: 3, maxWidth: 300, minWidth: 100, flex: {grow: 2}},
            {"column_name": "lnd_active",column_label:'', pos: 0},
        ]

    }

    // //кнопка  выделения юр. лица
    // extButton(value) {
    //     const context = this;
    //     if (window.IasConfig.devMode) console.debug(`${keyName} extButton`);
    //     const key='extbutton';
    //     if (f.isEditRight(value))
    //         return <Button ui={window.IasConfig.ui} key={key} name={key} iconCls={'x-fa fa-plus'}
    //                        handler={context.fromIdEditPanel.bind(context, this)}/>;
    //     else
    //         return '';
    // }
    //
    // //выделить юр. лицо
    // fromIdEditPanel(context, button) {
    //     const id = context.getCurrentId();
    //     if (window.IasConfig.devMode) console.debug(`${keyName} document.omIdEditPanel`, id);
    //     document.setCookie(context.props.tableName, null);
    //     context.props.parent.rowId=null;
    //     //задаем предварительные значения для полей
    //     context.props.parent.setState({pageDefaults:[{column_name:'land_parent',value:id,disabled:true}]});
    //     context.props.parent.showEditPanel(context.props.parent);
    //     //}
    // }
    //
    // //для колонки редакции. Проверка на колонку, проверка прав, значок
    // rndFunc(value) {
    //     const context=this;
    //     if (window.IasConfig.devMode) console.debug(`${keyName} rndFunc`);
    //     const editButton = context.editButton(value);
    //     const extButton=context.extButton(value);
    //     return [editButton,extButton];
    // }
}