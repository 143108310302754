import React from 'react';
import BaseSubGridPanel from "../../../Base/BaseSubGridPanel";

const Ext = window['Ext'];
const keyName = `PassCommentSubPanel`;

export default class PassCommentSubPanel extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.subElementName = `PassCommentPage`;
        context.noSingle=true;
        context.gridName = "PassCommentSubGrid";
        context.idName = 'row_id';
        context.rowId = props.rowId;
        context.topMenuButtonNames=['reload','add'];
    }
}

