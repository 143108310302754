import React, {Component} from 'react';
import {Panel} from "@sencha/ext-react-modern";
import 'fa-icons';

const key = "FooterPanel";

class FooterPanel extends Component {

    render() {
        const className="footer-panel";
        return <Panel
            id={key}
            key={key}
            layout={{
                type:'fit',
            }} docked={"bottom"}
            shadow
            cls={'no-print'}
            bodyCls={className}
            height={window.IasConfig.footerHeight}
            margin={`${window.IasConfig.margin}`}>
        </Panel>
    }
}

export default FooterPanel;