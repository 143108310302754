import ErrorBoundary from "../../ErrorBoundary";
import React from "react";
import BaseSubGrid from "../../Base/BaseSubGrid";
import * as f from "../../common/Funcs";
import {BaseButton, BaseTextButton} from "../../Base/BaseButtons";

const keyName = 'NamesakeSubGrid';
export default class NamesakeSubGrid extends BaseSubGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'ContactPage';
        context.tableName = 'namesake_contacts';
        context.activeColumn = 'contact_active';
        context.idName = 'contact_id';
        context.rowHeight = 65;
        // context
        context.hiddenColumns = [context.idName];
        context.noSingle = true;
        context.columnsDefaults = [
            {column_name: "contact_id", hidden: true, pos: 0},
            {column_name: "parent_id", hidden: true, pos: 0},
            {column_name: "contact_active", pos: 0},
            {
                column_name: "mrg", pos: 10, row: 1, data_type: 'custom', renderer: (params) => {
                    params.context = context;
                    return context.renderMergeContacts(params);
                }, maxWidth: 120
            },
            {column_name: "family_name", pos: 20, row: 1, title: true, className: 'copy-class', maxWidth: 250},
            {column_name: "io", pos: 30, row: 1, title: true, className: 'copy-class', maxWidth: 250},
            {column_name: "husers", row: 1, pos: 50, className: 'copy-class', flex: {grow: 2}},
            {column_name: "phones", hidden: true, row: 2, pos: 4, className: 'copy-class',},
            {column_name: "emails", hidden: true, row: 3, pos: 5, className: 'copy-class',}
        ];
        //                        <div className="x-after-input-el" onClick={() => navigator.clipboard.writeText(value)}/>
        context.tpl = (data) => {
            return (
                <div className={'contact-tpl copy-container'}>
                    <span className={'tpl-title'}>Тел:</span>
                    <div className={`${(data?.phones?.length > 0) ? 'copy-class' : ''} x-gridcell `}>
                        <span className={'tpl-text'}>{data.phones || ' - '}</span>
                        <div className="x-after-input-el"/>
                    </div>
                    <span className={'tpl-title'}> Email: </span>
                    <div className={`${(data?.emails?.length > 0) ? 'copy-class' : ''} x-gridcell `}>
                        <span className={'tpl-text'}>{data.emails || ' - '}</span>
                        <div className="x-after-input-el"/>
                    </div>
                </div>
            )
        };

    }

    renderMergeContacts(params) {
        let {record, context} = params;
        const clickFunc = () => {
            const parentId = context.props.rowId;
            const childId = record.data[context.idName];
            if (parentId && childId) {
                f.confirm({
                    title: 'Действие',
                    message: `Вы действительно хотите объединить контакт "${record.data.family_name} ${record.data.io}" с текущим?`,
                    datatype: 'date'
                }, (e) => {
                    if (e) {
                        f.callPath({
                            context: context.props.appViewPort,
                            path: `api/data/contacts/union/${parentId}/${childId}`,
                            callback: (result) => {
                                const c=context.props.parent.parent;
                                if (c.getData) c.getData({context:c});
                                context.props.appViewPort.page.grid.getData({context:context.props.appViewPort.page.grid})
                            }
                        })
                    }
                });
            }
        };

        return <BaseTextButton key={'merge'} text={'Объединить'} func={() => clickFunc()}/>;

    };
}
