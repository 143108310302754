import * as f from "../../../common/Funcs";
import React from "react";
import EaPage from "../Plan/EaPage";

const keyName = "PasEaPage";
export default class PasEaPage extends EaPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.testType='pas_ea';
        context.blankType='zmublank';
        context.columnsCount = [{width: '50'}, {width: '50'}];
        context.footerButtonNames = ['close'];
        context.subTables = [
            {
                column: 0,block:0,
                pos: 0,
                title: 'Тесты',
                elementName: 'TestSubPanel',
                testName:'pas_ea',
                tableName: 'pas_ea',
            },
            {
                column: 0,block:4,
                pos:10,
                title: 'Перечень прохождений',
                elementName: 'EaPassagesSub',
                tableName: 'eapassages',
                filterString: "ea_id=$area_id$",
                idName: 'ea_id',
                hiddenColumns: ['ea_id','passage_id'],
            },
            {
                //!!!!!
                column: 1,
                block: 7,
                title: 'Карта',
                // elementName: 'BaseZmuMapSub',
                readonly:true,
                tableName: 'eapol',
                elementName: 'ZmuEaSingleMapSub',
                idName: 'ea_id',
                // activeColumn: 'date',
                filterString: "ea_id=$area_id$",
            }];
    }
    isEditRight(){return false;}
}


