import React from 'react';
import {Panel} from "@sencha/ext-react-modern";
import * as g from "./gisFuncs";
import * as f from "./Funcs";

export const closePlan=(context)=>{
    const year=document.getCookie('yearFilter');
    const closed=window.IasLoApp.state.zmu.plan_closed;
    f.confirm(`Вы действительно хотите ${(closed)?'открыть':'закрыть'} планирование ${year} года?`, (result) => {
        if (result) {
            const callback=(result)=>{
                let zmu=window.IasLoApp.state.zmu;
                zmu.plan_closed=!zmu.plan_closed;
                window.IasLoApp.setState({zmu:zmu},()=> {
                    if (window.IasLoApp?.page?.eapanel) {
                        window.IasLoApp.page.eapanel.forceUpdate();
                    }
                });
            }
            f.callPath({context,path:`zmu/accyear/${(closed)?'year_open':'year_close'}/`,id:year,method:'GET',callback});
        }
    });
}
export const closeAcc=(context)=>{
    alert('closeAcc');
}

/*строка ссылки на документацию*/
export const manualString=(params)=>{
    let {type,descString,linkString}=params;
    if (!type) type='user';
    switch (type) {
        case 'user':
            if (!descString) descString=`Инструкции по работе с разделами ИАС "Животный мир" Вы можете скачать по ссылке `;
        case 'admin':
            if (!descString) descString=`Документацию по работе с инструментами администратора ИАС "Животный мир" Вы можете скачать по ссылке  `;
        case 'zmu':
            if (!descString) descString=`Инструкции по работе с разделами ИАС "Зимний маршрутный учет" Вы можете скачать по ссылке `;
    }
return descString+manualLink({type, linkString});
};
/*ссылка на документацию*/
export const manualLink=(type)=>{
    const panel=(str)=><Panel
        ui={window.IasConfig.ui}
        cls={'man-class'}
        key={'man'}
        html={str}/>;

    switch (type) {
        case 'user':
            return panel(`<a  target="_blank" href="${window.IasConfig.homePath}files/user_manual.pdf">Руководство пользователя</a>`);
        case 'admin':
            return panel(`<a  target="_blank" href="${window.IasConfig.homePath}files/admin_manual.pdf">Руководство администратора</a>`);
        case 'zmu':
            return panel(`<a  target="_blank" href="${window.IasConfig.homePath}files/zmu_manual.pdf">Руководство пользователя ЗМУ</a>`);
    }
}

