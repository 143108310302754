import ErrorBoundary from "../../ErrorBoundary";
import * as f from "../../common/Funcs";
import BaseByMapPage from "../../Base/BaseByMapPage";
const keyName = `DtpsPage`;


export default class DtpsPage extends BaseByMapPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.title=`список ДТП`;
        context.gridName="DtpGrid";
        context.subElementName = 'DtpPage';
        context.tableName = 'dtp';
        context.filters = [
            {name: 'from', required: true, message: f.locale('needfrom')},
            {name: 'to', required: true, message: f.locale('needto')},
            {name: 'region', required: false},
            {name: 'users', required: false},
            {name: 'meetspec', required: false},
            {name: 'num', required: false},
            // {name: 'fio', required: false},
            // {name: 'male', required: false},
            {name: 'page', required: false},
        ];
    }}