import React from 'react';
import {Panel} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";
import BaseSubGridPanel from "../Base/BaseSubGridPanel";
import BaseEditPage from "../Base/BaseEditPage";
import SimpleImportMap from "../Maps/SimpleImportMap";
import * as g from "../common/gisFuncs";
import landstyle from "../Maps/styles/landspol";
import admstyle from "../Maps/styles/admpol";
import zmustyle from "../Maps/styles/zmu";
import SimpleEditMap from "../Maps/SimpleEditMap";

const keyName = `BaseMapSub`;

export default class BaseMapSub extends BaseSubGridPanel {

    static defaultProps = {
        layerNames: [
            {
                layerName: 'landspol', hidden: false, type: 'own', styleFunction: landstyle
            },
            {
                layerName: 'admpol', hidden: false, type: 'own', styleFunction: admstyle
            },
        ],
        load: 'auto'
    }
    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.parent = props.parent;
        context.rowId = props.rowId;
        context.tableName = props.tableName;
        //элемент окна редактирования
        context.idName = props.idName || '_id';
        context.state = {
            data: context.props.data,
            showAll: context.props.showAll
        };
        context.topMenuButtons = [];
        context.noSingle = props.noSingle;
        context.collapsed = props.collapsed;

//меню
//         context.topMenuButtonNames = (props.topMenuButtonNames) ? props.topMenuButtonNames : ['reload', 'add', 'edit'];
        context.topMenuButtonNames = (props.topMenuButtonNames) ? props.topMenuButtonNames : ['reload'];
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.warn(error, errorInfo);
    }

    componentDidMount() {
        this.getData({context: this});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        // if (prevProps.rowId != context.props.rowId || prevState.showAll != context.state.showAll) context.getData({context});
        if (prevProps.rowId != context.props.rowId || prevState.showAll != context.state.showAll) context.getData({context});
    }

    addReloadButton() {
        const context = this;
        context.topMenuButtons.push({
            iconCls: 'x-fa fa-refresh',
            html:`<label class="menu-label">Обновить</label>`,
            tooltip: f.locale('reload'),
            handler: () => {
                if (context.state.unsaved) {

                }
                else {
                    context.getData({context});
                }
            }
        })
    }

    //checkbox фильтра по актуальности
    addShowAll() {
        const context = this;
        const key = 'showAll';
        context.topMenuButtons.unshift({
            iconCls: `x-fa ${(context.state.showAll) ? 'fa-check-square' : 'fa-square'}`,
            html: `<label>${f.locale(key)}</label>`,
            handler: (sender) => {
                context.setState({showAll: !context.state.showAll});
                context.getData({context});
            }
        })
    }

    getEditMenu(context) {
        context.addMenuButtons();
        return <Panel
            bodyCls={"inner-header"}
            key={`gridMenu${context.gridName}`}
            layout="hbox" shadow docked={"top"}
            cls={'no-print'}
            height={f.getInnerHeightMenu()}
            margin={`0 0 ${window.IasConfig.margin} 0`}>
            {context.topMenuButtons}
        </Panel>
    }

    isEditRight() {
        const rights = this.props.rights;
        return Boolean(this.subElementName)
            && f.isEditRight(rights);
    }

    //если нужен фильтр по колонке актуальности
    getFilterStringFromRow() {
        const context = this;
        const record = context.props.record;
        let filterString = context.props.filterString;
        if (context.props.parent.state.showAll)
            if (filterString)
                filterString += ` & actual=true`;
            else
                filterString = ` actual=true`;
        if (window.IasConfig.devMode) console.debug(`${keyName} getFilterStringFromRow`, ` ${record}`, `filterString ${filterString}`);
        if (!filterString) return '';
        //выполнить замену подстановок
        const array = filterString.match(/\$[^\$]*\$/g).map(e => e.replaceAll('$', ''));
        array.map(a => filterString = filterString.replace(`$${a}$`, (record[a]?.toLocaleDateString) ? record[a].toLocaleDateString() : record[a]));
        return filterString;
    }

    //контекст выполнения и столбец актуальности, если нужен фильтр по актуальности
    getData(params) {
        const {context, callback} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        const viewName = context.props.tableName || context.tableName;
        const getGeomFunc = (params) => {
            const {context, result} = params;
            if (!(result && result.features && result.features.length > 0)) return false;
            const addFunc = (context) => {
                const olMap = context.olMap;
                if (!olMap) setTimeout(() => addFunc(context), 200); else {
                    olMap.clearFeatures({ context: olMap,layerName:'important'});
                    olMap.clearFeatures({ context: olMap,layerName:'features'});
                    olMap.addFeatures({geoJson: {type:'FeatureCollection',features:result.features.filter(f=>f.properties.important)},layerName:'important', context: olMap,styleFunction:zmustyle});
                    context.gisFeature = olMap.addFeatures({geoJson: {type:'FeatureCollection',features:result.features.filter(f=>!f.properties.important)}, context: olMap});
                    context.setState({gisFeature:context.gisFeature});
                    const ext = olMap.featuresSource.getExtent();
                    olMap.setState({features: context.gisFeature,homeCenter: [(ext[2] - ext[0]) / 2 + ext[0], (ext[3] - ext[1]) / 2 + ext[1]]},()=>{
                        if (context.olMap?.addButtons) context.olMap.setState({buttons: context.olMap.addButtons()})
                    });
              //      olMap.select({features: context.gisFeature, context: olMap});
                    olMap.gotoFeatures({features: context.gisFeature, context: olMap});
                    olMap.mapResize();
                    if (callback) callback(result);
                }
            }
            setTimeout(() => addFunc(context), 500);
        }
        if (context.olMap) {
            if (context.olMap.selectSource) context.olMap.selectSource.clear();
            context.olMap.featuresSource.clear();
        }
         if ((context.props.rowId) &&context.props.rowId!= -1)
            g.getGeomById({
                callback: result => getGeomFunc({context: context, result: result}),
                id: context.props.rowId,
                // id: context.props.rowId,
                tableName: context.props.tableName,
                active: !context.state.showAll
            });
    }

//если карта занимает полностью блок - 100% высоты
    getHeight() {
        const context = this;
        let result=300;
        if (context.props.block&&context.props.parent?.form?.cmp?.el)
            result= (context.props.parent?.form?.cmp?.el.getHeight() - 120);
        return result;
    }

    getMap_old(context, params = {}) {
        let parent = context;
        while (!(parent instanceof BaseEditPage)) {
            parent = parent.props.parent;
        }
        return <SimpleImportMap
            ref={(e => {
                context.olMap = e;
                parent.olMap = e;
            })}
            height={context.getHeight() || 300} width={'100%'}
            rights={params.rights || context.props.rights}
            key={'simpleMap'}
            load={context.props.load}
            record={context.props.record}
            layerNames={context.props.layerNames}
            geomType={context.props.geomType}
            styleFunction={context.styleFunction || context.props.styleFunction}
            parent={context} appViewPort={context.props.appViewPort}/>;
    }

    getMap(context, params = {}) {
        let parent = context;
        while (!(parent instanceof BaseEditPage)) {
            parent = parent.props.parent;
        }
        return <SimpleEditMap
            ref={(e => {
                context.olMap = e;
                parent.olMap = e;
            })}
            height={context.getHeight() || 300} width={'100%'}
            rights={params.rights || context.props.rights}
            readonly={(!context.props.parent.isEditRight())}
            rowId={parent.rowId}
            key={'simpleEditMap'}
            load={context.props.load}
            record={context.props.record}
            layerNames={context.props.layerNames}
            geomType={context.props.geomType}
            styleFunction={context.styleFunction || context.props.styleFunction}
            parent={context} appViewPort={context.props.appViewPort}/>;
    }

    render() {
        const context = this;
        const elements = [context.getMap(context)];
        context.addMenuButtons();
        return (<Panel
            region={"center"} margin="3"
            layout={'fit'}
            title={context.props.title}
            cls={'edit-window-panel'}
            collapsible={{
                direction: 'top',
                dynamic: true
            }}
            collapsed={context.props.collapsed}
            width={'100%'}
            // height={context.getHeight() || 300}
            titleCollapse={true}
            key={'mapPanel'}
            tools={context.topMenuButtons}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            {elements}
        </Panel>)
    }
}

