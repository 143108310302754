import * as f from "../../../common/Funcs";
import * as z from "../../../common/zmuFuncs";
import React from "react";
import BaseEditAccPage from "../../../Base/ZMU/BaseEditAccPage";
import { BaseTextButton} from "../../../Base/BaseButtons";

//общая информация
//пользователь видит, только если вводил сам.
const keyName = "TablesPage";
export default class TablesPage extends BaseEditAccPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.topMenuButtonNames = ['reload', 'print',  'frompoint'];
        context.fieldsSet = [
        ];
        context.subTables = [...context.subTables,...[
            {
                title: 'ТАБЛИЦА 1. УЧЕТ СЛЕДОВ ЗВЕРЕЙ',
                column: 0,
                elementName: 'PassageTable1Sub',
                tableName: 'PassageTable1Sub',
                idName: 'passage_id',
                filterString: "passage_id=$passage_id$",
            },
            {
                title: 'ТАБЛИЦА 2. УЧЕТ СЛЕДОВ В ДЕНЬ ЗАТИРКИ',
                column: 0,
                elementName: 'PassageTable2Sub',
                tableName: 'PassageTable2Sub',
                idName: 'passage_id',
                filterString: "passage_id=$passage_id$",
            },
            {
                title: 'ТАБЛИЦА 3. УЧЕТ ПТИЦ',
                column: 0,
                elementName: 'PassageTable3Sub',
                tableName: 'PassageTable3Sub',
                idName: 'passage_id',
                filterString: "passage_id=$passage_id$",
            },
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='passages' and record_id = $passage_id$::varchar",
            }
        ]];
    }

    //перетянуть данные из введенных точек
    addFrompointButton() {
            const context = this;
            const key = 'frompointButton';
            //если точки не заполнены, кнопки нет.
        const row=context.props.parent.grid.getCurrentRow();
            if (!row?.pointsfilling_state||!context.isEditRight())
                return;
            context.topMenuButtons.push(<BaseTextButton name={key} key={key} text={'Импорт из точек'}  func={() => {
                f.callPath({context,path:`zmu/passages/${context.rowId}/transfer`,callback:(result)=>{
                        if (result.flash) {
                            f.alert({
                                title: 'Ответ сервера',
                                message: `Данные не перенесены. Сервер вернул ошибку: ${JSON.stringify(result.flash)}`
                            });
                        } else {
                            f.toast({title: 'Ответ сервера', message: 'Данные перенесены.', timeout: window.IasConfig.messageTimeout});
                            if (context.getData) context.getData({context});
                        }
                        // if (context.grid) context.grid.getData({context:context.grid});
                    }})
            }}/>);
        }
    //сохранить редакцию
    onSave(params) {
        let {context} = params;
        //обнулить id если копия
        const parent = context.props.parent;
        let data = [];
        Object.keys(context.elements).filter(e => context.elements[e]?.grid).map(e => {
                const eldata = context.elements[e].grid.store.getData().items.map(i => i.data).filter((r) => {
                    return (r.spec_name1 && (r.count_bog1) || (r.count_fld1) || (r.count_fst1)) || (r.spec_name2 && (r.count_bog2) || (r.count_fld2) || (r.count_fst2))

                });
                data = [...data, ...eldata];
            }
        );
        const callback = (result) => {
            if (result.flash) {
                f.alert({
                    title: 'Ответ сервера',
                    message: `Изменения не сохранены. Сервер вернул ошибку: ${JSON.stringify(result.flash)}`
                });
            } else {
                f.toast({title: 'Ответ сервера', message: 'Изменения сохранены.', timeout: window.IasConfig.messageTimeout});
            }
        };
        const prms = {
            pasId: context.rowId, data: data, callback
        };
        z.setPassTableData(prms);
    }

    getData(params) {
        const {context, callback, defaults} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        const prnt = context.props.parent;
        context.defaults = defaults;
        const tableName = context.tableName || prnt.tableName;
        const rowId = context.rowId;
        if (window.IasConfig.devMode) console.debug(`${keyName} getData ${tableName} ${rowId}`);
        const _params = {
            context: context, tableName: tableName, id: rowId, callback: (result) => {
                result.columns = result.columns.map(c => {
                     c.hidden = true;
                    return c;
                });
                if (context.props.rights) result.rights = context.props.rights;
                // if (context.upResult) result = context.upResult(context, result);
                context.setState({
                    data: result.data,
                    columns: result.columns,
                    id_field: result.id_field,
                    rights: result.rights
                });
            }
        };
        const prs = {...context.defaults, ..._params};
        if (callback) prs.callback = callback;
        if (tableName) {
            context.setState({columns: []}, () => {
                if (context.isUser) f.getUserData(prs);
                else f.getObjectData(prs);
            })
        }

    }

 /*   upResult(context, result) {
        result.columns = result.columns.map(c => {
            if (c.column_name.indexOf('gpx') > -1) {
                c.data_type = 'path';
                // c.data_type='check';
            }
            return c;
        });
        return result;
    }
*/
}


