import ErrorBoundary from "../../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../../Base/BaseSubGridPanel";

const keyName = `SpecSynSub`;

export default class SpecSynSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.subElementName = 'SpecSynPage';
        context.topMenuButtonNames=['add','edit','reload'];
        context.idName ='spec_id';
        context.gridName=`SpecSynSubGrid`;
        context.noSingle=true;
    }

    //заголовок окна
    setDialogTitle(context) {
//        context.dialog.cmp.setTitle(`Краснокнижное описание вида ${context.grid.getCurrentRow()[`name_rus`]}`);
    }

}

