import React from "react";
import BaseZmuPage from "./BaseZmuPage";
import * as f from "../../common/Funcs";
import {ReloadButton} from "../../common/Buttons";
import {Panel} from "@sencha/ext-react-modern";
import ErrorBoundary from "../../ErrorBoundary";

const keyName = `BaseZmuAnalizePage`;
export default class BaseZmuAnalizePage extends BaseZmuPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName="EaAnalizGrid";
        context.subElementName = 'EaPage';
        context.tableName = 'explore_areas';
        context.pageSize=100;
        context.filters = [
            {
                name: 'year',pos:1,
                required: true,
                message: f.locale('needyear')
            }, {name: 'users',pos:3}, {name: 'region',pos:2}, {name: 'it',pos:4},{name:'page',pos:60},{name:'num',pos:10}];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        const s = context.state;
        super.componentDidUpdate(prevProps, prevState, snapshot);
        if (prevState.accYear != s.accYear) {
            // if (context.grid) context.grid.getData({context:context.grid});
        }
    }

    componentDidCatch(error, errorInfo) {
    }

    //кнопка reload общая для всех страниц
    addReloadButton() {
        const context = this;
        const key = 'reloadbutton';
        context.topMenuButtons.push(<ReloadButton key={key} func={() => {
            context.getAccYear(context, document.getCookie('yearFilter'));
            context.grid.getData({context: context.grid});
        }}/>)
    }

    render() {
        const context = this;
        //тело таблицы ит
        const grid = context.getGrid(context);
        //строка панели инструментов раздела
        const editMenu = context.getEditMenu(context);
        //карточка ИТ
        const dialog = (context.getDialog) ? context.getDialog(context) : '';
        return (<Panel
            cls={'print-100-percent by-map-panel'}
            height={f.getCentralHeight()}
            region={"center"} margin="3"
            key={`gridPanel${keyName}`}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            <Panel layout={'fit'} key={'gridPanel'}>
                {editMenu}
                {grid}
                {dialog}
            </Panel>
        </Panel>)
    }

}