import ErrorBoundary from "../../ErrorBoundary";
import React from "react";
import DocumentsSubGrid from "./DocumentsSubGrid";

const Ext = window['Ext'];
const keyName = `HuntUserDocumentsSubGrid`;

export default class HuntUserDocumentsSubGrid extends DocumentsSubGrid {
    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        this.tableName = 'userdocuments';
    }

    //если нужен фильтр по колонке актуальности
    getFilterStringFromRow(activeColumn) {
        const context = this;
        const record = context.props.record;
        if (typeof (activeColumn) == 'undefined' && context.props.parent.activeColumn) activeColumn = context.props.parent.activeColumn;
        let filterString = context.props.filterString||context.filterString;
        if (activeColumn&&!context.props.parent.state.showAll)
            if (filterString)
                filterString += ` & ${activeColumn}=true`;
            else
                filterString = ` ${activeColumn}=true`;
        if (context.props.parent.state.region)
            if (filterString)
                filterString += ` & ${document.getCookie('regionFilter')}=any(region)`;
            else
                filterString = ` ${document.getCookie('regionFilter')}=any(region)`;

        if (window.IasConfig.devMode) console.debug(`${keyName} getFilterStringFromRow`, ` ${record}`, `filterString ${filterString}`);
        if (!filterString && !activeColumn) return '';
        //выполнить замену подстановок
        const array = filterString.match(/\$[^\$]*\$/g).map(e => e.replaceAll('$', ''));
        array.map(a => filterString = filterString.replace(`$${a}$`, (record[a]&&record[a].toLocaleDateString) ? record[a].toLocaleDateString() : record[a]));
        return filterString;
    }

}