import * as f from "../../common/Funcs";
import BaseEditPage from "../../Base/BaseEditPage";
const keyName='HuntDescPage';

export default class HuntDescPage extends BaseEditPage {
    static defaultProps={
        height:'80%',
        width:'50%'
    }

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'huntusers_description';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.idName = 'row_id';
        context.titleName = 'full_name';
         context.footerButtonNames=['saveclose','save','copy', 'close'];
        context.fieldsSet = [
            {title: 'Реквизиты охотпользователя'}
        ];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {column_name:'desc_huntuser',row:0,hidden:true},
            {column_name: "user_name", row: 0},
            {column_name: "desc_active",row:2,defaultValue: true},
        {column_name: "full_name", row: 1 },
        {column_name: "legal_address", row: 3},
        {column_name: "actual_address", row: 4},
        {column_name: "row_id", pos: 20, hidden:true},
        // {column_name: "date_off", row: 5, },
        {column_name: 'date_on', row:5, defaultValue: new Date()},
        ]];
        context.subTables=[
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: ()=>!f.isAdmin(),
                filterString: "table_name ='huntusers_description' and record_id = $row_id$::varchar",
            }
        ]
    }
}


