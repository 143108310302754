import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import * as f from "../../common/Funcs";
const keyName='HuntEconsPage';
export default class HuntEconsPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.title=`список охотхозяйств`;
        context.gridName = "HuntEconsGrid";
        context.subElementName = 'HuntEconPage';
        context.idName = 'eco_id';
        context.topMenuButtonNames= ['reload','xls','print','add','clipboard'];
        context.hiddenColumns = [context.idName, 'hu_id','order_value'];
        context.titleName = 'eco_name';
        context.filters=[
            {name:'ac',required:false},
            {name:'region',required:false},
            {name:'users',required:false},
            {name:'ecos',required:false},
            {name: 'num', required: false},

            // {name: 'page', required: false}
            ];
   }
}
