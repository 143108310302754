import BaseSubGrid from "../../Base/BaseSubGrid";
import ErrorBoundary from "../../ErrorBoundary";
import React from "react";

const Ext = window['Ext'];
const keyName = `LandsWithEcoSubGrid`;

export default class LandsWithEcoSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'landswitheconomy';
        //элемент окна редактирования
        context.idName = 'land_id';
        context.hiddenColumns = [context.idName, 'economyid', 'economy_huntuser'];
        context.columnsDefaults = [
            {column_name: "land_name",flex:{grow:2}},
            {column_name: 'economy_id', hidden: true},
            {column_name: "economy_name", hidden: true},
            {column_name: "rights", hidden: true},
            {column_name: 'land_area', maxWidth: 150},
            {column_name: 'land_region', minWidth: 200},
            {column_name: 'land_reason', minWidth: 200},
        ];
        context.singleColumns = [
            {column_name: 'land_id', row: 0, hidden: true},
            {
                column_name: 'economy_id',
                row: 0,
                hidden: true
            },
            {column_name: 'economy_name', minWidth: 200, row: 0, pos: 1},
            {column_name: 'land_active', row: 0, pos: 2},
            {column_name: 'land_name', row: 1, pos: 0},
            {column_name: 'land_region', row: 1, pos: 1},
            {column_name: 'land_area', row: 1, pos: 2},
            {
                column_name: 'actual_date',
                row: 0
            },
            {column_name: 'land_reason', hidden: true},
            {column_name: 'land_start_date'},
            {column_name: 'land_date_off'},
            {column_name: 'land_parent'},
            {column_name: 'actual_area'},
            {column_name: 'land_sa'},
            {column_name: 'land_type'}
        ]

        //группировка
        if (1 == 1) {
            context.grouped = context.props.grouped || true;
            context.groupFooter = context.props.groupFooter || {
                xtype: 'gridsummaryrow'
            };
            context.groupField = props.groupField || 'economy_name';
//            context.groupHeaderTpl=context.props.groupHeaderTpl||'{columnName}: {value:htmlEncode} test:{addme}'
            context.groupHeaderTpl =
                `<span class="economy-group-title">охотхозяйство: </span>
                <span class="link economy-group">{value:htmlEncode}</span>
                <span class="economy-group-title">; участков: {count}</span>`;

            // context.groupHeaderTpl='охотхозяйство: {value:htmlEncode}';
            // context.rowexpander = context.props.rowexpander || true;
            const storeProps = {
                data: context.state.data
            };
            if (context.groupField) storeProps.groupField = context.groupField;
            context.store = Ext.create('Ext.data.Store', storeProps);
        }
    }

    //создать участок в хозяйстве
    addLandToEco(context, record) {
        const id = record[1].data.economy_huntuser;
        if (window.IasConfig.devMode) console.debug(`${keyName} addLandToEco`, id);
        document.setCookie(context.props.tableName, null);
        context.props.parent.rowId = null;
        //задаем предварительные значения для полей
        context.props.parent.setState({pageDefaults: [{column_name: 'economy_huntuser', value: id, disabled: true}]});
        context.props.parent.showEditPanel(context.props.parent);
        //}
    }

    getHeight() {
        const context = this;
        const itemsCount = context.state.data.length;
        const headersCount = (new Set([...context.state.data.map(i => i.economy_id)])).size;
        if (itemsCount == 1 && context.state.singleMode) return '100%';
        const maxHeight = window.innerHeight / 3;
        const result = (1 + itemsCount) * 20 + headersCount * 30+20;
        return (maxHeight > result) ? result : maxHeight;
    }

//id выделенной строки
    getCurrentId() {
        if (window.IasConfig.devMode) console.debug("BaseGrid getCurrentId");
        try {
            return this.getCurrentRow()['economyid'];
        } catch (err) {
            return null;
        }
    }

    componentDidUpdate(nextProps, nextState, nextContext) {
        const context = this;
        //вешаем onClick на заголовок группы охотхозяйства
        window.toArray(document.getElementsByClassName('economy-group')).map(el=>{
            const f=(sender)=>{
                context.tableName='economies';
                const txt=el.innerText;
                context.props.parent.rowId=context.state.data.filter(d=>(d[context.groupField]==txt))[0]['economyid'];
                context.props.parent.showEditPanel(context.props.parent);
            }
            el.onclick=(e)=>f(e);
        })
        return true;
    }

}