import ErrorBoundary from "../ErrorBoundary";
import BaseEditPage from "../Base/BaseEditPage";
import * as f from "../common/Funcs";

export default class UserRolePage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("ContactPage constructor", arguments);
        context.tableName = 'user_roles';
        context.idName = 'row_id';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.columnsCount = 1;
        context.maximizeOnShow = false;
        context.fieldsSet = [
            {name: '1', title: 'Основная информация'},
        ];
        context.footerButtonNames = ['saveclose', 'save', 'delete', 'close'];
        context.columnsDefaults = [
            {column_name:'row_id',hidden:true,required:false},
            {
                column_name: 'role_user',
                disabled: true,
                hidden: true,
                // source: {
                //     tableName: 'users',
                //     titleField: 'fio',
                //     idField:'user_id'
                // }
            },

            {
                column_name: 'user_role',
                is_list: true,
                source: {
                    tableName: 'roles',
                    idField: 'role_id',
                    titleField: 'role_desc'
                },
                row: 0
            },
            {
                column_name: 'native_huntuser',
                is_list: true,
                source: {
                    tableName: 'hunt_users',
                    titleField: 'user_name',
                    idField: 'huntuser_id',
                    filterString: `date_off is null`
                },
                row: 1
            },
            {
                column_name: 'native_economy',
                is_list: true,
                source: {
                    tableName: 'economies',
                    titleField: 'economy_name',
                    idField: 'economy_id'
                },
                row: 2
            },
            {
                column_name: 'native_land',
                is_list: true,
                source: {
                    tableName: 'lands',
                    titleField: 'land_name',
                    idField: 'land_id',
                },
                row: 3
            },
            {
                column_name: 'native_region',
                is_list: true,
                source: {
                    tableName: 'regions',
                    titleField: 'region_name',
                    idField: 'oktmo',
                },
                row: 4
            },
        ];
        // context.subTables = [
        //     {
        //         pos: 2,
        //         elementName: 'BaseRelationSub',
        //         tableName: 'contactphones',
        //         subElementName: 'PhonePage',
        //         relation: {tableName: 'contact_phones', parentField: 'phone_contact', relField: 'contact_phone'},
        //         filterString: "phone_contact=$contact_id$",
        //         idName: 'phone_contact',
        //         hiddenColumns: ['phone_contact', 'phone_number'],
        //     },
        //     {
        //         pos: 2,
        //         elementName: 'BaseRelationSub',
        //         tableName: 'contactemails',
        //         subElementName: 'EmailPage',
        //         relation: {tableName: 'contact_emails', parentField: 'email_contact', relField: 'contact_email'},
        //         filterString: "email_contact=$contact_id$",
        //         idName: 'email',
        //         hiddenColumns: ['email_contact'],
        //     },
        //     //!!!сделать редакцию в таблице!
        //     {
        //         pos: 2,
        //         elementName: 'BaseSubGridPanel',
        //         tableName: 'contactaddress',
        //         subElementName: 'AddressPage',
        //         filterString: "address_contact=$contact_id$",
        //         idName: 'address_contact',
        //         hiddenColumns: ['address_contact'],
        //         columnsDefaults:[
        //             {column_name: 'address_type',title:true,pos:0},
        //             {column_name:'contact_address',title:true,pos:1}
        //         ],
        //         fieldset: 3
        //     },
        // ];
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record,parentRow) {
        context.title= 'роль пользователя';
        return context.title;
    }
}


