import BaseSubGrid from "../../Base/BaseSubGrid";
import ErrorBoundary from "../../ErrorBoundary";
import React from "react";

const Ext = window['Ext'];
const keyName = `DocumentsSubGrid`;

export default class DocumentsSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context = this;
        context.columnsDefaults=[
            {column_name:'path',pos:40,ignorePrint:true,ignoreExport:true,tableName:'documents'},
        ];
//шаблон строки
        context.tpl = (data) => {
            return (
                <div className={'doc-tpl'}>
                    <span className={'tpl-title'}>Дата начала:</span> <span className={'tpl-text'}>{data.date_on}</span> <span
                    className={'tpl-title'}> Дата окончания: </span> <span className={'tpl-text'}>{data.date_off}</span>
                </div>
            )
        };
        //группировка
        const storeProps = {
            data: context.state.data
        };
            context.grouped = context.props.grouped || true;
            if (context.grouped) {
                context.groupFooter = context.props.groupFooter || {
                    xtype: 'gridsummaryrow'
                };
                context.groupField = props.groupField || 'doc_type';
                context.groupHeaderTpl =
                    '<span>{value:htmlEncode}, количество документов: {count}' +
                    '</span>'

                storeProps.grouper= {
                        property: context.groupField, sortProperty: 'order_value',
                };
            }
            context.store = Ext.create('Ext.data.Store', storeProps);
    }

    getHeight() {
        const context=this;
        const itemsCount = context.state.data.length;
        const headersCount = (new Set([...context.state.data.map(i => i.doc_type)])).size;
        const maxHeight = window.innerHeight/2;
        if (itemsCount == 1) return  2 * 30 + headersCount * 30;
        const result = (1 + itemsCount) * 20 + headersCount * 30;
        return (maxHeight > result) ? result : maxHeight;
    }

}