import BaseSubGrid from "../../../Base/BaseSubGrid";
import * as f from '../../../common/Funcs';
import React from "react";
import {callPath} from "../../../common/Funcs";

const keyName = `PassLogSubGrid`;

export default class PassLogSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'pass_log';
        //элемент окна редактирования
        context.idName = 'log_id';
        context.hiddenColumns = ['log_id', 'record_id', 'table_name'];
        context.columnsDefaults = [
            {column_name: "op_name", maxWidth: 75, pos: 10}
            , {
                column_name: "record_dif", pos: 2, hidden: true, data_type: 'custom',renderer:()=>{}
            }
            , {column_name: "modified", maxWidth: 150, pos: 3}
            , {column_name: "fio", pos: 6},
            {
                column_name: "rights",hidden:true
            }
        ];
        context.tpl = (data) => {
            const content= Object.keys(data.record_dif).map(k => <div key={k+'key'}>
                <div key={k+'key1'}>{f.locale(k)}</div>
                <div key={k+'key2'}>{(data.record_dif[k]) ? data.record_dif[k].toString() : 'null'}</div>
            </div>);
            return (<div className={'log-tpl copy-container'}>
                {content}
            </div>)
        };
    }

    fetchFunction(params) {
        const {record, callback} = params;
        return f.callPath({id: record.passage_id, path: 'zmu/passages/log/', callback});
    }

}