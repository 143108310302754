import ErrorBoundary from "../../../ErrorBoundary";
import BaseSubGrid from "../../../Base/BaseSubGrid";

const keyName=`SpecDescSubGrid`;
//вид в КК - саб таблица
export default class SpecDescSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.height = 250;
        context.tableName = 'spec_desc';
        //элемент окна редактирования
        context.idName = 'spec';
        context.hiddenColumns = ['spec'];
    }

}