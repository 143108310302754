import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../Base/BaseSubGridPanel";

const keyName = `CardLogSub`;

export default class CardLogSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.noSingle= true;
        context.gridName=`CardLogSubGrid`;
        context.topMenuButtonNames =['reload'];
    }

    //заголовок окна
    setDialogTitle(context) {
        context.dialog.cmp.setTitle(`История изменений ${context.grid.getCurrentRow()[`user_name`]}`);
    }

}

