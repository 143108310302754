import React from "react";
import BaseGridPage from "../../../Base/BaseGridPage";

const keyName = `ZmuGraphPage`;
const title = `Зимний маршрутный учет. График численности.`;
export default class ZmuGraphPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const now = new Date();
        document.setCookie('toFilter', now.toLocaleDateString());
        context.gridName="ZmuGraphGrid";
        // context.subElementName = 'MappingPage';
        // context.tableName = 'mappings';
        context.filters = [ {name: 'year',pos:10,required:true}, {name: 'region',pos:20}, {name: 'zmuspec',pos:30,required:true}];
        context.state = {
            content: [`<p></p>`]
            , title: title
        };
    }

}