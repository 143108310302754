import React from "react";
import BaseByMapGrid from "../../Base/BaseByMapGrid";
import * as f from "../../common/Funcs";
import style from "../../Maps/styles/pnt";

const keyName = 'BasePointsGrid';

export default class BasePointsGrid extends BaseByMapGrid {
    static defaultProps = {}

    constructor(props) {
        super(props);
        const context = this;
        context.styleFunction = style;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'AnimalPage';
        context.tableName = `animal_points_view`;
        context.idName = 'row_id';
        context.topMenuButtonNames = ['reload', 'add', 'edit'];
        context.hiddenColumns = [context.idName, 'point_id', 'spec_id', 'passage_id'];
        context.columnsDefaults = [{column_name: "row_id"},
            {
                column_name: "spec_name",
                title: () => {
                    const edits=context.props.parent.isEditRight();
                    return edits;
                }, pos: 3, minWidth: 150, flex: {grow: 2}
            },
            {column_name: "meeting_date", flex: {grow: 2}},
            {column_name: "mrights", flex: {grow: 2}},
            {column_name: "geom", pos: 50, flex: {grow: 2}},
        ];

    }

    componentDidMount = () => {
        const context = this;
        // context.getData(context, context.props.parent.activeColumn);
        context.getData({context});
    }

    getData(params) {
        const {context, callback} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        context.store.loadRecords([]);
        context.inload = true;
        if (!context.parent) context.parent = context.props.parent.props.parent;
        window.mask();
        f.getViewData({
            context: context.props.appViewPort,
            viewName: context.tableName,
            filterString: `passage_id=${context.props.parent.props.rowId}`,
            callback: (result) => {
                context.inload = false;
                context.isLoaded = true;
                result.columns = result.columns.map(c => {
                    if (context.columnsDefaults) {
                        const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                        c = {...c, ...def};
                    }
                    return c;
                }).sort((a, b) => {
                    return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                });
                if (context.upResult) result = context.upResult(context, result);
                const upData = {columns: result.columns, vdata: result.data, error: result.flash};
                if (context.afterGetData)
                    context.afterGetData(context, upData);
                //заполнение карты пришедшими данными
                const olMap = context.props.parent.parent.olMap;
                if (olMap) {
                    const features = result.data.filter(r => r.geom).map(r => {
                        const props = {...r};
                        delete props.geom;
                        props.gid = props['row_id'];
                        props.source = context.tableName;
                        delete props['row_id'];
                        return {
                            type: 'Feature',
                            properties: props,
                            geometry: JSON.parse(r.geom)
                        }
                    })
                    olMap.clearFeatures({context: olMap,layerName: context.tableName});
                    olMap.addFeatures({
                        geoJson: features,
                        context: olMap,
                        layerName: context.tableName,
                        styleFunction: context.styleFunction
                    });
                    if (callback) callback(result);
                    window.unmask();
                }
            }
        })
    }

    //если нужен фильтр по колонке актуальности
    getFilterStringFromRow(activeColumn) {
        const context = this;
        const record = context.props.record;
        if (typeof (activeColumn) == 'undefined' && context.props.parent.activeColumn) activeColumn = context.props.parent.activeColumn;
        let filterString = context.props.filterString;
        if (activeColumn && !context.props.parent.state.showAll)
            if (filterString)
                filterString += ` & ${activeColumn}=true`;
            else
                filterString = ` ${activeColumn}=true`;
        if (window.IasConfig.devMode) console.debug(`${keyName} getFilterStringFromRow`, ` ${record}`, `filterString ${filterString}`);
        if (!filterString && !activeColumn) return '';
        //выполнить замену подстановок
        const array = filterString.match(/\$[^\$]*\$/g).map(e => e.replaceAll('$', ''));
        array.map(a => filterString = filterString.replace(`$${a}$`, (record[a]?.toLocaleDateString) ? record[a].toLocaleDateString() : record[a]));
        return filterString;
    }

}