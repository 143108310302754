import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../Base/BaseSubGridPanel";

const keyName = `HuntDescSub`;

export default class HuntDescSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.gridName=`HuntDescSubGrid`;
    }

    //заголовок окна
    setDialogTitle(context) {
        context.dialog.cmp.setTitle(`Основные реквизиты охотпользователя ${context.grid.getCurrentRow()[`user_name`]}`);
    }

}

