import BaseGrid from "./BaseGrid";
import * as f from "../common/Funcs";

const keyName = `BaseSubGrid`;

export default class  BaseSubGrid extends BaseGrid {
    constructor(props) {
        super(props);
        const context = this;
        context.height = props.height || 350;
        context.tpl=props.tpl;
        context.rowNumberWidth='40px';
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
    }

    componentDidMount = () => {
        const context = this;
            context.getData({context, activeColumn:context.props.parent.activeColumn});
    }

    //если нужен фильтр по колонке актуальности
    getFilterStringFromRow(activeColumn) {
        const context = this;
        const record = context.props.record;
        if (typeof (activeColumn) == 'undefined' && context.props.parent.activeColumn) activeColumn = context.props.parent.activeColumn;
        let filterString = context.props.filterString||context.filterString;
        if (activeColumn&&!context.props.parent.state.showAll)
            if (filterString)
                filterString += ` & ${activeColumn}=true`;
            else
                filterString = ` ${activeColumn}=true`;
        if (window.IasConfig.devMode) console.debug(`${keyName} getFilterStringFromRow`, ` ${record}`, `filterString ${filterString}`);
        if (!filterString && !activeColumn) return '';
        //выполнить замену подстановок
        const array = filterString.match(/\$[^\$]*\$/g).map(e => e.replaceAll('$', ''));
        array.map(a => filterString = filterString.replace(`$${a}$`, (record[a]&&record[a].toLocaleDateString) ? record[a].toLocaleDateString() : record[a]));
        return filterString;
    }

    getPaging() { return '';
    }

    afterGetData(context, upData) {
        context.isLoaded = true;
    }

    fetchFunction(params) {
        return f.getViewData(params);
    }

    //контекст выполнения и столбец актуальности, если нужен фильтр по актуальности
    getData(params) {
        const {context, activeColumn}=params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        context.store.clearData();
        if (!context.props.rowId || context.props.rowId==-1||!context.props.record) return;
            const filterString = context.getFilterStringFromRow(activeColumn);
            if (filterString.indexOf('undefined')>-1) return;
        const viewName = context.props.tableName || context.tableName;
        const ps=
            {
                context: context.props.appViewPort, viewName: viewName, filterString: filterString,record:context.props.record,
                callback:
                    (result) => {
                        //добавляем колонку редактирования, если её нету в списке и родитель выдал права на редактирование
                        if (!(result?.columns)) return;
                        if (result.columns.filter(
                            c => c.column_name.indexOf("rights") > -1).length == 0 && f.isEditRight(context.props.rights)) {
                            result.columns.push({
                                column_name: "rights",
                                data_type: "string",
                                max_length: null,
                                pos: 100
                            });
                            result.data.map(d => d.rights = context.props.rights);
                        }
                        result.columns = result.columns.map(c => {
                            c.flex={grow:2};
                            if (context.columnsDefaults) {
                                const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                                c = {...c, ...def};
                            }
                            return c;
                        }).sort((a, b) => {
                            return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                        });
                        context.isLoaded = true;
                        const upData = {columns: result.columns,data:result.data};
                        upData.singleMode = (!context.props.parent.noSingle)&&(result.data.length == 1);
                        // if (context.props.parent.subPanel)
                        if (result.data.length==0||context.props.parent.collapsed) {
                            context.props.parent.subPanel.collapse();}else {
                            context.props.parent.subPanel.expand();}
                        context.store.loadRawData(result.data);
                        context.setState(upData, () => {
                            if (upData.singleMode && context.single) context.single.getData({context:context.single});
                            context.afterGetData(context, result);
                            context.props.parent.setState({singleMode: upData.singleMode});
                        });
                    }
            };
        if (context.fieldsByList) ps.fields=context.fieldNames;
        context.fetchFunction(ps);
    }

    getHeight() {
        const context=this;
        const itemsCount = context.state.data.length;
        const maxHeight=window.innerHeight/2;
        if (itemsCount == 1&&context.state.singleMode) return '100%';
        const result= (1 + itemsCount) * (context.rowHeight||context.props.rowHeight)+10;
        return (maxHeight>result)?result:maxHeight;
    }

}
