import React, {Component} from 'react';
import {ExtContainer, Dialog, Panel} from "@sencha/ext-react-modern";
import ErrorBoundary from "../ErrorBoundary";

const keyName="FlashPanel";


class FlashPanel extends Component {
    constructor(props) {
        super(props);
        this.state={messages:props.messages||[]}
    }
    render() {
        const context=this;

        const messages=context.state.messages;
        const elements=messages.map((m,i)=> {
            const mes= (typeof(m)=='string')?{level:'w',message:m}:m;
            return <div
                name={`${mes.level}${i}`} key={`${mes.level}${i}`}
                width={window.innerWidth - 2 * window.IasConfig.margin - 14}
                className={`message-panel message-panel-${mes.level}`}>{mes.message}</div>;}
        )

        return (<Panel className={'in-front'}
            name={keyName}
            key={keyName}
        >{elements}</Panel>)
    }
}

export default FlashPanel;