import * as f from "../../common/Funcs";
import BaseEditByMapPage from "../../Base/BaseEditByMapPage";
import landstyle from "../../Maps/styles/landspol";
import admstyle from "../../Maps/styles/admpol";


const keyName="ViolationPage";
export default class ViolationPage extends BaseEditByMapPage {
    constructor(props) {
        super(props);
        const context = this;
        context.columnsCount = 2;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'violations';
        context.fieldsSet =     [
            {title: 'Факт встречи',fieldset: 'meeting',column:0,pos:1},
            {title: 'Описание животного',fieldset:'spec',column:0,pos:2},
            {title:'Карта',fieldset:'map',pos:3,column:0},
            {title: 'Документы',fieldset:'doc',column:1,pos:4},
            {title: 'Нарушение',fieldset:'viol',column:1,pos:5},
            {title: 'Нарушитель',fieldset:'pers',column:1,pos:6},
            {title: 'Результат',fieldset:'result',column:1,pos:7}
        ];
        context.columnsDefaults=[...context.lastUserColumns,...[
            {column_name:'meeting_num',row:0,pos:0},
            {pos: 0,                 labelAlign: 'left',
                column_name: 'meeting_type', hidden: true},
            {
                pos: 1, row:0,
                column_name: 'meeting_spec',
                labelAlign: 'left',
                fieldset : 'spec',
                is_list: true,
                source: {
                    tableName: 'meetings_spec',
                    titleField: 'spec_name',
                    idField: 'meeting_spec',
                }
            },
            {
                pos: 0,row:1,
                labelAlign: 'left',
                column_name: 'meeting_huntuser',
                is_list: true,
                source: {
                    tableName: 'hunt_users',
                    titleField: 'user_name',
                    idField: 'huntuser_id',
                }
            },
            {
                pos: 0,row:2,
                labelAlign: 'left',
                column_name: 'meeting_oktmo',
                is_list: true,
                source: {
                    tableName: 'regions',
                    titleField: 'region_name',
                    idField: 'oktmo',
                }
            },
            {column_name:'meeting_place',
                labelAlign: 'left',
                pos:7,row:9},
            {column_name:'meeting_comment',
                labelAlign: 'left',
                pos:8,row:20},
            {column_name:'geom',pos:11,block:1,fieldset:'map',
                mapButtonNames:['edit','drop'],
                layerNames: [
                    {
                        layerName: 'landspol', hidden: false, type: 'own', styleFunction: landstyle
                    },
                    {
                        layerName: 'admpol', hidden: false, type: 'own', styleFunction: admstyle
                    },
                ]},
            {"column_name":"doc_type","pos":10,"row":1,fieldset:'doc'},
            {"column_name":"blank_num","pos":20,"row":1,fieldset:'doc'},
            {"column_name":"doc_num","pos":30,"row":1,fieldset:'doc'},
            {"column_name":"doc_date","pos":40,"row":2,fieldset:'doc'},
            {"column_name":"inspector","pos":50,"row":2,fieldset:'meeting'},
            {"column_name":"viol_type","pos":0,"row":0,fieldset:'viol'},
            {"column_name":"viol_huntuser","pos":10,"row":2,fieldset:'viol'},
            {"column_name":"viol_person","pos":20,"row":1,fieldset:'viol'},
            {"column_name":"koap","pos":60,"row":2,fieldset:'viol'},
            {"column_name":"viol_bday","pos":0,"row":10,fieldset:'pers'},
            {"column_name":"viol_bplace","pos":0,"row":10,fieldset:'pers'},
            {"column_name":"viol_adress","pos":0,"row":11,fieldset:'pers'},
            {"column_name":"viol_phone","pos":0,"row":12,fieldset:'pers'},
            {"column_name":"viol_jplace","pos":0,"row":60,fieldset:'pers'},
            {"column_name":"pasp_type","pos":0,"row":13,fieldset:'pers'},
            {"column_name":"pasp_num","pos":0,"row":13,fieldset:'pers'},
            {"column_name":"pasp_where","pos":0,"row":20,fieldset:'pers'},
            {"column_name":"pasp_date","pos":0,"row":20,fieldset:'pers'},
            {"column_name":"huntcard_seria","pos":0,"row":50,fieldset:'pers'},
            {"column_name":"huntcard_num","pos":0,"row":50,fieldset:'pers'},
            {"column_name":"huntcard_where","pos":0,"row":51,fieldset:'pers'},
            {"column_name":"huntcard_date","pos":0,"row":51,fieldset:'pers'},
            {"column_name":"to_child","pos":0,"row":0,fieldset:'result'},
            {"column_name":"to_punish","pos":0,"row":0,fieldset:'result'},
            {"column_name":"resol_num","pos":0,"row":10,fieldset:'result'},
            {"column_name":"resol_date","pos":0,"row":10,fieldset:'result'},
            {"column_name":"resol_person","pos":0,"row":10,fieldset:'result'},
            {"column_name":"resol_result","pos":0,"row":20,fieldset:'result'},
            {"column_name":"resol_isk","pos":0,"row":30,fieldset:'result'},
            {"column_name":"isk_payed","pos":0,"row":30,fieldset:'result'},
            {"column_name":"resol_remov","pos":0,"row":40,fieldset:'result'},
            {"column_name":"resol_rem_reason","pos":0,"row":40,fieldset:'result'},
            {"column_name":"criminal","pos":0,"row":50,fieldset:'result'},
            {"column_name":"crim_where","pos":0,"row":50,fieldset:'result'},
            {"column_name":"crim_date","pos":0,"row":50,fieldset:'result'},
            {"column_name":"crim_result","pos":0,"row":50,fieldset:'result'},
        ]]
        context.columnsDefaults.filter(c => (c.column_name == 'meeting_type'))[0].value=3;
        context.subTables=[
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='violations' and record_id = $row_id$::varchar",
            }
        ]
        //context.columnsDefaults.push();
    }


    //заголовок формы редактирования по умолчанию
    titleFunc(context, record,parentRow) {
        try {
            context.title= `Нарушение от ${((record['meeting_date']) ? new Date(record['meeting_date']).toLocaleDateString() : '--.--.-- ')}`;
        } catch (e) {
            context.title= `Нарушение --.--.-- `;
        }
        return context.title;
    }

}


