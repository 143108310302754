import React from "react";
import { Cartesian, Panel} from "@sencha/ext-react-modern";
import BasePageGrid from "../../../Base/BasePageGrid";

const keyName = 'ZmuGraphGrid';

export default class ZmuGraphGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'PasAllPage';
        context.zmuTableId = 'passages';
        context.tableName = `passages_view`;
        context.idName = 'passage_id';
        context.hiddenColumns = ['passage_id', 'acc_year']
        context.columnsDefaults = [
            {column_name: 'route_name', pos: 1, minWidth: 120, title: true},
        ]
    }

    refresh() {
    }

    render() {
        const context = this;
        const theme = 'default';
        const content = [
            <Cartesian key={'chart'}
                // downloadServerUrl='http://svg.sencha.io'
                       layout={'fit'}
                       key={keyName + 'grid'}
                       name={keyName}
                //            height={context.getHeight()}
                       height={(context.props.height && context.props.height !== '100%') ? context.props.height : context.getHeight()}
                       maxHeight={context.props.maxHeight || context.getMaxHeight(context)}
                       maxWidth={context.props.maxWidth}
                       width={context.props.width}
                       minHeight={context.props.minHeight}
                       minWidth={context.props.minWidth}
                       insetPadding="20 10"
                       store={context.store}
                       scheme={theme}
                       axes={[{
                           type: 'numeric',
                           position: 'left',
                           title: 'Численность, особей'
                       }, {
                           type: 'category',
                           position: 'bottom'
                       }]}
                       series={[{
                           type: 'bar',
                           xField: 'yearval',
                           yField: 'q'
                       }]}
            />];
        return (
            <Panel key={`${keyName}gridpanel`} hidden={context.props.hidden}
                   ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
            >{content}</Panel>
        )
    }

}