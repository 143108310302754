import React from 'react';
import BaseSubGridPanel from "./BaseSubGridPanel";

const keyName = `BaseRelationSub`;

export default class BaseRelationSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.relation = props.relation;
        context.topMenuButtonNames=(props.topMenuButtonNames)?props.topMenuButtonNames: ['reload','add','edit','relation'];
    }

}

