import {Circle as CircleStyle, Fill, Text, Stroke, Style} from "ol/style";

const keyName = 'pnt.js';
export default (feature, resolution) => {
    if (window.cssOn) console.debug(`${keyName} css`, feature);
    const props = feature.getProperties()
    const specId = props.spec_id || props.meeting_spec;
    //  const specId = feature.getProperties().spec_name;
    const radius = 4;
    let color = [255, 255, 255, 1];
    let text = feature.getProperties().spec_name;
    let text2 = (feature.getProperties().biotop)?feature.getProperties().biotop.substring(0,1):'';
    //не понимают, почему разноцветное
    switch (specId) {
        case 8804:
            color = [140, 110, 65, 1];
            text = `Ка`;
            break;
        case 8805:
            color = [128, 128, 128, 1];
            text = `Во`;
            break;
        case 8813:
            color = [170, 55, 55, 1];
            text = `Го`;
            break;
        case 8862:
            color = [255, 180, 75, 1];
            text = `Бе`;
            break;
        case 8811:
            color = [170, 55, 55, 1];
            text = `Кул`;
            break;
        case 8807:
            color = [128, 128, 128, 1];
            text = `Ли`;
            break;
        case 8800:
            color = [60, 190, 100, 1];
            text = `Ло`;
            break;
        case 2141:
            color = [60, 190, 100, 1];
            text = `Ксиб`;
            break;
        case 2150:
            color = [170, 55, 55, 1];
            text = `Кук`;
            break;
        case 8816:
            color = [60, 190, 100, 1];
            text = `Хо`;
            break;
        case 8809:
            color = [128, 70, 70, 1];
            text = `Ро`;
            break;
        case 8838:
            color = [225, 225, 225, 1];
            text = `Зб`;
            break;
        case 8808:
            color = [128, 100, 100, 1];
            text = `Ры`;
            break;
        case 8801:
            color = [60, 190, 100, 1];
            text = `Кевр`;
            break;
        case 8837:
            color = [225, 225, 225, 1];
            text = `Зр`;
            break;
        case 8802:
            color = [60, 190, 100, 1];
            text = `Об`;
            break;
        case 8803:
            color = [60, 190, 100, 1];
            text = `Оп`;
            break;
        case 38303:
            color = [60, 190, 100, 1];
            text = `Ко`;
            break;
        case 38301:
            color = [170, 55, 55, 1];
            text = `Со`;
            break;
        case 38302:
            color = [170, 55, 55, 1];
            text = `Кр`;
            break;
        case 38304:
            color = [60, 190, 100, 1];
            text = `Кб`;
            break;
        case 38305:
            color = [60, 190, 100, 1];
            text = `ДСО`;
            break;
        case 8627:
            color = [200, 250, 250, 1];
            text = `Крп. б `;
            break;
        case 8628:
            color = [170, 215, 215, 1];
            text = `Крп. с `;
            break;
        case 8629:
            color = [240, 170, 235, 1];
            text = `Ряб`;
            break;
        case 8630:
            color = [160, 180, 155, 1];
            text = `Тет`;
            break;
        case 8631:
            color = [135, 155, 195, 1];
            text = `Гл`;
            break;
        case 0:
            color = [0, 0, 0, 1];
            text = '';
            break;
        default:
            console.error(props);
    }
    ;
    //боятся, что будет сливаться с топоосновой
    switch (props.biotop) {
        case 'болото':
            color = [206, 155, 210, 1];
            break;
        case 'поле':
            color = [128, 223, 120, 1];
            break;
        case 'лес':
            color = [37, 153, 43, 1];
            break;
    }
    ;
    color = [255, 0, 0, 1];
    if (props.count)
        text = text + props.count;
    const text_color = [255,255,255,1];
    const w = new Style({
        image: new CircleStyle({
            radius: radius + 1,
            fill: new Fill({
                color: [0, 0, 0, 1],
            }),
        }),
        text: new Text({
            text: text2,
            font: 'bold 12px Calibri,sans-serif',
            offsetX: 10,
            offsetY: 10,
            stroke: new Stroke({color: text_color, width: 2})
        }),
    });
    const p = new Style({
        image: new CircleStyle({
            radius: radius,
            fill: new Fill({
                color: color || [100, 100, 255, 1],
            }),
        }),
        text: new Text({
            text: text,
            font: 'bold 12px Calibri,sans-serif',
            offsetX: 10,
            offsetY: -10,
             stroke: new Stroke({color: text_color, width: 2})
        }),
    });
    return [w, p];
}