import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import * as f from "../../common/Funcs";
import BaseAccPage from  "../../Base/BaseAccPage";

const keyName = `PermisionsPage`;

export default class PermisionsPage extends BaseAccPage {
    static defaultProps={acc:'acc'};
    constructor(props) {
        super(props);
        const context = this;
        context.accId='5';
       context.filters.push({name:'acc',required:true,message:f.locale('needreestr')});
        context.filters.push({name:'accspec'},
            {name: 'num', required: false},
        );
        document.setCookie('accFilter','perm')
        //context.hiddenColumns=[context.idName];
    }
}
