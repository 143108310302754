import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName = `SpecLitPage`;

export default class SpecLitPage extends BaseEditPage {
    static defaultProps={
        width:'75%'
    }
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'spec_lit_sources';
        context.idName = 'id_lit_source';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.columnsCount=['50','50'];
        this.columnsDefaults = [...context.lastUserColumns,...[
            {column_name: 'id_lit_source', hidden: true},
            {column_name: 'lit_source', pos:10,row:1,disable:true},
            {column_name: 'publ_place_or_edition', pos:20,row:1},
            {column_name: 'publ_year', pos:0,row:2},
            {column_name: 'volume_issue', pos:0,row:3},
            {column_name: 'lit_auth', pos:0,row:4},
        ]];
        context.fieldsSet = [
            {title: 'Основная информация'}
        ];
        context.footerButtonNames = ['saveclose', 'save', 'delete', 'close'];
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record,parentRow) {
        if (!record) record=parentRow;
        return context.title;
    }

}


