import React from 'react';
import * as f from "../common/Funcs";
import {Panel} from "@sencha/ext-react-modern";
import Grids from "../collections/gridElements";
import * as pe from "../collections/panelElements";
import BasePage from "./BasePage";
import DialogForm from "../common/DialogForm";
import * as html2canvas from "html2canvas";
import * as g from "../common/gisFuncs";
import ErrorBoundary from "../ErrorBoundary";

const Ext = window['Ext'];
const keyName = `BaseGridPage`;

export default class BaseGridPage extends BasePage {

    constructor(props) {
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        super(props);
        const context = this;
        window.p = context;
        //меню страницы
        context.topMenuButtonNames = ['reload', 'xls', 'print', 'add', 'clipboard'];
    }

    componentDidMount() {
        const context = this;
        const app = context.props.appViewPort;
        app.setState({filters: context.filters, rightWidth: context.rightWidth}, () => {
            context.filters.map(k =>
                app.filters[`${k.name.firstToUpper()}Filter`]?.setState({required: k.required}));
        });
        if (app.autoload) context.update(context);
    }

    /*печатная форма, получение данных*/
    getJson(context) {
        if (!context) context = this;
        const getCD = (context) => {
            const state = context.grid.state;
            const source = Array.isArray(state.vdata) ? state.vdata : [state.vdata];
            const data = [];
            const columns = [];
            state.columns.filter(c => (c.column_name != context.idName &&
                c.column_name != context.props.idName &&
                c.column_name != 'geom' &&
                (!(context.props.hiddenColumns) || context.props.hiddenColumns.filter(h => h == c.column_name).length == 0) && c.column_name.indexOf('rights') == -1)).map(c => {
                columns.push({
                    column_name: c.column_name,
                    column_label: c.column_label || f.locale(c.column_name),
                    data_type: c.data_type,
                    cls: c.className
                    // hidden:c.hidden
                });
                source.map((r, idx) => {
                    if (!data[idx]) data[idx] = {};
                    data[idx][c.column_name] =
                        (c.is_list) ? context.elements[c.column_name].field.cmp.getRawValue() :
                            (c.data_type == 'date') ? (new Date(r[c.column_name])).toLocaleDateString() :
                                r[c.column_name];
                })
            });
            return {
                columns: columns,
                data: data
            };
        }
        const result = {};
        result.subs = [];
        // result.subs.push(getCD(context));
        result.subs.push(context.getColData({
            context,
            source: context.grid.state.vdata,
            scolumns: context.grid.state.columns
        }));
        result.desc = {title: context.title};
        result.subs[0].desc = {title: context.title, view_name: context.gridName};

        return result;

    }

    /*печатная форма, получение картинки, если есть на странице*/
    async getImg(context) {
        const promise = new Promise((resolve) => {
            if (context.olMap) {
                html2canvas(context.olMap.olMap.getViewport(), g.getExportOptions()).then(function (canvas) {
                    //   document.body.appendChild(canvas);
                    const imgData = canvas.toDataURL('image/png');
                    return resolve(imgData);
                });

            } else return resolve(null);
        });
        const result = await promise;
        return result;
    }

    /*перейти на страницу редактирования*/
    showEditPanel(context, newId, callId) {
        if (window.IasConfig.devMode) console.debug(`${keyName} showEditPanel`, Boolean(context.editForm), context.grid.getCurrentRow());
        context.setState({showEditPanel: true});
        //заголовок форме редактирования
        if (context.editForm) {

            //id с грида передать в форму редактирования и обновить данные
            if (!newId) {
                if (callId) {
                    context.editForm.record = context.grid.getCurrentRow();
                    context.editForm.rowId = callId;
                } else {
                    context.editForm.record = context.grid.getCurrentRow();
                    context.editForm.rowId = context.grid.getCurrentId();
                }
                window.ef = context.editForm;
            } else {
                context.editForm.rowId = -1;
                // context.editForm.rowId = null;
            }
            f.getTitle(context);
            context.editForm.getData({context: context.editForm});
        }
    }

    hideEditPanel(context) {
        context.setState({showEditPanel: false});
    }

    getDialog(context) {
        const p = context.getEditType(context);
        if (!p.el) return '';
        const pEl = <p.el
            ref={(e) => {
                if (e) {
                    context.editForm = e;
                }
            }}
            pageDefaults={context.state.pageDefaults}
            maxWidth={context.props.maxWidth}
            key={context.state.subElementName || context.subElementName}
            rights={context.state.rights}
            params={context.state.subParams || context.subParams}
            parent={context} appViewPort={context.props.appViewPort}/>
        const dialog = <DialogForm
            showEditPanel={context.state.showEditPanel}
            editFormEl={pEl}
            keyMapEnabled={true}
            keyMap={{
                ESC: 'onEscape',
                scope: 'this'
            }}
            propName={'dialog'}
            key={'dialog'} parent={context}/>
        // window.dialogs.push(dialog);
        return dialog;
    }

    getGrid(context) {
        return React.createElement(context.getGridType(context).el, {
            parent: context,
            appViewPort: context.props.appViewPort,
            key: context.gridName,
            name: context.gridName,
            width: '98%',
            height: '100%',
            ref: (grid) => context.grid = grid
        })
    }

    render() {
        const context = this;
        //тело таблицы
        const grid = context.getGrid(context);
        const editMenu = context.getEditMenu(context);
        const dialog = (context.getDialog) ? context.getDialog(context) : '';

        return (<ErrorBoundary  key={`error${keyName}`}><Panel
            cls={'print-100-percent'}
            height={f.getCentralHeight()}
            region={"center"} margin="3"
            key={`gridPanel${keyName}`}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            {editMenu}
            {grid}
            {dialog}
        </Panel></ErrorBoundary>)
    }
}

