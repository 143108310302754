import React from 'react';
import BaseEditPage from "../../Base/BaseEditPage";
import ZmuRouteMap from "../../Maps/ZMU/ZmuRouteMap";
import BaseMapSub from "../../Base/BaseMapSub";

const keyName = `ZmuRouteMapSub`;

export default class ZmuRouteMapSub extends BaseMapSub {

    constructor(props) {
        super(props);
        const context=this;
        context.state= {showAll : true};
    }

    getHeight() {
        const context = this;
        let result=300;
        if (context.props.block&&context.props.parent?.form?.cmp?.el) {
            result = (context.props.parent?.form?.cmp?.el.getHeight() - 100);
        }
        return result;
    }

    getMap(context, params = {}) {
        let parent = context;
        while (!(parent instanceof BaseEditPage)) {
            parent = parent.props.parent;
        }
        return <ZmuRouteMap
            ref={(e => {
                context.olMap = e;
                parent.olMap = e;
            })}
            height={context.getHeight()}
            readonly={(!context.props.parent.isEditRight()||context.props.parent.isApproved())}
            // width={params.width||'100%'}
            rights={params.rights || context.props.rights}
            key={'routeMap'}
            styleFunction={context.styleFunction||context.props.styleFunction}
            rowId={parent.rowId}
            parent={context} appViewPort={context.props.appViewPort}/>;
    }
}

