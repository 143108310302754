import React from 'react';
import SimpleImportMap from "../SimpleImportMap";
import * as f from "../../common/Funcs";
import {BaseButton, BaseTextButton} from "../../Base/BaseButtons";
import {disableInteractions} from "../MapControls";
import {Draw, Modify, Snap} from "ol/interaction";
import * as g from "../../common/gisFuncs";
import {Vector as VectorSource} from "ol/source";
import {Vector as VectorLayer} from "ol/layer";
import intersect from "@turf/intersect";
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";
import landstyle from "../styles/landspol";
import admstyle from "../styles/admpol";

let context = null;
const keyName = "ZmuEaSingleMap";
export default class ZmuEaSingleMap extends SimpleImportMap {

    static defaultProps = {
        mapButtonNames: ['upload', 'download', 'edit', 'cut'],
        layerNames: [
            {
                layerName: 'landspol', hidden: false, type: 'own', styleFunction: landstyle
            },
            {
                layerName: 'admpol', hidden: false, type: 'own', styleFunction: admstyle
            },
        ],
        load: 'auto',
        geomType: 'Polygon'
    }

    /*дополнительные инструменты на карте*/
    getAdditControls() {
        return []
    }

    isEditRight(context) {
        const page = context.props.parent.props.parent;
        return page.isEditRight(context.props.rights) &&
            context.featureOn;
    }

    addCutButton(buttons) {
        const context = this;
        if (!context.isEditRight(context)) return buttons;
        if (!buttons) buttons = [];
        buttons[0] = (<BaseButton key={'cutButton'} pressed={(this.state.inEdit)} iconCls={'fa fa-cut'}
                                  func={(e) => this.cutTool(e)}/>);
        buttons[20] = (<BaseTextButton key={'okButton'} hidden={true} ref={(r) => context.ok = r} text={'Завершить'}
                                       func={(e) => this.finishCutPolygon(context)}/>);
    }

    cutTool(e) {
        if (window.mapDebug) console.debug('cut start');
        const context = this;
        disableInteractions(context.olMap);
        if (context.state.isCut) {
            context.setState({isCut: false}, () => {
                context.cutSource.clear();
                context.ok.cmp.hide();
            })
        } else {
            const cut = {};
            context.setState({isCut: true}, () => {
                const editCut = (context, e) => {
                    const source = context.cutSource;
                    if (!source) return;
                    if (e.feature) {
                        context.ok.cmp.show();
                        disableInteractions(context.olMap);
                        return;
                        const modify = new Modify({
                            source: source,
                            deleteCondition: function (event) {
                                return event.originalEvent.altKey;
                            }
                        });
                        context.olMap.addInteraction(modify);
                        const snap = new Snap({source: source});
                        context.olMap.addInteraction(snap);
                        modify.on('modifyend', (event) => {
                            let geoJson = g.toGeoJson(source.getFeatures()[0]);
                        });
                    }
                }
                /*добавляем слой для обрезки*/
                const addCutLayer = (context) => {
                    context.cutSource = new VectorSource();
                    context.cutLayer  = new VectorLayer({
                        source: context.cutSource,
                        style: new Style({
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.2)',
                            }),
                            stroke: new Stroke({
                                color: [255, 125, 0, 1],
                                width: 2,
                            }),
                            image: new CircleStyle({
                                radius: 7,
                                fill: new Fill({
                                    color: [255, 125, 0, 1],
                                }),
                            }),
                        }),
                    });
                    context.cutLayer.statusName = 'cut';
                    context.cutLayer.pos = 100;
                    context.olMap.addLayer(context.cutLayer);
                    context.orderLayers(context);

                }
                /*включаем обрезку территории */
                if (!context.cutSource) addCutLayer(context);
                cut.draw = new Draw({
                    source: context.cutSource,
                    type: context.props.geomType,
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineDash: [10, 10],
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgba(0, 0, 0, 0.7)',
                            }),
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.2)',
                            }),
                        }),
                    }),
                });
                cut.draw.interactionName = context.props.geomType;
                context.olMap.addInteraction(cut.draw);
                cut.draw.on('drawend', function (e) {
                    editCut(context, e);
                });
            })
        }

    }

    /*Завершаем обрезку территории */
    finishCutPolygon(context) {
        if (window.mapDebug) console.debug('cut finish');
        // disableInteractions(olMap);
        context.setState({isCut: false}, () => {
            f.confirm('Обрезать территорию по выделению?', (e) => {
                if (e) {
                    if (window.IasConfig.devMode) console.debug(`${keyName}`);
                    const ea = context.featuresSource.getFeatures().find(f => f.values_.source == 'eapol');
                    const f1 = g.toGeoJson(ea);
                    const f2 = g.toGeoJson(context.cutSource.getFeatures()[0]);
                    const f3 = intersect(f1, f2);
                    ea.getGeometry().setCoordinates([f3.geometry.coordinates]);
                    context.featuresSource.getFeatures().find(f => f.values_.source == 'eapol').getGeometry().setCoordinates([f3.geometry.coordinates]);
                    context.setToField(ea);
                    // olMap.cutSource.clear();
                }
                context.cutSource.clear();
                context.ok.cmp.hide();
            })
        })
    }

    setToField(feature) {
        const context = this;
        // context.props.parent.gisFeature = feature;
        const coords = feature.getGeometry().getCoordinates();
        let object = {
            type: feature.getGeometry().getType(),
            crs: {"type": "name", "properties": {"name": "urn:ogc:def:crs:EPSG:3857"}}
        };
        object.coordinates = coords;
        // context.geomField.cmp.setValue(JSON.stringify(object));
        this.geomField = JSON.stringify(object);
    }

    /*

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context=this;
        if (prevProps.rowId!=context.props.rowId) {
            context.setState({buttons:context.addButtons()});
        }
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    isEditRight(context) {
        const page=context.props.parent.props.parent;
        return page.isEditRight(context.props.rights);
    }

    getValues(file) {
        if (window.IasConfig.devMode) console.debug(`${keyName} getBaseValues`, context.form.cmp.getValues());
        let result = new FormData();
        result.append('file', file);
        return result;
    }

    render() {
        const context = this;
        const height = context.props.height;
        const width = context.props.width;
        const readonly = context.props.readonly;

        let toolBox = '';
        if (!readonly) {
            const buttons = context.state.buttons;
            toolBox = <Panel layout={'vbox'} margin={3} key={`toolBoxPanel${keyName}`} name={`toolBoxPanel${keyName}`}>
                {buttons}
            </Panel>;
        }
        return <Panel
            layout={'hbox'} margin="3"
            key={`mapPanel${keyName}`}
            name={`mapPanel${keyName}`}
            className={'map-panel'}
            width={width}
            height={height}
            ref={p => context.cmp = p}
        >
            <div className={'map-container'} style={{height: height, width: width}}
                 ref={(m) => context.mapDiv = m}/>
            {toolBox}
        </Panel>;
    }

*/
}
