import BasePageGrid from "../../Base/BasePageGrid";
import ErrorBoundary from "../../ErrorBoundary";
import React from "react";
const keyName='HuntEconsGrid';
export default class HuntEconsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.idName = 'eco_id';
        context.hiddenColumns=[context.idName,'hu_id'];
        context.columnsDefaults = [
            {"column_name": "hu_name", pos: 1, minWidth:300, maxWidth: 200},
            {"column_name": "eco_name", pos: 2, minWidth:300, title:true, flex:{grow:2}},
            {"column_name": "eco_regions", pos: 3, minWidth: 300,maxWidth: 350},
        ]
    }


}