import ErrorBoundary from "../ErrorBoundary";
import BaseEditPage from "../Base/BaseEditPage";
import * as f from "../common/Funcs";

const keyName = "UserPage";
export default class UserPage extends BaseEditPage {
    static defaultProps = {
        width: '52%'
    }
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.isUser = true;
        context.tableName = 'users';
        context.idName = 'user_id';
        context.titleName = 'fio';
        context.maximizeOnShow=false;
        context.columnsCount = 1;
        context.footerButtonNames = ['saveclose', 'save', 'close'];
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.columnsDefaults = [
            {"column_name": "user_id", hidden:true},
            {"column_name": "fio", "row": 1, "pos": 0, required: true},
            {"column_name": "username", "row": 0, "pos":1},
            {"column_name": "active", "row": 2, "pos": 0, defaultValue:true},
            {"column_name": "isblocked", "row": 2, "pos": 1},
            {"column_name": "password", "row": 3, "pos": 0},
            {"column_name": "phone", "row": 5, "pos": 0},
            {"column_name": "email", "row": 4, "pos": 0},
            {"column_name": "user_contact", "row": 5, "pos": 0,
                is_list: true,
                source: {
                    tableName: 'users_contact',
                    titleField: 'fio',
                    idField: 'contact_id',
                    // filterString: `u_id=%user_id%`,
                }}
            //если нужен особый пассворд
            //{column_name: 'password', data_type: 'password'}
        ];
        context.subTables = [
            {
                pos: 2,
            title: 'Роли пользователя',
            elementName: 'BaseSubGridPanel',
                subName:'UserRolesSub',
                tableName: 'userrolesview',
                subElementName: 'UserRolePage',
                filterString: "role_user=$user_id$",
                idName: 'row_id',
                singleColumns:[{column_name:'row_id',hidden:true}, {column_name:'role_user',hidden:true}],
                columnsDefaults:[{column_name:'role_desc',title:true,minWidth:150}],
                hiddenColumns: ['role_user', 'row_id'],
                fieldset: 'roles',
                noSingle:true
            },
        ]
    }

    setData(context, submit) {
        f.setUserData({
            context: context,
            id: (context.rowId || context.props.parent.rowId),
            form: context.form,
            callback: submit
        })
    }
}


