import React from 'react';
import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName = 'SpecPage';
export default class SpecPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        window.efs = context;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'species_view';
        context.idName = 'spec_id';
        context.titleName = 'name_rus';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet = [
            {title: 'Характеристики', fieldset: 'char'},
        ];

        context.columnsDefaults = [...context.lastUserColumns, ...[
            {column_name: 'name_lat', row: 10, pos: 1},
            {column_name: 'name_rus', row: 10, pos: 2},
            {column_name: 'spec_id', hidden: true},
            {column_name: 'key', row: 10, pos: 0, width: "100px",},
            {column_name: 'spec_rang', default: 7, hidden: true},
            {column_name: 'sys_group', hidden: true},
            {column_name: 'sys_rus', hidden: false, row: 0, pos: 1, height: '55px', minWidth: '100%'}
            // data: [{value: false, title: 'самка'}, {value: true, title: 'самец'}]
        ]];
        context.subTables = [
            {
                title: 'Основные параметры',
                pos: 2,
                elementName: 'SpecDescSub',
                tableName: 'spec_desc',
                subElementName: null,
                filterString: "spec=$spec_id$",
                idName: 'spec',
                hiddenColumns: ['spec'],
                singleColumns: [{column_name: 'spec_desc', row: 1, pos: 0, labelAlign: 'top', height: 35},
                    {
                        column_name: 'hunting_object', row: 0, pos: 1, is_list: true, labelAlign: 'left',
                        data: [{value: false, title: 'не охот.ресурс'}, {value: true, title: 'охотресурс'}]
                    },
                    {
                        column_name: 'water_resource', row: 0, pos: 2, is_list: true, labelAlign: 'left',
                        data: [{value: false, title: 'не водный ресурс'}, {value: true, title: 'водный ресурс'}]
                    },
                    {
                        column_name: 'is_bird', row: 0, pos: 3, is_list: true, labelAlign: 'left',
                        data: [{value: false, title: 'не птица'}, {value: true, title: 'птица'}]
                    },
                ]
            }, //синонимы
            {
                title: 'Синонимы',
                pos: 2,
                elementName: 'SpecSynSub',
                tableName: 'species',
                filterString: "spec_parent=$spec_id$",
                fieldset: 'syn'
            }, //синонимы
            {
                title: 'Литературные источники',
                pos: 2,
                elementName: 'BaseRelationSub',
                // subName:'LitSourceSub',
                tableName: 'speclit',
                subElementName: 'SpecLitPage',
                relation: {
                    tableName: 'spec_lit',
                    parentField: 'spec_id',
                    relField: 'id_lit_source',
                    idName: 'id_lit_source'
                },
                filterString: "spec_id=$spec_id$",
                noSingle: true,
                idName: 'spec_id',
                hiddenColumns: ['spec_id', 'id_lit_source'],
                fieldset: 'lit'
            }, //лит. источники
            {
                title: 'В Красной Книге',
                pos: 3,
                elementName: 'RbSpecSub',
                tableName: 'specrb',
                filterString: "spec_id=$spec_id$",
            }, //КК
            {
                title: 'Актуальный статус вида',
                pos: 3,
                elementName: 'BaseSubGridPanel',
                // subName:'SpecStatusSub',
                noSingle: true,
                tableName: 'spec_status',
                subElementName: 'SpecStatusPage',
                activeColumn: 'status_active',
                filterString: "spec=$spec_id$",
                idName: 'row_id',
                rowHeight: 100,
                columnsDefaults: [...context.lastUserColumns,...[
                    {column_name: keyName},
                    {column_name: "status_active", row: 0, pos: 0},
                    {column_name: "spec_area", hidden: true, row: 0, pos: 10, className: 'copy-class'},
                    {column_name: "spec_count", pos: 0, row: 1, maxWidth: 150, className: 'copy-class'},
                    {column_name: "spec_state", pos: 2, row: 1, className: 'copy-class'},
                    {column_name: "spec_habitat_structure", hidden: true, pos: 3, row: 1, className: 'copy-class'},
                    {column_name: "spec_habitat_quality", hidden: true, pos: 3, row: 1, className: 'copy-class'},
                    {column_name: "spec_habitat_area", hidden: true, pos: 3, row: 1, className: 'copy-class'},
                    {column_name: "spec_start_year", hidden: true, pos: 3, row: 1, className: 'copy-class'},
                    {column_name: "spec_end_year", hidden: true, pos: 3, row: 1, className: 'copy-class'},
                    {column_name: "spec", hidden: true, pos: 3, row: 1, className: 'copy-class'},
                    //          {column_name: "phones", hidden: true, row: 2, pos: 4, className: 'copy-class',},
                    //         {column_name: "emails", hidden: true, row: 3, pos: 5, className: 'copy-class',}
                ]],
                tpl: (data) => {
                    return (<div className={'spec-status-tpl'}>
                            <div className={'tpl-row copy-container'}>
                                <span className={'tpl-title'}>Ареал обитания: </span>
                                <div className={'copy-class x-gridcell '}>
                                    <span className={'tpl-text'}>{data.spec_area}</span>
                                    <div className="x-after-input-el"/>
                                </div>
                            </div>
                            <div className={'tpl-row copy-container'}>
                                <span className={'tpl-title'}> Описание ареала: </span>
                                <div className={'copy-class x-gridcell '}>
                                    <span className={'tpl-text'}>{data.spec_habitat_area}</span>
                                    <div className="x-after-input-el"/>
                                </div>
                            </div>
                            <div className={'tpl-row copy-container'}>
                                <span className={'tpl-title'}> Структуры ареала: </span>
                                <div className={'copy-class x-gridcell '}>
                                    <span className={'tpl-text'}>{data.spec_habitat_structure}</span>
                                    <div className="x-after-input-el"/>
                                </div>
                            </div>
                            <div className={'tpl-row copy-container'}>
                                <span className={'tpl-title'}> Условия обитания: </span>
                                <div className={'copy-class x-gridcell '}>
                                    <span className={'tpl-text'}>{data.spec_habitat_quality}</span>
                                    <div className="x-after-input-el"/>
                                </div>
                            </div>
                        </div>
                    )
                }
            }, //КК
            {
                title: 'Фотографии',
                pos: 3,
                elementName: 'BaseSubGridPanel',
                subName: 'SpecPhotosSub',
                tableName: 'spec_photo',
                noSingle: true,
                filterString: "spec=$spec_id$",
                idName: 'photo_id',
                rowHeight: 100,
                columnsDefaults: [...context.lastUserColumns,...[
                    {column_name: "spec", hidden: true, row: 0, pos: 0},
                    {column_name: "photo_id", hidden: true, pos: 0},
                    {column_name: "photo_name", title: true, pos: 0, className: 'copy-class'},
                ]],
                tpl: (data) => {
                    return <div></div>
                }
            }, //КК
        ]
        console.log('subs', context.subTables);
    }
}


