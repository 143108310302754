import React from 'react';
import BaseEditPage from "../BaseEditPage";
import SimpleImportMap from "../../Maps/SimpleImportMap";
import BaseMapSub from "../BaseMapSub";
import ErrorBoundary from "../../ErrorBoundary";

const Ext = window['Ext'];
const keyName = `BaseZmuMapSub`;

let context = null;
export default class BaseZmuMapSub extends BaseMapSub {
    constructor(props) {
        super(props);
        const context=this;
        context.state= {showAll : true};
    }

    getMap(context, params = {}) {
        let parent = context;
        while (!(parent instanceof BaseEditPage)) {
            parent = parent.props.parent;
        }
        return <SimpleImportMap
            ref={(e => {
                context.olMap = e;
                parent.olMap = e;
            })}
            height={context.getHeight() ||300}
            width={'100%'}
            readonly={context.props.readonly}
            // width={params.width||'100%'}
            rights={params.rights || context.props.rights}
            key={'simpleMap'}
            layerNames={context.props.layerNames}
            styleFunction={context.styleFunction||context.props.styleFunction}
            rowId={parent.rowId}
//            rowId={parent.rowId}
            parent={context} appViewPort={context.props.appViewPort}/>;
    }
}

