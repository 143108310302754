import BaseSubGrid from "../../Base/BaseSubGrid";
import ErrorBoundary from "../../ErrorBoundary";

const keyName=`BankPropsSubGrid`;

export default class BankPropsSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'bank_props';
        //элемент окна редактирования
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName, 'acc_user'];
        context.columnsDefaults = [
            {column_name: "bik", title: true},
            {column_name: "cor_account", title: true},
            {column_name: "rs_account", title: true},
            {column_name: "bank_branch", title: true},
            {column_name: "bank_name", title: true}
        ];
        context.singleColumns=[
                {column_name:'row_id',row:0,hidden:true},
                {column_name: 'acc_user', hidden: true},
                {column_name: "bik", row: 0},
                {column_name: "bank_name", row: 0 },
                {column_name: "bank_branch", row: 0},
                {column_name: "cor_account", row: 1},
                {column_name: "rs_account", row:1},
            ];

    }

}