import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import DocumentsSub from "./DocumentsSub";

const keyName = `LandDocumentsSub`;

export default class LandDocumentsSub extends DocumentsSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.gridName=`LandDocumentsSubGrid`;
    }

}

