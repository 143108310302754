import ErrorBoundary from "../../ErrorBoundary";
import React from 'react';
import BasePassageTableSub from "../../Base/ZMU/BasePassageTableSub";

const keyName = `PassageTable3Sub`;

export default class  PassageTable3Sub extends BasePassageTableSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.relation = context.props.relation;
        context.topMenuButtonNames=(props.topMenuButtonNames)?props.topMenuButtonNames: ['reload'];
        context.gridName= props.gridName||`PasTable3SubGrid`;
        context.idName = props.idName || 'passage_id';
        context.noSingle=true;
    }


}

