import React, {Component} from 'react';
import * as f from "../../common/Funcs";
import {Panel} from "@sencha/ext-react-modern";
import * as g from "../../common/gisFuncs";
import style from "../../Maps/styles/singleea";
import BaseZmuMapSub from "../../Base/ZMU/BaseZmuMapSub";
import BaseEditPage from "../../Base/BaseEditPage";
import ZmuEaSingleMap from "../../Maps/ZMU/ZmuEaSingleMap";

const keyName = "ZmuEaSingleMapSub";

export default class ZmuEaSingleMapSub extends BaseZmuMapSub {

    static defaultProps = {
        mapButtonNames: [],
        layerNames: [],
    }

    constructor(props) {
        super(props);
        const context = this;
        context.tableName = props.tableName;
        context.geomType = 'Polygon';
        context.state.legendOn = false;
        context.topMenuButtonNames = ['reload'];
        if (props.styleFunction)
            context.styleFunction = props.styleFunction;
        else
            context.styleFunction = (feature, resolution) => {
            return style(feature, resolution, context);
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getData({context: this});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        // if (prevProps.rowId != context.props.rowId || prevState.showAll != context.state.showAll) context.getData({context});
        if (prevProps.rights != context.props.rights)
            if (context.olMap.addButtons) context.olMap.setState({buttons: context.olMap.addButtons()})
        if (prevState.showAll != context.state.showAll) context.getData({context});
    }

    getData(params) {
        const {context, update} = params;
        if (!context.objectsGrid) {
            console.error('нет объекта objectsGrid');
        }
        if ((context.objectsGrid.state.tableName == context.parent.props.parent.state.eaType) || update) {
            context.objectsGrid.getData({context: context.objectsGrid});
        } else {
            context.objectsGrid.setState({tableName: context.parent.props.parent.state.eaType});
        }
        const getGeomFunc = (params) => {
            const {context, result, callback} = params;
            if (context.olMap) context.olMap.featureOn=result && result.features && result.features.length > 0;
            if (context.olMap.addButtons) context.olMap.setState({buttons: context.olMap.addButtons()})
            if (!(result && result.features && result.features.length > 0)) return false;
            const addFunc = (context) => {
                const olMap = context.olMap;
                if (!olMap) setTimeout(() => addFunc(context), 200); else {
                    context.gisFeature = olMap.addFeatures({geoJson: result, context: olMap}).filter(f=>!f.getProperties().important);
                    const ext = olMap.featuresSource.getExtent();
                    olMap.setState({homeCenter: [(ext[2] - ext[0]) / 2 + ext[0], (ext[3] - ext[1]) / 2 + ext[1]]});
                    //  olMap.select({features: context.gisFeature, context: olMap});
                    olMap.gotoFeatures({features: context.gisFeature, context: olMap});
                    olMap.mapResize();
                    if (callback) callback(result);
                }
            }
            setTimeout(() => addFunc(context), 500);
        }
        if (context.olMap) {
            if (context.olMap.selectSource) context.olMap.selectSource.clear();
            context.olMap.featuresSource.clear();
        }
        if ((context.props.rowId) && context.props.rowId != -1) {
            g.getGeomById({
                callback: result => getGeomFunc({context: context, result: result}),
                id: context.props.rowId,
                // id: context.props.rowId,
                tableName: context.props.tableName,
                active: !context.state.showAll
            });
        }
    }

    getItems(context) {
        return context.objectsGrid.store.getData().items;
    }

    getHeight() {
        return this.parent.innerPanelHeight() + 20;
        //return f.getCentralHeight();
    }

    //таблица объектов. переключается "принципом формирования"
    getObjectsGrid(context) {
        const grid = React.createElement(context.getGridType(context, 'EaObjectsGrid').el, {
            parent: context,
            appViewPort: context.props.appViewPort,
            key: context.gridName,
            name: context.gridName,
            width: '95%',
            minWidth: '300px',
            height: context.getHeight()-30,
            rowId: context.props.rowId||-1,
            isEdit: context.props.parent.isEditRight(),
            hidden: !context.props.parent.isEditRight(),
            ref: (grid) => {
                if (grid) context.objectsGrid = grid;
                window.c = context;
            }
        })
        return grid;
    }

    getMap(context, params = {}) {
        let parent = context;
        while (!(parent instanceof BaseEditPage)) {
            parent = parent.props.parent;
        }
        return <ZmuEaSingleMap
            ref={(e => {
                context.olMap = e;
                parent.olMap = e;
            })}
            height={context.getHeight()-10 ||300}
            width={'95%'}
            readonly={context.props.readonly}
            // width={params.width||'100%'}
            rights={params.rights || context.props.rights}
            key={'simpleMap'}
            styleFunction={context.styleFunction||context.props.styleFunction}
            rowId={parent.rowId}
            //            rowId={parent.rowId}
            parent={context} appViewPort={context.props.appViewPort}/>;

    }

    render() {
        const context = this;
        //const height = context.props.height || context.getHeight();
        // const elements = [context.getMap(context), context.getObjectsGrid(context)];
        const width = (f.getCentralWidth() / 2);
        const elements = [context.getMap(context, {width: width})];
        context.addMenuButtons();
        const objPanel = <Panel id={'objPanelGrid'} key={'objPanelGrid'}
                                bodyCls={'no-print'}
                                docked={"right"}
                                resizable={{
                                    split: true,
                                    edges: 'west'
                                }}
                                listeners={{
                                    resize: (element, info, eOpts) => {
                                        g.mapResize(context);
                                    }
                                }}
                                margin={`5 0 0 ${window.IasConfig.margin}`}
                                padding={`${window.IasConfig.margin}`}
        >
            {context.getObjectsGrid(context)}
        </Panel>;
        elements.push(objPanel);
        return <Panel
            region={"center"} margin="3"
            layout={'fit'}
            title={context.props.title}
            cls={'edit-window-panel ea-single-panel'}
            collapsible={{
                direction: 'top',
                dynamic: true
            }}
            collapsed={context.props.collapsed}
            width={'100%'}
            height={context.getHeight() + 20}
            titleCollapse={true}
            key={'mapPanel'}
            tools={context.topMenuButtons}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            {elements}
        </Panel>;
    }

}
