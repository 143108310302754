import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import React from "react";
import HuntUsersGrid from '../../Grids/Office/HuntUsersGrid';
import * as f from '../../common/Funcs';
import {Button} from "@sencha/ext-react-modern";
import {PrintButton} from "../../common/Buttons";

const keyName = 'HuntUsersPage';
export default class HuntUsersPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.title=`список охотпользователей`;
        context.gridName = "HuntUsersGrid";
        context.subElementName = 'HuntUserPage';
        context.idName = 'hu_id';
        context.titleName = 'hu_name';
        context.hiddenColumns = [context.idName];
        context.topMenuButtonNames = ['reload', 'xls', 'print', 'add', 'clipboard'];
        context.filters = [
            {name: 'ac',pos:10, required: false,defaultValue:true},
            {name: 'region',pos:20, required: false},
            {name: 'users',pos:30,required: false},
            {name: 'num',pos:40, required: false},
            // {name: 'page',pos:, required: false}
        ];
    }

    //кнопки для меню
    addAdditButtons(props) {
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} addMenuButtons`, props, context.props.rights);
        context.topMenuButtons.push(context.addFormsMenu());
    }

    addFormsMenu() {
        const key = 'formsMenu';
        const callFunc = (form) => {
            window.open(`${window.IasConfig.homePath}forms/${form}`);
        };
        return <Button
            ui={window.IasConfig.ui}
            key={key}
            name={key}
            text={f.locale(key)}
            menu={
                [{text: f.locale('form21'), handler: () => callFunc('form21')}
                    // this.formButton('form21'),
                    //  this.formButton('form31'),
                    //  this.formButton('form23'),
                ]}
        />
    }

    //кнопка формы xml

    formButton(key = 'form21') {
        const context = this;
        const callFunc = () => {
            window.open(`${window.IasConfig.homePath}forms/${key}`);
        };
        return (<Button
            ui={window.IasConfig.ui}
            key={key + 'button'}
            name={key + 'button'}
            text={f.locale(key)}
            handler={callFunc}/>)
    }

}