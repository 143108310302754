import BaseSubGrid from "../../Base/BaseSubGrid";
import ErrorBoundary from "../../ErrorBoundary";

const keyName=`LandsSubGrid`;

export default class LandsSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
//        context.tableName = 'shortlands';
        //элемент окна редактирования
        context.idName = 'land_id';
        context.hiddenColumns = [context.idName, 'land_economy'];
        context.columnsDefaults=[            {column_name: 'land_name', title:true},{column_name: 'user_name', hidden:true},
        ];
        context.singleColumns = [
            {column_name: 'land_id', row: 0, hidden: true},
            {column_name: 'economy_name',hidden:true},
            {column_name: 'land_name', row: 0,pos:0},
            {column_name: 'land_region',row:1,pos:1},
            {column_name: 'land_area',row:1,pos:2},
            {column_name: 'land_reason', hidden:true},
        ]
    }


}