import ErrorBoundary from "../../../ErrorBoundary";
import * as g from "../../../common/gisFuncs";
import React from "react";
import BaseGrid from "../../../Base/BaseGrid";
import BaseEditPage from "../../../Base/BaseEditPage";
import Select from "ol/interaction/Select";

const Ext = window['Ext'];
const keyName = `EaObjectsGrid`;

export default class EaObjectsGrid extends BaseGrid {
    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.height = props.height || 150;
        context.rowNumberWidth = '40px';
        context.hiddenColumns = ['object_ea', 'row_id', 'ea_land', 'acc_year', 'gid', 'date_on', 'date_off', 'land_type', 'source'];
        context.columnsDefaults = [
            {
                column_name: 'label',
                column_label: 'Нераспределенные территории',
                flex: {grow: 2},
                className: 'copy-class'
            },
            {column_name: 'area_ga', column_label: 'S док, т.га', maxWidth: 100}, {
                column_name: 'gis_ga',
                maxWidth: 100
            },
            {column_name: 'geom'}];
        context.state = {tableName: null, olMap: null};
        let parent = context;
        while (!(parent instanceof BaseEditPage) && parent.props.parent) {
            // while (!(parent.hasOwnProperty('rowId')) && parent.props.parent) {
            parent = parent.props.parent;
        }
        context.parentPage = parent;
        if (parent) {
            parent.objectsStore = context.store;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        const context = this;
        if (context.state.tableName && context.state.tableName != prevState.tableName) {
            context.getData({context});
        }
    }

    /*    getHeight() {
            const context=this;
            const baseHeight=super.getHeight();
            const eaHeight=context.props.parent?.grid?.cmp?.bodyElement?.dom?.clientHeight;
            return (eaHeight)?(baseHeight-eaHeight):baseHeight*0.2;
        }*/
    getHeight() {
        const context = this;
        const count = context.store.getData().getCount();
        if (context.props.height) return context.props.height;
        if (count == 0) return 100;
        const baseHeight = super.getHeight();
        const rowHeights = count * 20 + 40;
        console.log('o height', (rowHeights > baseHeight * 0.6) ? baseHeight * 0.6 : rowHeights);
        return (rowHeights > baseHeight * 0.6) ? baseHeight * 0.6 : rowHeights;
    }

    getData(params) {
        const {context, callback} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        if (!context.state.tableName) return;
        context.isLoaded = false;
        context.inload = true;
        context.store.clearData();
        const callback1 =
            (result) => {

                //временно
                context.inload = false;
                result.columns = [...context.columnsDefaults];
                let parent = context;
                if (!context.parentPage) {
                    while (!(parent instanceof BaseEditPage) && parent.props.parent) {
                        parent = parent.props.parent;
                    }
                    context.parentPage = parent;
                }
                if (parent) {
                    parent.objectsStore = context.store;
                }
                if (context.props.isEdit) {
                    context.addCheckBox(context)
                    //наложить фильтр - или пустые или текущего участка
                    // context.rowId = parent.props.rowId;
                    result.columns.unshift({
                        column_name: 'object_ea',
                        pos: 1,
                        editable: true,
                        relation: true,
                        onChange: (val, record) => {
                            const f = context.olMap.getFeatureById({
                                id: Number(record.data.gid), context: context.olMap
                            });
                            const value = (val) ? (context.props.rowId || -1) : false;
                            f.set('object_ea', value);
                            return value;
                        }
                    });
                }
                context.store.clearFilter();
                context.store.addFilter((f) => {
                    return (f.data.object_ea == 0 || (f.data.object_ea == context.props.rowId && context.props.parent.tableName != 'explore_areas')) && f.data.rights != 'n';
                })

                //передать на сохранение родителю
                /*!!!*/
                if (parent) {
                    // parent.objectsStore = context.store;
                }


                // else {
                //     result.columns.push({
                //         column_name: 'object_name',
                //         pos: 10,
                //     });
                // }

                if (result.features) {
                    result.data = result.features.map(f => {
                        return {
                            ...f.properties, ...{geom: JSON.stringify(f.geometry)}
                        }
                    });
                }
                if (!result.data) result.data = [];
                result.data.map(r => {
                    if (!r.object_ea) r.object_ea = 0;
                    if (r.gid == context.state.check) r.object_ea = context.props.rowId;
                });
                context.isLoaded = true;
                const upData = {columns: result.columns, data: result.data, isLoaded: true};
                context.store.loadRawData(result.data);
                if (!context.olMap) context.olMap = context.props.parent.olMap;
                if (result.features) {
                    const olMap = context.olMap;
                    if (olMap) {
                        olMap.setVectorLayer({
                            layerName: 'features',
                            layerObjects: result.features,
                            styleFunction: context.props.parent.styleFunction,
                            apply: true,
                            context: olMap,
                            append: false
                        });
                        if (!context.selectSingleClick && context.props.isEdit) {
                            context.addSelectInteraction(context);
                        }
                    }
                }
                context.setState(upData, () => (callback) ? callback(result) : '');

            }

        const prms = {
            context,
            eaType: `ea_${context.state.tableName}`,
            callback: callback1
        };
        //свой вызов, возвращает columns и features - из которого извлекаем
        //убрать даты из запроса, год
        g.getEaGisData(prms);
    }

    addCheckBox() {

    }

    addSelectInteraction(context) {
        const olMap = context.olMap;
//включаем селект, чтобы было видно, что смотрим
        context.selectSingleClick = new Select({multi: true});
        context.selectSingleClick.on('select', (event) => {
            if (event.selected.length < 1) return;
            let feature;
            event.selected.map(s => {
                if (s.getProperties().source == `ea_${context.state.tableName}`) feature = s;
            });
            if (!feature || (feature.get('object_ea') != 0 && feature.get('object_ea') != context.props.rowId)) return
            context.selectSingleClick.getFeatures().clear();
            const value = (feature.getProperties().object_ea == context.props.rowId) ? 0 : context.props.rowId;
            feature.set('object_ea', value);
            const row = context.store.getData().items.find(i => i.data.gid == feature.getProperties().gid);
            row.set('object_ea', value);
            context.selectSingleClick.getFeatures().clear();
        });
        olMap.olMap.addInteraction(context.selectSingleClick);
    }

    gridSelected(context, grid, selected) {
        const p = context.props.parent;
        if (p.editForm) {
            p.showEditPanel(p, true);
            p.editForm.setAreaName(p.editForm, selected[0].data.label);
            setTimeout(() => {
                p.editForm.elements.ZmuEaSingleMapSub.objectsGrid.setState({check: selected[0].data.gid});
            }, 1000)
        }
        // context.olMap.select({ids: [Number(selected[0].data.gid)], context: context.olMap});
    }
}
