import ErrorBoundary from "../ErrorBoundary";
import React, {Component} from 'react';
import {Panel} from "@sencha/ext-react-modern";
import * as f from "../common/Funcs";

const key = "LeftPanel";

class LeftPanel extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.content.map(c=>c.key).toString()==this.props.content.map(c=>c.key).toString()) return true;
        this.props.appViewPort.setState({showMenu: true});
    }

    render() {
        const context = this;
        return <Panel
            id={key}
            key={key}
            name={key}
            layout={{
                type: 'vbox',
                align: 'left'
            }}
            cls={'no-print'}
            buttonAlign="left"
            shadow
            pack={"start"}
            width={(context.props.visible) ? window.IasConfig.leftWidth : 20} docked={"left"}
            title={'Меню раздела'}
            headerPosition={'right'}
            resizable={{
                split: true,
                edges: 'east',
                dynamic: true
            }}
            ref={(p) => {
                if (p) p.cmp.el.dom.getElementsByClassName('x-panelheader')[0].onclick = () => {
                    context.props.appViewPort.setState({showMenu: !context.props.appViewPort.state.showMenu});
                }
            }}
            margin={`0 ${window.IasConfig.margin} 0 0`}>
            <Panel
                hidden={!context.props.visible} layout={'vbox'}
                height={f.getCentralHeight()}
                className={"vertical-menu"} bodyCls={'vertical-menu-body'}>{context.props.content}</Panel>
        </Panel>
    }
}

export default LeftPanel;