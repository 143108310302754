import ErrorBoundary from "../../../ErrorBoundary";
import React, {Component} from "react";
import {BaseTextButton} from "../../../Base/BaseButtons";
import * as f from "../../../common/Funcs";
import {Panel} from "@sencha/ext-react-modern";
import BaseZmuPage from "../../../Base/ZMU/BaseZmuPage";
import {confirm} from "../../../common/Funcs";

const keyName = `ZmuPDocPage`;
const title = `Зимний маршрутный учет. Исследуемые территории.`;
export default class ZmuPDocPage extends BaseZmuPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.filters = [
            {name: 'year', pos: 10},
            {name: 'users', pos: 30},
            {name: 'region', pos: 20},
            {name: 'it', pos: 40},
            {name: 'editable',pos:50}];
        document.clearOther(context.filters);
        context.state = {
            content: [`<p></p>`]
            , title: title
        };
    }

    getButton(params) {
        const openDoc = (doctype) => {
            const call = (recreate) => {
                let filters = `?${(recreate) ? `recreate=1&` : ``}${f.getFilters('string')}&${'title=' + f.translit(params.label)}`;
                window.open(`${window.IasConfig.homePath}forms/${(params.type == 'pack') ? 'pack/' : ''}${params.key}/${params.ext}${filters}`);
                window.focus();
            };
            f.confirm(`Скачать ${params.label}?`, (ok) => {
                if (ok)
                    if (params.type == 'pack') f.confirm('Сгенерировать обновленные документы? Это займет дополнительное время, но все данные точно будут обновлены.', (recreate) => {
                        if (f.exist(recreate)) {
                            call(recreate);
                        }
                    });
                    else call(false);
            });
        }
        return <BaseTextButton key={params.key} name={params.key} text={params.label}
                               func={() => {
                                   if (window.IasConfig.devMode) console.debug("call pack");
                                   (params.attention) ? params.attention((e) => openDoc(params.key)) : openDoc(params.key)
                               }}/>;
    }

    render() {
        const context = this;
        const elements = [
            // context.getButton({context, key: 'ealist', ext:'xls',type:'pack', label: 'Скачать схемы ИТ'}),
            context.getButton({context, key: 'empty_zmublank', type: 'pack', ext: 'doc', label: 'Ведомости учетов'}),
            context.getButton({context, key: 'routegpx', type: 'pack', ext: 'gpx', label: 'Треки запланированных маршрутов'}),
            context.getButton({context, key: 'pregpx', type: 'pack', ext: 'gpx', label: 'Треки предварительного прохождения маршрутов'}),
            context.getButton({
                context,
                key: 'routesList',
                ext: 'xls',
                type: 'single',
                label: 'Перечень маршрутов учета'
            }),
            context.getButton({
                context,
                key: 'routes_ea',
                ext: 'xls',
                type: 'pack',
                label: 'Перечень маршрутов учета по ИТ',
                attention: (callback) => {
                    if (!document.getCookie('itFilter') &&
                        !document.getCookie('usersFilter') &&
                        !document.getCookie('regionFilter'))
                        f.confirm({
                                message: 'На данные не наложено ни одного фильтра.<br> ' +
                                    'В этих условиях генерация пакета может занять длительное время. <br>' +
                                    'Продолжить генерацию пакета без фильтров?'
                            }, (e) => {
                                if (e) callback(true)
                            }
                        );
                    else
                        callback(true);
                }
            }),
            context.getButton({
                context,
                key: 'easchema',
                ext: 'doc',
                type: 'pack',
                label: 'Схемы исследуемых территорий',
                attention: (callback) => {
                    if (!document.getCookie('itFilter') &&
                        !document.getCookie('usersFilter') &&
                        !document.getCookie('regionFilter'))
                        f.confirm({
                                message: 'На данные не наложено ни одного фильтра.<br> ' +
                                    'В этих условиях генерация пакета может занять длительное время. <br>' +
                                    'Продолжить генерацию пакета без фильтров?'
                            }, (e) => {
                                if (e) callback(true)
                            }
                        );
                    else
                        callback(true);
                }
            }),
            // context.getButton({context, key: 'routes_ea_list',  ext:'xls',type:'pack',label: 'Перечень маршрутов учета с группировкой по ИТ'}),
        ];
        return (
            <Panel
                cls={'print-100-percent by-map-panel'}
                bodyCls={'button-doc-panel'}
                layout={'vbox'}
                height={f.getCentralHeight()}
                region={"center"} margin="3"
                key={`buttonPanel${keyName}`}
                ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}>
                {elements}
            </Panel>
        );
    }
}