import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName = `EmailPage`;

export default class EmailPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'emails';
        context.idName = 'email';
        context.titleName = `email`;
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.footerButtonNames = ['saveclose', 'save', 'delete', 'close'];
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.columnsDefaults=[...context.lastUserColumns,...[
            {column_name: 'email_type',pos:0},
            {column_name: 'email_number',pos:1,disabled:false,data_type:'character varying'},
            {column_name: 'email_comment',pos:2,row:1},
            {column_name:'email', hidden:false,disabled:false}
        ]];
        context.fieldsSet = [
            {title: 'Основная информация'}
        ];
        context.subTables=[
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: ()=>!f.isAdmin(),
                filterString: `table_name ='emails' and record_id = '"$email$"'`,
            }
        ]
    }

}


