import BaseSubGridPanel from "../Base/BaseSubGridPanel";
import BankPropsSub from "../SubPanels/BankPropsSub";
import EconsSub from "../SubPanels/EconsSub";
import LandsSub from "../SubPanels/LandsSub";
import LandsWithEcoSub from "../SubPanels/LandsWithEcoSub";
import CardLogSub from "../SubPanels/CardLogSub";
import PassLogSub from "../SubPanels/ZMU/Acc/PassLogSub";
import StaffSub from "../SubPanels/StaffSub";
import PickupSub from "../SubPanels/PickupSub";
import HuntDescSub from "../SubPanels/HuntDescSub";
import ContactsSub from "../SubPanels/ContactsSub";
import NamesakeSub from "../SubPanels/NamesakeSub";
import HuntUserDocumentsSub from "../SubPanels/HuntUserDocumentsSub";
import LandDocumentsSub from "../SubPanels/LandDocumentsSub";
import ZoneDocumentsSub from "../SubPanels/ZoneDocumentsSub";
import HuntCodesSub from "../SubPanels/HuntCodesSub";
import BaseRelationSub from "../Base/BaseRelationSub";
import BaseMapSub from "../Base/BaseMapSub";
import BaseZmuMapSub from "../Base/ZMU/BaseZmuMapSub";
import BaseRelationGridPanel from "../Base/BaseRelationGridPanel";
import RbSpecSub from "../SubPanels/Spec/RbSpecSub";
import SpecSynSub from "../SubPanels/Spec/SpecSynSub";
import SpecDescSub from "../SubPanels/Spec/SpecDescSub";
import SpecLitSub from "../SubPanels/Spec/SpecLitSub";
import TestSubPanel from "../SubPanels/ZMU/TestSubPanel";
import PassCommentSubPanel from "../SubPanels/ZMU/Acc/PassCommentSubPanel";
import BiotopsSubPanel from "../SubPanels/BiotopsSubPanel";
import ZmuEaSingleMapSub from "../SubPanels/ZMU/ZmuEaSingleMapSub";
import ZmuRouteMapSub from "../SubPanels/ZMU/ZmuRouteMapSub";
import ZmuPasMapSub from "../SubPanels/ZMU/ZmuPasMapSub";
import ZmuRoutePasMapSub from "../SubPanels/ZMU/ZmuRoutePasMapSub";
import PassageTable1Sub from "../SubPanels/ZMU/PassageTable1Sub";
import PassageTable2Sub from "../SubPanels/ZMU/PassageTable2Sub";
import PassageTable3Sub from "../SubPanels/ZMU/PassageTable3Sub";
import ImageSub from "../SubPanels/ImageSub";
import BasePassageTableSub from "../Base/ZMU/BasePassageTableSub";
import PointBirdSub from "../SubPanels/ZMU/PointBirdSub";
import PointAnimalSub from "../SubPanels/ZMU/PointAnimalSub";
import EaRoutesSub from "../SubPanels/ZMU/EaRoutesSub";
import EaPassagesSub from "../SubPanels/ZMU/EaPassagesSub";
import SpecStatusSub from "../SubPanels/Spec/SpecStatusSub";
import CoordsSub from "../Maps/CoordsSub";
export default {
    BaseSubGridPanel,
    BankPropsSub,
    EconsSub,
    LandsSub,
    LandsWithEcoSub,
    StaffSub,
    PickupSub,
    HuntDescSub,
    HuntCodesSub,
    RbSpecSub,
    ContactsSub,
    HuntUserDocumentsSub,
    LandDocumentsSub,
    ZoneDocumentsSub,
    BaseRelationSub,
    BaseMapSub,
    BaseZmuMapSub,
    BaseRelationGridPanel,
    TestSubPanel,
    BiotopsSubPanel,
    ZmuEaSingleMapSub,
    ZmuRouteMapSub,
    ZmuPasMapSub,
    ZmuRoutePasMapSub,
    PassageTable1Sub,
    PassageTable2Sub,
    PassageTable3Sub,
    ImageSub,
    BasePassageTableSub,
    PointBirdSub,
    PointAnimalSub,
    EaRoutesSub,
    EaPassagesSub,
    SpecLitSub,
    SpecSynSub,
    SpecDescSub,
    SpecStatusSub,
    CoordsSub,
    PassCommentSubPanel,
    CardLogSub,
    PassLogSub,
    NamesakeSub
};
