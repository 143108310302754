import React from 'react';
import * as f from "../common/Funcs";
import {fixNullCells, flashMessages} from "../common/Funcs";

/*ЗМУ*/
//строка из таблицы
export const getAccYear = async (params) => {
    let {context,accYear, callback} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}zmu/accyear/get_year/${accYear}`, {
        credentials: 'include'
    })
        .then(res => res.json())
        .then(
            (result) => {
                    const app=context.props.appViewPort;
                    if (app) {
                        const values={}
                        app.setState({zmu: result.data}, () => {
                            if (app.closePlan) app.closePlan.forceUpdate();
                            validResponse({result, callback})
                        })
                    }
            },
            (error) => invalidResponse({error, callback})
        )
};

//задать текущий год
export const setYear=(context)=>{
    if (!document.getCookie('yearFilter')) {
        const year=((new Date()).getMonth()>5)?((new Date()).getFullYear()+1):(new Date()).getFullYear();
        const filterYear=()=>{
            if (context.props?.appViewPort?.filters?.YearFilter)
                context.props.appViewPort.filters.YearFilter.setValue(year);
            else
                setTimeout(()=>filterYear(),100);
        }
        filterYear();
    }
}

//генерация ИТ из объектов карты
export const eaFromObjects = async (params) => {
    let {accYear, callback} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}zmu/accyear/gen_ea/${accYear}`, {
        credentials: 'include'
    })
        .then(res => res.json())
        .then(
            (result) => validResponse({result, callback}),
            (error) => invalidResponse({error, callback})
        )
};

//сменить принцип формирования ИТ
export const changeEaType = async (params) => {
    let {context, accYear, eaType, callback} = params;
    if (window.IasConfig.devMode) console.debug(window.funcName(), params);

    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    };
    //откорректировать запрос
    document.setCookies();
    fetch(`${window.IasConfig.homePath}zmu/accyear/change_principle/${accYear}/${eaType}`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => validResponse({result, callback}),
            (error) => invalidResponse({error, callback})
        )
};

//сохранить параметры ИТ
export const setEaData = async (params) => {
    let {context, id, data, callback} = params;
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    //откорректировать запрос
    document.setCookies();
    fetch(`${window.IasConfig.homePath}zmu/ea/${id}`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => validResponse({result, callback}),
            (error) => invalidResponse({error, callback})
        )
};

//сохранить схему планирования маршрутов на год
export const setRoutePlan = async (params) => {
    let {year = document.getCookie('yearFilter'), data, callback} = params;

    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    //нужен бек
    document.setCookies();
    fetch(`${window.IasConfig.homePath}zmu/layout/${year}/`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => validResponse({result, callback}),
            (error) => invalidResponse({error, callback})
        )

};

//данные для грида, без указания прав
export const callTest_old = async (params) => {
    let {context, objectType, objectId, callback} = params;
    const accYear = document.getCookie('yearFilter');
    document.setCookies();
    fetch(`${window.IasConfig.homePath}'/calcs/${objectType}/year/${accYear}/id/${objectId}'`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (!result || result.state == false)
                    if (callback) return callback(false);
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                flashMessages(result);
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
};

//запрос данных по имени таблицы
export const getPassTableData = async (params) => {
    //
    let {context, tableId, pasId, callback} = params;
    document.setCookies();
    fetch(`${window.IasConfig.homePath}zmu/passages/${pasId}/tbl/${tableId}`, {
        credentials: 'include'
    })
        .then(res => {
            return res.json();
        })
        .then(
            (result) => {
                if (!result)
                    if (callback) return callback({data: [], columns: [], flash: []});
                //если ответ - страница, возвращаем вызов страницы
                if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
                    return window.IasLoApp.rePage(result.page);
                }
                if (result.columns) {
                    result.columns = result.columns.map(c => {
                        if (context.columnsDefaults) {
                            const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                            c = {...c, ...def};
                        }
                        return c;
                    }).sort((a, b) => {
                        return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                    });
                    result.columns.map(c => {
                        if (c.column_name.indexOf('path') > -1) c.data_type = 'path';
                        if (c.column_name.indexOf('rights') > -1) {
                            c.maxWidth = 100;
                            c.pos = 100;
                        }
                    });
                    const jsString = JSON.stringify(result.columns.map(c => {
                        return {column_name: c.column_name, pos: 0}
                    }));
                }
                flashMessages(result);
                if (result.data) result.data = fixNullCells(result.data);
                if (callback) return callback(result);
            },
            (error) => {
                if (callback) callback({
                    data: [],
                    columns: []
                });
                flashMessages({
                    flash: [{level: 'e', message: JSON.stringify(error.message)}]
                });
            }
        )
}

//запрос данных по имени таблицы
export const setPassTableData = async (params) => {
    let {context, pasId, data, callback} = params;
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    //откорректировать запрос
    document.setCookies();
    fetch(`${window.IasConfig.homePath}zmu/passages/${pasId}/tbls/save`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => validResponse({result, callback}),
            (error) => invalidResponse({error, callback})
        )
}

//запрос данных по имени таблицы
export const setSpecTableData = async (params) => {
    let {context, pasId, data, callback} = params;
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    //откорректировать запрос
    document.setCookies();
    fetch(`${window.IasConfig.homePath}zmu/accyear/spec/save`, requestOptions)
        .then(res => res.json())
        .then(
            (result) => validResponse({result, callback}),
            (error) => invalidResponse({error, callback})
        )
}

//сохранение сканированного файла в базу
//
export const setImageData = async (params) => {
    let {id, data,callback} = params;
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        body: data
    };
    let url= `${window.IasConfig.homePath}zmu/passages/${id}/scan`;
    document.setCookies();
    fetch(url,
        requestOptions
    ).then(res => {
            return res.json();
        }
    ).then(
        (result) => {
            if (window.IasConfig.devMode) console.debug(`отправка setImageData, result: ${result}`);
            flashMessages(result);
            if (callback) callback(result);
        },
        (error) => {
            if (callback) callback({
                path: [],
                columns: []
            });
            flashMessages({
                flash: [{level: 'e', message: JSON.stringify(error.message)}]
            });
        })
};


const validResponse = (params) => {
    const {result, callback} = params;
    if (!result)
        if (callback) return callback({data: [], columns: [], flash: []});
    //если ответ - страница, возвращаем вызов страницы
    if (result.page && window.IasLoApp && result.page != window.IasLoApp.state.pageName) {
        return window.IasLoApp.rePage(result.page);
    }
    if (result.columns) {
        const path_columns = result.columns.filter(c => (c.column_name.indexOf('path') > -1));
        path_columns.map(c => c.data_type = 'path');
        if (path_columns.length > 0 && (f.isEditRight(result.rights))) {
            path_columns.map(c => c.data_type = 'path').map(c => result.columns.push({
                column_name: "upload",
                data_type: "upload",
                pos: c.pos,
            }))
        }
        const jsString = JSON.stringify(result.columns.map(c => {
            return {column_name: c.column_name, pos: 0}
        }));
    }
    flashMessages(result);
    if (callback) return callback(result);

}
const invalidResponse = (params) => {
    let {error, callback} = params;
    if (callback) callback({
        data: [],
        columns: []
    });
    flashMessages({
        flash: [{level: 'e', message: JSON.stringify(error.message)}]
    });
}


