import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import * as f from "../../common/Funcs";

//управление пользователями и правами
const keyName = `SessionsPage`;
export default class SessionsPage extends BaseGridPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName = "SessionsGrid";
        context.subElementName ='UserPage';
        context.tableName = 'session_view';
        context.idName = 'log_id';
        context.titleName = 'hu';
        context.hiddenColumns = [context.idName];
        context.topMenuButtonNames= ['reload','xls','print','clipboard'];
        //фильтры в правой части
        context.filters=[
            {name:'ac',required:false},
            {name:'sessionops',pos:3,required:false},
            {name:'lousers',pos:4,required:false},
            {name: 'from',pos:1, required: true, message: f.locale('needfrom')},
            {name: 'to',pos:2, required: true, message: f.locale('needto')},
            {name: 'page',pos:60, required: false}
        ];
        context.props.appViewPort.setState({filters:context.filters});
    }


}
