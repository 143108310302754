import ErrorBoundary from "../../../ErrorBoundary";
import BaseSubGrid from "../../../Base/BaseSubGrid";

const keyName=`SpecSynSubGrid`;
//вид в КК - саб таблица
export default class SpecSynSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'species';
        //элемент окна редактирования
        context.idName = 'spec_id';
        context.hiddenColumns = ['spec_id', 'key','spec_parent', 'spec_rang'];
    }

}