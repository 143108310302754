import React, {Component} from 'react';
import {
    Panel,
} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";
import ErrorBoundary from "../ErrorBoundary";

const keyName = 'BaseTopPage';

export default class BaseTopPage extends Component {

    constructor(props) {
        super(props);
        const context = this;
        context.filters = [];
        context.state = {
            content: '', title: ''
        };
    }

    componentDidMount() {
        const context = this;
        context.props.appViewPort.setState({filters: context.filters, rightWidth: context.rightWidth});
        if (context.loadPageData) context.loadPageData(context);
    }

    loadPageData(context, keyName) {
        f.getPageData({
            context: context.props.appViewPort,
            np: (!context.props.parent?.filters?.filter?.(f => f.name == 'page').length),
            keyName: keyName || context.props.appViewPort.state.pageName,
            pageSize: context.pageSize,
            // filtersOn: true,
            callback: (result) => {
                context.isLoaded = true;
                context.inload = false;
                if (result.columns) {
                    result.columns = result.columns.map(c => {
                        if (context.columnsDefaults) {
                            const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                            c = {...c, ...def};
                        }
                        return c;
                    }).sort((a, b) => {
                        return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                    });
                    // context.setState({columns: result.columns,data:result.data});
                    const upData = {columns: result.columns, vdata: result.data, error: result.flash};
                    if (context.afterGetData) context.afterGetData(context, upData);
                    window.unmask();
                }
            }
        })
    }

    update(context) {
        const f = context.filters.filter(p => p.required && (!document.getCookie(`${p.name}Filter`)));
        if (context.grid)
            if (f.length == 0) context.grid.getData({context: context.grid});
            else context.grid.setState({columns: []});

    }

    render() {
        const context = this;
        const content = (context.state.content.join) ? context.state.content.join(``) : context.state.content;
        const title = context.state.title;
        const height = f.getInnerHeightMenu();
        const titleMargin = `0 0 ${window.IasConfig.margin} 0`;
        return (<ErrorBoundary><Panel
            height={f.getCentralHeight()}
            region={"center"} margin="3" key={'baseTopPanel'}
            layout={"fit"}
        >
            <Panel
                bodyCls={"inner-header"}
                key={keyName}
                layout="hbox" shadow docked={"top"}
                height={height}
                ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
                margin={titleMargin}
                html={
                    `<span className="page-title">${title}</span>`}/>
            <Panel key={'innerTopPanel'} bodyCls={"inner-content"} layout={"vbox"} scrollable={'y'} html={content}/>
        </Panel></ErrorBoundary>)
    }
}

