import React from 'react';
import ExtReactDOM from '@sencha/ext-react-modern';
import './css/index.css';
import html2canvas from 'html2canvas';
import * as f from './common/Funcs';
import * as serviceWorker from './serviceWorker';
import AppViewPort from "./AppViewPort";

window.html2canvas = html2canvas;

//error
const blocks = ['error', 'warn', 'log', 'debug'];
//перехват изменения размера
window.onresize = () => (window.IasLoApp) ? window.IasLoApp.forceUpdate() : null;
//перехват нажатия ctrl+alt+e
document.addEventListener('keyup', (event) => {
    if (event.altKey == true &&
        event.ctrlKey == true &&
        event.keyCode == 69) {
        html2canvas(document.body).then((canvas) => {
            const dataPng = canvas.toDataURL('image/png');
            //.replace(/data:image\/png;base64,/, '');
            f.saveFile({blobFile: dataPng, fileName: 'error', fileType: 'png'});
            let content = {};
            blocks.map(b => {
                content[b] = console[`${b}s`]
            });
            content['cookies'] = document.cookie;
            const replacerFunc = () => {
                const visited = new WeakSet();
                return (key, value) => {
                    if (typeof value == "object" && value !== null) {
                        if (visited.has(value)) {
                            return;
                        }
                        visited.add(value);
                    }
                    return value;
                };
            };
            const dataLog = 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(content, replacerFunc(), 2));
            f.saveFile({blobFile: dataLog, fileName: 'iaslo', fileType: 'log'});
        });
    }
});
Object.defineProperty(String.prototype, 'firstToUpper', {
    __proto__: null,
    value: function () {
        if (this == null) {
            throw new TypeError('"this" is null or not defined');
        }
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    configurable: true
});
Object.defineProperty(String.prototype, 'firstToLower', {
    __proto__: null,
    value: function () {
        if (this == null) {
            throw new TypeError('"this" is null or not defined');
        }
        return this.charAt(0).toLowerCase() + this.slice(1);
    },
    configurable: true
});
Object.defineProperty(HTMLCollection.prototype, 'getArray', {
    __proto__: null,
    value: function () {
        if (this == null) {
            return [];
        }
        var result = Array.prototype.slice.apply(this);
        return result;
    },
    configurable: true
});

ExtReactDOM.render(<AppViewPort
    id={'iasViewPort'}
    ref={(port => {
        window.IasLoApp = port;
    })}/>, document.getElementById('root'));

serviceWorker.unregister();

let baseColor = '#024059';
document.documentElement.style.setProperty("--base-color", baseColor);
