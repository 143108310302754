import ErrorBoundary from "../../ErrorBoundary";
import React, {Component} from 'react';
import {
    ExtGrid,
    Panel,
} from '@sencha/ext-react-modern';
import * as f from "../../common/Funcs";
import FullMap from "../../Maps/FullMap";
import BasePage from "../../Base/BasePage";

const keyName = 'MapPage';

export default class MapPage extends BasePage {

    constructor(props) {
        super(props);
        const context = this;
        const now = new Date();
        document.setCookie('toFilter', now.toLocaleDateString());
        context.state = {
            content: {data: [], title: ''}
        };
        context.filters = [{name: 'to',pos:1, required: false, label: f.locale('ondate'), gisSearch: false},
            {name: 'region',pos:2, required: false, nUpdate: true, gisSearch: true},
            {name: 'users',pos:3, required: false, nUpdate: true, gisSearch: true},
            {name: 'ecos',pos:4, required: false, nUpdate: true, gisSearch: true},
            {name: 'lands',pos:5, required: false, nUpdate: true, gisSearch: true},
            {name: 'mo',pos:6, required: false, nUpdate: true, gisSearch: true},
            {name: 'pop',pos:7, required: false, nUpdate: true, gisSearch: true},
            {name: 'hdrlin',pos:8, required: false, nUpdate: true, gisSearch: true},
            {name: 'hdrpol',pos:9, required: false, nUpdate: true, gisSearch: true},
            {name: 'fst',pos:10, required: false, nUpdate: true, gisSearch: true},
            {name: 'fstloc',pos:11, required: false, nUpdate: true, gisSearch: true},
            {name: 'fstqrt',pos:12, required: false, nUpdate: true, gisSearch: true},
        ];
    }

    componentDidMount() {
        const context = this;
        context.props.appViewPort.setState({filters: context.filters, rightWidth: context.rightWidth},
            () => {
                // if (!window.IasConfig.useApplyButton)
                    context.update(context);
            }
    );
    }

    update(context) {
        context.olMap.update(context.olMap);
    }

    render() {
        const context = this;
        return <Panel
            key={keyName}
            height={f.getCentralHeight()}
            region={"center"} bodyCls={'basemap-panel-body'}>
            <FullMap
                parent={context}
                appViewPort={context.props.appViewPort}
                defaultScale={2000000}
                ref={m => {
                    if (m)
                        context.olMap = m;
                }}
                width={'100%'}/>
        </Panel>
    }
}

