import {Circle as CircleStyle, Stroke, Style} from "ol/style";

const keyName='';
export default (feature, size = 9) => {
 if (window.cssOn) console.debug(`${keyName} css`,feature);
    const color = [0, 150, 255, 1];
    const Point = new Style({
        image: new CircleStyle({
            radius: 5,
            fill: null,
            stroke: new Stroke({color: color, width: 1}),
        }),
    });
    const Polygon = new Style({
        stroke: new Stroke({
            color: color,
            width: 1
        })
    });
    const LineString = new Style({
        stroke: new Stroke({
            color: color,
            width: 1
        })
    });
    return {'Point': Point, 'Polygon': Polygon, 'MultiPolygon': Polygon, 'LineString': LineString};
}