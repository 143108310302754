import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName = `ZonePage`;

export default class ZonePage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'zones';
        context.idName = 'zone_id';
        context.titleName = `zone_name`;
        context.columnsCount = [{width:'60'}, {width:'40'}];
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {
                pos: 0,
                column_name: 'zone_land',
                is_list: true,
                source: {
                    tableName: 'lands',
                    titleField: 'land_name',
                    idField: 'land_id',
                },
                disabled: true,
                row:0
            },
            {column_name:'zone_active',row:2,pos:4,defaultValue:true},
            {column_name:'zone_area',row:2,pos:2,maxWidth:120},
            {column_name:'date_on',row:2,pos:3},
            {column_name:'zone_name',row:1,pos:1}
        ]];
        context.subTables = [
            {
                column:0,
                block: 2,
                title: 'Документы',
                elementName: 'ZoneDocumentsSub',
                tableName: 'zonedocuments',
                activeColumn:'doc_active',
                relation: {tableName: 'zone_documents', parentField: 'doc_zone', relField: 'zone_doc'},
                subElementName: 'DocumentPage',
                filterString: "doc_zone=$zone_id$",
                idName: 'doc_id',
            },
            {
                title: 'Границы',
                elementName: 'BaseMapSub',
                column:1,
                block:1,
                mapButtonNames:['upload','download'],
                tableName: 'zonespol',
                idName:'zone_id',
                // activeColumn: 'date',
                filterString: "zone_id=$zone_id$",
            },
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='zones' and record_id = $zone_id$::varchar",
            }
        ]
    }
    titleFunc(context, record,parentRow) {
        let parentPage=context;
        while (parentPage.props&&parentPage.props.parent&&!parentPage.title) parentPage=parentPage.props.parent;
        const parentTitle=parentPage.title;
        if (record)
            context.title= `Зона охраны охотучастка ${parentTitle}`;
        else
            context.title= `Зона охраны охотучастка ${parentTitle}`;
        return context.title;
    }

}


