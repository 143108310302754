import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import {FileField, FormPanel, Panel} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";
import * as z from "../common/zmuFuncs";
import BasePage from "../Base/BasePage";
import {DeleteButton, DropButton} from "../common/Buttons";
import {downloadFile, flashMessages, setPageCount} from "../common/Funcs";
import BaseSubGridPanel from "../Base/BaseSubGridPanel";

const Ext = window['Ext'];
const keyName = `ImageSub`;

export default class ImageSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.tableName = 'passages_scan';
        context.idName = 'passage_id';
        context.parent = props.parent;
        context.rowId = props.rowId;
        context.subElementName='none';
        // context.topMenuButtonNames = (props.topMenuButtonNames) ? props.topMenuButtonNames : [
            context.topMenuButtonNames = [
            'upload',
            'download',
            'reload',
            'rotate',
            'clear'];
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.warn(error, errorInfo);
    }

    componentDidMount() {
        const context = this;
        if (context.props.rowId) {
            context.getData({context});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        if (prevProps.rowId != context.props.rowId
            // || (context.state.path == undefined)
        ) {
            context.getData({context});
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    const remove=()=>{
        let up=document.getElementById('img_file');
        if (up) {
            up.remove();
            remove();
        }
    }
    remove();
    }

    getGrid(context) {
        let html='';
        if (context.state.path) {
            const src = window.IasConfig.homePath + 'files/' + context.state.path.replace('\\', '/');
            html = `${html}<img  key="scanedImage" style="transform: rotate(${context.state.deg}deg) translate(10%, 10%)" src=${src}/>`;
        }
       return <Panel
            region={"center"} margin="3"
            cls={'edit-window-panel'}
            bodyCls={'scrolable'}
            height={context.getHeight()}
            scrollable={true}
            key={'imagePanel'}
            ref={p => {
                if (p) {
                    context.subPanel = p.cmp;
                } else {
                    context.subPanel = null;
                }
            }}
            html={html}
        >
        </Panel>
    }

    //форма для отправки данных на сервер
    getValues(file) {
        let result = new FormData();
        result.append('file', file);
        return result;
    }

    getData(params) {
        const {context, callback, defaults} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        const prnt = context.props.parent;
        context.defaults = defaults;
        const tableName = context.tableName || prnt.tableName;
        const rowId = context.props.rowId;
        if (window.IasConfig.devMode) console.debug(`${keyName} getData ${tableName} ${rowId}`);
        context.setState({"path": null}, () => {
            const _params = {
                context: context,
                tableName: tableName, id: rowId, callback: (result) => {
                    if (result.data) {
                        ['path', 'deg'].map(kk => {
                            const k = Object.keys(result.data).find(k => k.indexOf(kk) > -1);
                            if (k) {
                                context.setState({[kk]: result.data[k]});
                            }
                        })

                    }
                }
            }
            f.getObjectData(_params);
        });
    }

    addUploadButton(i) {
        const context = this;
        if (context.isEditRight(context)) {
            const fileElement = document.createElement('input');
            fileElement.type = 'file';
            fileElement.id = 'img_file';
            fileElement.className = 'upload-file-input hidden';
            fileElement.addEventListener("change", (e) => {
                const file = fileElement.files[0];
                if (file.type != 'image/png') {
                    f.alert('Загружаемый файл должен быть изображением.')
                } else {
                    f.confirm(`Загрузить файл ${file.name}?`, (res) => {
                        if (res) {
                            const formData = new FormData();
                            formData.append("file", file);
                            fetch(`${window.IasConfig.homePath}zmu/passages/${context.rowId}/scan`,
                                {
                                    credentials: 'include',
                                    method: "POST",
                                    body: formData
                                }).then(res => {
                                    return res.json();
                                }
                            ).then(
                                    (result) => {
                                        context.setState({"path": result.data.path});
                                    },
                                    (error) => {
                                        debugger;
                                    });
                        }
                    })

                }
            });
            document.body.appendChild(fileElement);
            //по клику - клик на инпуте
            //на change input-a  запрос на загрузку файла и загрузить если да
            context.topMenuButtons[i] = ({
                iconCls: 'x-fa fa-upload',
                tooltip: f.locale('upload'),
                handler: () => {
                    fileElement.click();
                    //      f.uploadFile(context.state.path);
                }
            })
        }
    }

    addDownloadButton(i) {
        const context = this;
        if (context.isEditRight(context)) {
            if (!context.isEmpty(context)) {
                context.topMenuButtons[i] = ({
                    iconCls: 'x-fa fa-download',
                    tooltip: f.locale('download'),
                    handler: () => {
                        f.downloadFile(context.state.path);
                    }
                })
            }
        }
    }

    addRotateButton(i) {
        const context = this;
        if (context.isEditRight(context)) {
            if (!context.isEmpty(context)) {
                context.topMenuButtons[i] = ({
                    iconCls: 'x-fa fa-rotate-left',
                    tooltip: f.locale('reload'),
                    handler: () => context.rotateImage(context)
                })
            }
        }
    }

    addReloadButton(i) {
        const context = this;
        context.topMenuButtons[i] = ({
            iconCls: 'x-fa fa-refresh',
            html:`<label class="menu-label">Обновить</label>`,
            tooltip: f.locale('reload'),
            handler: () => context.getData({context})
        });
    }

    addClearButton(i) {
        const context = this;
        if (!context.isEditRight(context)||context.isEmpty(context)) return;
        context.topMenuButtons[i] = ({
            iconCls: 'x-fa fa-trash',
            tooltip: f.locale('drop'),
            handler: () => context.clearTool(context)
        });
    }

    rotateImage(context) {
        let deg = (Number(context.state.deg) + 90) % 360;
        let values = new FormData();
        values.append('deg', deg);
        values.append('scan_path', context.state.path);
        context.setState({deg: deg}, () => {
            f.setObjectData({
                context: context,
                tableName: context.tableName,
                id: (context.props.parent.rowId),
                values: values,
            })
        });
    }

    isEmpty(context) {
        return Boolean(!context.state.path);
    }

    getHeight() {
        const context = this;
        return (context.props.parent?.form?.cmp.el.getHeight() - 115);
    }

    clearTool(context) {
        const dropFunc = () => {
            fetch(`${window.IasConfig.homePath}zmu/passages/${context.rowId}/scan`,
                {method: 'DELETE',
                    credentials: 'include',
                }
            ).then(res => {
                    return res.json();
                }
            )
                .then(
                    (result) => {
                        debugger;
                    },
                    (error) => {
                        debugger;
                    })
        };
        f.confirm({
            title: 'Удаление',
            message: `Вы действительно хотите удалить файл?`,
            context
        }, (e) => {
            if (e)
                dropFunc();
        });
    }

    render_old() {
        const context = this;
        const elements = [];
        let html = '';
        const isEdit = context.parent.isEditRight();

        context.addMenuButtons();
        if (context.state.path) {
            const src = window.IasConfig.homePath + 'files/' + context.state.path.replace('\\', '/');
            html = `${html}<img  key="scanedImage" style="transform: rotate(${context.state.deg}deg) translate(10%, 10%)" src=${src}/>`;
            elements.push();
        }

        return (<ErrorBoundary> <Panel
            region={"center"} margin="3"
            cls={'edit-window-panel'}
            height={context.getHeight()}
            title={context.props.title}
            tools={context.topMenuButtons}
            key={'imagePanel1'}
        >
            <Panel
                region={"center"} margin="3"
                cls={'edit-window-panel'}
                bodyCls={'scrolable'}
                height={context.getHeight()}
                scrollable={true}
                key={'imagePanel'}
                ref={p => {
                    if (p) {
                        context.subPanel = p.cmp;
                    } else {
                        context.subPanel = null;
                    }
                }}
                html={html}
            >
            </Panel>
        </Panel></ErrorBoundary>)
    }
}