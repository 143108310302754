import React from 'react';
import {ExtGrid, LockedGrid, Panel,} from '@sencha/ext-react-modern';

import * as f from "../../common/Funcs";
import BaseGrid from "../../Base/BaseGrid";
import GridField from "../../collections/GridField";
import ErrorBoundary from "../../ErrorBoundary";

const keyName = `AccGrid`;
const Ext = window['Ext'];
Ext.require('Ext.grid.plugin.Summary');

export default class AccGrid extends BaseGrid {

    constructor(props) {
        super(props);
        const context = this;
        context.rowNumberWidth = 40;
        context.columnsDefaults = [{column_name: "row_id"},
            {column_name: "huntuser_name", width: 250, pos: 1, docked: 'left'},
            {column_name: "spec_label", width: 140, pos: 0, docked: 'left'},
            {column_name: "region_name", width: 140, pos: 0, docked: 'left'},
            {column_name: "economy_name", minWidth: 140, docked: 'left'},
            {column_name: "land_name", width: 140, docked: 'left'},
            {
                column_name: "spec_name",
                title: true
            },
        ];
        const storeProps = {
            data: context.state.data
        };
        storeProps.groupField = 'region_name';
        context.store = Ext.create('Ext.data.Store', storeProps);
    }

    componentDidMount() {
    }

    //загрузка данных
    getData(params) {
        const {context} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName(), params);
        context.testOnSave(context,context.callData);
    }

    testOnSave(context,callback) {
        /*контроль несохраненных данных*/
        // if (context.state.modified) {
        //     f.confirm({
        //         title: 'Внимание, не все данные сохранены.',
        //         message: 'Сохранить данные перед обновлением страницы?'
        //     }, (result) => {
        //         if (result) {
        //             context.props.parent.saveFunc(context.props.parent, () => (callback)?callback(context):'');
        //         } else if (callback) callback(context);
        //     })
        // } else
            if (callback) callback(context);
    }

    callData(context) {
        context.setState({columns: []}, () => {
                context.store.clearData();
                context.store.loadRecords([]);
                context.inload = true;
                f.getAccPageData({
                    // context: context.props.appViewPort,
                    accId: context.props.accId,
                    // filters: context.props.parent.filters,
                    callback: (result) => {
                        context.isLoaded = true;
                        context.inload = false;
                        //    context.setState({'title': 'updated'});
                        result.columns.push({
                            column_name: 'acc_rights',
                            pos: -1,
                            hidden: true,
                            getColor: (val) => (val == 'v') ? [100, 100, 100, 0.5] : [0, 0, 0, 1]
                        });
                        result.columns = result.columns.map(c => {
                            if (context.columnsDefaults) {

                                const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                                c = {...c, ...def};
                                if (!def) c.colored = 'fontrecord';
                            }
                            return c;
                        }).sort((a, b) => {
                            return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                        });
                        const upData = {
                            columns: result.columns,
                            modified: false,
                            vdata: result.data,
                            error: (result.flash) ? result.flash.map(f => f.text) : []
                        };
                        context.afterGetData(context, upData);
                        context.props.parent.setState({acc_form: result.desc?.acc_form});
                        //отмечаем обяхмтельные фильтры для учетов
                        Object.keys(result.desc).filter(k =>
                            result.desc[k]?.length == 1).map(k =>
                            window.IasLoApp.filters[`${k.firstToUpper()}Filter`]?.setState({required: result.desc[k] == 'g'}));
                    }
                }).then(r => void 0)
            }
        )
    }

    afterEdit(context, sender, location, eOpts) {
        //признак измененной записи
        location.record.data.modified = true;
    }

    beforeEdit(context, sender, location) {
        //признак измененной записи
        const allow = f.isEditRight(location.record.data.acc_rights);
        if (allow) {
            location.record.data.modified = true;
            context.setState({modified: true});
        }
        return allow;
    }

    getHeight() {
        return f.getCentralHeight() - f.getInnerHeightMenu();
    }

    getGrid(context, columns) {
        const plugins = {
            gridfilters: true,
            gridexporter: true,
            gridsummaryrow: true
        };
        return <ErrorBoundary key={`${keyName}err`}><ExtGrid
            layout={'fit'}
            key={keyName + 'grid'}
            cls={'acc-grid'}
            name={keyName}
            height={context.getHeight()}
            maxHeight={context.getMaxHeight(context)}
            rowHeight={context.props.rowHeight}
            rowNumbers={{
                width: context.rowNumberWidth,
                docked: 'left'
            }}
            plugins={plugins}
            // groupField={context.groupField}
            groupHeader={{
                xtype: 'gridsummaryrow',
                floated: false
            }}
            platformConfig={{
                '!desktop': {
                    plugins: {
                        grideditable: true,
                        gridsummaryrow: true
                    }
                },
                desktop: {
                    plugins: {
                        // rowedit:true,
                        gridcellediting: true,
                        gridsummaryrow: true
                    }
                }
            }}
            itemConfig={context.getItemConfig(context)}
            store={context.store}
            ref={grid => context.grid = grid}
            variableHeights={false}
            listeners={{
                columnmenucreated(grid, column, menu, eOpts) {
                    menu.on('beforeshow', (sender) => {
                        sender.items.items.map(i => {
                            if (i.menu)
                                i.menu.getItems().items.map(c => {

                                    //!!добавить поля
                                    if (['acc_rights',
                                        'region_name',
                                        'huntuser_name',
                                        'acc_year',
                                        'acc_region',
                                        'acc_huntuser'
                                    ].indexOf(c.column._dataIndex) > -1) c.hide();
                                });
                            f.localeMenu(i);
                        });
                    });
                },
                edit: (sender, location, eOpts) => {
                    context.afterEdit(context, sender, location, eOpts)
                },
                beforeedit: (sender, location) => {
                    context.beforeEdit(context, sender, location)
                }
            }}
        >
            {columns}
        </ExtGrid></ErrorBoundary>
    }

    getColumn(context, c) {
        const hidden = ((context.hiddenColumns && context.hiddenColumns.indexOf(c.column_name) > -1) || c.hidden || c.column_name == 'order_value');
        const isNum = (c.data_type == 'integer' || c.data_type == 'numeric');
        const summary = ((isNum && !hidden) || (c.column_name == 'region_name')) ? 'max' : null;
        if (isNum) {
            c.width = 75;
            c.className = 'rotate-header'
        }
        c.minWidth = c.width;
        c.maxWidth = c.width;

        return <GridField
            key={c.column_name}
            column_name={c.column_name}
            column_label={c.column_label}
            // scrollDock={(c.docked)?'start':'end'}
            // docked={c.docked}
            // scrollable={(c.docked)}
            flex={c.flex}
            readonly={c.readonly}
            hidden={hidden}
            className={c.className}
            getColor={c.getColor}
            colored={c.colored}
            parent={context.props.parent}
            grid={context}
            relation={c.relation}
            title={c.title}
            align={c.align}
            ignoreExport={c.ignoreExport}
            editable={c.editable}
            data_type={c.data_type}
            width={c.width}
            minWidth={c.minWidth}
            maxWidth={c.maxWidth * ((context.state?.data?.length > 10) ? 0.95 : 1)}
            ignorePrint={c.ignorePrint}
            summary={summary}
            summaryRenderer={(x, scope, id, context, ...args) => {
                if (x) {
                    if (!context) {
                        context = {refreshContext: scope};
                        id = scope.dataIndex;
                    }
                    if (context.refreshContext.group) {
                        if (id == 'region_name') {
                            // if (window.IasConfig.devMode) console.debug(`${x} region sumrow`, arguments);
                            return x;
                        } else {
                            if (window.IasConfig.devMode) console.debug(`calc ${id} sumrow`, arguments);
                            return context.refreshContext.records.map(i => Number(i.getData()[id])).reduce((a, b) => a + b);
                            // const records=scope.group.data.items.map(t=>t.data);
                            const records = context.refreshContext.records;
                            return records.map(i => Number(i.getData()[id])).reduce((a, b) => a + b);
                        }
                    } else {
                        if (id == 'region_name') return 'Итого';
                        else {
                            // const records=scope.store.getData().items;
                            const records = context.refreshContext.store.getData().items;
                            return records.map(i => Number(i.getData()[id])).reduce((a, b) => a + b);
                        }
                    }
                } else return '';
            }
            }
            grouper={{
                groupFn: (...args) => {
                    debugger;
                }
            }}
        />
    }

    render() {
        // if (window.IasConfig.devMode) console.debug(`${keyName} render`);
        const context = this;
        const columns = [];
        const content = [];
        if (!context.inload) {
            const loadedtext = context.emptyMessages(context.state.error);
            if (loadedtext) content.push(loadedtext);
            if (context.isLoaded && !loadedtext && context.state.columns) {
                window.unmask();
                /*заполнение полей для store*/
                context.store.fields = context.state.columns.filter(c => c.column_name !== context.idName);
                /*обработка ячейки с признаком редакции*/
                context.state.columns.sort((a, b) => (a.pos == null) ? 1 : a.pos - b.pos).map(c => {
                    //const column = context.getExtColumn(c);
                    const column = context.getColumn(context, c);
                    /*форматирование ячеек*/
                    columns.push(column);
                });
            }
            const itemConfig = (context.tpl) ? {
                body: {
                    tpl: context.tpl()
                }
            } : {};
            //тело таблицы
            if (columns.length > 0) {
                const grid = context.getGrid(context, columns);
                content.push(grid);
                window.testGrid = grid;
                // content.push(context.getPaging(context, columns));
            }
        } else {
            window.mask();
        }
        //панель инструментов гриды. Внутреннее меню страницы - в base...page
        content.push(context.getToolBar());

        return (<Panel key={keyName + 'key'}
                       ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >{content}</Panel>)
    }
}
