import BasePointPage from "../../../Base/ZMU/BasePointPage";
import * as f from "../../../common/Funcs";

const keyName = "AnimalPage";
export default class AnimalPage extends BasePointPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'passage_point_animal_view';
        context.columnsDefaults = [...context.columnsDefaults, ...[
            {column_name: "passage_id", hidden: true},
            {
                column_name: "spec_id",
                required: true,
                "pos": 2, row: 20,
                "is_list": true,
                source: {
                    tableName: 'zmu_species',
                    titleField: 'spec_name',
                    idField: 'zmu_spec',
                    filterString: `acc_year=${document.getCookie('yearFilter')} and (isbird=false or isbird is null)`,
                }
            },
            {
                "column_name": "days_old",
                labelAlign: 'left',
                "pos": 5, row: 50,
            }]];
    }

    onCancel(context) {
        const id=context.props?.parent?.props?.rowId;
        if (id)
        f.callPath({
            context: context.props.appViewPort,
            path: `zmu/passages/${id}/test`
        });
        super.onCancel(context);
    }
}


