import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import React from "react";
import SpecPage from "../../SinglePages/Regbook/SpecPage";
import {Button} from "@sencha/ext-react-modern";
import * as f from '../../common/Funcs';
import {BaseTextButton} from "../../Base/BaseButtons";

const keyName = 'SpeciesPage'
export default class SpeciesPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        context.title='Список видов';
        document.dropCookie('speciesFilter');
        document.dropCookie('savingloFilter');
        document.dropCookie('savingrfFilter');
        document.dropCookie('huntingFilter');
        document.dropCookie('wateringFilter');
        // document.setCookie('autoload',false);
        context.props.appViewPort.autoload=false;
        context.filters = [
            {name: 'species', hidden: false, required: false},
            {name: 'hunting', hidden: false, required: false},
            {name: 'savinglo', hidden: false, required: false},
            {name: 'savingrf', hidden: false, required: false},
            {name: 'watering', hidden: false, required: false},
            {
                name: 'year',
                hidden: false,
                required: false
            },
            {name: 'page', required: false}
            ];
        context.rightWidth = 300;
        context.gridName = "SpeciesGrid";
        context.subElementName = 'SpecPage';
        context.tableName = 'species';
        context.idName = 'specid';
        context.state = {};

    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        const context = this;
        if (prevState.rowId !== context.state.rowId) {
            context.setSpecData(context.state.rowId);
        }
        if (prevState.showEditPanel==true&&this.state.showEditPanel==false) {
            window.unmask();
        }
    }

    setSpecData(specId) {
        const context = this;
        if (context.editForm) {
            //id с грида передать в форму редактирования и обновить данные
            context.rowId = specId;
            context.editForm.getData({context:context.editForm});
        }
    }

    //кнопки для меню
    addAdditButtons(props) {

        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} addMenuButtons`, props, context.props.rights);
        context.topMenuButtons.push(<BaseTextButton key={'cardonoff'} text={'карточка'} func={()=>{
            context.grid.setState({cardHidden:!context.grid.state.cardHidden});
            if (context.grid?.grid?.cmp)
            context.grid.grid.cmp.setHeight(context.grid.getHeight());
        }
        }/>);
        context.topMenuButtons.push(context.formButton('fauna'));
        context.topMenuButtons.push(context.formButton('pack/speckk'));
    }


    //кнопка формы xml

    formButton(key = 'form21') {

        const context = this;
            const openDoc = () => {
                const call = (recreate) => {
                    let filters = `?${(recreate) ? `recreate=1&recalc=1&` : ``}${f.getFilters('string')}&${'title=' + f.translit(f.locale(key))}`;
                    window.open(`${window.IasConfig.homePath}forms/${key}/xls${filters}`);
                    window.focus();
                };
                f.confirm({
                    message: 'Выполнить перерасчет?<br>' +
                        'Это займет дополнительное время.',
                    buttons: {yes: {text: 'Пересчитать'}, no: {text: 'Скачать без перерасчета'},cancel:{text:'Отменить'}}}, (recreate) => {
                    if (f.exist(recreate)) {
                        call(recreate);
                    }
                });
            }

        const callFunc = () => {
                if (window.IasConfig.devMode) console.debug("call pack");
               openDoc(key)
        };
        return (<Button
            ui={window.IasConfig.ui}
            key={key + 'button'}
            name={key + 'button'}
            text={f.locale(key)}
            handler={callFunc}/>)
    }


    /*render() {
        const context = this;
        return <Panel>
            <SpecPage ref={(e) => context.editForm = e}
                      pageDefaults={context.state.pageDefaults}
                      key={context.subElementName}
                 //     rights={(document.getCookie('isAuth') == 1) ? 'd' : 'r'}
                      parent={context} appViewPort={context.props.appViewPort}/>
        </Panel>;
    }*/
}
