import ErrorBoundary from "../../../ErrorBoundary";
import BaseSubGrid from "../../../Base/BaseSubGrid";

const keyName=`RbSpecSubGrid`;
//вид в КК - саб таблица
export default class RbSpecSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.tableName = 'spec_on_redbook';
        //элемент окна редактирования
        context.idName ='row_id';
        context.hiddenColumns= ['row_id', 'spec_id', 'rb_id'];
        context.columnsDefaults=[
            {column_name: 'rb_name', title: true},{column_name: 'spec_status', title: true}]
    }

}