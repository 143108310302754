import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import * as f from "../../common/Funcs";

//управление пользователями и правами
const keyName = `LogsPage`;
export default class LogsPage extends BaseGridPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName = "LogGrid";
        context.subElementName = 'LogPage';
        context.tableName = 'log_view';
        context.idName = 'log_id';
        context.titleName = 'hu';
        context.hiddenColumns = [context.idName];
        context.topMenuButtonNames= ['reload','xls','print','clipboard'];
        //фильтры в правой части
        context.filters=[
            {name:'op',pos:3,required:false},
            {name:'fio',pos:4,required:false},
            {name:'table',pos:5,required:false},
            {name: 'from',pos:1, required: true, message: f.locale('needfrom')},
            {name: 'to',pos:2, required: true, message: f.locale('needto')},
            {name: 'page',pos:60, required: false}
        ];
        context.props.appViewPort.setState({filters:context.filters});
    }


}