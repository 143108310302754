import {Fill, Stroke, Style, Text} from "ol/style";
import * as g from "../../common/gisFuncs";

const keyName = 'planset';
export default (feature, resolution) => {
    if (window.cssOn) console.debug(`${keyName} css`, feature);
    const source = feature.getProperties().source;
    const w = new Style({
        // fill: new Fill({
        //     color: 'rgba(255, 255, 255, 0.5)',
        // }),
        stroke: new Stroke({
            color: [255, 255, 255, 1],
            width: 5
        })
    });
    let color = [0, 0, 0, 1];
    let fillcolor = [255, 255, 255, 0.1];
    switch (source) {
        case 'setl_eeko':
            color = [255, 0, 0, 0.5];
            fillcolor = [255,255,255, 0.1];
            break;
        case 'plan':
            switch (feature.getProperties().land_type) {
                case 'закрепленные':
                    color = [0, 255, 0, 0.5];
                    fillcolor = [0, 255, 0, 0.1];
                    break;
                case 'общедоступные':
                    color = [50, 50, 0, 0.5];
                    fillcolor = [50, 50, 0, 0.1];
                    break;
                case 'федеральные ООПТ':
                    color = [255,0,0, 0.5];
                    fillcolor = [255,0,0, 0.2];
                    break;
                case 'региональные ООПТ':
                    color = [255,0,255, 0.5];
                    fillcolor = [255, 0,255, 0.1];
                    break;
            }
            color = [0, 50, 155, 1];
            break;
    }
    const p = new Style({
        fill: new Fill({
            color: fillcolor,
        }),
        offsetX: 10,
        offsetY: -10,
        stroke: new Stroke({
            color: color,
            width: 1
        }),
        text: (source == 'setl_eeko') ? new Text({
            text: g.getText(feature, {resolution: resolution}), stroke: new Stroke({color: [255, 255, 255], width: 1}),
            fill: new Fill({color: 'black'}),
            overflow: (resolution < 50)
        }) : null
    });
    return [p];
}