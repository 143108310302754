import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import BaseRelationSub from "../Base/BaseRelationSub";
import {Button} from "@sencha/ext-react-modern";
import Subs from "../collections/subElements";
import DialogForm from "../common/DialogForm";

const keyName = `ContactsSub`;

export default class  ContactsSub extends BaseRelationSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.relation = context.props.relation;
        context.topMenuButtonNames=(props.topMenuButtonNames)?props.topMenuButtonNames: ['reload','add','edit','relation'];
        context.gridName= `ContactsSubGrid`;
        context.idName =  'contact_id';
        context.noSingle=true;
        // context.hiddenColumns=props.hiddenColumns ||[context.idName];
        context.activeColumn = props.activeColumn || null;
        context.subElementName = props.subElementName;
        // context.columnsDefaults = props.columnsDefaults || [];
        // context.relColumnsDefaults = props.relColumnsDefaults || [];
    }


}

