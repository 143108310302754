import BasePageGrid from "../../Base/BasePageGrid";
import * as f from "../../common/Funcs";
import ErrorBoundary from "../../ErrorBoundary";
import React from "react";

const keyName = 'HuntUsersGrid';
export default class HuntUsersGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.idName = 'hu_id';
        context.hiddenColumns = [context.idName];
        context.columnsDefaults = [
            {"column_name": "hu_name", pos: 1, minWidth:300,maxWidth:300,flex:{grow:2},title:true},
            {"column_name": "hu_regions", pos: 2, minWidth: 300,className:'copy-class',flex:{grow:2}},
            {"column_name": "hu_number", pos: 3, maxWidth: 100},
            {column_name: "hu_active", column_label:' ',pos: 0},
            {"column_name": "hu_date_on", hidden:true,pos: 5, minWidth: 200, maxWidth: 200},
            {column_name: "hu_date_off",  hidden:true, pos: 6, minWidth: 200, maxWidth: 200},
        ]
    }

    //кнопка  выделения юр. лица
    extButton(value) {
        const context = this;
        //   if (window.IasConfig.devMode) console.debug(`${keyName} extButton`);
        const key = 'extbutton';
        if (f.isEditRight(value))
            return <span className={'x-fa fa-plus'} onClick={() => context.recordChild.bind(context, this)}/>;
        else
            return '';
    }


    //для колонки редакции. Проверка на колонку, проверка прав, значок
    rndFunc222(value) {
        const context = this;
        //      if (window.IasConfig.devMode) console.debug(`${keyName} rndFunc`);
        const editButton = context.editButton(value);
        const extButton = context.extButton(value);
        return [editButton, extButton];
    }

}