import ErrorBoundary from "../../ErrorBoundary";
import React from "react";
import BaseSubGrid from "../../Base/BaseSubGrid";

const keyName = 'ContactsSubGrid';
export default class ContactsSubGrid extends BaseSubGrid {
    static defaultProps = {data: [], tableName: 'hunt_users', idName: 'hu_id', rowHeight: 44, rowId: null}

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'ContactPage';
        context.idName = 'contact_id';
        context.hiddenColumns = [context.idName, 'contact_huntuser'];
        context.columnsDefaults = [
            {column_name: keyName},
            {column_name: "contact_id", hidden: true, pos: 0},
            {column_name: "contact_huntuser", hidden: true, pos: 0},
            {column_name: 'contact_eco', hidden: true},
            {column_name: 'contact_land', hidden: true},
            {column_name: 'contact_pickup', hidden: true},
            {column_name: "contact_active", pos: 0},
            {column_name: "num", pos: 0, hidden: true, row: 1, maxWidth: 50},
            {column_name: "fio", pos: 2, row: 1, title: true, className: 'copy-class', minWidth:400},
            {column_name: "post", pos: 3, row: 1, className: 'copy-class',minWidth:200},
            {column_name: "phones", hidden: true, row: 2, pos: 4, className: 'copy-class',},
            {column_name: "emails", hidden: true, row: 3, pos: 5, className: 'copy-class',}
        ];
        context.singleColumns = [
            {column_name: "contact_id", hidden: true, pos: 0},
            {column_name: "contact_active", hidden: true, pos: 0},
            {column_name: "contact_huntuser", hidden: true, pos: 0},
            {column_name: 'contact_land', hidden: true},
            {column_name: 'contact_pickup', hidden: true},
            {column_name: "num", pos: 0, row: 1},
            {column_name: "full_name", pos: 2, row: 1, title: true},
            {column_name: "post", pos: 3, row: 1},
            {column_name: "phones", row: 2, pos: 4,},
            {column_name: "emails", row: 2, pos: 5,}
        ];
        //                        <div className="x-after-input-el" onClick={() => navigator.clipboard.writeText(value)}/>
        context.tpl = (data) => {
            return (
                <div className={'contact-tpl copy-container'}>
                    <span className={'tpl-title'}>Тел:</span>
                    <div className={'copy-class x-gridcell '}>
                    <span className={'tpl-text'}>{data.phones}</span>
                    <div className="x-after-input-el"/></div>
                    <span className={'tpl-title'}> Email: </span>
                    <div className={'copy-class x-gridcell '}>
                    <span className={'tpl-text'}>{data.emails}</span>
                    <div className="x-after-input-el"/>
                    </div>
                </div>
            )
        };


    }

}