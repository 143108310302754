import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName = `SpecRbPage`;

export default class SpecRbPage extends BaseEditPage {
    static defaultProps={
        width:'75%'
    }
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'spec_on_redbook';
        context.idName = 'row_id';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.columnsCount=['50','50'];
        this.columnsDefaults = [...context.lastUserColumns,...[
            {column_name: 'rb_id'
            , pos:0,row:0,is_list: true,
                source: {
                    tableName: 'spec_rb',
                    titleField: 'rb_name',
                    idField: 'rb_id',
                }},
            {column_name: 'spec_id', hidden: true},
            {column_name: 'spec_rb_desc', pos:10,row:10,disable:true},
            {column_name: 'spec_status', pos:20,row:10},
            {column_name: 'rb_desc', pos:0,row:20},
            {column_name: 'rb_distr', pos:0,row:30},
            {column_name: 'rb_eco_desc', pos:0,row:40},
            {column_name: 'trend', pos:0,row:45},
            {column_name: 'lim_rus', pos:10,row:50},
            {column_name: 'sav_rus', pos:0,row:60},
            {column_name: 'lit_source', pos:0,row:70},
            {column_name: 'draf_rus', pos:0,row:80},
            {column_name: 'photo_path_f', row:90,
                path: (context) => `bio/`,
            },
            {column_name: 'photo_label_f', hidden: true},
            {column_name: 'photo_pth_d', row:90,hidden:true},
            {column_name: 'photo_label_d', hidden: true},
            {column_name: 'sys_group', hidden: true},
        ]];
        context.fieldsSet = [
            {title: 'Основная информация'}
        ];
        context.footerButtonNames = ['saveclose', 'save', 'delete', 'close'];
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record,parentRow) {
        if (!record) record=parentRow;
        context.title= `${((record['name_rus']) ? record['name_rus'] : 'вид ')} в ${((record['rb_name']) ? record['rb_name'] : '')}`;
        return context.title;
    }

}


