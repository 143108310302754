import React, {Component} from 'react';
import * as f from "../common/Funcs";
import ExtReactDOM, {Button, ExtNumberfield, FileField, FormPanel, Panel} from "@sencha/ext-react-modern";
import 'ol/ol.css';
import Map from 'ol/Map';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import {Circle as CircleStyle, Stroke, Style, Text} from 'ol/style';
import {Vector as VectorLayer} from 'ol/layer';
import View from 'ol/View';
import {defaults as defaultControls} from 'ol/control';
import {easeOut} from 'ol/easing';
import * as OlExtent from 'ol/extent';
import {getVectorContext as OlRender} from 'ol/render';
import {unByKey} from 'ol/Observable';
import * as Controls from "./MapControls"
import * as g from "../common/gisFuncs";
import {fromLonLat, getPointResolution, toLonLat, transform as Transform} from "ol/proj";
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import style from "../Maps/styles/base";
import selSt from "./styles/selection";
import flashSt from "./styles/flash";
import {Draw, Modify, Snap} from "ol/interaction";
import {DropButton, EditButton} from "../common/Buttons";
import {BaseTextButton} from "../Base/BaseButtons";
import landspolStyle from "./styles/landspol";
import admpolStyle from "./styles/admpol";
import landstyle from "./styles/landspol";
import admstyle from "./styles/admpol";
import {disableInteractions} from "./MapControls";

const keyName = "BaseMap";
let myMap;
const layerPosDefaults = {
    'plan': 5,
    'oopt': 5,
    'landspol': 10,
    'setl_eeko': 15,
    'admpol': 20,
    'eapol': 25,
    'features': 28,
    'dispol': 30,
    'routeslin': 31,
    'routes': 31,
    'passagelin': 35,
    'correctlin': 40,
    'points':45,
    'select': 50,
    'flash': 60,
    'important': 90,
    'goto': 100,
};
export default class BaseMap extends Component {
    static defaultProps = {
        mapButtonNames: [],
        layerNames: [{
            layerName: 'landspol', hidden: false, type: 'own', styleFunction: landspolStyle
        },
            {
                layerName: 'admpol', hidden: false, type: 'own', styleFunction: admpolStyle
            },],
        styleFunction: null,
        features: null,
        objects: null,
        appViewPort: null,
        parent: null,
        width: null,
        height: null,
        load: 'demaind'
    }

    constructor(props) {
        super(props);
        const context = this;
        if (props.styleFunction) context.styleFunction = props.styleFunction;
        context.coordinates = {lat: {d: 0, m: 0, s: 0}, lon: {d: 0, m: 0, s: 0}};
        context.state = {
            features: props.features || [],
            legendOn: true,
            currentTopo: 'OSM'
        }
    }

    componentDidMount() {
        const context = this;
        // const layers = Controls.getTopos('osm');
        //опциональный слой
        const layers = {layers: []};
        layers.layers.push(context.createFeaturesLayer(context, context.state.features));
        const controls = defaultControls().extend([...context.getBaseControls(), ...context.getAdditControls()]);
//        const vector
        //векторный для отрисовки
        if (window.IasConfig.devMode) console.debug(`${keyName} createMap`);
        const setMap = () => {
            const target = document.querySelectorAll(`div .map-${context.constructor.name.toLowerCase()}#${context.mapDiv.id}`)[0];
            if (!target) setTimeout(() => setMap(), 300);
            context.olMap = new Map({
                controls: controls,
                layers: layers.layers,
                // target: context.mapDiv,
                target: target,
                view: new View({
                    projection: 'EPSG:3857',
                    center: context.state.homeCenter || window.IasConfig.homeCenter,
                    zoom: 7,
                }),
            });
            window.olMap=context.olMap;
            context.setTopos('osm');
            context.olMap.mapControl = context;
            // if (context.props.objects) {
            //     const objectsIds = context.props.objects.map(o => o.properties.id);
            //     context.gotoFeatures({context: context, ids: objectsIds});
            // }
            context.olMap.on('click', function (evt) {
                if (context.select) context.olMap.removeInteraction(context.select);
            });
            context.olMap.once('postrender', () => {
                context.createSelectLayer(context);
                if (context.props.objects) {
                    context.addFeatures({context: context, geoJson: context.props.objects});
                    const objectsIds = context.props.objects.map(o => o.properties.id);
                    context.gotoFeatures({context: context, ids: objectsIds});
                }
                setTimeout(() => {
                    context.mapResize(context);
                }, 500);
            });
            context.olMap.getView().on('change:resolution', function() {
                context.olMap.getLayers().getArray().filter(l=>l.statusName=='topo').map(l=>l.getSource().refresh());
            });
        }
        setMap();
    }

    componentWillUnmount() {
        if (document.getElementsByClassName('ol-goto-container')[0]) document.getElementsByClassName('ol-goto-container')[0].remove();
    }

    getActualDate(context) {

        return document.getCookie('toFilter');
    }

    addButtons() {
        const context = this;
        context.topMenuButtons = [];
        const buttons = [];
        if (!context.props.mapButtonNames) debugger;
        context.props.mapButtonNames.map(nm => {
            context[`add${nm.firstToUpper()}Button`](buttons);
        })
        return buttons;
    }

    addEditButton(buttons) {
        const context = this;
        if (!context.isEditRight(context)) return buttons;
        if (!buttons) buttons = [];
        buttons[1] = (<EditButton key={'editButton'} pressed={(this.state.inEdit)} func={() => this.editTool()}/>);
    }

    addClearButton(buttons) {
        const context = this;
        if (!context.isEditRight(context)) return buttons;
        if (!buttons) buttons = [];
        buttons[2] = (<DropButton key={'dropButton'} func={() => this.clearTool()}/>);
    }

    getValues(file) {
        if (window.IasConfig.devMode) console.debug(`${keyName} getBaseValues`);
        let result = new FormData();
        result.append('file', file);
        return result;
    }

    addUploadButton(buttons) {
        const context = this;
        if (!context.isEditRight(context)) return buttons;
        if (!buttons) buttons = [];
        if (context.props.rowId) {
            buttons[3] = (
                <FormPanel
                    cls={'map-upload-form'} bodyCls={'map-upload-body'} key={'mapuploadForm'}
                    ref={f => context.form = f}
                    layout={'fit'}>
                    <FileField
                        key={`mapfile`}
                        labelAlign={'top'}
                        onChange={(field) => context.uploadTool(field, context)}
                        onClick={() => {
                            debugger;
                        }}
                        ref={(f) => context.upload}
                    />
                </FormPanel>)
        }
    }

    uploadTool(field, context = this) {
        const sub = context.props.parent;
        let res = (field.sender) ? field.sender.getFiles() :
            (field.currentTarget) ? field.currentTarget.files : null;
        if (!res || res.length == 0) return;
        const xxx = res["0"].name.substr(-3);
        if (!(xxx == 'zip' || xxx == 'gpx')) return f.alert('Можно загрузить shp (должен быть упакован в zip файл) или gpx файл. ');
        f.confirm(`Загрузить объект из файла ${res["0"].name}?`, (result) => {
            if (result) {
                context.file = res["0"];
                if (!context.file) return;
                f.setGeoData({
                    context: context,
                    tableName: sub.tableName,
                    id: (sub.props.rowId || sub.rowId),
                    //связь через таблицу связей
                    idName: sub.idName,
                    type: xxx,
                    values: context.getValues(context.file),
                    callback: () => {
                        sub.getData({context: sub})
                    }
                })
            }
        });
    }

    addDownloadButton(buttons) {
        if (!buttons) buttons = [];
        const context = this;

        if (context.props.rowId) {
            buttons[4] = (<BaseTextButton key={'shp'} name={'shp'} text={'shp'}
                                          func={() => context.downloadTool('shp', context)}/>)
            buttons[5] = (<BaseTextButton key={'gpx'} name={'gpx'} text={'gpx'}
                                          func={() => context.downloadTool('gpx', context)}/>)
        }
    }

    downloadTool(type, context = this) {
        const sub = context.props.parent;
        f.getGeoData({
            context: context,
            tableName: sub.tableName,
            id: (sub.props.rowId),
            type: type,
            // id: (sub.rowId),
            //связь через таблицу связей
            idName: sub.idName,
            callback: (result) => {
                f.flashMessages(result);
                if (!result.gpx) return;
                f.saveFile({
                    stringFile: result.gpx,
                    fileName: `${f.locale(sub.tableName)}_id_${sub.props.rowId}`,
                    // fileName: `${f.locale(sub.tableName)}_id_${sub.rowId}`,
                    fileType: 'gpx'
                })
            }
        })
    }

    //общая функция добавления и редактирования
    editTool(context = this) {
        const source = context.featuresSource;
        if (context.state.inEdit) {
            context.cancelTools();
            context.setState({inEdit: false});
        } else {
            context.setState({inEdit: true});
            if (source.getFeatures().length > 0) {
                context.modify = new Modify({
                    source: source,
                    deleteCondition: function (event) {
                        return event.originalEvent.altKey;
                    }
                });
                context.olMap.addInteraction(context.modify);
                context.snap = new Snap({source: source});
                context.olMap.addInteraction(context.snap);
                context.modify.on('modifyend', (event) => {
                    if (context.props.geomType == 'Point') {
                        context.cancelTools();
                        context.setState({inEdit: false});
                    }
                    let geoJson = g.toGeoJson(source.getFeatures()[0]);
                    console.log('debug ', geoJson);
                    context.setState({features: source.getFeatures()}, () => {
                        context.addFeatures({geoJson, context});
                        if (event.features)
                            context.setToField(event.features.getArray()[0]);
                        //для таблицы координат
                        if (context.state.coordinates) {
                            context.coords = context.state.features[0].getGeometry().getCoordinates().map(c => Transform(c, 'EPSG:3857', 'EPSG:4326'));
                            if (context.fillStore) context.fillStore(context);
                        }
                    });
                });
            } else {
                context.draw = new Draw({
                    source: source,
                    type: context.props.geomType || context.geomType,
                });
                context.olMap.addInteraction(context.draw);
                context.draw.on('drawend', (event) => {
                    const {feature, target, type} = event;
                    context.cancelTools();
                    let geoJson = g.toGeoJson(feature);
                    context.setState({features: [feature], inEdit: false});
                    context.addFeatures({geoJson, context});
                    context.setToField(event.feature);
                })
            }
        }        //поставить новый
        //записать в geom
    }

    //общая функция добавления и редактирования
    editnTool(context = this) {
        const source = context.featuresSource;
        const coordinates = {lat: {d: 0, m: 0, s: 0}, lon: {d: 0, m: 0, s: 0}};
        if (context.state.inEdit) {
            const getCoordinates = () => {
                const byParent = (parent) => {
                    return context.coordinates[parent].d.cmp.getValue() +
                        Number(context.coordinates[parent].m.cmp.getValue()) / 60 +
                        Number(context.coordinates[parent].s.cmp.getValue()) / 3600;
                }
                let x = byParent('lon');
                let y = byParent('lat');
                return [x, y];
            };
            context.cancelTools();
            //скрыть окно, отжать кнопку
            let p = document.getElementsByClassName('ol-goto-container');
            if (p.getArray().length > 0) p.getArray().map(i => i.remove());
            const coordinates = fromLonLat(getCoordinates());
            const feature = new Feature(new Point(coordinates));
            const source = context.olMap.getLayers().array_.find(l => l.id == 'select').getSource();
            source.clear();
            source.addFeature(feature);
            context.olMap.getView().setCenter(coordinates);

            // let geoJson = g.toGeoJson(feature);
            context.setState({features: [feature], inEdit: false});
            // context.addFeatures({geoJson, context});
            context.setToField(feature);
        } else {
            context.setState({inEdit: true});
            disableInteractions(context.olMap);
            //объекты - инпуты

            //изменения координат
            const changeCoord = (event) => {
                const name = event.sender.name;
                const parent = event.sender.getParent().name;
                const disable = (name) => {
                    coordinates[parent][name].cmp.disable();
                    coordinates[parent][name].cmp.setValue(null);
                };
                const enable = (name) => {
                    coordinates[parent][name].cmp.enable();
                }
                //отключаем, если десятичные
                if (name == 'd') {
                    if (event.newValue % 1 > 0) {
                        disable('m');
                        disable('s');
                    } else {
                        enable('m');
                        enable('s');
                    }
                }
                if (name == 'm') {
                    if (event.newValue % 1 > 0) {
                        disable('s');
                    } else {
                        enable('s');
                    }
                }
            };

            //контейнер попапа
            const getContainer = () => {
                let p = document.getElementsByClassName('ol-popup');
                if (p.getArray().length > 0) p.getArray().map(i => i.remove());
                p = document.getElementsByClassName('ol-goto-container');
                if (p.getArray().length > 0) p.getArray().map(i => i.remove());
                p = document.createElement('div');
                p.className = 'ol-goto-container no-pref';
                p.innerHTML = '<a href="#" class="ol-popup-closer hidden"/>' +
                    '<div class="ol-popup-content"></div>';
                if (context.props.gotoCls)
                    context.props.parent.form.cmp.element.dom.querySelector('.' + context.props.gotoCls).append(p);
                else
                    window.IasLoApp.rightPanel.panel.cmp.element.dom.append(p);
                // const closer = document.getElementsByClassName('ol-popup-closer').getArray()[0];
                // closer.onclick = function () {
                //     overlay.setPosition(undefined);
                //     closer.blur();
                //     disableGotoPoint(olMap);
                //     return false;
                // };
                return p;
            };
            //поле ввода координаты
            const getField = (name, parent) => <ExtNumberfield
                key={name} name={name} label={(name == 'd') ? 'градусы' : (name == 'm' ? 'минуты' : 'секунды')}
                cls={'filter-field number-field'}
                width={'30%'}
                decimals={2} minValue={0} maxValue={(name == 'd') ? 180 : 60}
                value={null}
                ref={fi => {
                    if (!fi) return;
                    coordinates[parent][name] = fi;
                }}
                onChange={changeCoord}
            />

            getContainer();
            const contentContainer = document.getElementsByClassName('ol-goto-container').getArray()[0];
            const content = <Panel layout={'vbox'} title={'Ввод координат'}>
                <Panel title={'С.Ш.'} layout={'hbox'} name={'lat'} key={'lat'}>
                    {[getField('d', 'lat'), getField('m', 'lat'), getField('s', 'lat')]}
                </Panel>
                <Panel title={'В.Д.'} layout={'hbox'} name={'lon'} key={'lon'}>
                    {[getField('d', 'lon'), getField('m', 'lon'), getField('s', 'lon')]}
                </Panel>
                <Panel layout={'hbox'} height={'30px'} name={'b'} key={'b'}>
                    <Button name={'goto'} key={'goto'}
                            handler={() => context.editeTool(context)} text={'Применить'}
                            centered={true} ui={window.IasConfig.ui}
                    /></Panel>
            </Panel>;
            ExtReactDOM.render(content, contentContainer);
            if (source.getFeatures().length > 0) {
                context.modify = new Modify({
                    source: source,
                    deleteCondition: function (event) {
                        return event.originalEvent.altKey;
                    }
                });
                context.olMap.addInteraction(context.modify);
                context.snap = new Snap({source: source});
                context.olMap.addInteraction(context.snap);
                context.modify.on('modifyend', (event) => {
                    if (context.props.geomType == 'Point') {
                        context.cancelTools();
                        context.setState({inEdit: false});
                    }
                    let geoJson = g.toGeoJson(source.getFeatures()[0]);
                    console.log('debug ', geoJson);
                    context.setState({features: source.getFeatures()}, () => {
                        if (event.features)
                            context.setToField(event.features.getArray()[0]);
                        context.addFeatures({geoJson, context});
                        //для таблицы координат
                        if (context.state.coordinates) {
                            context.coords = context.state.features[0].getGeometry().getCoordinates().map(c => Transform(c, 'EPSG:3857', 'EPSG:4326'));
                            if (context.fillStore) context.fillStore(context);
                        }
                    });
                });
            } else {
                context.draw = new Draw({
                    source: source,
                    type: context.props.geomType || context.geomType,
                });
                context.olMap.addInteraction(context.draw);
                context.draw.on('drawend', (event) => {
                    const {feature, target, type} = event;
                    context.cancelTools();
                    let geoJson = g.toGeoJson(feature);
                    context.setState({features: [feature], inEdit: false});
                    context.addFeatures({geoJson, context});
                    context.setToField(event.feature);
                })
            }
        }        //поставить новый
        //записать в geom
    }

    setToField(feature) {
        const context = this;
        if (!context.geomField) return;
        if (!feature)
            return context.geomField.cmp.setValue(null);

        const coords = feature.getGeometry().getCoordinates();
        let object = context.geomField.cmp.getValue();
        if (object) object = JSON.parse(object);
        else object = {
            type: feature.getGeometry().getType(),
            crs: {"type": "name", "properties": {"name": "urn:ogc:def:crs:EPSG:3857"}}
        };
        object.coordinates = coords;
        context.geomField.cmp.setValue(JSON.stringify(object));
    }

    //очистить привязку
    clearTool(context = this) {
        const p = context.props.parent || context.props.rowId;
        f.confirm(`Удалить геометрию объекта?`, (confirm) => {
            if (confirm) {
                const callback = (result) => {
                    if (result.state) {
                        context.olMap.mapControl.featuresSource.clear();
                        context.olMap.mapControl.selectSource.clear();
                    }
                }
                g.deleteGeomById({context, id: p.rowId, tableName: p.tableName, idName: p.idName, callback});
            }
        });

    }

    dropTool(context = this) {
        const p = context.props.parent || context.props.rowId;
        f.confirm(`Удалить геометрию объекта?`, (confirm) => {
            if (confirm) {
                const emit = (send) => (this.props?.onRemoveFeatures) ? this.props.onRemoveFeatures(send) : null;
                context.setToField(null);
                context.olMap.mapControl.featuresSource.clear();
                context.olMap.mapControl.selectSource.clear();
                emit({sender: context, layerName: 'features'});
            }
        });

    }

    cancelTools() {
        const context = this;
        try {
            if (context.olMap.interactions.getArray().filter(i => i == context.modify).length > 0) context.olMap.removeInteraction(context.modify);
        } catch {
        }
        try {
            if (context.olMap.interactions.getArray().filter(i => i == context.draw).length > 0) context.olMap.removeInteraction(context.draw);
        } catch {
        }
        try {
            if (context.olMap.interactions.getArray().filter(i => i == context.snap).length > 0) context.olMap.removeInteraction(context.snap);
        } catch {
        }
    }

    update(context) {
        if (!context) context = this;
        const record = context.props.record;
        context.setState({buttons: context.addButtons()});
        context.props.layerNames.map(l => {
            const loadLayers = (result, apply = true) => {
                context.setVectorLayer({
                    layerName: l.layerName,
                    styleFunction: l.styleFunction,
                    layerObjects: result,
                    hidden: l.hidden,
                    type: l.type || 'own',
                    apply: apply,
                    context: context
                });
                context.olMap.render();
                if (l.goto && result?.features) {
                    /*!!!*/
                    context.gotoFeatures({
                        context, features: result?.features
                    });
                }
            };
            switch (l.type) {
                case 'own':
                    let filter = l.filter;
                    if (filter && record) Object.keys(record).map(k => filter = filter.replace(`$${k}$`, record[k]));
                    loadLayers(null, true);
                    const actualDate = (['route', 'pass', 'erase', 'ea'].some(w => l.layerName.indexOf(w) > -1)) ? `06.06.${document.getCookie('yearFilter')}` : context.getActualDate(context);
                    g.getGisData({
                        context: context, tableName: l.layerName, filter,
                        actualDate, callback: loadLayers
                    });
                    break;
                default:
                    context.setExtLayers(l.layerName);
                    break;
            }
        });
    }

    getHeight() {
        return f.getCentralHeight();
    }

    getScreenResolution() {
        const devicePixelRatio = window.devicePixelRatio || 1;
        const elements = document.getElementsByClassName('dpi');
        if (elements.length == 0) return 96;
        else return elements[0].offsetHeight * devicePixelRatio;
    }

    /*карту в определенный масштаб*/
    setScale(context, scale = 50) {
        const map = context.olMap;
        const screenResolution = context.getScreenResolution();
        const pointResolution = getPointResolution(
            map.getView().getProjection(),
            screenResolution / 25.4,
            map.getView().getCenter()
        );
        const scaleResolution = scale / pointResolution;
        map.getView().setResolution(scaleResolution);
    }

    /*выбор новой топоосновы*/
    setTopos(topoName) {
        const context = this;
        const olMap = context.olMap;
        let i = 1;
        //чистим от предыдущей
        while (i) {
            let topo = olMap.getLayers().getArray().filter(l => l.statusName == 'topo')[0];
            if (topo) {
                try {
                    olMap.removeLayer(topo);
                } catch (e) {
                }
            } else i = 0;
        }
        //вставляем нововую
        if (topoName) {
            const newLayers = Controls.getTopos(topoName);
            newLayers.layers.map(l => olMap.getLayers().getArray().unshift(l));
            //обновить легенду
            if (context.state.legendOn) {
                const childs = [];
                context.props.appViewPort.setState({
                    LeftPanel: ['']
                }, () => {
                    newLayers.legends.map((l, idx) => {
                        const callback = (result) => {
                            if (result.html) {
                                childs.push(<Panel key={`legend${idx}`} name={`legend${idx}`} className={'legend-sub'}
                                                   html={result.html}/>);
                                context.props.appViewPort.setState({
                                    LeftPanel: [
                                        <Panel key={'leftLegend'} name={'leftLegend'}
                                               bodyCls={'left-legend'}>{childs}</Panel>
                                    ]
                                });
                            }
                        }
                        if (l.url) {
                            g.getLegend({context: context, url: l.url, callback: callback});
                        } else {
                            callback(l);
                        }
                    })
                });

            }
            //обновить карту
            olMap.render();
        }
    }

    /*выбор новой топоосновы*/
    setExtLayers(layerName) {
        const context = this;
        const olMap = context.olMap;
        let i = 1;
        //чистим от предыдущей
        while (i) {
            let lyr = olMap.getLayers().getArray().filter(l => l.name == layerName)[0];
            if (lyr) {
                try {
                    olMap.removeLayer(lyr);
                } catch (e) {
                }
            } else i = 0;
        }
        //вставляем нововую
        if (layerName) {
            const newLayers = Controls.getLayer(layerName);
            newLayers.layers.map(l => olMap.getLayers().getArray().push(l));
            //обновить карту
            olMap.render();
        }
    }

    /**/

    /*векторный слой*/
    setVectorLayer(params) {
        //зачем apply? если false, карта не отрисовывает объекты
        let {layerName, layerObjects, styleFunction, apply, hidden, context, append, features, pos} = params;
        if (window.IasConfig.devMode) console.debug(`${keyName} setVectorLayer`, context, layerName, layerObjects);
        if (layerObjects) {
            const featureCollection = context.createFeatureCollection((layerObjects) ? layerObjects : []);
            features = context.createFeatures(featureCollection);
        }
        if (!context[`${layerName}Source`]) {
            context[`${layerName}Source`] = new VectorSource({
                // features: features
            });
        } else {
            if (!append) context[`${layerName}Source`].clear();
        }
        if (features) context[`${layerName}Source`].addFeatures(features);

        if (!context[`${layerName}Layer`]) {
            context[`${layerName}Layer`] = new VectorLayer({
                source: context[`${layerName}Source`],
                visible: !(hidden),
                style: styleFunction || context.styleFunction,
            });
            // if (pos||pos==0) context[`${layerName}Layer`].setZIndex(pos);
            context[`${layerName}Layer`].id = layerName;
        }
        if (!pos) pos = layerPosDefaults[layerName];
        if (!pos) pos = 40;
        if (pos) context[`${layerName}Layer`].pos=pos;
        if (apply && context.olMap.getLayers().getArray().filter(l => l.id == layerName).length == 0) {



            // const layers = context.olMap.getLayers().getArray();
            //  let lastLayers = ['features', 'select', 'flash'];
            //   let lastLayers = ['select', 'flash'];
            //   if (layerName == 'features') lastLayers = ['select', 'flash'];
            //   if (layerName == 'select') lastLayers = ['flash'];
            //   let idx =pos|| layers.findIndex(l => lastLayers.indexOf(l.id) > -1);
            //   if (idx == -1)
            context.olMap.getLayers().getArray().push(context[`${layerName}Layer`]);
            context.orderLayers(context);
            //   else
            //       context.olMap.getLayers().getArray().splice(idx, 0, context[`${layerName}Layer`]);
        }
        return context[`${layerName}Layer`];
    }

    /*сортировка слоев по позициям*/
    orderLayers(context) {
        const ls=        context.olMap.getLayers().getArray();
        ls.map(l=>(!l.pos)?l.pos=layerPosDefaults[l.id]:'');
        ls.filter(l=>l?.setZIndex&&l.pos)
            .sort((a, b) => a.pos - b.pos)
        .map(l => l.setZIndex(l.pos));
    }

    /*переключение видимости слоя*/
    toggleLayer(params) {
        const {context, layerName} = params;
        let {visible} = params;
        const layerArray = context.olMap.getLayers().getArray().filter(l => l.id == layerName);
        if (layerArray.length == 0) return;
        const layer = layerArray[0];
        if (!f.exist(visible)) {
            visible = !layer.getVisible();
        }
        layer.setVisible(visible);
        return visible;
    }

    /*векторый слой объектов*/
    createFeaturesLayer(context, objects) {
        return context.setVectorLayer({
            layerName: 'features',
            context: context,
            layerObjects: objects || context.props.objects
        });
    }

    /*векторный слой для выделения*/
    createSelectLayer(context) {
        return context.setVectorLayer({
            layerName: 'select',
            context: context,
            styleFunction: context.selectionStyle,
            apply: true
        });
    }

    /*векторный слой для вспышек*/
    createFlashLayer(context) {
        return context.setVectorLayer({
            layerName: 'flash',
            styleFunction: context.flashStyle,
            context: context
        });
    }

    /*оформление набора объектов в коллекцию geoJson*/
    createFeatureCollection(objects) {
        if (objects.type && objects.type == 'FeatureCollection') return objects;
        if (Array.isArray(objects) == false) {
            objects = [objects]
        }
        ;
        return {
            'type': 'FeatureCollection',
            'crs': {
                'type': 'name',
                'properties': {
                    'name': 'EPSG:3857',
                },
            },
            'features': objects
        }
    }

    createFeatures(collection) {
        const emit = (send) => (this.props?.onCreateFeatures) ? this.props.onCreateFeatures(send) : null;

        if (!(collection.type == 'FeatureCollection')) {
            try {
                collection = myMap.createFeatureCollection(collection);
            } catch {
                debugger;
                return [];
            }
        }
        try {
            if (collection.features.length == 0) return [];
            else {
                const result = new GeoJSON().readFeatures(collection);
                emit({sender: this, data: result});
                return result;
            }
        } catch (e) {
            console.error(e);
            debugger;
            emit({sender: this, error: e, data: []});
            return [];
        }
    }

    addFeatures(params) {
        let {geoJson, context, styleFunction, layerName = 'features', features,append} = params;
        if (!context) console.error('нет контекста');
        if (!geoJson && !features) geoJson = params;
        const emit = (send) => (this.props?.onAddFeatures) ? this.props.onAddFeatures(send) : null;
        if (window.IasConfig.devMode) console.debug(`${keyName} addFeatures`, params);
        if (geoJson) {
            const featureCollection = context.createFeatureCollection(geoJson);
            features = context.createFeatures(featureCollection);
        }
        // if (!context[`${layerName}Source`])
            context.setVectorLayer({
            context,
            layerName,
            features,
            styleFunction,
            apply: true,
            append
        })
        // else {
        //     context[`${layerName}Source`].addFeatures(features);
        //     if (styleFunction) context[`${layerName}Layer`].setStyle(styleFunction);
        // }
        context.olMap.render();
        emit({sender: context, data: features, layerName});
        return features;
    }

    /*выбрать объект по id*/
    getFeatureById(params) {
        let {id, layerName = 'features', context} = params;
        if (window.IasConfig.devMode) console.debug(`getFeatureById`, params);
        if (!context) console.error('нет контекста');
        if (!id) id = params;
        let ff;
        if (id)
            ff = context[`${layerName}Source`].getFeatures().filter(f => f.getProperties().id == id || f.getProperties().gid == id);
        if (ff && ff.length > 0) return ff[0]; else return null;
    }

    getFeaturesByFilter(params) {
        let {filter, layerName = 'features', context} = params;
        if (window.IasConfig.devMode) console.debug(`getFeaturesByFilter`, params);
        if (!context) console.error('нет контекста');
        if (!filter) filter = params;
        let ff;
        if (filter) {
            ff = context[`${layerName}Source`].getFeatures().filter(f => {
                const p = f.getProperties();
                return Object.keys(filter).every(k =>
                    p[k] == filter[k]
                )
            })
        }
        return ff;
    }

    /*очистить контейнер объектов*/
    clearFeatures(params) {
        let {context, layerName = 'features'} = params;
        if (!context) {
            console.error('нет контекста');
            context = this;
        }
        if (window.IasConfig.devMode) console.debug(`${keyName} clearFeatures`);
        if (context[`${layerName}Source`]) context[`${layerName}Source`].clear();
    }

    /*удаление объекта по id*/
    removeFeatureById(params) {
        let {id, layerName, context} = params;
        if (!context) console.error('нет контекста');
        if (!id) id = params;
        window.alert('under construction');
    }

    /*фокус на объектах, выбранных по idшкам*/
    gotoFeatures(params) {
        if (!params) return;
        let {ids, features, zoom, context, layerName = 'features'} = params;
        if (!context) console.error('нет контекста');
        if (window.IasConfig.devMode) console.debug(`${keyName} gotoFeatures`, params);
        if (!features && !ids) {
            features = context[`${layerName}Source`].getFeatures();
        }
        if (ids && !Array.isArray(ids)) ids = [ids];
        let coords = [];
        if (ids && !features) {
            features = [];
            ids.map(id => {
                const feature = context.getFeatureById({id: id, context: context});
                if (feature)
                    features.push(feature);
            })
        }
        if (features.length == 0) return;
        if (!features[0]?.getGeometry) {
            features = context.createFeatures(features);
        }
        features.map(f => {
            if (f?.getGeometry && f.getGeometry()) coords.push(f.getGeometry().getCoordinates())
        });
        if (coords.length == 0) return;
        const view = context.olMap.getView();
        while (!(coords[0].length == 2 && !isNaN(coords[0][0]))) {
            coords = coords.reduce((a, b) => {
                return [...a, ...b]
            }, []);
        }
        try {
            const extent = OlExtent.boundingExtent(coords);
            if (!extent) return false;
            const point = OlExtent.getCenter(extent);
            view.setCenter(point);
            if (zoom) view.setZoom(zoom);
            else {
                const resol = view.getResolutionForExtent(extent);
                if (resol)
                    view.setResolution(resol);
                else context.setScale(context);
            }
        } catch {
            debugger;
            return false;
        }
    }

    /*фокус на объектах, выбранных по idшкам*/
    changeFeatures(params) {
        let {changes, context = this, layerName = 'features'} = params;
        if (window.IasConfig.devMode) console.debug(`${keyName} changeFeatures`);
        if (!changes) return;
        if (!Array.isArray(changes)) changes = [changes];
        changes.map(f => {
            if (f?.id) {
                const feature = context.getFeatureById({id: f.id, context, layerName});
                feature.setProperties(f);
            }
        });
        context.olMap.render();
        // const view = context.olMap.getView();
    }

    /*добавить точку и перейти к ней*/

    /*недоделано*/
    gotoPoint(params) {
        let {x, y, context, styleFunction, text} = params;
        if (!context) console.error('нет контекста');
        if (!x || !y) console.error('нет координат');
        if (!styleFunction) styleFunction = () => {
            const color = 'rgba(0,225,225,1)';
            return new Style({
                image: new CircleStyle({
                    radius: 10,
                    fill: null,
                    stroke: new Stroke({
                        color: color,
                        width: 1,
                    }),
                }),
                text: new Text({
                    text: text,
                    offsetX: 10,
                    offsetY: -10,
                    stroke: new Stroke({
                        color: color,
                        width: 1
                    })
                })
            })
        };
        const point = new Feature(new Point([x, y]));
        context.setVectorLayer({
            layerName: 'goto',
            context: context,
            styleFunction: styleFunction,
            layerObjects: [point],
            apply: true
        })
    }

    mapResize(context) {
        if (!context) context = this;
        if (!context.olMap) return console.error('нет контекста');
        if (window.IasConfig.devMode) console.debug(`${keyName} map resize`);
        context.olMap.updateSize();
    }

    /*выделение по набору объектов или набору id*/
    select(params) {
        let {features, ids, context, clear = true, filter} = params;
        if (window.IasConfig.devMode) console.debug(`${keyName} select`, params);
        if (!context) console.error('нет контекста');

        // if (!features && !ids) features = context.featureSo;
        if ((!features) && ids) {
            if (!Array.isArray(ids)) ids = [ids];
            features = ids.map(id_ => context.getFeatureById({id: id_, context: context})).filter(f => f !== null);
        }
        if (filter)
            features = context.getFeaturesByFilter({filter: filter, context: context}).filter(f => f !== null);

        if (!context.selectedLayer) context.selectedLayer = context.createSelectLayer(context);

        if (!context.selectedSource) context.selectedSource = context.selectedLayer.getSource();

        if (clear) context.selectedSource.clear();
        if (!features[0]?.getGeometry) {
            features = context.createFeatures(features);
        }
        context.selectedSource.addFeatures(features);
        context.olMap.render();
    }

    /*очистить выделение*/
    clearSelection(context) {
        // let {context} = params;
        if (window.IasConfig.devMode) console.debug(`${keyName} select`, context);
        if (!context) console.error('нет контекста');
        if (context.selectedSource)
            context.selectedSource.clear();
    }

    /*вспышка по набору объектов или набору id */
    flash(params) {
        let {features, ids, context} = params;
        if (!context) console.error('нет контекста');
        if (window.IasConfig.devMode) console.debug(`${keyName} flash`, params);
        if ((!features || features.length == 0) && ids)
            features = ids.map(id_ => context.getFeatureById({id: id_, context: context})).filter(f => f !== null);
        if (!Array.isArray(features)) features = [features];
        context.createFlashLayer(context);
        if (!features[0]?.getGeometry) {
            features = context.createFeatures(features);
        }
        const start = new Date().getTime();
        const animate = (feature, event) => {
            const vectorContext = OlRender(event);
            const frameState = event.frameState;
            const flashGeom = feature.getGeometry().clone();
            const elapsed = frameState.time - start;
            const elapsedRatio = elapsed / duration;
            // radius will be 5 at start and 30 at end.
            const radius = easeOut(elapsedRatio) * 25 + 5;
            const opacity = easeOut(1 - elapsedRatio);

            const style = new Style({
                image: new CircleStyle({
                    radius: radius,
                    stroke: new Stroke({
                        color: 'rgba(255, 0, 0, ' + opacity + ')',
                        width: 0.25 + opacity,
                    }),
                }),
                stroke: new Stroke({
                    color: 'rgba(255, 0, 0, ' + opacity + ')',
                    width: 0.25 + opacity,
                }),
            });

            vectorContext.setStyle(style);
            vectorContext.drawGeometry(flashGeom);
            if (elapsed > duration) {
                unByKey(listenerKey);
                return;
            }
            // tell OpenLayers to continue postrender animation
            context.olMap.render();
        }
        const listenerKey = context.flashLayer.on('postrender', animate);
        const duration = 300;
    }

    //подключение базовых контролов карты
    getBaseControls() {
        return [Controls.getMousePosition(), Controls.getScaleControl(), Controls.getBasemapToggle(), Controls.getPdfTool(), Controls.getInfo(), Controls.getGoHome(), Controls.getLineMeasure(), Controls.getLayersToggle()];
    }

//подключение индивидуальных контролов
    getAdditControls() {
        return [];
    }

    styleFunction(feature, resolution) {
        return style(feature, resolution);
    }

    selectionStyle(feature, resolution) {
        return selSt(feature, resolution);
    }

    flashStyle(feature, resolution) {
        return flashSt(feature, resolution);
    }

    defaultsInteractions(context) {
        Controls.disableInteractions(context.olMap);
    }

    render() {
        const context = this;
        myMap = context;
        const height = context.props.height || context.getHeight();
        const width = context.props.width || (f.getCentralWidth() / 2);
        return <Panel
            layout={'fit'}
            key={`mapPanel${keyName}`}
            name={`mapPanel${keyName}`}
            className={'map-panel'}
            width={width}
            height={height}
            ref={p => (p) ? (context.cmp = p.cmp) : null}
        >
            <div className={`map-${context.constructor.name.toLowerCase()}`} style={{height: height, width: width}}
                 ref={(m) => context.mapDiv = m}/>
        </Panel>;
    }
}

