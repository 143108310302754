import ErrorBoundary from "../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../Base/BaseSubGridPanel";
import * as f from "../common/Funcs";

const keyName = `LandsWithEcoSub`;

export default class LandsWithEcoSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.gridName=`LandsWithEcoSubGrid`;
        context.topMenuButtonNames = (props.topMenuButtonNames) ? props.topMenuButtonNames : ['reload','edit'];
    }

    //кнопки для меню
    addAdditButtons() {
        const context = this;
        //добавление новой записи
        const ecoAddFunc = () => {
            context.grid.tableName='economies';
            document.setCookie(context.grid.tableName, null);
            context.rowId = null;
            context.showEditPanel(context);
        };
        context.topMenuButtons.push({iconCls: 'x-fa fa-plus',
            html:`<label class="menu-label">Добавить</label>`,
            handler: ecoAddFunc})
   //     context.topMenuButtons.push({iconCls: 'x-fa fa-plus', handler: landAddFunc})
        //если есть поле "активный" чекбокс - показать все
        context.addShowAll();
    }

    //кнопка редактирования, только для single mode
    addEditButton() {
        const context = this;
        const editFunc = () => {
            context.grid.tableName='economies';
            context.rowId = context.grid.getFirstRow()['economyid'];
            context.showEditPanel(context);
        };
        context.topMenuButtons.push({
            iconCls: 'x-fa fa-edit',
            html:`<label class="menu-label">Изменить</label>`,
            handler: editFunc,
            hidden: !(context.grid && context.grid.state.singleMode)
        })
    }

    //перейти на страницу редактирования
    showEditPanel(context) {
        if (!Boolean(context.subElementName)) return console.debug(`${keyName} showEditPanel: no subName`)
        // собирает ограничения для формы редактирования из грида - родительское значение поля
        const gridFilter = context.grid.getFilterStringFromRow().split('&');
        if (window.IasConfig.devMode) console.debug(`${keyName} showEditPanel`, gridFilter);
        context.setState({
            //применяется фильтр, указанный для саба в описании в родительском объекте
            pageDefaults: gridFilter.map(pair => {
                const ar = pair.replace(' ','').split('=');
                return {column_name: ar[0], value: ar[1], disabled: true};
            }), showEditPanel: true
        }, () => (context.dialog) ? context.dialog.cmp.toFront() : '');
        const parentRow=context.props.parent.state.data;
        f.getTitle(context,parentRow);
        if (context.editForm) {
            //          context.editForm.getData(context.editForm, context);
            context.editForm.rowId = context.rowId;
            context.editForm.getData({context:context.editForm});
        }
    }


}

