import ErrorBoundary from "../../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../../Base/BaseSubGridPanel";

const keyName = `SpecLitSub`;

export default class SpecLitSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.subElementName = null;
        context.gridName=`SpecLitSubGrid`;
    }

    //заголовок окна
    setDialogTitle(context) {
//        context.dialog.cmp.setTitle(`Краснокнижное описание вида ${context.grid.getCurrentRow()[`name_rus`]}`);
    }

}

