import ErrorBoundary from "../../ErrorBoundary";
import React from 'react';
import {Panel} from '@sencha/ext-react-modern';
    import * as f from "../../common/Funcs";
import BasePage from "../../Base/BasePage";
import Grids from "../../collections/gridElements";
import BaseSubGridPanel from "../../Base/BaseSubGridPanel";

const Ext = window['Ext'];
const keyName = `TestSubPanel`;

export default class TestSubPanel extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.gridName = "TestsSubGrid";
        context.idName = 'object_id';
        context.rowId = props.rowId;
        // context.rowId = props.rowId;
        context.tableName = props.tableName;
        context.testName = props.testName;
        // context.hiddenColumns = ['object_id','test_object','test_year'];
        // context.columnsDefaults = [{column_name:'state',pos:0},{column_name:'test_label',pos:1,}];
        context.state = {
            data: [],
        };
        context.store = Ext.create('Ext.data.Store', {
            data: context.state.data,
        });
        context.topMenuButtonNames=['reload','repeat'];
        /*context.topMenuButtons=[
             {
             iconCls: 'x-fa fa-refresh',
             tooltip: f.locale('reload'),
             //запрос данных без пересчета тестов
             handler: () => context.grid.getData({context:context.grid})
         },
             {
                 iconCls: 'x-fa fa-repeat',
                 tooltip: f.locale('repeat'),
                 //ззапрос данных с пересчетом тестов
                 handler: () => context.grid.getData({context:context.grid, repeat:true})
             }
         ];*/
    }


    // генерация таблицы
    getGridw(context) {
        const p= {el:Grids[context.gridName ]};
        return React.createElement(p.el, {
            parent: context,
            rowId:context.props.rowId,
            // rowId:context.props.rowId,
            appViewPort: context.props.appViewPort,
            tableName: context.props.tableName,
            testName:context.props.testName,
            rowHeight:context.props.rowHeight,
            key: context.props.tableName,
            name: context.props.tableName,
            ref: (g) => {
                if (g) context.grid = g
            }
        })
    }

    render() {
        const context = this;
        const elements = [];
        const grid = context.getGrid(context);
        context.addMenuButtons();
        elements.push(grid);
        return (<Panel
            region={"center"} margin="3"
            layout={'fit'}
            title={context.props.title}
            cls={'edit-window-panel'}
            collapsible={{
                direction: 'top',
                dynamic: true
            }}
            collapsed={context.props.collapsed}
            //   height={'100%'}
            titleCollapse={true}
            key={'gridPagePanel'}
            tools={context.topMenuButtons}
            ref={p => {
             if   (p) {
                 context.subPanel = p.cmp;
             } else {
                 context.subPanel = null;
             }
            }}
        >
            {elements}
        </Panel>)
    }
}

