import ErrorBoundary from "../../../ErrorBoundary";
import BaseZmuAnalizePage from "../../../Base/ZMU/BaseZmuAnalizePage";
import * as z from "../../../common/zmuFuncs";

const keyName = `ZmuEaAnalizePage`;
const title=`Зимний маршрутный учет. Анализ учета по исследуемым территориям.`;
export default class ZmuEaAnalizePage extends BaseZmuAnalizePage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        z.setYear(context);
        if (!document.getCookie('yearFilter')) {
            const year = (new Date()).getFullYear() + ((new Date()).getMonth() > 5 ? 1 : 0);
            if (context.filters.YearFilter) context.filters.YearFilter.setValue(year);
        }
        context.gridName="PasEaAnalizGrid";
        context.subElementName = 'PasEaPage';
        context.tableName = 'explore_areas';
    }
}
