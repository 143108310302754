import React, {Component} from 'react';
import {Button, ToolTip} from "@sencha/ext-react-modern";
import * as f from "../common/Funcs";
import ErrorBoundary from "../ErrorBoundary";

//типовые кнопки с иконкой и текстомы
export class
BaseButton extends Component {
    static defaultProps = {
        name: 'button',
        iconCls: 'fa-exclamation',
        func: () => {
        }
    }
    render() {
        const context=this;
        const props = this.props;

        return (<ErrorBoundary  key={`error${props.name}`}><Button
            ui={window.IasConfig.ui}
            key={props.name}
            name={props.name}
            cls={`no-print ${(this.props.pressed)?'is-pressed':''}`}
            iconCls={`x-fa ${props.iconCls}`}
            tooltip={props.tooltip||f.locale(props.name)}
            handler={props.func}
            text={props.text}
            listeners={{
                mouseover:{
                    element:'buttonElement',
                    fn:(e)=>{
                        setTimeout(() => {
                                const isFocused = e.currentTarget.matches(':hover');
                                if (isFocused) {
                                    f.showTip(context,f.locale(props.name)||props.text,e);
                                }
                            }, 1000
                        );
                    }
                },
                mouseout:{
                    element:'buttonElement',
                    fn:(e)=>{
                       f.hideTip();
                    }
                }
            }}
        >
        </Button></ErrorBoundary>)
    }
}

export class BaseTextButton extends Component {
    static defaultProps = {
        name: 'button',
        iconCls: 'fa-exclamation',
        text: 'button text',
        func: () => {
        }
    }

    render() {
        const context=this;
        const props = this.props;
        return (<ErrorBoundary  key={`error${props.name}`}><Button
            ui={window.IasConfig.ui}
            key={props.name}
            name={props.name}
            cls={'no-print'}
            text={props.label|| f.locale(props.text)}
            ref={(b) => (b)?context.cmp = b.cmp:null}
            handler={props.func}
            hidden={props.hidden}
            listeners={{
                mouseover:{
                    element:'buttonElement',
                    fn:(e)=>{
                        setTimeout(() => {
                                const isFocused = e.currentTarget.matches(':hover');
                                if (isFocused) {
                                    f.showTip(context,f.locale(props.name),e);
                                }
                            }, 1000
                        );
                    }
                },
                mouseout:{
                    element:'buttonElement',
                    fn:(e)=>{
                        f.hideTip();
                    }
                }
            }}>
        </Button></ErrorBoundary>)
    }
}

