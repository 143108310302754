import BaseGridPage from "../../Base/BaseGridPage";
import React from "react";
import {BaseButton} from "../../Base/BaseButtons";
import {
    Button,
    ExtTextareafield,
    FormPanel,
    Textfield,
    Toolbar
} from "@sencha/ext-react-modern";
import * as f from "../../common/Funcs";
import DialogForm from "../../common/DialogForm";

const Ext = window['Ext'];


//управление пользователями и правами
const keyName = `UsersAdminPage`;
export default class UsersAdminPage extends BaseGridPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.title = `список пользователей`;
        context.gridName = "UsersAdminGrid";
        context.subElementName = 'UserPage';
        context.tableName = 'users';
        context.idName = 'user_id';
        context.titleName = 'hu';
        context.hiddenColumns = [context.idName];
        context.topMenuButtonNames = ['reload', 'xls', 'print', 'add', 'clipboard'];

        //фильтры в правой части
        context.filters = [
            {name: 'ac',pos:1, required: false,defaultValue:true}, {name: 'fio',pos:2, required: false},{name:'role',pos:3,required: false}];

    }

    addAdditButtons() {
        const context = this;

        context.topMenuButtons.push(<BaseButton iconCls={'fa-envelope'} key={'email'}  func={() => {
            context.showSendPanel(context);
        }}/>);

    }
    /*перейти на страницу редактирования*/
    showSendPanel(context) {
        if (window.IasConfig.devMode) console.debug(`${keyName} showSendPanel`);
        context.setState({showSendPanel: true});
    }

    /*перейти на страницу редактирования*/
    hideSendPanel(context) {
        if (window.IasConfig.devMode) console.debug(`${keyName} hideSendPanel`);
        context.setState({showSendPanel: false});
    }

    getDialog(context) {
        const send = (sender) => {
            const data = Ext.ComponentQuery.query("formpanel")[0].getValues();
            data.email = context.grid.store.getData().items.map(i=>i.data.email);
            const requestOptions = {
                method: 'POST',
                //     mode:'no-cors',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(data)
            };
            document.setCookies();
            fetch(`${window.IasConfig.homePath}users/send`,
                requestOptions
            ).then(res => {
                    return res.json();
                }
            )
                .then(
                    (result) => {
                        context.hideSendPanel(context);
                        f.flashMessages(result);
                    },
                    (error) => {
                        debugger;
                        return error;
                    })
        };
        const form=<FormPanel method={"POST"} key={"emailForm"} id={"emailForm"}  width={'800px'}>
            <Textfield id={'subjectField'} name={'subject'} label="Заголовок"
                       required
                       placeholder={`Это обязательное поле. Напишите слово "регистрация" для повторной отправки сообщения о регистрации.`}/>
            <ExtTextareafield name="textfield" id={'textField'} label="Текст письма"
                              placeholder="Это обязательное поле"  height={'90%'}
            />
            <Toolbar shadow={false} docked="bottom" layout={{type: 'hbox', pack: 'right'}}>
                <Button text="Отправить всем в списке" handler={()=> {
                    send();
                    context.hideSendPanel(context)
                }}/>
                <Button text="Отмена" handler={()=>context.hideSendPanel(context)}/>
            </Toolbar>
        </FormPanel>;
        const dialog = <DialogForm showEditPanel={context.state.showSendPanel} editFormEl={form} propName={'emailDialog'} title="Почта"
                                   key={'emailDialog'} parent={context}/>
        // window.dialogs.push(dialog);
        return [dialog,super.getDialog(context)];

    }


    // update(context){
    //     if (context.grid) context.grid.getData(context.grid);
    // }
    // //кнопки для меню
    // addMenuButtons(props){
    //     const context=this;
    //     if (window.IasConfig.devMode) console.debug(`${keyName} addMenuButtons`,  props,context.props.rights);
    //     context.addAddButton();
    // }


}