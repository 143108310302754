import BaseEditByMapPage from "../../Base/BaseEditByMapPage";
import * as f from "../../common/Funcs";
import {PrintButton} from "../../common/Buttons";
import ErrorBoundary from "../../ErrorBoundary";

import React from "react";
import {callPath} from "../../common/Funcs";

//общая информация
//пользователь видит, только если вводил сам.
const keyName = "BaseEditAccPage";
export default class BaseEditAccPage extends BaseEditByMapPage {
    static defaultProps = {
        //minWidth:'900px',
        height: '95%',
        width: '95%',
        allowNull: true
    }

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'passages_view';
        context.testType = 'passage';
        context.blankType='zmublank';
        context.idField = 'passage_id';
        context.columnsCount = [{width: '70'}, {width: '30'}];
        context.hiddenColumns = ['passage_id'];
        context.columnsDefaults = [
            {
                column_name: 'last_user', is_list: true, disabled: true, cls: 'last-class',
                row: 1000, pos: 1,
                source: {
                    tableName: 'users',
                    titleField: 'fio',
                    idField: 'user_id'
                }
            },
            {
                column_name: 'last_modified', cls: 'last-class', width: 250, data_type: 'date', disabled: true,
                row: 1000, pos: 0
            }
        ];
        context.topMenuButtonNames = ['reload', 'print', 'clipboard'];
        context.footerButtonNames = ['save', 'saveclose', 'close'];
        context.fieldsSet = [
            {title: 'Основные данные', fieldset: 'route', column: 0, pos: 0},
        ];
        context.subTables = [
            {
                column: 1,
                block: 1,
                title: 'отсканировано',
                elementName: 'ImageSub',
                idName: 'passage_id',
                collapsed: true
            }
        ];
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        try {
            context.title = `Маршрут ${record['route_name']}`;
        } catch (e) {
        }
        return context.title;
    }

    //есть ли права на запиcь
    isEditRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.passage_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (state == false) && f.isEditRight(rights);
    }

    //есть ли права на добавление, добавлять нельзя
    isAddRight() {
        return false;
    }

    //есть ли права на удаление, удалять нельзя
    isDropRight() {
        return false;
    }

    upResult(context, result) {
        result.columns = result.columns.map(c => {
            if (c.column_name.indexOf('gpx') > -1 && c.column_name.indexOf('upload') == -1) {
                c.data_type = 'path';
                // c.data_type='check';
            }
            return c;
        }).sort((a, b) => a.pos - b.pos);
        return result;
    }

    //отправка данных на сервер. сначала обновление, потом треки
    setData1(context, submit, path) {
        f.setObjectData({
            context: context,
            tableName: context.tableName,
            id: (context.rowId),
            path: path,
            //связь через таблицу связей
            relation: context.props.relation,
            // rowId: context.props.parent.rowId,
            rowId: context.props.parent.rowId,
            values: context.getValues(context),
            callback: (result) => {
                return;
                /*!!!*/
                Object.keys(context.elements).filter(k => {
                    return context.elements[k].file?.file
                }).map((k) => {
                    const file = context.elements[k].file.file;
                    const res = file.name.substr(-3);
                    if (!(res == 'zip' || res == 'gpx')) return false;
                    let values = new FormData();
                    values.append(k, file);
                    /* return f.setGeoData({
                         context: context,
                         tableName: `${k.substr(0, k.indexOf('_'))}lin`,
                         id: (context.rowId),
                         idName: 'passage_id',
                         type: res,
                         values: values,
                         callback: () => {
                             // sub.getData({context:sub})
                         }
                     })*/
                    return f.callPath({
                        method: 'POST',
                        path: `zmu/passages/${(context.rowId)}/gpx`,
                        values, callback: () => {
                            debugger;
                            // sub.getData({context:sub})
                        }
                    })
                });
                submit(result);
            }
        });
    }

    //кнопка экспорта в html
    addPrintButton() {
        const context = this;
        const b = 'printtop';
        const openDoc = () => {
            let filters = `?recreate=1&${f.getFilters('string')}`;
            try {
                let pasid;
                if (context.props?.parent?.grid)
                    pasid = context.props.parent.grid?.getCurrentRow().passage_id;
                window.open(`${window.IasConfig.homePath}forms/pack/${context.blankType}/doc${filters}&${(pasid) ? `pasid=${pasid}` : `routeid=${context.rowId}`}`);
            } catch {
                {
                    window.open(`${window.IasConfig.homePath}forms/pack/${context.blankType}/doc${filters}&routeid=${context.rowId}`);
                }
            }
        }
        context.topMenuButtons.push(<PrintButton key={`${b}Button`} text={`${b}Button`} name={`${b}Button`}
                                                 func={() => {
                                                     openDoc();
                                                 }}/>);
    }

}


