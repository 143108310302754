import {Circle as CircleStyle, Fill, Stroke, Style, Text} from "ol/style";

const keyName='selection.js';
export default  (feature,resolution) => {
 if (window.cssOn) console.debug(`${keyName} css`,feature);
    const color = 'rgba(25,225,225,1)';
    const color2 = 'rgba(225,90,0,1)';
    switch(feature.getGeometry().getType()) {
        case 'Point':
            return [
                new Style({
                    image: new CircleStyle({
                        radius: 8,
                        fill:null,
                        stroke: new Stroke({
                            color: color2,
                            width: 5,
                        }),
                    }),
                    text: new Text({
                        text: feature.getProperties().label,
                        offsetX: 10,
                        offsetY: -10,                        stroke: new Stroke({
                            color: color2,
                            width: 2
                        })
                    })
                }),
                new Style({
                    image: new CircleStyle({
                        radius: 8,
                        fill:null,
                        stroke: new Stroke({
                            color: color,
                            width: 3,
                        }),
                    }),
                    text: new Text({
                        text: feature.getProperties().label,
                        offsetX: 10,
                        offsetY: -10,                        stroke: new Stroke({
                            color: color,
                            width: 1
                        })
                    })
                })];
        case 'MultiLineString':
        case 'LineString':
           return [
                new Style({
                    stroke: new Stroke({
                        color: color2,
                        width: 5
                    }),
                    text: new Text({
                        text: feature.getProperties().label,                        offsetX: 10,
                        offsetY: -10,
                        textBaseline: 'bottom',
                        placement:'line',
                        stroke: new Stroke({
                            color: color2,
                            width: 3
                        })
                    })
                }),new Style({
                stroke: new Stroke({
                    color: color,
                    width: 3
                }),
                text: new Text({
                    text: feature.getProperties().label,
                    offsetX: 10,
                    offsetY: -10,
                    textBaseline: 'bottom',
                    placement:'line',                    stroke: new Stroke({
                        color: color,
                        width: 1
                    })
                })
            })];
        case 'Polygon':
        case 'MultiPolygon':
        default:
            return [
                new Style({
                    stroke: new Stroke({
                        color: color2,
                        width: 5
                    }),
                    text: new Text({
                        text: feature.getProperties().label,
                        offsetX: 10,
                        offsetY: -10,
                        fill: new Fill({
                            color: color2
                        })
                    })
                }),
                new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.01)',
                    }),
                    stroke: new Stroke({
                        color: color,
                        width: 3
                    }),
                    text: new Text({
                        text: feature.getProperties().label,
                        offsetX: 10,
                        offsetY: -10,
                        fill: new Fill({
                            color: color
                        })
                    })
                })]
    }
};