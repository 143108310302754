import BaseTopPage from "../../Base/BaseTopPage";

const keyName = 'IndexPage';
export default class IndexPage extends BaseTopPage {

    constructor(props) {
        super(props);
        const context = this;
        const content = [];
        context.state = {
            content: content, title: ''
        }
    }
    afterGetData(context, result) {
        const title=result.vdata.filter(row=>row.param_name.indexOf('title')>-1)[0].param_value;
        const content=result.vdata.filter(row=>row.param_name.indexOf('content')>-1).sort((a,b)=>(a.param_name<b.param_name)?-1:1).map(row=>row.param_value);
        context.setState({title:title,content:content});
    }
}
