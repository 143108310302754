import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyNane = 'StaffPage'
export default class StaffPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyNane} constructor`, arguments);
        context.tableName = 'economy_staff';
        context.idName = 'staff_id';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet = [{title: 'Основная информация'}
        ];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {
                column_name: 'staff_economy',
                is_list: true,
                source: {
                    tableName: 'economies',
                    idField: 'economy_id',
                    titleField: 'economy_name'
                }, pos: 0, row: 0
            }, {column_name: 'staff_post', pos: 1, row: 1},
            {column_name: 'count', pos: 2, row: 1}]];
        context.footerButtonNames = ['saveclose', 'save', 'delete', 'close'];
        context.subTables=[
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='economy_staff' and record_id = $staff_id$::varchar",
            }
        ]
    }
}


