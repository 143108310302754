import React, {Component} from 'react';
import {Panel} from "@sencha/ext-react-modern";
import * as f from "./Funcs";

const key="InlineMenu";

class InlineMenu extends Component {

    render(){
        //кнопка добавить - появляется когда есть разрешение
        //экпорт в xls
        //печать списка
        const context=this;
        return <Panel
            hidden={true}
            id={key}
            key={key}
            layout="fit" shadow docked={"top"}
            height={f.getInnerHeightMenu()}
            margin={`0 0 ${window.IasConfig.margin} 0`}>
        <div>{context.props.content}</div>
    </Panel>}
}
export default InlineMenu;