import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

export default class AddressPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("ContactPage constructor", arguments);
        context.tableName = 'contacts_address';
        context.idName = 'address_id';
        context.titleName = 'contact_address';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.footerButtonNames = ['saveclose', 'save', 'copy', 'delete', 'close'];
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {column_name: 'address_contact', hidden: true},
            {column_name: 'contact_address', hidden: false, disabled: false, row: 1},
            {column_name: 'address_type', hidden: false, maxWidth: 150, pos: 0, row: 0}
        ]];
        context.subTables = [{
            title: 'История изменений',
            elementName: 'CardLogSub',
                collapsed: true,
            noSingle: true,
            subElementName: 'LogPage',
            hidden: () => !f.isAdmin(),
            filterString: "table_name ='contacts_address' and record_id = $address_id$::varchar",
        }
        ]
    }
}


