import BaseEditByMapPage from "../../Base/BaseEditByMapPage";
import landspolStyle from "../../Maps/styles/landspol";
import admpolStyle from "../../Maps/styles/admpol";
import paslinStyle from "../../Maps/styles/zmu";
import zmupntStyle from "../../Maps/styles/pnt";

const keyName = "BasePointPage";
export default class BasePointPage extends BaseEditByMapPage {
    static defaultProps = {
        height: '95%',
        width: '75%',
        allowNull: true
    }

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'passage_point_animal_view';
        context.idName = 'point_id';
        context.fieldsSet = [
            {title: 'Факт встречи'},
            {title: 'Карта', fieldset: 'map', pos: 10, column: 1, block: 1},
        ];
        context.columnsCount = [{width: '40%'}, {width: '50%'}];
        context.columnsDefaults = [...context.lastUserColumns, ...[
            {column_name: "passage_id", hidden: true},
            {
                "column_name": "on_erase",
                column_label:'В день затирки',
                labelAlign: 'left',
                "pos": 3, row: 10,
                "data_type": "boolean",
            },
            {
                "column_name": "biotop",
                labelAlign: 'left',
                "pos": 6, row: 15,
                is_list: true,
                data: [{value: 'fst', title: 'лес'}, {value: 'fld', title: 'поле'}, {value: 'bog', title: 'болото'}],
                "required": true,
            },
            {
                "column_name": "count",
                labelAlign: 'left',
                minValue:1,
                "pos": 6, row: 40,
                "required": true,
            },
            {
                column_name: 'geom',
                width: '100%',
                fieldset: 'map',
                mapButtonNames: ['edit', 'drop'],
                gotoCls: 'single-page-column-0',
                onAddFeatures: (event) => {
                    if (event.layerName == 'features'&&event.data?.length)
                        context.elements.CoordsSub.getFromFeature(context.elements.CoordsSub, event.data[0]);

                },
                onRemoveFeatures:(event)=>{
                    if (event.layerName == 'features')
                        context.elements.CoordsSub.getFromFeature(context.elements.CoordsSub,null);
                },
                layerNames: [
                    {
                        layerName: 'landspol', hidden: false, type: 'own', styleFunction: landspolStyle
                    },
                    {
                        layerName: 'passagelin',
                        hidden: false,
                        type: 'own',
                        styleFunction: paslinStyle,
                        filter: 'gid=$passage_id$',
                        goto: true
                    },
                    {
                        layerName: 'points',
                        hidden: false,
                        type: 'own',
                        styleFunction: zmupntStyle,
                        filter: 'passage_id=$passage_id$',
                    },
                    {
                        layerName: 'admpol', hidden: false, type: 'own', styleFunction: admpolStyle
                    },]
            }]];
        context.subTables = [
            {
                pos: 2,
                elementName: 'CoordsSub',
                title: 'Ввод координат',
                fieldset: 0,
                column: 0,
            },
        ];
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        try {
            context.title = `Факт встречи ${record['spec_name']} (${record['biotop']})`;
        } catch (e) {
            context.title = `Факт встречи `;
        }
        return context.title;
    }

}


