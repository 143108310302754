import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";

const keyName = 'DocumentsPage';

export default class DocumentsPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.title=`список документов`;
        context.gridName = `DocumentsGrid`;
        context.subElementName = 'DocumentPage';
        context.tableName = 'documents';
        context.idName = 'documentid';
        context.hiddenColumns = [context.idName];
        context.topMenuButtonNames = ['reload', 'xls', 'print', 'add','clipboard'];
        context.filters = [
            {name: 'ac', required: false,defaultValue:true},
            {name: 'region', required: false},
            {name: 'users', required: false},
            {name: 'ecos', required: false},
            {name: 'lands', required: false},
            {name: 'num', required: false},
            {name: 'from', required: false},
            {name: 'to', required: false},
            {name: 'org', required: false},
            {name: 'doctype', required: false},
            {name: 'page', required: false}
        ];
        //context.hiddenColumns.push('hu_id');
        }
}