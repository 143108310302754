import React from 'react';
import * as f from "../common/Funcs";
import {Button, Panel, TextField} from "@sencha/ext-react-modern";
import BasePage from "./BasePage";
import {PageFilter} from "../collections/panelFilters";
import ErrorBoundary from "../ErrorBoundary";

const Ext = window['Ext'];
const keyName = `BaseRelationGridPanel`;

export default class BaseRelationGridPanel extends BasePage {

    constructor(props) {
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        super(props);
        const context = this;
        context.gridName = 'BaseRelationGrid';
        // if (props.parent) {
        //     const getSubEl = (me) => (me.props.subElementName) ? me.props.subElementName : (me.props.parent) ? getSubEl(me.props.parent) : '';
        //     context.subElementName = getSubEl(context);
        // }
        context.relation = props.relation;
        context.relWidth=f.getCentralWidth()*0.8;
        context.parent = props.parent;
        context.rowId = props.rowId;
        // context.rowId = props.rowId;
        context.idName = context.props.idName || '_id';
        context.relColumnsDefaults = props.relColumnsDefaults;
        context.subElementName = props.subElementName;
        context.hiddenColumns = context.props.hiddenColumns || [context.idName, 'hu_id'];
        context.state = {
            data: context.props.data || [], title: 'Started', showEditPanel: false,
            pageCurrent:1,
            pageSize:100,
            search:''
        };
        context.store = Ext.create('Ext.data.Store', {
            data: context.state.data,
            pageSize: 100,
        });
        context.topMenuButtons = [];

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context=this;
    if (prevState.search!=context.state.search||prevState.pageCurrent!=context.state.pageCurrent) {
        context.grid.getData({context:context.grid});
    }}

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        context.title = context.props.parent.props.title;
        return context.title;
    }

    //перейти на страницу редактирования
    // showEditPanel(context) {
    //     const gridFilter = context.grid.getFilterStringFromRow().split('&');
    //     if (window.IasConfig.devMode) console.debug(`${keyName} showEditPanel`, gridFilter);
    //     context.setState({
    //         //применяется фильтр, указанный для саба в описании в родительском объекте
    //         pageDefaults: gridFilter.map(pair => {
    //             const ar = pair.replace(' ', '').split('=');
    //             return {column_name: ar[0], value: ar[1], disabled: true};
    //         }), showEditPanel: true
    //     });
    //     const parentRow = context.props.parent.state.data;
    //     f.getTitle(context, parentRow);
    //     if (context.editForm) context.editForm.getData(context.editForm, context);
    // }

    // hideEditPanel(context) {
    //     context.setState({showEditPanel: false});
    // }

    isEditRight() {
        const rights = this.props.rights;
        return f.isEditRight(rights);

    }

    // getDialog(context) {
    //     // if (!window.dialogs) window.dialogs = [];
    //     const p = context.getEditType(context);
    //     //тело таблицы
    //     const edit = <p.el
    //         ref={(e => context.editForm = e)}
    //         pageDefaults={context.state.pageDefaults}
    //         relation={context.props.relation}
    //         rowId={context.props.parent.rowId}
    //         // rowId={context.rowId}
    //         rights={context.props.rights}
    //         disactive={context.props.active}
    //         key={context.subElementName}
    //         parent={context} appViewPort={context.props.appViewPort}/>;
    //     //окно редактирования. проверить скорость - прятать или генерировать
    //     const dialog = <DialogForm showEditPanel={context.state.showEditPanel} editFormEl={edit} propName={'dialog'}
    //                                key={'dialog'}
    //                                parent={context}/>
    //     // window.dialogs.push(dialog);
    //     return dialog;
    // }

    getSearchElement(context) {
/*
        const onChange = (context, val, sender) => {
            if (window.IasConfig.devMode) console.debug(`searchfield onChange`, arguments);
            const store = context.grid.store;
            // return true;
            store.getFilters().clear();
            if (val && val.length > 0) {
                const filterFunction = (item) => {
                    const data=(item.data)?item.data:item;
                    return Object.keys(data).filter(i=>typeof(data[i])=='string'&&data[i].toLowerCase().indexOf(val.toLowerCase())>-1).length>0;
                };
                store.addFilter(filterFunction);
            } else
                store.addFilter(()=>true);
        }
*/
        const onChange = (context, val, sender) => {
            if (window.IasConfig.devMode) console.debug(`searchfield onChange`, arguments);
            const store = context.grid.store;
            if (val==null) val='';
            if (val!=context.state.search&&(val==''||val.length>2)) {
                context.setState({search: val,pageCurrent:1});
            }
            // return true;
         /*   store.getFilters().clear();

            if (val && val.length > 0) {
                const filterFunction = (item) => {
                    const data=(item.data)?item.data:item;
                    return Object.keys(data).filter(i=>typeof(data[i])=='string'&&data[i].toLowerCase().indexOf(val.toLowerCase())>-1).length>0;
                };
                store.addFilter(filterFunction);
            } else
                store.addFilter(()=>true);
        */
        }
        return <TextField
            key={`searchfield${context.rowId}`} name={`searchfield${context.rowId}`} label={'Контестный фильтр'}
            cls={`filter-field text-field`}
            ref={fi => context.field = fi}
            listeners={{
                keyup: (sender, event) => {
                    return onChange(context, sender.getValue());
                }
            }}
        />

    }

    getPaging() {
        const context = this;
        if (context.paging)
            return <Panel ref={(p => context.panel = p)} key={'pagingPanel'}>
                <LoadPaging ref={(p => context.paging = p)} key={'paging'} parent={context.props.panel}/>
            </Panel>
        else return '';
    }

    render() {
        const context = this;
        const elements = [];
        const p = {grid: context.getGridType(context).el};
        elements.push(context.getSearchElement(context));
        elements.push(context.getPaging(context));
        elements.push(React.createElement(p.grid, {
            parent: context.parent,
            panel:context,
            appViewPort: context.props.appViewPort,
            tableName: context.props.tableName,
            idName: context.props.idName || context.idName,
            hiddenColumns: context.hiddenColumns,
            columnsDefaults: context.relColumnsDefaults,
            record: context.props.record,
            relation: context.props.relation,
            rowId: context.props.rowId||context.rowId,
            parentGrid: context.props.parentGrid,
            rights: context.props.rights,
            filterString: context.props.filterString,
            key: context.props.tableName,
            name: context.props.tableName,
            // paging:100,
            ref: (grid) => context.grid = grid
        }));
        // if (context.subElementName) {
        //     const dialog = (context.getDialog) ? context.getDialog(context) : '';
        //     elements.push(dialog);
        // }

        return (<ErrorBoundary ><Panel
            height={f.getCentralHeight()}
            region={"center"} margin="3"
            key={'gridPagePanel'}
            width={context.relWidth}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            {elements}
        </Panel></ErrorBoundary>)
    }

}

export class LoadPaging extends PageFilter {
    keyName = 'gridloadpaging'

    constructor(props) {
        super(props);
        const context = this;
        context.state = {pageCurrent: context.props.parent.state.pageCurrent};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        const current=context.state.pageCurrent;
        if (current != prevState.pageCurrent) {
            const parent = context.props.parent;
            if (window.IasConfig.devMode) console.debug(`updatePage `, current);
            parent.setState({pageCurrent:current});
        }
        return true;
    }

    render() {
        const context = this;
        const buttons = [];
        buttons.push(<Button
            docked={'left'}
            region={'center'}
            disabled={context.state.pageCurrent==1}
            cls={'no-print'}
            iconCls={`x-fa fa-backward`}
            key={'prev'}
            handler={() => context.setState({pageCurrent: 1})}/>);
        buttons.push(<Button
            docked={'right'}
            region={'center'}
            cls={'no-print'}
            iconCls={`x-fa fa-forward`}
            key={'last'}
            handler={() => context.setState({pageCurrent: context.state.pageCurrent+1})}/>);
        return (
            <Panel key={'pageCountPanel0'}>
                <Panel
                    cls={'page-count-panel'}
                    key={'pageCountPanel00'}
                    bodyCls={'page-count-body'}
                    hidden={context.props.hidden}
                    layout={'fit'}
                    maxWidth={'100%'}>
                    <Panel
                        cls={'page-count-panel'}
                        bodyCls={'page-count-body'}
                        key={'pageCountPanel000'}
                        layout={'fit'}>
                        {buttons}
                    </Panel>
                </Panel>
            </Panel>
        );
    }
}
