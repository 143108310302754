import React from 'react';
import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName = 'SpecStatusPage';
export default class SpecStatusPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        window.efs = context;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'spec_status';
        context.idName = 'row_id';
        context.titleName = '';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet = [
            {title: 'Основные параметры', fieldset: 'char'},
        ];

        context.columnsDefaults = [...context.lastUserColumns, ...[{
            "column_name": "spec_start_year",
            "pos": 0,
            "row": 0
        }, {"column_name": "spec_end_year", hidden: true, "pos": 0, "row": 0},
            {"column_name": "spec_area", "pos": 0, "row": 10}, {
                "column_name": "spec_count",
                "pos": 0,
                "row": 20
            }, {"column_name": "spec_state", "pos": 0, "row": 30},
            {"column_name": "life_cycle", "pos": 0, "row": 35},
            {"column_name": "spec_habitat_structure", "pos": 0, "row": 40},
            {"column_name": "spec_habitat_quality", "pos": 0, "row": 50},
            {"column_name": "spec_habitat_area", "pos": 0, "row": 60},
            {"column_name": "pop_indicators", "pos": 0, "row": 70},
            {"column_name": "pop_biotops_percent", "pos": 0, "row": 75},
            {"column_name": "sub_role", "pos": 0, "row": 80},


            {"column_name": "spec", hidden: true, "pos": 0, "row": 0}, {
                "column_name": "status_active",
                "pos": 0,
                "row": 0
            }, {"column_name": "row_id", hidden: true, "pos": 0, "row": 0}]
        ]
        ;
    }
}


