import BaseSubGrid from "../../../Base/BaseSubGrid";

const keyName=`PassCommentSubGrid`;

export default class PassCommentSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'pass_comments_view';
        //элемент окна редактирования
        context.idName = 'row_id';
        context.subElementName = `PassCommentPage`;
        context.hiddenColumns = [context.idName,'passage_id'];
        context.columnsDefaults = [
            {column_name:'last_date',hidden:false},
            {column_name:'fio',title:true},
            {column_name:'comment'},
        ];
    }

}