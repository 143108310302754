import {Stroke, Style, Text, Fill} from "ol/style";
import * as g from "../../common/gisFuncs";

const keyName='admpol.js';
export default (feature, resolution) => {
 if (window.cssOn) console.debug(`${keyName} css`,feature);
    const w = new Style({
        text: new Text({
            text: g.getText(feature, {resolution: resolution}),
            offsetX: 10,
            offsetY: -10,            stroke: new Stroke({
                color: [0, 0, 0, 1],
                width: 3
            })
        })
    });
    const p = new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.1)',
        }),
        stroke: new Stroke({
            color: [255, 0, 0, 1],
            lineDash: [10, 5, 1, 5],
            width: 1
        }),
        text: new Text({
            text: g.getText(feature, {resolution: resolution}),
            offsetX: 10,
            offsetY: -10,            stroke: new Stroke({
                color: [255, 255, 255, 1],
                width: 2
            })
        })
    });
    return [w, p];
}