import ErrorBoundary from "../../ErrorBoundary";
import BaseGridPage from "../../Base/BaseGridPage";
import * as f from "../../common/Funcs";

//управление пользователями и правами
const keyName = `NewsPage`;
export default class NewsPage extends BaseGridPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName = "NewsGrid";
        context.subElementName = 'NewPage';
        context.tableName = 'news';
        context.idName = 'row_id';
        context.titleName = 'hu';
        context.hiddenColumns = [context.idName];
        context.topMenuButtonNames= ['reload','print','clipboard','add'];
        //фильтры в правой части
        context.filters=[
            {name:'num',pos:3,required:false},
            {name: 'from',pos:1,  message: f.locale('needfrom')},
            {name: 'to',pos:3,  message: f.locale('needto')},
            {name: 'page',pos:60, required: false}
        ];
        context.props.appViewPort.setState({filters:context.filters});
    }


}