import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName=`PhonePage`;

export default class PhonePage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'phones';
        context.idName='phone_number';
        context.titleName=`phone`;
        context.footerButtonNames=['saveclose','save', 'delete','close'];
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.columnsDefaults=[...context.lastUserColumns,...[
            {column_name: 'phone_type',pos:0},
            {column_name: 'phone_number',pos:1,disabled:false,hidden:false},
            {column_name: 'phone_comment',pos:2,row:1},
        ]];
        context.fieldsSet=[
            {title: 'Основная информация'}
        ];
        context.subTables=[
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='phones' and record_id = $phone_number$::varchar",
            }
        ];
    }

}


