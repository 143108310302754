import ErrorBoundary from "../../../ErrorBoundary";
import BaseSubGrid from "../../../Base/BaseSubGrid";

const keyName=`SpecLitSubGrid`;
//вид в КК - саб таблица
export default class SpecLitSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'spec_on_redbook';
        //элемент окна редактирования
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName,'spec_id', 'rb_id','name_rus'];
    }

}