import React from "react";
import * as f from "../../../common/Funcs";
import * as z from "../../../common/zmuFuncs";
import * as g from '../../../common/gisFuncs';
import {Panel} from "@sencha/ext-react-modern";
import BaseZmuPage from "../../../Base/ZMU/BaseZmuPage";
import ZmuEaMap from "../../../Maps/ZMU/ZmuEaMap";
import {ReloadButton} from "../../../common/Buttons";
import style from "../../../Maps/styles/ea";
import {BaseButton} from "../../../Base/BaseButtons";

const keyName = `ZmuEaPage`;
const title = `Зимний маршрутный учет. Исследуемые территории.`;
export default class ZmuEaPage extends BaseZmuPage {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const now = new Date();
        context.styleFunction = (feature, resolution) => {
            return style(feature, resolution, context);
        };
        context.gridName = "EaGrid";
        context.subElementName = 'EaPage';
        context.subElementParams = {eaType: 'admpol'};
        context.topMenuButtonNames = ['xls', 'html', 'reload', 'clipboard', 'add'];
        context.tableName = 'explore_areas';
        context.idName = 'area_id';
        context.hiddenColumns = [context.idName];
        context.filters = [
            {name:'year',pos:10,required:true, message: f.locale('needyear')},
            {name:'users',pos:40},
            {name:'region',pos:30, gisSearch: true},
            {name:'it', pos:50,gisSearch: true},
            {name:'page',pos:60}
        ];
        //       document.setCookie('toFilter',now.toLocaleDateString());
        if (!document.getCookie('yearFilter')) {
            const year = (new Date()).getFullYear() + ((new Date()).getMonth() > 5 ? 1 : 0);
            if (context.filters.YearFilter) context.filters.YearFilter.setValue(year);
        }
        document.clearOther(context.filters);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        const s = context.state;
        super.componentDidUpdate(prevProps, prevState, snapshot);
        if (prevState.eaType != context.state.eaType && context.state.eaType != '') {
            // context.sb.cmp.setValue(context.state.eaType);
        }
        if (prevState.eaType != s.eaType && Boolean(s.eaType)) {
            if (context.stopUpdate) context.stopUpdate = false;
            else {
                if (s.eaType) {
                    z.changeEaType({context, eaType: s.eaType, accYear: s.accYear});
                    if (context.objectsGrid) context.objectsGrid.setState({tableName: s.eaType});
                    const app = context.props.appViewPort;
                    if (app.autoload) context.grid.setState({eaType: s.eaType});
                }
            }
        }
    }

    isEditRight() {
        const context = this;
        const app = context.props.appViewPort;
        let isEditRight = (app?.state?.zmu) && !app?.state?.zmu?.plan_closed;
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        /* isEditRight=isEditRight&&
             ((new Date()).getMonth() > 5 &&
                 (Number(context.state.accYear) == (new Date()).getFullYear() + 1));*/
        return isEditRight && f.isEditRight(rights);
    }

    //кнопка автогенерации ИТ из обьектов
    addAdditButtons(context = this) {
        const key = 'genButton';
        let isauth = f.getRoles();
        if (context.isEditRight() && isauth && isauth.filter(a => a == 10).length > 0) {
            context.topMenuButtons.push(<BaseButton key={key} name={key} iconCls={'fa-magic'} func={() => {
                f.confirm({
                        title: 'Подтверждение действия',
                        message: `Рассчитать Исследуемые территории из объектов карты на ${context.state.accYear} год?`,
                        type: 'confirm'
                    },
                    function (result) {
                        if (result) {
                            const accYear = document.getCookie('yearFilter');
                            window.mask({message: 'расчет территорий...'});
                            const callback = () => {
                                context.getAccYear(context, accYear);
                                context.objectsGrid.getData({
                                    context: context.objectsGrid, callback: () => {
                                        context.grid.getData({
                                            context: context.grid, callback: () => {
                                                window.unmask();
                                            }
                                        });
                                    }
                                });
                            };
                            z.eaFromObjects({callback, accYear});
                        }
                    });
            }}/>)
        }
    }

    //кнопка reload общая для всех страниц
    addReloadButton() {
        const context = this;
        const key = 'reloadbutton';
        context.topMenuButtons.push(<ReloadButton key={key} func={() => {
            context.update(context);
        }}/>)
    }

    //таблица объектов. переключается "принципом формирования"
    getObjectsGrid(context) {
        const gridName = 'EaObjectsGrid';
        return React.createElement(context.getGridType(context, gridName).el, {
            parent: context,
            appViewPort: context.props.appViewPort,
            key: gridName,
            name: gridName,
            rowId: context.rowId,
            width: '98%',
            // height: f.getCentralHeight() - 200,
            ref: (grid) => {
                context.objectsGrid = grid
            }
        })
    }

    update(context) {
        const upFunction = () => {
            if (context.objectsGrid) context.objectsGrid.getData({context: context.objectsGrid});
            if (context.grid) context.grid.getData({context: context.grid});
        }
        if (context.props.appViewPort.state.zmu?.acc_year != document.getCookie('yearFilter')) {
            context.getAccYear(context, document.getCookie('yearFilter'), upFunction);
        } else
            upFunction();
    }

    render() {
        const context = this;
        //тело таблицы ит
        const grid = context.getGrid(context);
        //тело таблицы объектов
        const objectsGrid = (context.isEditRight()) ? context.getObjectsGrid(context) : '';
        //строка панели инструментов раздела
        const editMenu = context.getEditMenu(context);
        //карточка ИТ
        const dialog = (context.getDialog) ? context.getDialog(context) : '';
        return (<Panel
            cls={'print-100-percent by-map-panel'}
            key={`gridPanel${keyName}`}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            <Panel docked={"left"} width={"50%"} key={'gridPanel'}
                   resizable={{
                       split: true,
                       edges: 'east',
                       dynamic: true
                   }}
                   listeners={{
                       resize: (element, info, eOpts) => {
                           g.mapResize(context);
                       }
                   }}>
                {editMenu}
                {objectsGrid}
                {dialog}
                {grid}
            </Panel>
            <Panel height={"100%"} key={'mapPanel'} className='zmu-ea-map-panel'>
                <ZmuEaMap key={'zmuMap'} parent={context} appViewPort={context.props.appViewPort} width={'100%'}
                          styleFunction={context.styleFunction}
                          ref={(m => {
                              context.olMap = m;
                          })}/>
            </Panel>
        </Panel>)
    }
}

