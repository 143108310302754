import * as f from "../common/Funcs";
import BaseMap from "./BaseMap";
import landstyle from "../Maps/styles/landspol";
import admstyle from "../Maps/styles/admpol";
import * as Controls from "./MapControls";

const keyName = "SimpleMap";

export default class SimpleMap extends BaseMap {
    static defaultProps = {
        mapButtonNames: [],
        layerNames: [
            {
                layerName: 'landspol', hidden: false, type: 'own', styleFunction: landstyle
            },
            {
                layerName: 'admpol', hidden: false, type: 'own', styleFunction: admstyle
            },
        ],
        load: 'auto'
    }

    constructor(props) {
        super(props);
        const context = this;
        context.geomType = (props.geomType)?props.geomType:'Point';
        context.state.legendOn = false;
    }

    componentDidMount() {
        super.componentDidMount();
        const context = this;
        if (context.props.load == 'auto') {
        context.update(context);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        if (context.props.load == 'record' && context.props.record &&
            prevProps.rowId != context.props.rowId) {
            context.update(context);
        }
    }

    getHeight() {
        return (f.getCentralHeight() / 2);
    }

    getAdditControls() {
        return [
            Controls.getClearSelection()
        ];
    }

}
