import React from "react";
import BasePointsGrid from "../../../Base/ZMU/BasePointsGrid";

const keyName='AnimalsGrid';

export default class AnimalsGrid extends BasePointsGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = `animal_points_view`;
        context.idName = 'point_id';
    }

}