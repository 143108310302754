import ErrorBoundary from "../../../ErrorBoundary";
import React,{Component} from "react";
import BaseZmuAnalizePage from "../../../Base/ZMU/BaseZmuAnalizePage";

const keyName = `ZmuPasAnalizePage`;
const title=`Зимний маршрутный учет. Анализ исследуемых территорий.`;
export default class ZmuPasAnalizePage extends BaseZmuAnalizePage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName="PasAnalizGrid";
        context.subElementName = 'PasAllPage';
        context.tableName = 'passages';
    }

}