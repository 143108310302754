import ErrorBoundary from "../../../ErrorBoundary";
import React from "react";
import * as f from "../../../common/Funcs";
import BaseZmuPage from "../../../Base/ZMU/BaseZmuPage";
import {Panel} from "@sencha/ext-react-modern";
import ZmuMap from "../../../Maps/ZMU/ZmuMap";
import * as g from "../../../common/gisFuncs";

const keyName = `ZmuRoutesPage`;
const title=`Зимний маршрутный учет. Маршруты.`;
export default class ZmuRoutesPage extends BaseZmuPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName="RoutesGrid";
        context.subElementName = 'RoutePage';
        context.tableName = 'routes';
        context.idName = 'row_id';
        context.topMenuButtonNames = ['xls', 'html', 'reload', 'clipboard','add'];
        context.hiddenColumns=[context.idName];
        context.filters =[
            {name:'editable',pos:15},
            {name:'year',pos:10,required:true, message: f.locale('needyear')},
            {name:'ac',pos:20,defaultValue:true},
            {name:'num',pos:55},
            {name:'users',pos:40},
            {name:'region',pos:30, gisSearch: true},
            {name:'it', pos:50,gisSearch: true},
            {name:'page',pos:60}
        ];
        if (!document.getCookie('yearFilter'))
        context.props.appViewPort.filters?.YearFilter?.setValue?.((new Date()).getFullYear() + ((new Date()).getMonth() > 5 ? 1 : 0));
        context.state = {
            content: [`<p></p>`]
            , title: title
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        const s = context.state;
        super.componentDidUpdate(prevProps, prevState, snapshot);
        if (prevState.accYear != s.accYear) {
            if (context.grid) context.grid.getData({context:context.grid});
        }
    }

    render() {
        const context = this;
        //тело таблицы
        const grid = context.getGrid(context);
        const editMenu = context.getEditMenu(context);
        const dialog = (context.getDialog) ? context.getDialog(context) : '';
        return (<Panel
            cls={'print-100-percent by-map-panel'}
            key={`gridPanel${keyName}`}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            <Panel docked={"left"} width={"50%"} key={'gridPanel'}
                   resizable={{
                       split: true,
                       edges: 'east',
                       dynamic: true
                   }}
                   listeners={{
                       resize:(element, info, eOpts)=>{
                           g.mapResize(context);
                       }
                   }}>
                {editMenu}
                {grid}
                {dialog}
            </Panel>
            <Panel  height={"100%"} key={'mapPanel'}>
                <ZmuMap key={'zmuMap'} parent={context} appViewPort={context.props.appViewPort} width={'100%'} styleFunction={context.styleFunction}
                           ref={(m => context.olMap = m)}/></Panel>
        </Panel>)
    }

}