import * as f from "../../common/Funcs";
import BaseEditByMapPage from "../../Base/BaseEditByMapPage";


const keyName = "MappingPage";
export default class MappingPage extends BaseEditByMapPage {
    constructor(props) {
        super(props);
        const context = this;
        context.columnsCount = 2;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'mappings';
        context.fieldsSet = [...context.fieldsSet, ...[]];
        context.columnsDefaults = [...context.lastUserColumns,...context.columnsDefaults, ...[
            {
                column_name: 'geom',
                row: 0,
                pos: 0,
                fieldset: 'map'
            }]
        ];
        context.columnsDefaults.filter(c => (c.column_name == 'meeting_type'))[0].value=1;
        context.subTables=[
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: () => !f.isAdmin(),
                filterString: "table_name ='mappings' and record_id = $row_id$::varchar",
            }
        ]
        //context.columnsDefaults.push();
    }


    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        try {
            context.title = `Факт встречи от ${((record['meeting_date']) ? new Date(record['meeting_date']).toLocaleDateString() : '--.--.-- ')}`;
        } catch (e) {
            context.title = `Факт встречи --.--.-- `;
        }
        return context.title;
    }
}


