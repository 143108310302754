import ErrorBoundary from "../../../ErrorBoundary";
import BaseAnalizGrid from "../../../Base/ZMU/BaseAnalizGrid";

const keyName = 'PasAnalizGrid';

export default class PasAnalizGrid extends BaseAnalizGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'PasAllPage';
        context.zmuTableId='passages';
        context.tableName = `passages_view`;
        context.idName = 'passage_id';
        context.hiddenColumns = ['passage_id', 'acc_year']
        context.columnsDefaults = [
            {column_name: 'route_name', pos: 1, minWidth: 120, title: true},
        ]
    }
}