import {Stroke, Style} from "ol/style";

const keyName='dtplin.js';
export default (feature,resolution) => {
 if (window.cssOn) console.debug(`${keyName} css`,feature);
    const p = new Style({
        stroke: new Stroke({
            color: [255, 50, 50, 1],
            width: 3
        })
    });
    return [p];
}