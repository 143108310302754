import React from 'react';
    import * as f from "../common/Funcs";
import TestSubPanel from "./ZMU/TestSubPanel";

const Ext = window['Ext'];
const keyName = `BiotopsSubPanel`;

export default class BiotopsSubPanel extends TestSubPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.gridName = "BiotopsSubGrid";
        context.idName = 'object_id';
        context.rowId = props.rowId;
        console.log(context.rowId);
        context.calcTableName='land';
        // context.rowId = props.rowId;
        context.tableName = props.tableName;
        context.state = {
            data: [],
        };
        context.store = Ext.create('Ext.data.Store', {
            data: context.state.data,
        });
        context.topMenuButtonNames=['reload','repeat','xls'];
        context.topMenuButto= [
            {
                iconCls: 'x-fa fa-repeat',
                html:`<label class="menu-label">Пересчитать</label>`,
                tooltip: f.locale('repeat'),
                //ззапрос данных с пересчетом тестов
                handler: () => {
                    f.callCalcs({
                        context,
                        tableId: context.calcTableName,
                        objectId: context.props.rowId,
                        callback: () => {
                            context.grid.getData({context: context.grid});
                        }
                    })
                }
            }
        ];
    }

    addRepeatButton(i) {
        const context = this;
        context.topMenuButtons[i]=(  {
            iconCls: 'x-fa fa-repeat',
            html:`<label class="menu-label">Пересчитать</label>`,
            tooltip: f.locale('repeat'),
            //ззапрос данных с пересчетом тестов
            handler: () => {
                f.callCalcs({
                    context, tableId: context.calcTableName, objectId: context.props.rowId, callback: () => {
                        context.grid.getData({context: context.grid});
                    }
                })
            }
        })
    }

    //кнопка экспорта в xls
    addXlsButton(i) {
        const context = this;
        const key = 'landbiotops';

        context.topMenuButtons.push(
            {
                iconCls: 'fa fa-file-excel-o',
                tooltip: f.locale('xls'),
                //ззапрос данных с пересчетом тестов
                handler: () => {
                    const r=context;
                    document.setCookie('idFilter',context.rowId);
                    window.open(`${window.IasConfig.homePath}forms/${key}/xls`);
                }
            }
        );
    }

}

