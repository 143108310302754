import ErrorBoundary from "../../../ErrorBoundary";
import BasePageGrid from "../../../Base/BasePageGrid";
import * as f from "../../../common/Funcs";
import {Panel} from "@sencha/ext-react-modern";
import React from "react";
import GridField from "../../../collections/GridField";
import Pels from "../../../collections/panelElements";

const keyName = 'SpeciesGrid';
export default class SpeciesGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'SpecPage';
        context.idName = 'spec_id';
        context.hiddenColumns = [context.idName];
        context.columnsDefaults = [{column_name: 'spec_id', pos: 0, row: 0, hidden: true}, {
            column_name: 'name_rus', title: true, flex: {grow: 2},
            pos: 40,
            row: 0
        }, {column_name: 'name_lat', title: true, flex: {grow: 2}, pos: 50, row: 0}, {
            column_name: 'rb', flex: {grow: 2},
            pos: 30,
            row: 0
        }, {column_name: 'hunting_object', pos: 10, row: 0}, {
            column_name: 'water_resource',
            pos: 20,
            row: 0
        }, {column_name: 'rights', pos: 100, row: 0}]
    }

    getData(params) {
        if (params?.context?.view) params.context.view.setState({rowId: null});
        super.getData(params);
    }

    //выполняется после загрузки
    afterGetData(context, upData) {
        context.isLoaded = true;
        upData.vdata.map(i => (i.rights == 'r' ? '' : i.rights));
        if (!upData.vdata || upData.vdata.length == 0) context.store.clearData();
        context.setState(upData, () => {
            context.applyFilter(context);
            context.store.on('filterchange', (store, filters, eOpts) => {
                context.applyFilter(context)
            });
            context.store.on('beforesort', (store, sorters, eOpts) => {
                context.applyFilter(context)
            });
        });
    }

    //выбор в таблице = заполнение превью карточки вида
    gridSelected(context, grid, selected) {
        const sel = selected[0];
            if (sel)
                context.view.setState({rowId: sel.data.spec_id});
            else
                context.view.setState({rowId: null});
    }

    //превью карточки вида - не редактируется
    getView(context) {
        return <Pels.SpecViewPage
            ref={(e) => {
                if (e) {
                    context.view = e;
                }
            }}
            pageDefaults={context.state.pageDefaults}
            height={context.getViewHeight()}
            key={`view${context.state.subElementName || context.subElementName}`}
            rights={context.state.rights}
            parent={context} appViewPort={context.props.appViewPort}/>
    }

    getHeight() {
        const context=this;
        // return f.getCentralHeight() - ((!this.paging) ? 40 : 70) - this.getViewHeight();
        const baseHeight = (f.getCentralHeight() - ((!context.paging) ? 40 : 70));
        if (context.state.cardHidden) return baseHeight;
        // if (this.view?.form?.cmp && !this.view.form.cmp.isHidden())
            return baseHeight - this.getViewHeight();
        // else
        //     return baseHeight;
    }

    getViewHeight() {
        const baseHeight = (f.getCentralHeight() - ((!this.paging) ? 40 : 70));
        return Math.floor(baseHeight * 0.7);
    }

    render() {
        const context = this;
        const columns = [];
        const content = [];
        const loadedtext = context.emptyMessages();
        if (loadedtext) content.push(loadedtext);
        if (context.isLoaded && !loadedtext) {
            if (context.state.columns) {
                /*заполнение полей для store*/
                context.store.fields = context.state.columns.filter(c => c.column_name !== context.idName);
                /*обработка ячейки с признаком редакции*/
                context.state.columns.sort((a, b) => a.pos - b.pos).map(c => {
                    const hidden = (f.exist(c.hidden)) ? c.hidden :
                        ((context.hiddenColumns && context.hiddenColumns.indexOf(c.column_name) > -1) || c.column_name == 'order_value');
                    columns.push(<GridField
                        key={c.column_name}
                        column_name={c.column_name}
                        column_label={c.column_label}
                        flex={c.flex}
                        readonly={c.readonly}
                        hidden={(hidden)}
                        className={c.className}
                        getColor={c.getColor}
                        colored={c.colored}
                        parent={context.props.parent}
                        grid={context}
                        relation={c.relation}
                        title={c.title}
                        align={c.align}
                        ignoreExport={c.ignoreExport}
                        groupHeaderTpl={(context.groupField == c.column_name) ? context.groupHeaderTpl : ''}
                        editable={c.editable}
                        data_type={c.data_type}
                        minWidth={c.minWidth}
                        maxWidth={c.maxWidth * ((context.state?.data?.length > 10) ? 0.95 : 1)}
                        ignorePrint={c.ignorePrint}
                    />)
                })
            }
            if (columns.length > 0) {
                content.push(context.getGrid(context, columns));
                content.push(context.getView(context));
            }
        }
        //панель инструментов гриды. Внутреннее меню страницы - в base...page
        content.push(context.getToolBar());
        return (
            <Panel key={'basegridpanel'}
                   ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
            >{content}</Panel>
        )
    }

}