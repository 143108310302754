import {Fill, Stroke, Style, Text} from "ol/style";
import * as g from "../../common/gisFuncs";

const keyName='landspol.js';
export default  (feature, resolution) => {
 if (window.cssOn) console.debug(`${keyName} css`,feature);
    console.info('resol  ',resolution);
    const w = new Style({
        // fill: new Fill({
        //     color: 'rgba(255, 255, 255, 0.5)',
        // }),
        stroke: new Stroke({
            color: [255, 255, 255, 1],
            width: 5
        })
    });
    let color =[0, 0, 0, 1];
    let fillcolor=[255, 255, 255, 0.1];
    let disabled=  (feature.getProperties().source=='dispol');
        switch (feature.getProperties().land_type) {
        case -1: break;
        case -2:
        case -3:
            color = [255, 0, 0, 1];
            break;
        case 0:
            color = [0, 255, 0, 1];
            break;
        case 1:
        case 2:
            color = [0, 50, 155, 1];
            break;
    }
     if(disabled)   fillcolor=[0,0,0,0.3];
    const p = new Style({
        fill: new Fill({
            color: fillcolor,
        }),
        offsetX: 10,
        offsetY: -10,
        stroke: new Stroke({
            color: color,
            width: (disabled)?1:3
        }),
        text: new Text({
            text: g.getText(feature, {resolution: resolution}),            stroke: new Stroke({color: [255, 255, 255], width: 3}),
            fill: new Fill({color: color}),
            overflow:(!disabled&&resolution<50)
        })
    });
    return [w, p];
}