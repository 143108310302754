import ErrorBoundary from "../../ErrorBoundary";
import BaseAccPage from  "../../Base/BaseAccPage";
import * as f from "../../common/Funcs";
const keyName = `MonitoringsPage`;

export default class MonitoringsPage extends BaseAccPage {
    static defaultProps={acc:'monitoring'};
    constructor(props) {
        super(props);
        const context = this;
        context.accId='3';
        context.filters.push({name:'monitoring',required:true,message:f.locale('needmonitoring')});
    }
}
