import BaseSubGrid from "../../Base/BaseSubGrid";
import ErrorBoundary from "../../ErrorBoundary";

const keyName=`EconsSubGrid`;

export default class EconsSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'economies';
        //элемент окна редактирования
        context.idName = 'economy_id';
        context.hiddenColumns = [context.idName, 'economy_huntuser']
        context.rowexpander=true;
    }


}