import React from 'react';
import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName='SpecPage';
export default class SpecSynPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        window.efs=context;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.tableName = 'species';
        context.idName='spec_id';
        context.titleName='name_rus';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.fieldsSet=[
            {title: 'Названия',fieldset:'char'},
        ];

        context.columnsDefaults = [...context.lastUserColumns,...[
            {column_name: 'name_lat', row: 10, pos: 1},
            {column_name: 'name_rus', row: 10, pos: 2},
            {column_name: 'spec_id', hidden: true},
            {column_name: 'spec_rang', defaultValue: 8,hidden:true},
            {column_name: 'spec_parent', hidden: true},
            {column_name: 'key', hidden: true},]
        ];
    }
}


