import BasePageGrid from "../../Base/BasePageGrid";
import React from "react";
const keyName='ContactsGrid';
export default class ContactsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'ContactPage';
        context.idName = 'contactid';
        context.pageSize = 300;
        context.paging = false;
        context.hiddenColumns=[context.idName];
        context.columnsDefaults =  [
            {column_name: "contactid", hidden:true,pos:0},
            {column_name: "contactactive", pos:1},
            {column_name: "fio",pos:2, flex:{grow:2}, title: true},
            {column_name: "cpost",flex:{grow:2}, pos:3,},
            {column_name: "hu_users",flex:{grow:2}, pos:4,},
            {column_name: "econs", hidden:true,},
        ];

    }

}