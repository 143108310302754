import React, {Component} from 'react';
import * as f from "../common/Funcs";
import {Dialog, Panel} from "@sencha/ext-react-modern";
import ErrorBoundary from "../ErrorBoundary";


export default class DialogForm extends Component {

    constructor(props) {
        super(props);
        const context = this;
        context.state = {
            content: {data: [], title: ''}
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const props = this.props;
        const  context=this;
        context.dialog.cmp.setMaxWidth(context.innerPanelMaxWidth(props.isNew));
        if (props.parent && props.parent.editForm && !props.parent.editForm.parentDialog) props.parent.editForm.setParentDialog(props.parent.editForm, context);
        if (props.editFormEl && props.propName && props.parent) {
            context.centerEl(props.parent[props.propName].cmp);
        }
    }

    componentWillUpdate(nextProps, nextState) {
/*        const props = this.props;
        const  context=this;
        //if (nextProps.isNew!=props.isNew) {
            context.dialog.cmp.setMaxWidth(context.innerPanelMaxWidth(nextProps.isNew));
        //    context.dialog.cmp.setMaxHeight(context.innerPanelMaxHeight(nextProps.isNew));
        // }
        if (props.parent && props.parent.editForm && !props.parent.editForm.parentDialog) props.parent.editForm.setParentDialog(props.parent.editForm, context);
        if (props.editFormEl && props.propName && props.parent) {
            context.centerEl(props.parent[props.propName].cmp);
        }*/
    }

    componentWillUnmount() {
        try {
            if (window.IasLoApp)
            delete window.IasLoApp.dialogs[this.cmpId];
        } catch (e) {
            const ee = e;
            debugger;
        }
    }

    centerEl(d) {
        if (!(d && d.toFront && d.el)) return;
        d.toFront();
        d.syncFloatWrap();
        if (d.el) {
            d.el.translate(0,0,0);
            d.el.setTop((window.innerHeight-d.el.getHeight())/2);
            d.el.setLeft((window.innerWidth-d.el.getWidth())/2);
        }
    }

    innerPanelMaxHeight(isNew) {
        const context = this;
        const editForm = (context.props.propName == 'dialog') ? context.props.parent.editForm : context.props.parent.relation;
        if (!(editForm && editForm.state && editForm.state.columns && editForm.state.columns.length > 0)) return context.props.editForm.props.height;
        const rowsHeight = (1 + editForm.state.columns.reduce((x, y) => (x.row > y.row) ? x : y).row) * 30;
        const toolsHeight = ((editForm.isEdit) ? (window.IasConfig.headerHeight * 2) :
            (window.IasConfig.headerHeight)) + f.getInnerHeightMenu();
        let h = 0;
        if (isNew) return (rowsHeight + toolsHeight);
        else {
            try {
                const a1 = editForm.a1;
                if (a1)
                    return window.toArray(a1.cmp.bodyElement.dom.children).reduce((s, e) => s + e.clientHeight, 0) + toolsHeight;
            } catch(e) {debugger;}
        }
    }

    innerPanelHeight(isNew = false) {
        const context = this;
        const base = (context.baseHeight) ? context.baseHeight : f.getCentralHeight();
        if (context.props.parent.relation) return null;
        return base+50;
    }

    innerPanelMaxWidth(isNew) {
        const context = this;
        const e=context.props.editFormEl;
        if (isNew&&(e.subTables&&e.subTables.length>1)) return '50%';
        if (e.props.maxWidth) return e.props.maxWidth;
        else return e.props.width;
    }

    render() {
        const context = this;
        const p = context.props.parent;
        const editFormEl = context.props.editFormEl;
        const propName = context.props.propName;
        return <Dialog
            hidden={!context.props.showEditPanel}
            x={null}
            title={context.props.title || ''}
            titleHeight={window.IasConfig.headerHeight}
            closable maximizable
            closeAction="hide"
            maskTapHandler={() => p.hideEditPanel(p)}
            layout={'fit'}
            maximized={false}
            height={context.innerPanelHeight(context.props.isNew)}
            // maxWidth={context.innerPanelMaxWidth(context.props.isNew)}
            width={editFormEl.props.width}
            ref={di => {
                if (di) {
                    p[propName] = di;
                    context.dialog = di;
                    context.cmpId = di.cmp.id;
                    if (window.IasLoApp) window.IasLoApp.dialogs[di.cmp.id] = di;
                }
            }}
            key={`dialog${p.subElementName}`}
            bodyPadding={window.IasConfig.dialogBodyPadding}
            defaultFocus="#ok"
            toFrontOnShow={true}
            onFocus={(sender) => {
                context.centerEl(sender);
            }}
            onShow={(e) => {
                context.centerEl(context.props.parent[context.props.propName].cmp);
                if (editFormEl.maximizeOnShow) {
                    e.sender.maximize();
                }
            }}
            onHide={() => {
                p.hideEditPanel(p);
                window.unmask();
            }}
            onResize={(a) => {
                const top = a.element.el.getTop();
                // if (top < 0 || top > 300)
                context.centerEl(a.element);
            }}
            onMaximize={(dialog) => {
                if (context.props.parent.editForm) {
                    context.props.parent.editForm.forceUpdate();
                }
            }}
            onRestore={(dialog) => {
                if (context.props.parent.editForm) {
                    context.props.parent.editForm.forceUpdate();
                }
            }}
            onActive={(newActiveItem, context, oldActiveItem, eOpts) => {
                debugger;
            }}
            maximizeAnimation={null}
            restoreAnimation={null}

        > <ErrorBoundary>{editFormEl}</ErrorBoundary>
        </Dialog>
    }
}

