import React from 'react';
import {
    Panel,
    FieldSet, FormPanel
} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";
import EditFormField from '../collections/EditFormField';
import BaseEditPage from "./BaseEditPage";
import ErrorBoundary from "../ErrorBoundary";

const keyName = 'BaseEditPage';

export default class BaseSinglePage extends BaseEditPage {

    constructor(props) {
        super(props);
        const context = this;
        //столбцов по умолчанию 2
        context.columnsCount = 1;
        context.tableName = context.props.tableName;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        //поле-ключ из приходящих данных
        context.elements = {};
        context.rowId = props.rowId;
        context.columnsDefaults = props.columns;
        context.columnsCount = props.columnsCount;
        context.subTables = [];
        context.elements = {};
        context.footerButtonNames = [];
        //меню страницы
        context.topMenuButtonNames = [];
        context.fieldsSet = [
            {title: ''}];
        context.state = {
            data: [], columns: [], title: '', rights: 'r'
        };
    }

    componentDidMount() {
        // this.getData(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        if (prevProps.tableName != context.props.tableName || prevProps.rowId != context.props.rowId) {
            if (window.IasConfig.devMode) console.debug(`${keyName} getData from componentDidUpdate`, arguments);
            context.getData({context});
        }
    }

    //функция запроса данных с сервера
    getData(_params) {
        const {context, defaults} = _params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        const prnt = context.props.parent;
        const tableName = context.props.tableName;
        const rowId = context.props.rowId;
        const lastUserColumns = [
            {
                column_name: 'last_user', is_list: true, disabled: true, cls: 'last-class',
                row: 1000, pos: 1, hidden: () => {
                    return context.state.data?.last_user == null
                },
                source: {
                    tableName: 'users',
                    titleField: 'fio',
                    idField: 'user_id'
                }
            },
            {
                column_name: 'last_modified',
                cls: 'last-class',
                width: 250,
                data_type: 'date',
                disabled: true,
                hidden: () => context.state.data?.last_modified == null,
                row: 1000,
                pos: 0
            }
        ];
        const params = {
            context: context, tableName: tableName, id: rowId, callback: (result) => {
                if (!result.columns) result = {columns: [], data: {}};
                context.setState({data: result.data}, () => {
                    let parent = context;
                    while (!((parent.singleColumns && parent.singleColumns.length) || (parent.props.singleColumns && parent.props.singleColumns.length)) && parent.props.parent) {
                        parent = parent.props.parent;
                    }
                    let singleColumns = (parent.props.singleColumns) ? parent.props.singleColumns : parent.singleColumns;
                    singleColumns = [...singleColumns, ...lastUserColumns];
                    result.columns = result.columns.map(c => {
                        const def = (singleColumns) ? singleColumns.filter(d => d.column_name == c.column_name)[0] : [];
                        //если есть значения по умолчанию, связь с родителем
                        c = {...c, ...def};
                        // c.readonly = true;
                        if (((parent.props.hiddenColumns) && parent.props.hiddenColumns.indexOf(c.column_name) > -1) ||
                            ((parent.hiddenColumns) && parent.hiddenColumns.indexOf(c.column_name) > -1)) c.hidden = true;
                        // c.disabled = true;
                        // result.data[c.column_name] = c.value;
                        return c;
                    });
                    // result.rights = 'r';
                    context.setState(result, () => {
                        context.state.columns.filter(c => c.is_list && c.source).map(c => {
                            (c.record = result.data);
                            if (context.elements[c.column_name].getRefData)
                                context.elements[c.column_name].getRefData(context.elements[c.column_name]);
                        });
                         return   (window.IasConfig.devMode) ? console.debug(`${keyName} dataGetted and Setted`, arguments) : ''
                        }
                    );
                });
            }
        };
        //заголовок окну
        if (tableName) {
            context.setState({columns: []}, () => {
                f.getSingleData(params);
            })
        }

    }

    innerPanelHeight(isNew = false) {
        const context = this;
        const base = (context.baseHeight) ? context.baseHeight : f.getCentralHeight();
        return base -
            ((context.isEdit) ? window.IasConfig.headerHeight * 2 :
                window.IasConfig.headerHeight) - ((isNew) ? 0 : context.fieldsSet.length * 25)
    }

    //поле формы
    getEditField(context, fsc, isNew) {
        //проверяем наличие группы и добавляем если надо
        const value = context.state.data[fsc.column_name];
        const editable = f.isEditRight(context.state.rights);
        try {
            const e = <EditFormField cname={context.props.tableName + '.' + fsc.column_name}
                                     columnDescription={fsc} key={fsc.column_name} record={context.state.data}
                                     isNew={false}
                                     ref={(el => context.elements[fsc.column_name] = el)}
                                     value={value} tableName={context.tableName} disableSingleEdit={context.props.disableSingleEdit} parent={context}
                                     dialog={context.props.parent.dialog}
                                     disabled={context.props.disableSingleEdit||!(context.isEditRight())} readonly={!context.isEditRight() || fsc.readonly}
            />;
            return e;
        } catch (error) {
            return null;
        }
    }

    //сформировать панель
    getSubPanel(context, fselements, idx, title) {
        const frows = [[]];
        fselements.map(el => {
                const record = (el.props.columnDescription && el.props.columnDescription.row) ? el.props.columnDescription.row : 0;
                if (!record || record == 0) frows[0].push(el);
                else {
                    if (!frows[record])
                        frows[record] = [];
                    frows[record].push(el);
                }
            }
        );
        const rows = frows.map((record, idx) => {
            return <FieldSet layout={{type: 'fit', align: 'left'}} key={`record${idx}`}>{record}</FieldSet>
        });
        return <Panel
            key={`fieldSet${idx}`}
            cls={'edit-window-panel'}
            hidden={fselements.length == 0} scrollable={true}
            ref={pnl => context['pnl' + idx] = pnl}>
            {rows}</Panel>
    }

    //распределить по столбцам
    getColumns(context, content) {
        const columns = [];
        const length = content.filter(c => !c.props.hidden).length;
        const count = (length == 1) ? 1 : context.props.parent.singleColumnsCount;
        if (!count) return columns;
        const width = (100 / count).toFixed(0);
        for (let i = 1; i <= count; i++) {
            columns.push(<Panel
                ref={a => context[`a${i}`] = a}
                key={`column${i}`}
                scrollable
                collapsed={false}
                modal={true}
                width={`${width}%`}
            >
                {content.filter((c, idx) =>
                    (idx >= ((length / count) * (i - 1)) &&
                        (idx < ((length / count) * i))) || c.props.column == idx
                )}
            </Panel>)
        }
        return columns;
    }

    render() {
        const context = this;
        //признак нового объекта
        const content = [];
        const fselements = [];
        if (context.state.columns && context.state.columns.length > 0)
            //выбрать поля и отсортировать
        {
            const fscolumns = context.state.columns.sort((a, b) => a.pos - b.pos);
            //элементы полей
            fscolumns.map(fsc => {
                const e = context.getEditField(context, fsc);
                if (e) fselements.push(e);
            });
            content.push(context.getSubPanel(context, fselements));
        }
        const columns = context.getColumns(context, content);

        const form = <ErrorBoundary key={`errFormPanel`}><FormPanel
            key={'formEditPanel'}
            cls={'edit-page'} bodyCls={'edit-page-body'}
            ref={f => context.form = f}
            maxHeight={f.getCentralHeight()}
            url={`${window.IasConfig.homePath}api/data/${context.tableName}/row/${context.rowId}`}
            layout={'fit'}
        >
            {columns}
            {/*{toolbar}*/}
        </FormPanel></ErrorBoundary>;

        const panel = <ErrorBoundary key={'errbaseeditpanel'}>
            <Panel
                layout={"fit"} margin="3"
                key={'baseeditpanel'}
                ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
                maxHeight={f.getCentralHeight()}
            >
                {content}
            </Panel></ErrorBoundary>;

        /**/
        // return (panel)
        return (form);
    }

}

