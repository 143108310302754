import {Fill, Stroke, Style, Text} from "ol/style";
import * as g from "../../common/gisFuncs";

const keyName = 'oopt.js';

const getStyleDesc = (feature, resolution) => {
    const sa = feature.getProperties().name.toLowerCase();
    const styleDesc = {fill: {color: 'rgba(0,0,0,0)'}};
    if (sa[0] != 'ф' ?? resolution > 1000) return styleDesc;
    switch (sa) {
        case 'федеральный государственный природный заказник, действующий':
            styleDesc.line = {color: 'rgba(230,0,0,1)', width: 2};
            break;
        case 'федеральный государственный природный заказник, не действующий':
            styleDesc.line = {color: 'rgba(52,52,52,1)', width: 2};
            styleDesc.fill = {color: 'rgba(212,207,199,0.1)'};
            break;
        case 'федеральный государственный природный заказник, перспективный':
            styleDesc.line = {color: 'rgba(52,52,52,1)', width: 2};
            break;
        case 'федеральный государственный природный заповедник, действующий':
            styleDesc.line = {color: 'rgba(230,0,0,1)', width: 2, lineDash: [10, 1, 1, 1]};
            break;
        case 'федеральный государственный природный заповедник, не действующий':
            styleDesc.line = {color: 'rgba(52,52,52,1)', width: 2};
            styleDesc.fill = {color: 'rgba(212,207,199,0.1)'};
            break;
        case 'федеральный государственный природный заповедник, перспективный':
            styleDesc.line = {color: 'rgba(52,52,52,1)', width: 2, lineDash: [10, 1, 1, 1]};
            break;
        case 'федеральный дендрологический парк и ботанический сад, действующий':
            styleDesc.line = {color: 'rgba(230,0,0,1)', width: 2, lineDash: [3, 3]};
            break;
        case 'федеральный лечебно-оздоровительная местность и курорт, действующий':
            styleDesc.line = {color: 'rgb(230,0,0)', width: 2, lineDash: [1, 5]};
            break;
        case 'федеральный национальный парк, действующий':
            styleDesc.line = {color: 'rgba(230,0,0,1)', width: 2, lineDash: [13, 5, 4, 0]};
            break;
        case 'федеральный национальный парк, перспективный':
            styleDesc.line = {color: 'rgba(52,52,52,1)', width: 2, lineDash: [13, 5, 4, 0]};
            break;
        case 'федеральный памятник природы, действующий':
            styleDesc.line = {color: 'rgba(230,0,0,1)', width: 2, lineDash: [8, 1, 3, 1, 3, 1]};
            break;
        case 'местный государственный природный заказник, действующий':
            styleDesc.line = {color: 'rgba(255,0,0,1)', width: 2};
        case 'региональный государственный природный заказник, действующий':
            styleDesc.line = {color: 'rgba(255,0,0,1)', width: 2};
            break;
        case 'региональный государственный природный заказник, не действующий':
        case 'местный государственный природный заказник, не действующий':
            styleDesc.line = {color: 'rgba(52,52,52,1)', width: 2};
            break;
        case 'местный памятник природы, действующий':
        case 'региональный памятник природы, действующий':
            styleDesc.line = {color: 'rgba(255,0,0,1)', width: 2, lineDash: [8, 1, 3, 1, 3, 1]};
            break;
        case 'местный памятник природы, не действующий':
        case 'региональный памятник природы, не действующий':
            styleDesc.line = {color: 'rgba(52,52,52,1)', width: 2, lineDash: [8, 1, 3, 1, 3, 1]};
            break;
        case 'региональный природный парк, действующий':
            styleDesc.line = {color: 'rgba(255,0,0,1)', width: 2, lineDash: [6, 3]};
            break;
        case 'местный природный парк, не действующий':
        case 'региональный природный парк, не действующий':
            styleDesc.line = {color: 'rgba(52,52,52,1)', width: 2, lineDash: [6, 3]};
            break;
        default:
            if (sa.indexOf('не действующий') >= 0)
                styleDesc.line = {color: 'rgba(52,52,52,1)', width: 2, lineDash: [1, 5]};
            else
                styleDesc.line = {color: 'rgba(255,0,0,1)', width: 2, lineDash: [1, 5]};
    }
    return styleDesc;
}

export default (feature, resolution) => {
    if (window.cssOn) console.debug(`${keyName} css`, feature);
    const styleDesc = getStyleDesc(feature, resolution);
    const w = new Style({
        stroke: new Stroke(styleDesc.line),
        fill: new Fill( styleDesc.fill),
        text: new Text({
            text: g.getText(feature, {resolution: resolution}), stroke: new Stroke({color: [255, 255, 255], width: 3}),
            fill: new Fill({color:'black'}),
            overflow: (resolution < 50)})
    });
    return [w];
}