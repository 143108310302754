import ErrorBoundary from "../../ErrorBoundary";
import React from 'react';
import BaseSubGridPanel from "../../Base/BaseSubGridPanel";
import BaseRelationGridPanel from "../../Base/BaseRelationGridPanel";
import BaseRelationSub from "../../Base/BaseRelationSub";

const keyName = `RbSpecSub`;

export default class RbSpecSub extends BaseSubGridPanel {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.subElementName = `SpecRbPage`;
        context.topMenuButtonNames=['add','edit','reload'];
        context.gridName=`RbSpecSubGrid`;
        context.noSingle=true;

    }

    //заголовок окна
    setDialogTitle(context) {
        context.dialog.cmp.setTitle(`Краснокнижное описание вида ${context.grid.getCurrentRow()[`name_rus`]}`);
    }

}

