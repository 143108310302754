import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

const keyName = `BankPropsPage`;

export default class BankPropsPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.subElementName = 'BankPropPage';
        context.tableName = 'bank_props';
        context.idName = 'row_id';
        context.subTables=[
            {
                title: 'Изменения',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: ()=>!f.isAdmin(),
                filterString: "table_name ='bank_props' and record_id = $row_id$::varchar",
            }
        ];

        context.columnsDefaults =[...context.lastUserColumns,...[
            {column_name: 'bik', pos: 0, row: 0},
            {column_name: 'bank_name', pos: 1, row: 0},
            {column_name: 'cor_account', pos: 3, row: 1},
            {column_name: 'rs_account', pos: 4, row: 1},
            {column_name: 'acc_user', hidden: true, pos: 0, row: 0},
            {column_name: 'bank_branch', pos: 2, row: 0}]];
        context.footerButtonNames = ['saveclose', 'save', 'delete', 'close'];
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
    }

}


