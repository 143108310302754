import React from 'react';
import {ExtGrid, ExtRownumberer, Panel,} from '@sencha/ext-react-modern';

import * as f from "../../common/Funcs";
import BaseGrid from "../../Base/BaseGrid";
import GridField from "../../collections/GridField";
import * as z from "../../common/zmuFuncs";
import ErrorBoundary from "../../ErrorBoundary";

const keyName = `PasTableSubGrid`;
const Ext = window['Ext'];
Ext.require('Ext.grid.plugin.Summary');

export default class PasTableSubGrid extends BaseGrid {

    static defaultProps = {
        data: [], vdata: [], pageSize: 50, tableName: '', idName: '', rowHeight: 20, rowId: null
    }

    constructor(props) {
        super(props);
        const context = this;
        context.elements = {};
        context.rowNumberWidth = '40px';
        context.editStyle = 'rowedit';
        context.columnsDefaults = [{column_name: "row_id"},
            {column_name: "huntuser_name", width: 250, pos: 1, docked: 'left'},
            {column_name: "spec_label", width: 140, pos: 0, docked: 'left'},
            {column_name: "region_name", width: 140, pos: 0, docked: 'left'},
            {column_name: "economy_name", minWidth: 400, docked: 'left'},
            {column_name: "spec_name", title: true},
        ];
        const storeProps = {
            data: context.state.data
        };
        storeProps.groupField = 'region_name';
        context.store = Ext.create('Ext.data.Store', storeProps);
    }

    componentDidMount = () => {
        const context = this;
        // context.getData(context, context.props.parent.activeColumn);
         context.getData({context});
    }

    getHeight(context) {
        const itemsCount = context.state.data.length;
        const result = (1 + itemsCount) * (context.props.rowHeight + 2);
        return result;
    }

    getMaxHeight(context) {
        const itemsCount = context.state.data.length;
        const result = (1 + itemsCount) * context.props.rowHeight;
        const height = context.getHeight(context);
        if (result > height)
            return result;
        else
            return height;
    }

    //загрузка данных
    getData(params) {
        const {context} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName(), params);
        context.setState({columns: []}, () => {
            context.store.clearData();
            context.store.loadRecords([]);
            context.inload = true;
            z.getPassTableData({
                    context: context.props.appViewPort,
                    tableId: context.tableId,
                    pasId: context.props.rowId,
                    callback: (result) => {
                        context.isLoaded = true;
                        context.inload = false;
                        result.columns = context.columnsDefaults;
                        context.props.parent.setState({rights: result.rights})
                        let upData = {
                            columns: result.columns,
                            data: result.data,
                            error: (result.flash) ? result.flash.map(f => f.text) : []
                        };
                        if (context.afterGetData) upData = context.afterGetData(context, upData);
                        context.isLoaded = true;
                        context.setState(upData, () => {
                            context.store.loadRawData(result.data);
                            setTimeout(() =>
                                result.columns.filter(c => c.is_list && c.source).map(c =>
                                    (c.record = result.data) && context.elements[c.column_name].getRefData(context.elements[c.column_name])), 1000);
                        });
                    }
                }
            )
        })
    }

    //добавляет пустые строки
    afterGetData(context, upData) {
        const emptyRow = {};
        context.columnsDefaults.map(c => {
            emptyRow[c.column_name] = null
        });
        while (upData.data.length < 6) {
            emptyRow.spec_id1 = upData.data.length;
            upData.data.push({...emptyRow});
        }
        return upData;
    }

    afterEdit(context, sender, location, eOpts) {
        //признак измененной записи
        // location.record.data.spec_id1 = context.state.ref.filter(r => r.title == location.record.data.spec_name1)[0].value;
        location.record.data.spec_id1 = context.elements.spec_name1.state.ref.filter(r=> r.title == location.record.data.spec_name1)[0].value;
        return true;
    }

    beforeEdit(context, sender, location) {
        //признак измененной записи
        const allow = f.isEditRight(context.props.parent.state.rights);
        if (allow) location.record.data.modified = true;
        return allow;
    }

    getGrid(context, columns) {
        return <ExtGrid
            layout={'fit'}
            key={keyName + 'grid'}
            cls={'acc-grid no-copy'}
            name={keyName}
            height={context.getHeight(context)}
            maxHeight={context.getMaxHeight(context)}
            rowHeight={context.props.rowHeight}
            rowNumbers={{
                width: context.rowNumberWidth
            }}
            plugins={context.editStyle}
            itemConfig={context.getItemConfig(context)}
            store={context.store}
            ref={grid => context.grid = grid}
            variableHeights={false}
            sortable={false}
            listeners={{
                columnmenucreated(grid, column, menu, eOpts) {
                    menu.on('beforeshow', (sender) => {
                        sender.items.items.map(i => {
                            f.localeMenu(i);
                        });
                    });
                },
                edit: (sender, location, eOpts) => context.afterEdit(context, sender, location, eOpts),        // не вызывается
                beforeedit: (sender, location) => context.beforeEdit(context, sender, location)
            }}
        >
            {columns}
        </ExtGrid>
    }

    getColumn(context, c) {
        const hidden = ((context.hiddenColumns && context.hiddenColumns.indexOf(c.column_name) > -1) || c.hidden || c.column_name == 'order_value');
        const isNum = (c.data_type == 'integer' || c.data_type == 'numeric');
        if (isNum) {
            if (!c.width)
                c.width = 80;
            c.editable = true;
            c.minWidth = 30;
        } else {
        }

        return <GridField
            key={c.column_name}
            column_name={c.column_name}
            column_label={c.column_label}
            columnDescription={c}
            flex={c.flex}
            readonly={c.readonly}
            hidden={hidden}
            className={'no-copy'}
            getColor={c.getColor}
            colored={c.colored}
            parent={context}
            grid={context}
            relation={c.relation}
            title={c.title}
            align={c.align}
            ignoreExport={c.ignoreExport}
            editable={c.editable}
            data_type={c.data_type}
            width={c.width}
            minWidth={c.minWidth}
            maxWidth={c.maxWidth * ((context.state?.data?.length > 10) ? 0.95 : 1)}
            ref={(el => {
                if (el) {
                    context.elements[c.column_name] = el;
                }
            })}
            ignorePrint={c.ignorePrint}
        />
    }

    render() {
        const context = this;
        const columns = [];
        const content = [];
        if (!context.inload) {
            const loadedtext = context.emptyMessages(context.state.error);
            if (loadedtext) content.push(loadedtext);
            if (context.isLoaded && !loadedtext && context.state.columns) {
                window.unmask();
                /*заполнение полей для store*/
                context.store.fields = context.state.columns.filter(c => c.column_name !== context.idName);
                /*обработка ячейки с признаком редакции*/
                context.state.columns.sort((a, b) => (a.pos == null) ? 1 : a.pos - b.pos).map(c => {
                    const column = context.getColumn(context, c);
                    /*форматирование ячеек*/
                    columns.push(column);
                });
            }
            const itemConfig = (context.tpl) ? {
                body: {
                    tpl: context.tpl()
                }
            } : {};
            //тело таблицы
            if (columns.length > 0) {
                const grid = context.getGrid(context, columns);
                content.push(grid);
                window.testGrid = grid;
            }
        } else {
            window.mask();
        }
        //панель инструментов гриды. Внутреннее меню страницы - в base...page
        content.push(context.getToolBar());

        return (
            <Panel key={keyName + 'key'} ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}>{content}</Panel>)
    }
}
