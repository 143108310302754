import BaseEditPage from "./BaseEditPage";
import * as g from "../common/gisFuncs";
import * as f from '../common/Funcs';
import landstyle from "../Maps/styles/landspol";
import admstyle from "../Maps/styles/admpol";

const keyName = "BaseEditByMapPage";

export default class BaseEditByMapPage extends BaseEditPage {
    static defaultProps = {
        height: '95%',
        width: '95%'
    }
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'meetings';
        context.idName = 'row_id';
        context.columnsCount = [{width: '25'}, {width: '75'}];
        context.fieldsSet =     [
            {title: 'Факт встречи',fieldset: 'meeting',column:0,pos:1},
            {title: 'Описание животного',fieldset:'spec',column:0,pos:2},
            {title:'Карта',fieldset:'map',pos:10,column:1,block:1}
        ];
        context.columnsDefaults =[...context.lastUserColumns,... [
            {column_name:'meeting_num',row:0,pos:0},
            {pos: 0,                 labelAlign: 'left',
                column_name: 'meeting_type', hidden: true},
            {
                pos: 1, row:0,
                column_name: 'meeting_spec',
                labelAlign: 'left',
                fieldset : 'spec',
                is_list: true,
                source: {
                    tableName: 'meetings_spec',
                    titleField: 'spec_name',
                    idField: 'meeting_spec',
                }
            },
            {
                pos: 0,row:1,
                labelAlign: 'left',
                column_name: 'meeting_huntuser',
                is_list: true,
                source: {
                    tableName: 'hunt_users',
                    titleField: 'user_name',
                    idField: 'huntuser_id',
                }
            },
            {
                pos: 0,row:2,
                labelAlign: 'left',
                column_name: 'meeting_oktmo',
                is_list: true,
                source: {
                    tableName: 'regions',
                    titleField: 'region_name',
                    idField: 'oktmo',
                }
            },
            // {column_name:'meeting_date',
            //     labelAlign: 'left',
            //     row:6,pos:6},
            {column_name:'meeting_place',
                labelAlign: 'left',
                pos:7,row:9},
            {column_name:'meeting_comment',
                labelAlign: 'left',
                pos:8,row:20},
            {column_name: 'meeting_count',
                labelAlign: 'left',
                row:1, fieldset: 'spec'},
            {column_name: 'meeting_direction',
                labelAlign: 'left',
                row:4, fieldset: 'spec'},
            {column_name:'geom',pos:11,block:1,fieldset:'map',
                mapButtonNames:['edit','drop'],
                layerNames: [
                    {
                        layerName: 'landspol', hidden: false, type: 'own', styleFunction: landstyle
                    },
                    {
                        layerName: 'admpol', hidden: false, type: 'own', styleFunction: admstyle
                    },
                ]},
        ]];
        context.footerButtonNames = ['saveclose', 'save','delete', 'close'];
    }

    //заголовок формы редактирования по умолчанию
    titleFunc(context, record, parentRow) {
        try {
            context.title = ` ${((record['meeting_date']) ? new Date(record['meeting_date']).toLocaleDateString() : '--.--.-- ')}`;
        } catch (e) {
            context.title = ` --.--.-- `;
        }
        return context.title;
    }

    //есть ли геометрия маршрута
    hasGeom() {
        const context = this;
        const feature = context.olMap?.featuresSource?.getFeatures()[0];
        if (!feature) return false;
        else return feature;
    }

    saveGeometry(params) {
        let {context, feature, path,callback}=params;
        if (!path) path='zmu/routes/gis/';
        if (!feature) feature=context.hasGeom();
        if (!callback) callback=()=>{
            context.olMap.props.parent.getData({context:context.olMap.props.parent});
        };
        if (!feature) return f.toast('Нет данных для сохранения.')
        const geometry = g.toGeoJson(feature);
        if (geometry) {
            let values = new FormData();
            values.append('geometry', JSON.stringify(geometry));
            f.setObjectData({
                context: context,
                id: (context.rowId),
                path,
                values: values,
                callback: callback
            })
        } else
            callback();
    }

}


