import ErrorBoundary from "../../../ErrorBoundary";
import React,{Component} from "react";
import BaseZmuAnalizePage from "../../../Base/ZMU/BaseZmuAnalizePage";

const keyName = `ZmuPRAnalizePage`;
const title=`Зимний маршрутный учет. Анализ исследуемых территорий.`;
export default class ZmuPRAnalizePage extends BaseZmuAnalizePage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName="RAnalizGrid";
        context.subElementName = 'RoutePage';
        context.tableName = 'routes';
    }

}