import ErrorBoundary from "../../../ErrorBoundary";
import React from "react";
import BaseByMapGrid from "../../../Base/BaseByMapGrid";
import * as f from "../../../common/Funcs";
import BaseZMUGrid from "../../../Base/ZMU/BaseZMUGrid";
import BasePageGrid from "../../../Base/BasePageGrid";

const keyName = 'CorrectGrid';

// export default class RoutesGrid extends BaseZMUGrid {
export default class CorrectGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = `routes`;
        context.pageSize = 300;
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName];
        context.columnsDefaults = [
            {column_name: "route_name", pos: 1, className: 'no-copy', title: true, maxWidth: 100, minWidth: 100},
            {column_name: "region_name", className: 'no-copy', pos: 2, minWidth: 150, maxWidth: 150},
            {
                column_name: "huntuser_name",
                className: 'no-copy',
                flex: {grow: 2},
                pos: 3,
                width: 200
            },
            {
                column_name: "year_on",
                pos: 4, minWidth: 75, maxWidth: 100, column_label: 'С...'
            },
            {
                column_name: "year_off", column_label: 'По...', minWidth: 75, maxWidth: 100,
                pos: 5
            },
            {
                column_name: "route_active",
                pos: 0
            },
            {"column_name": "geom", className: 'no-copy', ignoreExport: true, pos: 1},
            {"column_name": "spec_id", "data_type": "integer", pos: 1, hidden: true},
            {
                "column_name": "oktmo",
                "data_type": "numeric", pos: 1, hidden: true
            },
            {"column_name": "spec_name", "data_type": "character varying", pos: 20},
            {
                "column_name": "region_name",
                "data_type": "character varying", pos: 10
            },
            {"column_name": "qy1", "data_type": "integer", pos: 30},
            {
                "column_name": "qy0",
                "data_type": "integer", pos: 40
            },
            {"column_name": "diff", "data_type": "integer", pos: 50,minWidth:250}
        ]
    }

    componentDidMount() {
        super.componentDidMount();
        // context.getData(context, context.props.parent.activeColumn);
    }

    getHeight() {
        return f.getCentralHeight() - ((!this.paging) ? 40 : 70);
    }
}