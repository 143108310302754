import ErrorBoundary from "../../../ErrorBoundary";
import BaseAnalizGrid from "../../../Base/ZMU/BaseAnalizGrid";
import React,{Component} from "react";

const keyName = 'RAnalizGrid';

export default class RAnalizGrid extends BaseAnalizGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'RoutePage';
        context.zmuTableId='routes';
        context.objectType='route';
        context.tableName = `routes`;
        context.idName = 'route_id';
        context.hiddenColumns = ['route_id', 'acc_year']
        context.columnsDefaults = [
            {column_name: 'route_name', pos: 1, minWidth: 120, title: true},
        ]
    }
    // render() {
    //     return super.render();
    // }
}