import BaseSubGrid from "../../Base/BaseSubGrid";
import ErrorBoundary from "../../ErrorBoundary";

const keyName=`HuntCodesSubGrid`;

export default class HuntCodesSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'huntusers_codes';
        //элемент окна редактирования
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName, 'codes_huntuser'];
        context.singleColumns=[
            {column_name:'inn',row:0,pos:1},
            {column_name:'code_active',row:0,pos:5},
            {column_name: "kpp", row: 0,pos:2},
            {column_name: "okved",row:0,pos:3},
            {column_name: "okpo", row: 1 ,pos:4},
            {column_name: "oktmo", row: 1,pos:5},
            {column_name: "okonh", row: 1,pos:6},
            {column_name: "ogrn", pos: 1,row: 1},
            // {column_name: "date_off", row: 1},
            {column_name: 'date_on', row:1},
            {column_name: "codes_huntuser", row: 4,hidden:true},
            {column_name: "row_id", row: 4,hidden:true},
        ];

    }

}