import React from 'react';
import { Panel,} from '@sencha/ext-react-modern';

import PasTableSubGrid from "../../../Base/ZMU/PasTableSubGrid";

const keyName = `PasTable1SubGrid`;
const Ext = window['Ext'];
Ext.require('Ext.grid.plugin.Summary');

export default class PasTable1SubGrid extends PasTableSubGrid {

    constructor(props) {
        super(props);
        const context = this;
        context.tableId = 'pas_animal_data'
        context.editStyle = 'cellediting';
        context.columnsDefaults = [
            {
                column_name: 'spec_id1',
                data_type: 'integer',
                pos: 1,
                hidden: true,
            },
            {column_name: 'spec_name1', data_type: 'text', pos: 2,
                minWidth: 150,
                width: 150},
            {
                column_name: 'count_fst1',
                data_type: 'integer',
                pos: 3,
                width: 50,
            },
            {column_name: 'count_fld1', data_type: 'integer', pos: 4, width: 50},
            {
                column_name: 'count_bog1',
                data_type: 'integer',
                pos: 5,
                width: 50
            },
            {column_name: 'empty', data_type: '', pos: 6, hidden: true, width: 50},
            {
                column_name: 'spec_id2', hidden: true,
                data_type: 'integer',
                pos: 7,
            },
            {column_name: 'spec_name2', data_type: 'text', pos: 8,
                minWidth: 150,
                width: 50},
            {
                column_name: 'count_fst2',
                data_type: 'integer',
                pos: 9,
                minWidth: 150
            },
            {column_name: 'count_fld2', data_type: 'integer', pos: 10, width: 50},
            {
                column_name: 'count_bog2',
                data_type: 'integer',
                pos: 11,
                width: 50
            },
        ];
        const storeProps = {
            data: context.state.data
        };
        context.store = Ext.create('Ext.data.Store', storeProps);
    }

    // componentDidMount = () => {
    //     const context = this;
    //      context.getData(context);
    // }

    afterGetData(context, upData) {
        return upData;
    }

    render() {
        // if (window.IasConfig.devMode) console.debug(`${keyName} render`);
        const context = this;
        const columns = [];
        const content = [];
        if (!context.inload) {
            window.unmask();
            /*заполнение полей для store*/
            context.store.fields = context.state.columns.filter(c => c.column_name !== context.idName);
            /*обработка ячейки с признаком редакции*/
            context.state.columns.sort((a, b) => (a.pos == null) ? 1 : a.pos - b.pos).map(c => {
                //const column = context.getExtColumn(c);
                const column = context.getColumn(context, c);
                /*форматирование ячеек*/
                columns.push(column);
            });
        }
        const itemConfig = (context.tpl) ? {
            body: {
                tpl: context.tpl()
            }
        } : {};
        //тело таблицы
        if (columns.length > 0) {
            const grid = context.getGrid(context, columns);
            content.push(grid);
            window.testGrid = grid;
            // content.push(context.getPaging(context, columns));
        }
        //панель инструментов гриды. Внутреннее меню страницы - в base...page
        content.push(context.getToolBar());

        return (<Panel key={keyName + 'pankey'}
                       ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >{content}</Panel>)
    }
}
