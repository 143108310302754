import ErrorBoundary from "../ErrorBoundary";
import React, {Component} from 'react';

import {
    Panel,
    FormPanel,
    Textfield,
    Button,
    Toolbar,
} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";

const Ext = window['Ext'];
const keyName = `Url`;

export default class url extends Component {
    constructor(props) {
        super(props);
        const context = this;
        context.state = {
            content: {data: [], title: ''}
        };
    }

    render() {
        const context = this;
        const gotoLogin=()=>{
            context.props.appViewPort.rePage('Login');
        }
        const send = (sender) => {
            const requestOptions = {
                method: 'POST',
                //     mode:'no-cors',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(Ext.ComponentQuery.query("formpanel")[0].getValues())
            };
            document.setCookies();
            fetch(`${window.IasConfig.homePath}users/url`,
                requestOptions
            ).then(res => {
                    return res.json();
                }
            )
                .then(
                    (result) => {
                        if (result && result.status) f.toast({
                            title: 'Сброс пароля',
                            message: 'На почтовый ящик, привязанный к логину, отправлена ссылка для сброса пароля.'
                        })
                        gotoLogin();
                    },
                    (e) => {
                        debugger;
                        return f.alert({title: 'Ошибка', message: `${e}`})
                    })
        };
        return <Panel
            key={keyName}
            height={f.getCentralHeight()}
            width={200} region={"center"} margin="3">
            <FormPanel method={"POST"} key={`${keyName}Form`} id={`${keyName}Form`} title="Сброс пароля">
                <Textfield id={'loginField'} name={'login'} label="Имя пользователя (логин) для сброса пароля"
                           required
                           placeholder="Это обязательное поле"/>
                <Toolbar shadow={false} docked="bottom" layout={{type: 'hbox', pack: 'right'}}>
                    <Button text="Запросить ссылку" handler={send}/>
                    <Button text="Вернуться" handler={gotoLogin}/>
                </Toolbar>
            </FormPanel>
        </Panel>
    }
}

