import React from "react";
import BaseByMapGrid from "../../Base/BaseByMapGrid";
import ErrorBoundary from "../../ErrorBoundary";

const keyName='ViolationGrid';

export default class ViolationsGrid extends BaseByMapGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'DocumentPage';
        // context.tableName = `documents`;
        context.idName = 'row_id';
        context.hiddenColumns=[context.idName];
        context.columnsDefaults = [
            {"column_name": "row_id", "pos": 0,hidden:true}, {
                "column_name": "doc_type",
                "pos": 0,title:true
            }, {"column_name": "blank_num", "pos": 1,title:true},
            {"column_name": "doc_num", "pos": 2,title:true},
            {
                "column_name": "doc_date",
                "pos": 3
            },
            {"column_name": "user_name", "pos": 5,minWidth: "200px"},
            {
                "column_name": "region_name",
                "pos": 4, minWidth:'120px'
            },
            {"column_name": "viol_date", "pos": 6}, {
                "column_name": "inspector",
                "pos": 7,minWidth: "200px"
            }, {"column_name": "viol_person", "pos": 8,minWidth: "200px"}, {
                "column_name": "viol_bday",
                "pos": 9
            }, {"column_name": "viol_bplace", "pos": 10,minWidth: "160px"}, {
                "column_name": "viol_adress",
                "pos": 11,minWidth: "200px"
            }, {"column_name": "viol_phone", "pos": 12}, {
                "column_name": "viol_jplace",
                "pos": 13,minWidth: "200px"
            }, {"column_name": "pasp_type", "pos": 14}, {"column_name": "pasp_num", "pos": 15}, {
                "column_name": "pasp_where",
                "pos": 16,minWidth: "200px"
            }, {"column_name": "pasp_date", "pos": 17}, {
                "column_name": "huntcard_seria",
                "pos": 18
            }, {"column_name": "huntcard_num", "pos": 19}, {
                "column_name": "huntcard_where",
                "pos": 20,minWidth: "200px"
            }, {"column_name": "huntcard_date", "pos": 21}, {"column_name": "koap", "pos": 22}, {
                "column_name": "mrights",
                "pos": 0
            }];
    }

}