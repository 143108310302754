import ErrorBoundary from "../../../ErrorBoundary";
import BaseAnalizGrid from "../../../Base/ZMU/BaseAnalizGrid";

const keyName = 'PasEaAnalizGrid';

export default class PasEaAnalizGrid extends BaseAnalizGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = null;
        context.zmuTableId='pas_ea';
        context.objectType='pas_ea';
        context.tableName = `ea_table`;
        context.idName = 'area_id';
        context.hiddenColumns = ['area_id', 'acc_year']
        context.columnsDefaults = [
            {column_name: 'area_name', pos: 1, minWidth: 120, title: true},
        ]
    }
}