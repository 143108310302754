import React from "react";
import BaseZmuGrid from "../../Base/ZMU/BaseZMUGrid";
import * as f from "../../common/Funcs";

const keyName = 'BaseAnalizGrid';

export default class BaseAnalizGrid extends BaseZmuGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'EaPage';
        context.zmuTableId='ea';
        context.rowNumberWidth = '40px';
        context.tableName = `ea_table`;
        context.idName = 'area_id';
        context.hiddenColumns = ['area_id', 'acc_year']
        context.pageSize = 500;
        context.columnsDefaults = [
            {column_name: 'area_name',  pos: 1, minWidth: 120, title: true},
        ]
    }

    renderRepeatTest(params) {
        let {record, context} = params;
        const clickFunc = (k) => {
            const id = record.data[context.idName];
            const callTest = (k) => {
                const reloadRow = (result) => {
                    context.getData({context, id});
                }
                f.callTests({zmuTableId: context.zmuTableId, objectId: id, callback: reloadRow,wait:true});
            };
            f.callCalcs({zmuTableId: context.zmuTableId, objectId: id, callback: callTest,wait:true});
        };

        return <div key={'repeat_test'} className="x-fa fa-repeat" onClick={() => clickFunc()}/>;

    };

    renderRepeatCalc(params) {
        let {record, context} = params;
        const clickFunc = (k) => {
            const id = record.data[context.idName];
                const reloadRow = (result) => {
                    context.getData({context, id});
            };
            f.callCalcs({zmuTableId: context.zmuTableId, tableId:'ea_quantity',objectId: id, callback: reloadRow,wait:true});
        };

        return <div key={'repeat_calc'} className="x-fa fa-repeat" onClick={() => clickFunc()}/>;

    };

    updateSingleRow(result) {
        const context=this;
        if (result.columns) {
            const row=            this.store.getData().items.find(i => i.data[context.idName] == result.data[0][context.idName]);
            const data=result.data[0];
            result.columns.map(c=>{
                    row.set(c.column_name,data[c.column_name]);
            })
        }
    }

    getData(params) {
        const {context, id,callback} = params;
        if (window.IasConfig.devMode) console.debug(window.funcName(),params);
        const call = () =>                /*пересчитать тесты*/
            f.callPath({
                context: context.props.appViewPort,
                id: id,
                path: `zmu/${context.zmuTableId||context.objectType}/test_grid/${(id)?'':'undefined'}`,
                callback: (result) => {
                    context.isLoaded = true;
                    context.inload = false;
                    if (id) return context.updateSingleRow(result);
                    if (!result.columns) return;
                    result.columns.push({
                        column_name: 'repeat_test',
                        column_label:'Обновить тесты',
                        className:'rotate-header',
                        data_type: 'custom',
                        renderer: (params) => {
                            params.context = context;
                            return context.renderRepeatTest(params);
                        },
                        maxWidth: 80,
                        pos: 23
                    });
                    result.columns.push({
                        column_name: 'repeat_calc',
                        data_type: 'custom',
                        className:'rotate-header',
                        column_label:'Пересчитать',
                        renderer: (params) => {
                            params.context = context;
                            return context.renderRepeatCalc(params);
                        },
                        maxWidth: 80,
                        pos: 22
                    });
                    result.columns = result.columns.map(c => {
                        if (context.columnsDefaults) {
                            const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                            c = {...c, ...def};
                            if (c.column_name.indexOf('test') > -1) {
                                c.className = 'rotate-header';
                                c.width = 80;
                                c.minWidth = c.width;
                            }

                        }
                        return c;
                    }).sort((a, b) => {
                        return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                    });
                    const upData = {
                        columns: result.columns,
                        pageCount:result.pageCount,
                        vdata: result.data,
                        error: (result.flash) ? result.flash.map(f => f.text) : []
                    };
                    context.afterGetData(context, upData);
                    context.props.parent.setState({acc_form: result.desc?.acc_form},()=>(callback)?callback(result):'');
                }
            }).then(r => void 0);
        if (!id) {
            context.setState({columns: []}, () => {
                    context.store.clearData();
                    context.store.loadRecords([]);
                    context.inload = true;
                    call();
                }
            )
        } else {
            call();
        }
    }

    resultUpdate(result) {
        result.columns.unshift({
            column_name: 'colorfield',
            data_type: 'color',
            color_field: this.idName,
            pos: 0,
        });
        return result;
    }

}