import BasePageGrid from "../../Base/BasePageGrid";
import ErrorBoundary from "../../ErrorBoundary";
import React from "react";

const keyName = 'NewsGrid';
export default class NewsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName];
        context.columnsDefaults = [
            {column_name:'section',maxWidth:300,title:true},
            {column_name:'news_text',title:true},
        ]
    }
}