import ErrorBoundary from "../../ErrorBoundary";
import BaseSubGrid from "../../Base/BaseSubGrid";

const keyName = `CardLogSubGrid`;

export default class CardLogSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'log_view_short';
        //элемент окна редактирования
        context.idName = 'log_id';
        context.hiddenColumns =['log_id', 'record_id', 'table_name'];
        context.columnsDefaults=[
            {column_name: "op_name", maxWidth:75,pos: 10}
            , {column_name: "record_dif", pos: 2, title: true}
            , {column_name: "modified", maxWidth:100, pos: 3}
            , {column_name: "fio", pos: 6}
        ];
    }

}