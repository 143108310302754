import ErrorBoundary from "../ErrorBoundary";
import BaseEditPage from "../Base/BaseEditPage";
import * as f from "../common/Funcs";

const keyName = "ProfilePage";
export default class ProfilePage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.maximizeOnShow=false;
        context.columnsCount = 1;
        context.isUser = true;
        context.tableName = 'users';
        context.idName = 'user_id';
        context.titleName = 'fio';
        context.height=f.getCentralHeight();
        context.rowId = context.props.rowId;
        context.fieldsSet = [
            {name: 1, title: 'Основная информация'},
        ];
        context.columnsDefaults = [
            {column_name:'fio',required:true,disabled: false, row:0},
            {column_name: 'active', disabled: true, row:1},
            {column_name: 'isblocked', disabled: true, row:1},
            {column_name: 'password',pos:100, row:2},
            {column_name: 'phone', disabled: false, row:3},
            {column_name: 'email', disabled: true, row:3},
            {"column_name": "user_contact",hidden:true}
        ];
        context.subTables = [
            {
                title: 'Роли пользователя',
                elementName: 'BaseSubGridPanel',
                subName:'UserRolesSub',
                tableName: 'userrolesview',
                topMenuButtonNames:["reload"],
                subElementName: 'UserRolePage',
                height:'100%',
                rights:'r',
                filterString: "role_user=$user_id$",
                idName: 'row_id',
                noSingle:true,
                hiddenColumns: ['role_user', 'row_id'],
            },
        ]
    }
    componentDidMount() {
        const context=this;
        context.props.appViewPort.setState({filters:[],rightWidth:context.rightWidth},
        context.getData({context}));
    }

    innerPanelHeight() {
        const context = this;
        const base =  f.getCentralHeight();
        return base +20-
            ((context.isEdit) ? window.IasConfig.headerHeight * 2 :
                window.IasConfig.headerHeight)
    }

    //функция запроса данных с сервера
    getData(_params) {
        const {context}=_params;
        const parent = context.props.parent;
        if (window.IasConfig.devMode) console.debug(`${keyName} getData`);
        const params = {
            context: context, id: 'cookie', callback: (result) => {
                result.columns = result.columns.map(c => {
                    const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                    //если есть значения по умолчанию, связь с родителем
                    const ext = (context.props.pageDefaults) ?
                        context.props.pageDefaults.filter(d => d.column_name == c.column_name)[0] :
                        {};
                    c = {...c, ...def, ...ext};
                    return c;
                });
                context.rowId=result.data.user_id;
                if (context.props.rights) result.rights = context.props.rights;
                context.setState(result, () => {
                    try {
                        context['pnl0'].cmp.expand();
                        result.columns.filter(c => c.is_list && c.source).map(c => context.elements[c.column_name].getRefData(context.elements[c.column_name]))
                    } catch (e) {
                        debugger;
                    }

                });
            }
        };
        f.getUserData(params);
    }

    setData(context, submit) {
        f.setUserData({
            context: context,
            id: 'cookie',
            form: context.form,
            callback: submit
        })
    }
    //закрыть редактирование
    onCancel(context) {
        context.props.appViewPort.rePage(context.props.appViewPort.state.lastPage)
    }
}