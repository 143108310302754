import BasePageGrid from "../../Base/BasePageGrid";
import ErrorBoundary from "../../ErrorBoundary";
import React from "react";
const Ext = window['Ext'];

const keyName = 'DocumentsGrid';
export default class DocumentsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'DocumentPage';
        // context.tableName = `documents`;
        context.idName = 'documentid';
        context.hiddenColumns = [context.idName];
        context.pageSize=300;
        // context.noPaging = false;
        context.columnsDefaults=[
            {column_name:'docname',title:true,pos:1,minWidth:'400',flex:{grow:2}},
            {column_name:'docorg',pos:2,minWidth:'300',flex:{grow:2}},
            {column_name:'docactive',pos:0,minWidth: "40",maxWidth:"40"},
            {column_name:'doc_rights',pos:3,ignorePrint: true,ignoreExport: true},
            {column_name:'docpath',pos:4,ignorePrint:true,ignoreExport:true,tableName:'documents'},
            {column_name:'doc_type',hidden:true}
        ];
        //группировка
        const storeProps = {
            data: context.state.data
        };
        // context.grouped = context.props.grouped || true;
        // if (context.grouped) {
        //     context.groupFooter = context.props.groupFooter || {
        //         xtype: 'gridsummaryrow'
        //     };
        //     context.groupField = props.groupField || 'doc_type';
        //     context.groupHeaderTpl =
        //         `<div>{value:htmlEncode}, количество документов На странице: {count}</div>`
        //
        //     storeProps.grouper= {
        //         property: context.groupField, sortProperty: 'order_value',
        //     };
        // }
        context.store = Ext.create('Ext.data.Store', storeProps);
    }

}