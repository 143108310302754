import React from 'react';
import DocumentsSub from "./DocumentsSub";

const keyName = `HuntUserDocumentsSub`;

export default class HuntUserDocumentsSub extends DocumentsSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.gridName=`HuntUserDocumentsSubGrid`;
    }

    //кнопки для меню
    addAdditButtons() {
        const context = this;
        //если есть поле "активный" чекбокс - показать все
        if (context.activeColumn) context.addShowAll();
        context.addRegion();
    }
    addRegion() {
        const context = this;
        const key = 'region';
        if (document.getCookie('regionFilter'))
        context.topMenuButtons.unshift({
            iconCls: `x-fa ${(context.state.region) ? 'fa-check-square' : 'fa-square'}`,
            html: `<label key="region_label">${'фильтр по району'}</label>`,
            handler: (sender) => {
                context.setState({region: !context.state.region});
                context.reloadFunc();
            }
        })
    }



}

