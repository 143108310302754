import ErrorBoundary from "../ErrorBoundary";
import React, {Component} from 'react';

import {
    Panel,
    FormPanel,
    Textfield,
    PasswordField,
    Button,
    Toolbar,
} from '@sencha/ext-react-modern';
import * as f from "../common/Funcs";

const Ext = window['Ext'];
const keyName = `Login`;

class Login extends Component {

    constructor(props) {
        super(props);
        const context = this;
        context.state = {
            content: {data: [], title: ''}
        };
    }

    /*смена страницы*/
    rePage(newPageName) {
        const context = this;
        context.props.appViewPort.rePage.call(context.props.appViewPort, newPageName);
    }

    update() {
    }

    render() {
        const context = this;
        if (window.IasConfig.devMode) console.debug("render Login", context.props, context.state);
        //очистить куки
        document.clearCookies();
        const login = (sender) => {
            const requestOptions = {
                method: 'POST',
                //     mode:'no-cors',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(Ext.ComponentQuery.query("formpanel")[0].getValues())
            };
            document.setCookies();
            fetch(`${window.IasConfig.homePath}users/login`,
                requestOptions
            ).then(res => {
                    return res.json();
                }
            )
                .then(
                    (result) => {
                        if (result.status) {
                            document.setCookie('userfio',result.userfio);
                            f.toast({
                                title: 'Вход в систему', message: `Добро пожаловать, ${result.userfio}`
                            });

                            const prevPageName=window.IasLoApp.nextPage||'Index';
                            context.rePage(prevPageName);
                            // document.setCookie('nextPage','Index');
                        } else if (result.blockedtime) {
                            //сообщение об ошибке
                            f.alert({
                                title: 'Вход в систему',
                                message: `Данная учетная запись временно заблокирована. Доступ будет возможен через ${result.blockedtime}`
                            });
                        } else if (result.a==false) {
                            //сообщение об ошибке
                            f.alert({
                                title: 'Вход в систему',
                                message: `Данная учетная запись отключена. Обратитесь к администратору ресурса.`
                            });
                        } else if (result.status == false) {
                            f.alert({
                                title: 'Вход в систему',
                                message: `Учетная запись с данным логином/паролем не обнаружена. Доступ к системе не возможен.`
                            });
                            //сообщение об ошибке логина или пароля. Остаемся на странице, сбрасываем пароль
                        } else if (result.flash) {
                            f.flashMessages(result);
                        }
                        return result;
                    },
                    (error) => {
                        debugger;
                        return error;
                    })
        };
        const geturl=()=>context.rePage('Url');
        return <Panel
            key={"loginPage"}
            height={f.getCentralHeight()}
            width={200} region={"center"} margin="3">
            <FormPanel method={"POST"} key={"loginForm"} id={"loginForm"} title="Авторизация">
                <Textfield id={'loginField'} name={'login'} label="Имя пользователя (логин)/email/номер телефона"
                           required
                           placeholder="Это обязательное поле"/>
                <PasswordField name="password" id={'pasField'} label="Пароль" required
                               placeholder="Это обязательное поле" listeners={{
                    keyup: (sender, event) => {
                        if (event.altKey == false &&
                            event.ctrlKey == false &&
                            event.keyCode == 13) login(sender);
                    }
                }}/>
                <Toolbar shadow={false} docked="bottom" layout={{type: 'hbox', pack: 'right'}}>
                    <Button text="Вход" handler={login}/>
                    <Button text="Забыл пароль" handler={geturl}/>
                </Toolbar>
            </FormPanel>
            <Panel docked={'bottom'} key={'forHU'} html={context.props.globalContext?.reg_text}></Panel>
        </Panel>
    }
}

export default Login;