import BasePageGrid from "../../Base/BasePageGrid";
import ErrorBoundary from "../../ErrorBoundary";
import React from "react";

const keyName = 'SessionsGrid';
export default class SessionsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        context.idName = 'log_id';
        context.hiddenColumns = [context.idName];
        context.columnsDefaults = [
            {column_name: 'u_name',title:true, flex: {grow: 2}, pos: 0},
            {
                column_name: 'op_name', flex: {grow: 2}, pos: 3
            },
            {column_name: 'modified', minWidth: 150, pos: 2},
            {column_name: 'record_id', pos: 4,hidden:true}
        ]
        context.pageSize = 300;
    }

    //для колонки редакции. Проверка на колонку, проверка прав, значок
    rndFunc(value) {
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} rndFunc`);
        const editButton = context.editButton(value);
        return [editButton];
    }

}
