import React from 'react';
import {
    Panel,
    Button,
} from '@sencha/ext-react-modern';

import * as f from "../common/Funcs";
import BaseGridPage from "./BaseGridPage";
import SimpleMap from "../Maps/SimpleMap";
import style from "../Maps/styles/mappings";
import ErrorBoundary from "../ErrorBoundary";

const keyName = `BaseByMapPage`;

export default class BaseByMapPage extends BaseGridPage {

    constructor(props) {
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        //очистка фильтра при загрузке, иначе путается с мониторингом и реестром
        super(props);
        const context = this;
        context.paging = true;
        context.styleFunction=style;
        context.filters = [
            {name: 'from',pos:1, required: true, message: f.locale('needfrom')},
            {name: 'to',pos:2, required: true, message: f.locale('needto')},
            {name: 'region',pos:3, required: false,gisSearch:true},
            {name: 'user',pos:4, required: false},
            {name: 'meetspec',pos:5, required: false},
            {name: 'male',pos:6, required: false},
            {name: 'age',pos:7, required: false},
            {name: 'fio',pos:8, required: false},
            {name: 'char',pos:9, required: false},
            {name: 'page',pos:60, required: false}
        ]
        context.gridName = "BaseByMapGrid";
        context.tableName = 'meetings';
        context.subElementName = 'BaseEditByMapPage';
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName];
    }

    //кнопка zoom на все
    addZoomButton() {
        const context = this;
        const key = 'zoombutton';
        if (window.IasConfig.devMode) console.debug(`${keyName} ${key}`);
        const zoomFunc = () => {
            if (window.IasConfig.devMode) console.debug("zoomFunc", arguments);
            // context.olMap.gotoFeatures({context:context.olMap,ids: context.grid.store.getData().items.map(i => i.data.row_id), zoom: 8})
            context.olMap.gotoFeatures({context: context.olMap, zoom: 7})
        };
        context.topMenuButtons.push(<Button
            ui={window.IasConfig.ui}
            key={key}
            name={key}
            iconCls={'x-fa fa-home'}
            handler={zoomFunc}/>)
        //context.setState({manuButtons:context.topMenuButtons});

    }

    //кнопка новой записи в таблицу
    //$new,$button,$huntusers,$grid,$call
    addSelectButton() {
        const context = this;
        const key = 'selectbutton';
        if (window.IasConfig.devMode) console.debug(`${keyName} ${key}`);
        const clearFunc = () => {
            if (window.IasConfig.devMode) console.debug("selectFunc", arguments);
            context.rowId = context.grid.getCurrentId();
            context.olMap.clearSelection(context.olMap);
        };
        context.topMenuButtons.push(<Button
            ui={window.IasConfig.ui}
            key={key}
            name={key}
            iconCls={'x-fa fa-circle'}
            handler={clearFunc}/>)
        //context.setState({manuButtons:context.topMenuButtons});

    }

    addAdditButtons(props) {
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} addtopMenuButtons`, props, context.props.rights);
        const rights = context.props.rights || document.getCookie('rights');
//        if (['a','u','d'].indexOf(rights)>-1 )
//         context.addAddButton();
        context.addZoomButton();
        // context.addSelectButton();
    }

    render() {
        const context = this;
        //тело таблицы
        const grid = context.getGrid(context);
        const editMenu = context.getEditMenu(context);
        const dialog = (context.getDialog) ? context.getDialog(context) : '';
        return (<ErrorBoundary  key={`error${keyName}`}><Panel
            cls={'print-100-percent by-map-panel'}
            height={f.getCentralHeight()}
            region={"center"} margin="3"
            key={`gridPanel${keyName}`}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}>
            <Panel docked={"bottom"} height={'50%'} width={"100%"} key={'gridPanel'}>
                {editMenu}
                {grid}
                {dialog}
            </Panel>
            <Panel docked={"top"} height={'50%'} width={"100%"} key={'mapPanel'}>
                <SimpleMap key={'simpleMap'} parent={context} appViewPort={context.props.appViewPort} width={'100%'} styleFunction={context.styleFunction}
                           ref={(m => context.olMap = m)}/></Panel>
        </Panel></ErrorBoundary>)
    }
}

