import ErrorBoundary from "../../ErrorBoundary";
import BaseSubGrid from "../../Base/BaseSubGrid";

const keyName=`StaffSubGrid`;

export default class StaffSubGrid extends BaseSubGrid {
    constructor(props) {
        super(props);
        const context=this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.tableName = 'economy_staff';
        //элемент окна редактирования
        context.idName = 'staff_id';
        context.hiddenColumns = [context.idName,'staff_economy'];
    }

}