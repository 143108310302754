import React,{Component} from "react";
import BaseZmuPage from "../../../Base/ZMU/BaseZmuPage";
import {Panel} from "@sencha/ext-react-modern";
import * as g from "../../../common/gisFuncs";
import * as f from "../../../common/Funcs";
import ZmuMap from "../../../Maps/ZMU/ZmuMap";
import BaseGridPage from "../../../Base/BaseGridPage";

const keyName = `ZmuCorrectPage`;
const title=`Зимний маршрутный учет. Маршруты с отклонениями от плана при прохождении.`;
export default class ZmuCorrectPage extends BaseGridPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName="CorrectGrid";
        context.subElementName = 'RoutePassedPage';
        context.tableName = 'passages';
        context.topMenuButtonNames = ['xls', 'html', 'reload', 'clipboard'];
        context.filters =[
            {name:'year',required:true, message: f.locale('needyear')},
            {name:'num',label:'Отклонение в метрах',required: true,message: 'необходимо указать отклонение от маршрута в метрах'},{name:'users'},
            {name:'region'},
            {name:'it'}
        ];
        //     if (context.props.appViewPort.filters?.YearFilter) context.props.appViewPort.filters.YearFilter.setValue((new Date()).getFullYear() + ((new Date()).getMonth() > 5 ? 1 : 0));
    //    context.olMap='';
    }


}