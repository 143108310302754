import BaseGrid from "./BaseGrid";
import * as f from "../common/Funcs";


export default class BasePageGrid extends BaseGrid {
    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug("BasePageGrid constructor", arguments);
        document.setCookie('pageFilter', 1);
    }

    getData(params) {
        const {context,callback}=params;
        if (window.IasConfig.devMode) console.debug(window.funcName());
        context.store.loadRecords([]);
        context.inload=true;
        window.mask();
        f.getPageData({
            context: context.props.appViewPort,
            np:context.props.parent.filters.filter(f=>f.name=='page').length==0,
            keyName: context.props.appViewPort.state.pageName,
            pageSize: context.pageSize,
            filtersOn: true,
            callback: (result) => {
                context.isLoaded = true;
                context.inload=false;
                if (result.columns) {
                    result.columns = result.columns.map(c => {
                        if (context.columnsDefaults) {
                            const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                            c = {...c, ...def};
                        }
                        return c;
                    }).sort((a, b) => {
                        return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                    });
                    // context.setState({columns: result.columns,data:result.data});
                    const upData={columns: result.columns,vdata:result.data,error:result.flash};
                    context.afterGetData(context,upData);
                    if (callback) callback(result);
                    window.unmask();
                }
            }
        })
    }

    //выделить дочерний объект
    recordChild(context) {
        const id = context.getCurrentId();
        document.setCookie(context.props.tableName, null);
        context.props.parent.rowId = null;
        //задаем предварительные значения для полей
        context.props.parent.setState({pageDefaults: [{column_name: 'parent_id', value: id, disabled: true}]});
        context.props.parent.showEditPanel(context.props.parent,true);
        //}
    }

}