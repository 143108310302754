import ErrorBoundary from "../../ErrorBoundary";
import BaseEditPage from "../../Base/BaseEditPage";
// import * as f from "../../common/Funcs";

export default class ContactRelPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("ContactRelPage constructor", arguments);
        const p= props.parent.props.subElementParams;
        context.tableName =p.tableName||'huntusers';
        context.idName = 'row_id';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.footerButtonNames = ['saveclose', 'save','delete', 'close'];
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {"column_name":p.contactName,disabled:true,pos:0,row:1,
                is_list: true,
                source: {
                    tableName: 'viewcontacts',
                    titleField: 'fio_name',
                    idField: 'contact_id'
                },
            },
            {"column_name":p.ownerName,disabled:true,pos:0,row:1,
                is_list: true,
                source: {
                    tableName: p.ownerTable,
                    titleField: p.ownerTitle,
                    idField: p.ownerId
                },
            },
            //{"column_name":"contact_id",pos:0,row:0},
        ]];
        context.subTables = [
        ]
    }
}


