import React from "react";
import BaseSubGrid from "../../Base/BaseSubGrid";
import * as f from "../../common/Funcs";


const keyName = 'BiotopsSubGrid';

export default class BiotopsSubGrid extends BaseSubGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'ContactPage';
        context.tableName = 'land_biotops_view';
        context.idName = 'bt_land';
        context.filterString = "bt_land=$land_id$";
        context.pageSize = 300;
        context.paging = false;
        context.hiddenColumns = [context.idName, 'bt_type'];
        context.columnsDefaults = [{column_name: 'category', minWidth: 250},{column_name: 'class', minWidth: 250},{column_name: 'land_bt_area', minWidth: 250},{column_name:'rights',hidden:true}];

    }




    //получение данных. если repeat, сначал пересчет
    /*   getData(params) {
           const {context, repeat = false} = params;
           context.store.clearData();
           if (!context.props.rowId || context.props.rowId == -1) return;
           const filterString = context.getFilterStringFromRow();
           if (filterString.indexOf('undefined') > -1) return;
           f.getViewData(
               {
                   context: context.props.appViewPort, viewName: context.tableName, filterString: filterString,
                   callback:
                       (result) => {
                           context.isLoaded = true;
                           result.columns = result.columns.map(c => {
                               c.flex = {grow: 2};
                               if (context.columnsDefaults) {
                                   const def = context.columnsDefaults.filter(d => d.column_name == c.column_name)[0];
                                   c = {...c, ...def};
                               }
                               return c;
                           }).sort((a, b) => {
                               return !((f.exist(a.pos) ? a.pos : 50) > (f.exist(b.pos) ? b.pos : 50)) ? -1 : 1
                           });
                           const upData = {columns: result.columns, data: result.data};
                           if (context.props.parent.subPanel)
                               if (result.data.length == 0) {
                                   context.props.parent.subPanel.collapse();
                               } else {
                                   context.props.parent.subPanel.expand();
                               }
                           context.store.loadRawData(result.data);
                           context.setState(upData, () => {
                           });
                       }
               })

       }*/
}