import ErrorBoundary from "../../../ErrorBoundary";
import React from "react";
import BaseZmuGrid from "../../../Base/ZMU/BaseZMUGrid";
const keyName='EaGrid';

export default class EaGrid extends BaseZmuGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'EaPage';
        context.rowNumberWidth='40px';
        context.tableName = `ea_table`;
        context.idName='area_id';
        context.hiddenColumns=['area_id','acc_year']
        context.columnsDefaults=[
            {column_name:'area_name',pos:1,minWidth:120,title:true},
            {column_name: "fst_ga",hidden:true},
            {column_name: "fld_ga",hidden:true},
            {column_name: "bog_ga",hidden:true},
            {column_name: "geom",hidden:true},
    ]
    }
    update(context) {
        // if (!context.state.eaType) return;
        context.getData({context});
    }

    /*легенда*/
    resultUpdate(result) {
        result.columns.unshift({
            column_name: 'colorfield',
            data_type:'color',
            color_field:'area_id',
            pos: 0,
        });
        return result;
    }

    getHeight() {
        const context=this;
        const baseHeight=super.getHeight();
        // const eaTypeHeight=context.props.parent.eapanel?.panel?.cmp?.bodyElement?.dom.clientHeight||0;
        const obHeight=context.props.parent?.objectsGrid?.cmp?.bodyElement?.dom?.clientHeight||0;
        console.log('oea height',obHeight,baseHeight);
        // return (obHeight)?(baseHeight-obHeight-eaTypeHeight-20):(baseHeight-eaTypeHeight-20);
        return (obHeight)?(baseHeight-obHeight-20):(baseHeight-20);
    }
    gridSelected(context, grid, selected) {
        if (!context.olMap) context.olMap = context.props.parent.olMap;
        context.olMap.select({filter:{object_ea:Number(selected[0].data.area_id)}, context: context.olMap});
    }

}