import React from 'react';
import * as f from "../../common/Funcs";
import { Panel} from "@sencha/ext-react-modern";
import BaseByMapPage from "../BaseByMapPage";
import style from "../../Maps/styles/zmu";
import * as z from "../../common/zmuFuncs";
import ErrorBoundary from "../../ErrorBoundary";

const keyName = `BaseZmuPage`;

export default class BaseZmuAccPage extends BaseByMapPage {
    static defaultProps = {acc: 'acc'};

    constructor(props) {
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        //очистка фильтра при загрузке, иначе путается с мониторингом и реестром
        super(props);
        const context = this;
        z.setYear(context);
        document.setCookie('yearFilter',
            (new Date()).getFullYear());
        context.filters = [{
            name: 'year',pos:1,
            required: true,
            message: f.locale('needyear')
        }, {name: 'users',pos:3}, {name: 'region',pos:2}, {name: 'it',pos:4},{name:'num',pos:5}, {name: 'page',pos:60}];
        context.topMenuButtonNames = ['xls', 'html', 'reload', 'clipboard'];
        context.styleFunction = style;
        context.state = {
            eaTypeData: [],
            eaType: null,
            accYear: null,
            currentId: null
        };
        //меню страницы
    }

    componentDidMount() {
        const context = this;
         // super.componentDidMount();
        const app = context.props.appViewPort;
        app.setState({filters: context.filters, rightWidth: context.rightWidth},
            () => {
                // context.getAccYear(context, document.getCookie('yearFilter'));
                app.setState({filters: context.filters, rightWidth: context.rightWidth}, () => {
                    context.filters.map(k =>
                        app.filters[`${k.name.firstToUpper()}Filter`]?.setState({required: k.required}));
                });
                 // if (app.autoload) context.update(context);
                context.setState({accYear: Number(document.getCookie('yearFilter'))});
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context = this;
        const s = context.state;
        if (prevState.accYear != s.accYear) {
            context.state.eaType = '';
            context.getAccYear(context, s.accYear,()=>{
                    if (context.grid&&context.props.appViewPort.autoload) context.grid.getData({context:context.grid});
            });
        }
    }

    styleFunction(feature, resolution) {
        return style(feature, resolution);
    };

    //есть ли права на запиcь
    isEditRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.plan_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (!state) && f.isEditRight(rights);
    }

    //есть ли права на добавление
    isAddRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.plan_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (!state) && f.isAddRight(rights);
    }

    //есть ли права на удаление
    isDropRight() {
        const context = this;
        const state = (context.props.appViewPort?.state?.zmu?.plan_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (!state) && f.isDropRight(rights);
    }

    //кнопки для меню
    addAdditButtons() {
        if (window.IasConfig.devMode) console.debug(`${keyName} addAdditButton`);
        const context = this;
    }

    update(context) {
        const upFunction=()=>{
            const f = context.filters?.filter(p => p.required && (!document.getCookie(`${p.name}Filter`)));
            if (context.grid)
                if (f.length == 0) {
                    context.setState({accYear: null}, () => context.setState({accYear: Number(document.getCookie('yearFilter'))}, () => {
                        context.grid.update(context.grid)
                    }));
                } else {
                    context.grid.setState({columns: []});
                }
        }
        if (context.props.appViewPort.state.zmu?.acc_year!=document.getCookie('yearFilter')) {
            context.getAccYear(context,document.getCookie('yearFilter'),upFunction)
        }
        else {
            upFunction();
        }

    }

    //запрос года.
    getAccYear(context, accYear, callback) {
        if (window.IasConfig.devMode) console.debug(window.funcName());
        const callback1 = (result) => {
            context.applyYear(context, result, callback)
        }
        if (accYear == null) return callback1();
        z.getAccYear({
            context, accYear: Number(accYear), callback: callback1
        });
    }

    applyYear(context, result, callback) {
        //пустой год
        if (window.IasConfig.devMode) console.debug(window.funcName());
        if (1 == 0 && !context.state?.eaTypeLoaded) {
            //запрос справочника - принцип формирования
            f.getRefData({
                context: context,
                tableName: 'ea_type',
                titleField: 'ea_type_name',
                idField: 'ea_type_id',
                filterString: '',
                callback: (refData) => {
                    if (refData.data && refData.data.length) {

                        if (context.editForm) context.editForm.setState({eaType: context.state.eaType});
                        context.setState({
                            eaTypeData: refData.data,
                            eaTypeLoaded: true,
                            accYearData: context.state.accYearData,
                            rights: context.state.rights,
                            eaType: context.state.eaType
                        }, () => {
                            if (context.eatype) context.eatype.cmp.setValue(context.state.eaType);
                            if (callback) callback(refData);
                        });
                    } else
                        context.setState({
                            eaTypeData: [], eaTypeLoaded: false, accYearData: context.state.accYearData,
                            rights: context.state.rights,
                            eaType: context.state.eaType
                        }, () => {
                            if (callback) callback(refData);
                        });
                }
            })
        }
        if (!result) {
            return context.setState({
                accYearData: {},
                rights: 'v',
                eaType: null
            });
        }
        if (!(result.data?.acc_year)) return f.flashMessages('нет данных по году');
        const rights = result.rights;
        // context.stopUpdate=true;
        if (context.editForm) context.editForm.setState({eaType: result.data.ea_type});
        if (context.olMap) {
            {
                context.olMap.update();
            }
        }
        context.setState({
            accYearData: result.data,
            rights: result.rights,
            eaType: result.data.ea_type,
            accYear: Number(result.data.acc_year)
        }, (res) => {
            context.subElementParams = {eaType: result.data.ea_type};
            if (callback) callback();
        })
    }

    render() {
        const context = this;
        //тело таблицы
        const grid = React.createElement(context.getGridType(context).el, {
            parent: context,
            appViewPort: context.props.appViewPort,
            key: context.gridName,
            name: context.gridName,
            height: '100%',
            context: context,
            ref: (grid) => context.grid = grid
        });
        const editMenu = context.getEditMenu(context);
        const dialog = (context.getDialog) ? context.getDialog(context) : '';
        const p = context.getGridType(context);
        return (<Panel
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
            height={f.getCentralHeight()}
            region={"center"} margin="3"
            key={`gridPanel${keyName}`}
        >
            {editMenu}
            {grid}
            {dialog}
        </Panel>)
    }
}

