import ErrorBoundary from "../ErrorBoundary";
import React, { Component } from 'react';
import {ExtGrid, ExtGridcolumn, ExtActioncolumn, Panel, Button} from '@sencha/ext-react-modern';
import ExtColumn from "@sencha/ext-react-modern/dist/ExtColumn";
const Ext = window['Ext'];

export default class TestPage extends Component {

    store = Ext.create('Ext.data.Store', {
        autoLoad: true,
        fields: ['name', 'cuisine'],
        data: [
            { 'name': 'Lisa',    "cuisine":"555-111-1224"  },
            { 'name': 'Bart',    "cuisine":"555-222-1234" },
            { 'name': 'Homer',   "cuisine":"555-222-1244"  },
            { 'name': 'Marge',  "cuisine":"555-222-1254"  }
        ],
    });

    helperTpl = data => (
        <ul>
            { data.group.items.map((item, i) => <li key={i}>{item.data.name}</li>) }
        </ul>
    )

    //-----------------------------------------------
    //Cell actions:
    onGear = (grid, info) => {
        Ext.Msg.alert('Settings', `Change settings for ${info.record.data.name}`);
    }

    onPin = (grid, info) => {
        Ext.Msg.alert('Pin', `Pinned item ${info.record.data.name}`);
    }

    onSearch = (grid, info) => {
        Ext.Msg.alert('Search', `Search for item ${info.record.data.name}`);
    }

    //-----------------------------------------------
    //Group actions:
    onGroupPrint = (grid, info) => {
        this.doGroup(info, 'Print Group');
    }

    onGroupRefresh = (grid, info) => {
        this.doGroup(info, 'Refresh Group');
    }

    onGroupSave = (grid, info) => {
        this.doGroup(info, 'Save Group');
    }

    doGroup = (info, action) => {
        Ext.Msg.alert(action, this.helperTpl.apply({ group: info.group }));
    }

    //кнопка экспорта в xls
    addXlsButton() {
        if (window.IasConfig.devMode) console.debug("BaseGridPage xlsButton");
        const context = this;
        const key = 'xlsbutton';
        context.topMenuButtons.push();
    }

    render() {
        const exportFunc = () => {
            this.grid.cmp.saveDocumentAs({
                type: 'xlsx',
                title:'test',
                fileName: `${'test'}.xlsx`
            });
        }
        return (
            <Panel>
                <Panel
                    layout="hbox" shadow docked={"top"}>
                    {<Button
                        ui='raise'
                        iconCls={'x-fa fa-file-excel-o'}
                        handler={exportFunc}/>}
                </Panel>
            <ExtGrid title="Grid Tools"
                  store={this.store}
                     ref={(grid)=>this.grid=grid}
                     height={400}
                     plugins={{
                         gridfilters: true,
                         gridexporter: true
                     }}
            >
                <ExtColumn
                    text="Name"
                    dataIndex="name"
                    flex={1}
                    maxWidth="200"
                />
                <ExtColumn
                    text="Cuisine"
                    dataIndex="cuisine"
                    flex={1}
                    maxWidth="150"
                />
                <ExtColumn
                    text="Actions"
                    width="80"
                />
            </ExtGrid></Panel>
        )
    }
};

