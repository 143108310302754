const getUnikey = (params) => {
    let {tableName, actualDate, filter} = params;
    if (!tableName) return;
    let key = tableName;
    if (actualDate) key += actualDate.replace(/\W/g,'');
    if (filter) key += `?${filter}`;
    return key;
}

export const getCache = (params) => {
    const unikey = getUnikey(params);
    if (!window.IasLoApp?.cache)
        return false;
    return window.IasLoApp.cache[unikey];
};

export const setCache = (params, result) => {
    const unikey = getUnikey(params);
    if (!window.IasLoApp) return;
    if (!window.IasLoApp.cache)  window.IasLoApp.cache={};
    window.IasLoApp.cache[unikey] = result;
};

export const deleteCache = (params, result) => {
    const unikey = getUnikey(params);
    window.IasLoApp.cache[unikey] = null;
};


