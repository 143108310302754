import React, {Component} from 'react';
import * as f from "../../common/Funcs";
import * as g from "../../common/gisFuncs";
import style from "../styles/zmu";
import landstyle from "../styles/landspol";
import admstyle from "../styles/admpol";
import ZmuMap from "./ZmuMap";
import ErrorBoundary from "../../ErrorBoundary";

const keyName = "ZmuEaMap";

export default class ZmuEaMap extends ZmuMap {
    // answer
    static defaultProps={
        mapButtonNames:[],
        layerNames :[
        {
            layerName: 'landspol', hidden: false, type: 'own', styleFunction: landstyle
        },
        {
            layerName: 'admpol', hidden: false, type: 'own', styleFunction: admstyle
        },
    ],}
    constructor(props) {
        super(props);
        const context = this;
        //geomType= 'Point', 'LineString', 'LinearRing', 'Polygon', 'MultiPoint', 'MultiLineString', 'MultiPolygon', 'GeometryCollection', 'Circle'.
        context.geomType = 'LineString';
        context.state.legendOn = false;
        if (props.styleFunction) context.styleFunction = props.styleFunction; else context.styleFunction=style;
    }

    componentDidMount() {
        super.componentDidMount();
        // this.update(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const context=this;
        // if (context.state.tableName!=prevState.tableName) context.getData(context);

    }

    getData(params) {
        const {context}=params;
        const olMap=context.olMap;
        const callback=(result)=>{
            context.clearFeatures({context: context});
            context.addFeatures({geoJson: result.features, context: context});
        }
        const prms= {context,cache:false, tableName:`ea_${context.state.tableName}`, actualDate:`01.01.${document.getCookie('yearFilter')}`, callback};
        g.getGisData(prms);
    }

    getHeight() {
        return f.getCentralHeight();
    }

    getAdditControls() {
        return [];
    }
}
