import {Circle as CircleStyle, Fill, Style} from "ol/style";

const keyName='dtppnt.js';
export default (feature,resolution) => {
 if (window.cssOn) console.debug(`${keyName} css`,feature);
    const props = feature.getProperties()
    const specId = props.spec_id || props.meeting_spec;
    const radius = 4;
    const colors = {
        8801: [144, 255, 144, 1],
        8804: [224, 255, 50, 1],
        8818: [230, 170, 20, 1],
        8800: [245, 105, 105, 1],
        8842: [255, 105, 215, 1],
        8805: [125, 125, 125, 1]
    };
    const w = new Style({
        image: new CircleStyle({
            radius: radius + 1,
            fill: new Fill({
                color: [0, 0, 0, 1],
            }),
        }),
    });
    const p = new Style({
        image: new CircleStyle({
            radius: radius,
            fill: new Fill({
                color: colors[specId] || [100, 100, 255, 1],
            }),
        }),
    });
    return [w, p];
}