import React from 'react';
import BaseRelationSub from "../BaseRelationSub";
import * as f from "../../common/Funcs";

const keyName = `BasePassageTableSub`;

export default class  BasePassageTableSub extends BaseRelationSub {

    constructor(props) {
        super(props);
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        const context = this;
        context.topMenuButtonNames=['reload','add','edit'];
        context.gridName= props.gridName||`PasTableSubGrid`;
        context.idName = props.idName || 'passage_id';
        context.noSingle=true;
    }
    isEditRight() {
        const context=this;
        const rights = context.props.rights;
      //  const subElementName=context.props.parent.props.parent.state.subElementName;
        return  !context.props.readonly&& f.isEditRight(rights);
      //  Boolean(subElementName) &&
    //    !context.props.readonly&& f.isEditRight(rights);
    }

}

