import ErrorBoundary from "../../ErrorBoundary";
import React from "react";
import BaseByMapGrid from "../../Base/BaseByMapGrid";
import BasePageGrid from "../../Base/BasePageGrid";
const keyName='MappingsGrid';

export default class MappingsGrid extends BaseByMapGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // context.subElementName = 'DocumentPage';
        context.tableName = `mappnt`;
        context.idName = 'row_id';
        context.hiddenColumns=[context.idName];
    }

}