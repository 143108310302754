import ErrorBoundary from "../../../ErrorBoundary";
import React from "react";
import * as f from "../../../common/Funcs";
import {Panel} from "@sencha/ext-react-modern";
import ZmuMap from "../../../Maps/ZMU/ZmuMap";
import BaseZmuAccPage from "../../../Base/ZMU/BaseZmuAccPage";

const keyName = `ZmuPassagesPage`;
const title=`Зимний маршрутный учет. Ввод данных.`;
export default class ZmuPassagesPage extends BaseZmuAccPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.gridName="PassagesGrid";
        // context.subElementName = 'PassagePage';
        context.subElementName = 'PasAllPage';
        context.tableName = 'passages';
        context.idName = 'passage_id';
        context.hiddenColumns=[context.idName];
        if (!document.getCookie('yearFilter')) {
            const year = (new Date()).getFullYear();
            if (context.filters.YearFilter) context.filters.YearFilter.setValue(year);
        }
        context.filters = [{
            name: 'year',
            required: true,
            message: f.locale('needyear'),
            pos:10
        },
            {name:'ac',label:'только незаполненные',pos:0,defaultValue:false},
         //  {name:'editable',pos:},
            {name: 'region',pos:20},  {name: 'users',pos:30},{name: 'it',pos:40},{name:'num',pos:50}, {name: 'page',pos:60}];

        // context.state = {
        //     subElementName : 'RoutePage'
        // };
    }
    //есть ли права на добавление
    isAddRight() {
        const context = this;
        const state=(context.props.appViewPort?.state?.zmu?.passage_closed);
        const rights = this.state.rights || this.props.rights || document.getCookie('rights');
        return (!state) &&  f.isAddRight(rights);
    }
    render() {
        const context = this;
        //тело таблицы
        const grid = context.getGrid(context);
        const editMenu = context.getEditMenu(context);
        const k=context.state.subElementName;
        const dialog = (context.getDialog) ? context.getDialog(context) : '';
        const mapPanel=   <Panel docked={"right"} height={"100%"}  width={"50%"} key={'mapPanel'}>
            <ZmuMap key={'zmuMap'} parent={context} appViewPort={context.props.appViewPort} width={'100%'} styleFunction={context.styleFunction}
                    ref={(m => context.olMap = m)}/></Panel>;
        return (<Panel
            cls={'print-100-percent by-map-panel'}
            key={`gridPanel${keyName}`}
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
        >
            <Panel docked={"left"} width={"100%"} key={'gridPanel'}>
                {editMenu}
                {grid}
                {dialog}
            </Panel>
            {/*{mapPanel}*/}
        </Panel>)
    }

}