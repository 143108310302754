import React, {Component} from 'react';
import {CheckBoxField, Panel, Button} from "@sencha/ext-react-modern";
import * as f from "../common/Funcs";
import * as filters from "../collections/panelFilters";

const key = "RightPanel";

/*странице зависимые фильтры - дописать*/
class RightPanel extends Component {

    static defaultProps = {filters: [], width: 250,locales:{}}

    constructor(props) {
        super();
        const context = this;
        context.rightWidth = props.width;
        context.state = {filters: props.filters};
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (nextProps.filters !== this.props.filters||nextProps.globalContext!==this.props.globalContext);
    }

    getLoadButton(context) {
        const app = context.props.appViewPort;
        const apply = () => {
            document.setCookies();
            if (app.page?.update) app.page.update(app.page);
        }
        return <Panel layout={'fit'} width={'100%'} style={{paddingRight: '10px', marginTop: '5px'}}
        >
            <Button
                ui={window.IasConfig.ui}
                key={'applyButton'}
                name={'applyButton'}
                cls={'no-print filter-button'}
                text={'Применить фильтры'}
                handler={apply}
                listeners={{
                    mouseover: {
                        element: 'buttonElement',
                        fn: (e) => {
                            setTimeout(() => {
                                    const isFocused = e.currentTarget.matches(':hover');
                                    if (isFocused) {
                                        f.showTip(context, 'Загрузить данные с учетом фильтра', e);
                                    }
                                }, 1000
                            );
                        }
                    },
                    mouseout: {
                        element: 'buttonElement',
                        fn: () => {
                            f.hideTip();
                        }
                    }
                }}>
            </Button>
        </Panel>;
    }

    getAutoLoadButton() {
        const onChange = (val) => {
            const app = window.IasLoApp;
            app.autoload = val;
            document.setCookie('autoload', val);
            if (val)
                if (app.page?.update)
                    app.page.update(app.page);
        };
        return <CheckBoxField
            key={'autoloadcheckbox'} name={'autoloadcheckbox'} boxLabel={'Автозагрузка данных'}
            cls={'filter-check-box'}
            onChange={val => onChange(val.newValue)}
            checked={document.getCookie('autoload')}/>
    }

    render() {
        const context = this;
        const filterElements = [];
        //фильтр задан
        context.props.filters.sort((a, b) => (a.pos - b.pos)).map((f) => {
            const fName = `${f.name.firstToUpper()}Filter`;
            const en = {el: filters[fName]};
            const element = <en.el
                appViewPort={context.props.appViewPort}
                parent={context}
                hidden={f.hidden || false}
                gisSearch={f.gisSearch}
                nUpdate={f.nUpdate}
                required={f.required}
                label={f.label || context.state.globalContext?.locales?.[fName]}
                ref={f => {
                    context.props.appViewPort.filters[fName] = f;
                }}
                key={f.name}
                defaultValue={f.defaultValue}/>
            filterElements.push(element);
        });

        const autoloadButton = context.getAutoLoadButton(context);
        const applyButton = (filterElements.filter(f => !f.props.hidden).length > 0) ? context.getLoadButton(context) : '';
        console.debug('filters', filterElements);
        const height = f.getCentralHeight() - 5;
        return <Panel id={key} key={key}
                      bodyCls={'no-print right-filters-panel'}
                      layout={{
                          type: 'vbox'
                      }}
                      ref={(p => context.panel = p)}
                      height={height}
                      docked={"right"}
                      resizable={{
                          split: true,
                          edges: 'west'
                      }}
                      margin={`5 0 0 ${window.IasConfig.margin}`}
                      padding={`${window.IasConfig.margin}`}
        >
            {autoloadButton}
            {filterElements}
            {applyButton}
        </Panel>
    }
}

export default RightPanel;