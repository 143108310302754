import BasePageGrid from "../../../Base/BasePageGrid";
import React from "react";

const keyName = 'EventsGrid';
export default class EventsGrid extends BasePageGrid {

    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        context.subElementName = 'EventPage';
        context.idName = 'row_id';
        context.pageSize = 300;
        context.paging = false;
        context.hiddenColumns = [context.idName];
        context.columnsDefaults = [
            {column_name: 'event_object', title: true},
            {column_name: 'event_desc', title: true},
        ];

    }
    update(context) {
        context.getData({context});
    }

}