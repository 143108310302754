import ErrorBoundary from "../../ErrorBoundary";
import BaseAccPage from "../../Base/BaseAccPage";
import * as f from "../../common/Funcs";

const keyName = `StocksPage`;
export default class StocksPage extends BaseAccPage {
    static defaultProps={acc:'stock'};
    constructor(props) {
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, props);
        // document.dropCookie('stockFilter');
        super(props);
        const context = this;
        context.filters.push({name: 'stock', required: true, message: f.locale('needstock')});
        context.filters.push({name:'accspec'});
        context.accId = '2';
    }
}
