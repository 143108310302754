import ErrorBoundary from "../../ErrorBoundary";
import BaseEditPage from "../../Base/BaseEditPage";
import * as f from "../../common/Funcs";

export default class ContactPage extends BaseEditPage {
    constructor(props) {
        super(props);
        const context = this;
        if (window.IasConfig.devMode) console.debug("ContactPage constructor", arguments);
        context.tableName = 'contacts';
        context.idName = 'contact_id';
        context.titleName = 'fio';
        context.rowId = context.props.rowId || document.getCookie(context.tableName);
        context.footerButtonNames = ['saveclose', 'save', 'delete', 'close'];
        context.fieldsSet = [
            {title: 'Основная информация'},
        ];
        context.columnsDefaults = [...context.lastUserColumns,...[
            {"column_name": "num", pos: 0, row: 0},
            {"column_name": "family_name", pos: 1, row: 1},
            {"column_name": "first_name", pos: 2, row: 1},
            {"column_name": "second_name", pos: 3, row: 1},
            {
                pos: 0,
                column_name: 'contact_org',
                is_list: true,
                source: {
                    tableName: 'contact_org',
                    titleField: 'contact_org_name',
                    idField: 'contact_org_id',
                },
                row:2,
            },
            {"column_name": "post", pos: 4, row: 2},
            {"column_name": "address", pos: 4, row: 3},
            {"column_name": "contact_active", pos: 0, row: 0,defaultValue:true}
        ]];
        context.subTables = [
            {
                title: 'Телефоны контакта',
                elementName: 'BaseRelationSub',
                subName:'ContactPhonesSub',
                tableName: 'contactphones',
                subElementName: 'PhonePage',
                editWidth:'50%',
                relation: {tableName: 'contacts_phones', parentField: 'phone_contact', relField: 'contact_phone',idName:'phone_id'},
                filterString: "phone_contact=$contact_id$",
                noSingle:true,
                idName: 'phone_number',
                hiddenColumns: ['phone_id','phone_contact', 'phone_number'],
                columnsDefaults: [...context.lastUserColumns,...[{column_name: 'phone',flex:{grow:2}, title:true,pos:0},
                    {column_name: 'phone_comment',flex:{grow:2}, title:true,pos:0}]]
            },
            {
                title: 'E-mails контакта',
                elementName: 'BaseRelationSub',
                subName:'ContactEmailsSub',
                editWidth:'50%',
                tableName: 'contactemails',
                subElementName: 'EmailPage',
                relation: {tableName: 'contacts_emails', parentField: 'email_contact', relField: 'contact_email',idName:'email_id'},
                filterString: "email_contact=$contact_id$",
                noSingle:true,
                idName: 'email',
                hiddenColumns: ['email_contact','email_id'],
                columnsDefaults: [...context.lastUserColumns,...[{column_name: 'email',flex:{grow:2}, title:true,pos:0},
                    {column_name: 'email_comment',flex:{grow:2}, title:true,pos:1},
                    {column_name: 'email_number',hidden:true,data_type:'text'}
        ]]
            },
            {
                title: 'Адреса контакта',
                subName:'ContactAddressSub',
                elementName: 'BaseSubGridPanel',
                editWidth:'50%',
                tableName: 'contactaddress',
                subElementName: 'AddressPage',
                filterString: "address_contact=$contact_id$",
                noSingle:true,
                idName: 'address_id',
                hiddenColumns: ['address_contact','address_id'],
                columnsDefaults: [...context.lastUserColumns,...[
                    {column_name:'address_type',hidden:false,width:100,maxWidth:100,minWidth:100,pos:1},
                    {column_name: 'contact_address', flex:{grow:2},disabled:false,hidden:false,title:true,pos:0}]]
            },
            {
                title: 'Охотпользователи контакта',
                elementName: 'BaseRelationSub',
                editWidth:'50%',
                subName:'ContactUsersSub',
                topMenuButtonNames: ['reload','relation'],
                tableName: 'contactusers',
                subElementParams: {tableName:'huntusers_contacts',contactName:'huntuser_contact',ownerName:'contact_huntuser',ownerTable:'hunt_users',ownerTitle:'user_name',ownerId:'huntuser_id'},
                activeColumn:'active',
                subElementName: 'ContactRelPage',
                relation: {tableName: 'huntusers_contacts', parentField: 'huntuser_contact', relField: 'contact_huntuser',idName:'huntuser_id'},
                filterString: "huntuser_contact=$contact_id$",
                noSingle:true,
                idName: 'row_id',
                hiddenColumns: ['huntuser_contact','huntuser_id','row_id'],
                columnsDefaults: [...context.lastUserColumns,...[
                    {column_name:'active',pos:0,hidden:false},{column_name:'user_name',flex:{grow:2},title:true},{column_name:'post',flex:{grow:2},title:true}]]
            },
            {
                title: 'Охотхозяйства контакта',
                elementName: 'BaseRelationSub',
                editWidth:'50%',
                subName:'ContactEconomiesSub',
                topMenuButtonNames: ['reload','relation'],
                tableName: 'contactecos',
                subElementParams: {tableName:'economies_contacts',contactName:'eco_contact',ownerName:'contact_eco',ownerTable:'economies',ownerTitle:'economy_name',ownerId:'economy_id'},
                activeColumn:'active',
                subElementName: 'ContactRelPage',
                relation: {tableName: 'economies_contacts', parentField: 'eco_contact', relField: 'contact_eco',idName:'economy_id'},
                filterString: "eco_contact=$contact_id$",
                noSingle:true,
                idName: 'row_id',
                hiddenColumns: ['eco_contact','economy_id','row_id'],
                columnsDefaults: [...context.lastUserColumns,...[{column_name:'economy_name'},{column_name:'post',title:true}]]
            },
            {
                title: 'Пункты выдачи',
                elementName: 'BaseRelationSub',
                subName:'ContactPickUpSub',
                topMenuButtonNames: ['reload','relation'],
                tableName: 'contactpickups',
                subElementParams: {tableName:'pickup_contacts',contactName:'pickup_contact',ownerName:'contact_pickup',ownerTable:'pickup_voucher',ownerTitle:'pickup_address',ownerId:'pickup_id'},
                activeColumn:'active',
                subElementName: 'ContactRelPage',
                relation: {tableName: 'pickup_contacts', parentField: 'pickup_contact', relField: 'contact_pickup',idName:'pickup_id'},
                filterString: "pickup_contact=$contact_id$",
                noSingle:true,
                idName: 'row_id',
                hiddenColumns: ['pickup_contact','pickup_id','row_id'],
                columnsDefaults: [...context.lastUserColumns,...[
                    {column_name:'active',pos:0,hidden:false},{column_name:'pickup_address',flex:{grow:2}},{column_name:'post',flex:{grow:2},title:true}]]
            },
            {
                title: 'История изменений',
                elementName: 'CardLogSub',
                collapsed: true,
                noSingle: true,
                subElementName: 'LogPage',
                hidden: ()=>!f.isAdmin(),
                filterString: "table_name ='contacts' and record_id = $contact_id$::varchar",
            },
            {
                title: 'Однофамильцы',
                elementName: 'NamesakeSub',
                subElementName: 'ContactPage',
                filterString: "parent_id=$contact_id$",
                idName: 'contact_id',
                hiddenColumns: ['contact_id', 'num'],
                collapsed: true
            },
        ]
    }
}


